import useAuth from '@/composables/useAuth.js'
import useBlogPostLastSeen from '@/composables/useBlogPostLastSeen.js'
import useSystemGuidanceTrack from '@/composables/useSystemGuidanceTrack.js'

import { computed } from 'vue'

export default function useBottomNavigation () {
  const { apiKey, isCompanyAdminRegionOrAbove } = useAuth()
  const { getHaveUnreadMessages } = useSystemGuidanceTrack(apiKey.value)
  const { getHaveUnreadPosts, unreadPostsCount } = useBlogPostLastSeen(apiKey.value)
  const companyContext = computed(() => isCompanyAdminRegionOrAbove.value === true)
  const brokenItemRoute = computed(() => companyContext.value === true ? 'staff/company_faulty_devices' : 'account_faulty_devices')
  const chatRoute = computed(() => companyContext.value === true ? 'staff/chat' : 'chat')
  const blogRoute = computed(() => companyContext.value === true ? 'staff/blog' : 'blog')
  const accountsRoute = computed(() => 'company_stores')
  const menuRoute = computed(() => companyContext.value === true ? 'staff/menu' : 'menu')
  const showAccountsButton = computed(() => isCompanyAdminRegionOrAbove.value === true)

  const showMenuBadge = computed(() => {
    if (getHaveUnreadMessages.value === false) return false
    if (companyContext.value === true) return true
    return true
  })

  return {
    companyContext,
    getHaveUnreadPosts,
    unreadPostsCount,
    brokenItemRoute,
    chatRoute,
    blogRoute,
    accountsRoute,
    menuRoute,
    showAccountsButton,
    showMenuBadge
  }
}
