<template>
  <v-dialog v-model="dialog" max-width="300">
    <v-card>
      <v-card-title class="text-center pb-5 blue-grey lighten-5">
        <span class="text-h6 full-width">
          {{ $t('additional_allowed_jobs.title', { jobName }) }}
        </span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container>
          <div :class="{ rtl: isRtl }" class="black--text">
            <v-row
              v-for="(user, index) in additionlExecutors" :key="index"
              align="center" justify="center"
            >
              <v-col cols="12" class="red--text text-center">
                {{ user }}
              </v-col>
            </v-row>
          </div>
          <v-row
            no-gutters align="end" justify="end"
            class="mt-3 text-right"
          >
            <v-btn color="blue darken-1" text @click="close">
              {{ $t('close') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { isRtl } from '@/i18n.js'

import { ref } from 'vue'
export default {
  setup () {
    const dialog = ref(false)
    const jobName = ref(null)
    const additionlExecutors = ref([])

    const openDialog = ({ name, allowedExecutors = [] } = {}) => {
      dialog.value = true
      jobName.value = name
      additionlExecutors.value = allowedExecutors
    }

    const close = () => {
      dialog.value = false
    }
    return {
      dialog,
      jobName,
      additionlExecutors,
      isRtl,
      openDialog,
      close
    }
  }
}
</script>
