<template>
  <v-dialog v-model="dialog" max-width="550" persistent>
    <v-card :class="{ 'rtl': isRtl, 'ltr': !isRtl }" class="blog-create">
      <v-card-title class="text-center pb-4">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="3" class="texts-start">
            <v-btn icon color="black" @click="close">
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-center">
            <span class="t-600-18">
              {{ dialogTitle }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-0 bg-color16">
        <v-row
          align="start" justify="center" no-gutters
          class="py-4"
        >
          <v-col cols="11" class="row align-start justify-space-between mb-2">
            <v-col cols="6">
              <user-avatar size="30" show-name-and-avatar />
            </v-col>
            <v-col v-if="showAccountSelect" cols="6">
              <select-account-for-blog-post v-model="blogPost.account_api_key" />
            </v-col>
          </v-col>
          <v-col v-if="showTagsSelect" cols="10">
            <tag-chips-select
              v-model="blogPost.tags"
              class="tag-select" :max-tags="1"
              :api-key="apiKey"
              background-color="white"
              context="blog_post_tags"
            />
          </v-col>
          <v-col v-if="haveLinks" cols="10" class="my-4">
            <link-preview-loader v-model="blogPost.link" :url="firstLink" />
          </v-col>
          <v-col cols="10">
            <v-textarea
              v-model="blogPost.content"
              class="required"
              auto-grow
              rows="5"
              background-color="white"
              :rules="[requiredField]"
              counter="3000"
              :label="$t('blog_post_create_dialog.content_placeholder')"
            />
          </v-col>
          <v-col cols="10" class="d-flex align-start justify-start mt-6">
            <multiple-image-uploader
              v-if="isCreateMode"
              ref="multipleImageUploader"
              :max-images="6"
              icon="image"
              icon-color="#9E9E9E"
              :disabled="imageInputDisabled"
              @change="changeImageFileCount"
            />
            <video-input
              v-if="isCreateMode"
              v-model="attahcedVideo"
              class="pps-3"
              icon-color="#9E9E9E"
              :disabled="videoInputDisabled"
            />
            <v-btn
              class="mms-auto send-btn" :loading="isLoading"
              :disabled="isSaveDisabled"
              rounded
              color="#0E4AFD"
              @click="save"
            >
              <span class="mme-2">{{ $t('save') }}</span>
              <v-icon>send</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import useCompany from '@/composables/useCompany.js'
import useAuth from '@/composables/useAuth.js'
import useBlogPost from '@/composables/useBlogPost.js'

import { isFile, isObject, isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { requiredField } from '@/classes/InputValidators.js'

import { findAllLinks, hasAnyLinks } from '@/helpers/Url.js'
import { i18n, isRtl } from '@/i18n.js'

import { computed, ref } from 'vue'

const DialogMode = {
  create: 1,
  update: 2,
  updateReply: 3,
  reply: 4
}

export default {
  components: {
    'tag-chips-select': () => import('@/components/shared/tag_chips_select/TagChipsSelect.vue'),
    'select-account-for-blog-post': () => import('@/components/blog/SelectAccountForBlogPost.vue'),
    'multiple-image-uploader': () => import('@/components/shared/MultipleImageUploader.vue'),
    'video-input': () => import('@/components/shared/VideoInput.vue'),
    'link-preview-loader': () => import('@/components/shared/link_preview/LinkPreviewLoader.vue'),
    'user-avatar': () => import('@/components/shared/UserAvatar.vue')
  },
  setup (_, { emit }) {
    const dialog = ref(false)
    const isLoading = ref(false)
    const multipleImageUploader = ref(null)
    const attahcedVideo = ref(null)
    const imageFilesCount = ref(null)
    const dialogMode = ref(DialogMode.create)
    const { apiKey } = useAuth()
    const { isMultipleMemberships } = useCompany(apiKey.value)
    const { createPost, updatePost, newBlogPost } = useBlogPost(apiKey.value)

    const blogPost = ref(newBlogPost())
    const resetValues = () => {
      isLoading.value = false
      attahcedVideo.value = null
      blogPost.value = newBlogPost()
      imageFilesCount.value = null
      multipleImageUploader.value?.resetImages()
    }

    const onOpenDialog = (mode = DialogMode.create) => {
      dialog.value = true
      dialogMode.value = mode
    }

    const updateBlogPost = () => {
      resetLinksIfDeleted()
      updatePost({ post: blogPost.value, parentId: blogPost.value.parent_id })
        .catch(errHandler)
        .finally(close)
    }

    const createBlogPost = () => {
      const imageFiles = multipleImageUploader.value?.getImageFiles()
      resetLinksIfDeleted()
      if (!showAccountSelect.value) {
        blogPost.value.account_api_key = apiKey.value
      }
      createPost({ blogPost: blogPost.value, imageFilesCount: imageFilesCount.value, imageFiles, videoFile: attahcedVideo.value })
        .then(() => multipleImageUploader.value?.resetImages())
        .catch(errHandler)
        .finally(close)
    }
    const resetLinksIfDeleted = () => {
      if (haveLinks.value === true) return

      blogPost.value.link = {}
    }
    // Methods
    const openDialog = onOpenDialog
    const changeImageFileCount = (count) => (imageFilesCount.value = count)

    const openDialogForReply = (parentPost) => {
      blogPost.value.parent_id = parentPost.id
      onOpenDialog(DialogMode.reply)
    }

    const openDialogForUpdate = ({ post, parentId = null }) => {
      blogPost.value.id = post.id
      blogPost.value.content = post.content
      blogPost.value.parent_id = parentId
      if (isObject(post.link)) {
        blogPost.value.link = JSON.parse(JSON.stringify(post.link))
      }
      if (Array.isArray(post.tags) && post.tags.length > 0) {
        blogPost.value.tags = JSON.parse(JSON.stringify(post.tags))
      }
      onOpenDialog(Number.isInteger(parentId) ? DialogMode.updateReply : DialogMode.update)
    }

    const close = () => {
      dialog.value = false
      resetValues()
    }

    const save = () => {
      isLoading.value = true
      if (isCreateMode.value || dialogMode.value === DialogMode.reply) {
        return createBlogPost()
      } else {
        updateBlogPost()
      }
    }

    // Computed
    const isValidApiKey = computed(() => {
      if (!showAccountSelect.value) return true
      const blogPostVal = blogPost.value
      return blogPostVal.account_api_key === null || (isString(blogPostVal.account_api_key) && blogPostVal.account_api_key.length > 0)
    })

    const validBlogPost = computed(() => {
      if (isValidApiKey.value !== true) return false
      if (!isString(blogPost.value.content)) return false
      return blogPost.value.content.length > 0 && blogPost.value.content.length <= 3000
    })
    const isSaveDisabled = computed(() => isLoading.value || validBlogPost.value === false)
    const isCreateMode = computed(() => dialogMode.value === DialogMode.create)
    const showAccountSelect = computed(() => isCreateMode.value && isMultipleMemberships.value)
    const showTagsSelect = computed(() => isCreateMode.value || dialogMode.value === DialogMode.update)
    const showSelectAllAccounts = computed(() => isMultipleMemberships.value && isCreateMode.value)
    const videoInputDisabled = computed(() => isLoading.value || (Number.isInteger(imageFilesCount.value) && imageFilesCount.value > 0))
    const imageInputDisabled = computed(() => isLoading.value || isFile(attahcedVideo.value))
    const haveLinks = computed(() => isString(blogPost.value.content) && hasAnyLinks(blogPost.value.content))

    const firstLink = computed(() => findAllLinks(blogPost.value.content)[0])
    const dialogTitle = computed(() => {
      if (dialogMode.value === DialogMode.create) {
        return i18n.t('blog_post_create_dialog.new_post_title')
      } else if (dialogMode.value === DialogMode.reply) {
        return i18n.t('blog_post_create_dialog.new_comment_title')
      } else if (dialogMode.value === DialogMode.update) {
        return i18n.t('blog_post_create_dialog.post_update_title')
      } else {
        return i18n.t('blog_post_create_dialog.comment_update_title')
      }
    })

    return {
      dialog,
      isLoading,
      blogPost,
      multipleImageUploader,
      isSaveDisabled,
      showSelectAllAccounts,
      dialogTitle,
      isCreateMode,
      showTagsSelect,
      videoInputDisabled,
      imageInputDisabled,
      attahcedVideo,
      showAccountSelect,
      isRtl,
      haveLinks,
      firstLink,
      apiKey,
      openDialog,
      openDialogForUpdate,
      close,
      save,
      requiredField,
      openDialogForReply,
      changeImageFileCount,
      dialogMode
    }
  }
}
</script>
<style lang="scss">
.rtl.blog-create {
  .send-btn {
    color: white !important;
    .v-icon {
      transform: rotate(180deg) !important;
    }
  }
}

.blog-create {
  .tag-select {
    fieldset {
      display: none;
    }
  }

  .send-btn {
    color: white;
  }

}
</style>
