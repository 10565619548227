<template>
  <v-row
    justify="space-between" align="stretch" class="broken-item-stats"
    :class="{ 'rtl': isRtl }"
  >
    <v-col cols="4">
      <broken-item-single-stat
        :title="$t('company_broken_items.total')"
        :stat-value="totalResults"
        border-radius="16px"
        icon="construction"
      />
    </v-col>
    <v-col cols="4">
      <broken-item-single-stat
        :title="$t('company_broken_items.urgent_faults')"
        :stat-value="stats.high_priority_count"
        border-radius="16px"
        icon="local_fire_department"
      />
    </v-col>
    <v-col cols="4">
      <broken-item-single-stat
        :title="$t('company_broken_items.avg_open_time')" :stat-value="stats.avg_open_time"
        border-radius="16px"
        icon="date_range"
      />
    </v-col>
  </v-row>
</template>

<script>
import useCompanyBrokenItem from '@/composables/useCompanyBrokenItem.js'
import { isRtl } from '@/i18n.js'
import { computed, ref } from 'vue'
export default {
  components: {
    'broken-item-single-stat': () => import('@/components/broken_items/stats/BrokenItemSingleStat.vue')
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const showMenu = ref(false)
    const { stats } = useCompanyBrokenItem(props.apiKey)

    // Methods
    const onMenuClick = () => (showMenu.value = !showMenu.value)

    // Computed
    const totalResults = computed(() => {
      const statusCount = stats.value.status_count || {}
      return Object.values(statusCount).reduce((acc, val) => acc + val, 0)
    })

    return {
      showMenu,
      stats,
      totalResults,
      isRtl,
      onMenuClick
    }
  }
}
</script>
