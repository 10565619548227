
import { batchCreate as batchCreateShortLinks } from '@/api_client/UrlShortener.js'
import useCompany from '@/composables/useCompany.js'
import useAccount from '@/composables/useAccount.js'
import { itemTypes } from '@/models/ItemOperationListItemTypes.js'
import { getFrequencyTextWithDays, FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { OperationStatuses } from '@/store/modules/OperationSchedule.js'
import { dateToISO8601String } from '@/helpers/DateTime.js'
import { isString, isObject, betterImageQuality } from '@/helpers/Utils.js'
import { translateShiftWithShiftPrefix } from '@/store/modules/OrganizationJob.js'
import { i18n } from '@/i18n.js'

const createShareContent = async ({ operation, currentDate, apiKey, hideShifts = false, medium }) => {
  const shortLinks = await createShortLinks({ operation, apiKey })
  if (medium === 'pdf') return createShareContentForPdf({ operation, currentDate, shortLinks, apiKey, staffOperation: hideShifts })

  const result = { content: '', shortLinks }
  addIsStaffOperation(operation, result)
  addJob(operation, result, hideShifts)
  addDescription(operation, result)
  addFrequency({ operation, result })
  addShiftPart(operation, result, hideShifts)
  addStartTime(operation, result)
  addCreator(operation, result)
  addStatus(operation, result)
  addInnerList(operation, result)
  addGuidanceImages(operation, result)
  addFile(operation, result)
  return result.content
}

const frequencyText = ({ operation }) => {
  return getFrequencyTextWithDays({
    frequency: operation.frequency,
    frequencyType: operation.frequency_type,
    dateString: operation.first_scheduled_date
  })
}

const shiftPartName = (shiftPart) => {
  switch (shiftPart) {
    case 'start':
      return i18n.t('shift_start')
    case 'middle':
      return i18n.t('shift_middle')
    case 'end':
      return i18n.t('shift_end')
    case 'shift_transfer':
      return i18n.t('shift_transfer')
    case 'during_shift':
      return i18n.t('during_shift')
  }
}

const addIsStaffOperation = (operation, result) => {
  if (!operation.staff_operation) return
  result.content += `${i18n.t('operation_share.hq')}
`
}

const addJob = (operation, result, hideShifts) => {
  const shiftTranslated = translateShiftWithShiftPrefix(operation.account_job_shift)
  const jobDepartment = operation.account_job_department_name
  if (isString(jobDepartment) && jobDepartment.length > 0) {
    result.content += `${jobDepartment}: `
  }
  if (hideShifts) {
    result.content += `${operation.account_job_name}
`
  } else {
    result.content += `${operation.account_job_name} ${shiftTranslated}
`
  }
}

const addDescription = (operation, result) => {
  if (!isString(operation.operation_description)) return
  result.content += `${operation.operation_description}
`
}

const addFrequency = ({ operation, result }) => {
  if (operation.frequency_type === FrequencyTypes.ExectDate) return addExactDateFrequency({ operation, result })

  result.content += `${frequencyText({ operation })}
`
}

const addExactDateFrequency = ({ operation, result }) => {
  if ('endDateUserFormat' in operation) {
    result.content += `${i18n.t('account_operations.op_original_date_with_end_date', { date: operation.operation_next_date, endDate: operation.endDateUserFormat })}
`
  } else {
    result.content += `${i18n.t('account_operations.op_original_date', { date: operation.operation_next_date })}
`
  }
}

const addShiftPart = (operation, result, hideShifts) => {
  if (operation.staff_operation || hideShifts || !isString(operation.shift_part)) return
  result.content += `${shiftPartName(operation.shift_part)}
`
}

const addStartTime = (operation, result) => {
  if (!operation.start_time) return
  result.content += `${i18n.t('operation_share.start_time', { startTime: operation.start_time })}
`
}
const addCreator = (operation, result) => {
  const creator = operation.creator_data
  if (!isObject(creator)) return
  result.content += `${i18n.t('operation_share.creator', { firstName: creator.first_name, lastName: creator.last_name })}
`
}
const addStatus = (operation, result) => {
  if (operation.status === OperationStatuses.notReported) {
    result.content += `${i18n.t('operation_share.not_reported')}
`
    return
  }
  result.content += `${i18n.t('operation_share.reported', { firstName: operation.reporter_first_name, lastName: operation.reporter_last_name })}      
`
  if (operation.require_image && isString(operation.image_url)) {
    const imageUrl = findShortLink({ url: betterImageQuality(operation.image_url), shortLinks: result.shortLinks })
    result.content += `${i18n.t('operation_share.report_image')}
    \u2022 ${imageUrl}
`
  }
}

const addGuidanceImages = (operation, result) => {
  const guidanceImages = operation.guidance_images
  if (!Array.isArray(guidanceImages)) return
  result.content += `${i18n.t('operation_share.guidance_images')}
`
  guidanceImages.forEach((image) => {
    const imageUrl = findShortLink({ url: image.url, shortLinks: result.shortLinks })
    result.content += `  \u2022 ${imageUrl}
`
  })
}

const addFile = (operation, result) => {
  if (!isString(operation.guidance_file)) return
  const fileLink = findShortLink({ url: operation.guidance_file, shortLinks: result.shortLinks })
  result.content += `
${i18n.t('operation_share.attahced_file')}
  \u2022 ${fileLink}
`
}

const addInnerList = (operation, result) => {
  const innerList = operation.item_operation_list
  if (!Array.isArray(innerList)) return
  addInnerListTitle({ innerList, result })
  innerList.forEach((item) => {
    if (item.item_type === itemTypes.title) {
      result.content += `  ${item.description}
`
    } else {
      result.content += '  \u2022 '
      if (item.bool_value) {
        if (Number.isInteger(item.int_value)) {
          if (item.item_type === itemTypes.rating) {
            result.content += `${createStarRatingString(item.int_value)}`
          } else {
            result.content += `${item.int_value === 0 ? '\u2717 ' : '\u2713 '}`
          }
        } else {
          result.content += '\u2713 '
        }
      }
      result.content += `${item.description}
`
      if (item.item_type === itemTypes.image && Array.isArray(item.images)) {
        item.images.forEach((image) => {
          const imageLink = findShortLink({ shortLinks: result.shortLinks, url: betterImageQuality(image.url) })
          result.content += `  \u2022    ${imageLink}
`
        })
      }
      if (item.string_value) {
        result.content += ` ${item.string_value}
`
      }
    }
  })
}

const createStarRatingString = (rating) => {
  const blankStart = new Array(5 - rating).fill('\u2606 ')
  const fullStart = new Array(rating).fill('\u2605 ')
  return [...blankStart, ...fullStart].join('')
}

const addInnerListTitle = ({ innerList, result }) => {
  const list = (innerList || []).filter((item) => item.item_type !== 'title')
  const done = list.filter((item) => item.bool_value === true && Number.isInteger(item.report_id))
  result.content += `${i18n.t('operation_share.checklist', { done: done.length, total: list.length })}
`
}

const gatherLinks = (operation) => {
  const links = []
  if (operation.require_image && isString(operation.image_url)) {
    links.push(betterImageQuality(operation.image_url))
  }
  const guidanceImages = operation.guidance_images
  if (Array.isArray(guidanceImages)) {
    guidanceImages.forEach((image) => {
      links.push(image.url)
    })
  }
  if (isString(operation.guidance_file)) {
    links.push(operation.guidance_file)
  }
  const innerList = operation.item_operation_list
  if (Array.isArray(innerList)) {
    innerList.forEach((item) => {
      if (item.item_type === itemTypes.image && Array.isArray(item.images)) {
        item.images.forEach((image) => {
          links.push(betterImageQuality(image.url))
        })
      }
    })
  }
  return links
}

const createShortLinks = async ({ operation, apiKey }) => {
  const contentLinks = gatherLinks(operation)
  if (contentLinks.length === 0) return contentLinks
  const response = await batchCreateShortLinks(apiKey, contentLinks)
  return response.data.urls
}

const findShortLink = ({ shortLinks, url }) => {
  const link = shortLinks.find((item) => item.url === url)
  return link ? link.short_url : url
}

const createShareContentForPdf = ({ operation, currentDate, shortLinks, apiKey, staffOperation = false }) => {
  const { companyName } = useCompany(apiKey)
  const { accountCountryCode, accountName } = useAccount()
  const result = {}
  const fields = ['account_job_department_name', 'operation_description', 'item_operation_list',
    'shift_part', 'status', 'creator_data', 'start_time', 'account_job_name',
    'account_job_shift', 'frequency', 'frequency_type', 'end_date', 'operation_next_date',
    'first_scheduled_date', 'reporter_first_name', 'reporter_last_name']
  fields.forEach((field) => {
    if (operation[field]) {
      result[field] = operation[field]
    }
  })
  result.api_key = apiKey
  result.id = operation.operation_id
  result.report_date = dateToISO8601String(new Date())
  result.operation_date = currentDate
  result.locale = i18n.locale
  result.company_name = companyName.value
  result.country_code = accountCountryCode.value
  if (staffOperation === true) {
    delete result.shift_part
    delete result.account_job_shift
  } else {
    result.account_name = isString(operation.account_name) ? operation.account_name : accountName.value
  }

  if (!Array.isArray(result.item_operation_list)) return result

  result.item_operation_list.forEach((item) => {
    if (item.item_type === itemTypes.image && Array.isArray(item.images) && item.images.length > 0) {
      const imageLink = findShortLink({ shortLinks, url: betterImageQuality(item.images[0].url) })
      item.image_url = imageLink
    }
  })

  return result
}

export {
  createShareContent
}
