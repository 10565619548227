<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="350"
  >
    <v-card>
      <v-card-title class="text-center pb-5 blue-grey lighten-5">
        <span class="headline full-width">
          {{ $t('single_task_notification.title') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row align="center" justify="space-between" no-gutters>
            <v-col cols="12">
              <v-checkbox
                v-model="onCompleteTaskNotification"
                :label="$t('single_task_notification.on_complete')"
              />
            </v-col>
            <v-col v-if="operationHasStartTime" cols="12">
              <v-checkbox
                v-model="onUnCompleteTaskNotification"
                :label="$t('single_task_notification.on_un_complete')"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="pt-2 pr-2">
          <v-spacer />
          <v-btn
            color="blue darken-1" text :disabled="isLoading"
            @click="close(false)"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1" depressed tile
            :loading="isLoading" :disabled="isLoading"
            @click="save"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import useUserNotification from '@/composables/useUserNotification.js'

import { isString } from '@/helpers/Utils.js'

import { ref } from 'vue'

const OPERATION_SCHEDULE_MODEL = 'OperationSchedule'
export default {
  setup () {
    const dialog = ref(false)
    let oldVals = {}
    let operationId = null
    let resolveFunc = null
    let currentApiKey = null
    const onCompleteTaskNotification = ref(false)
    const onUnCompleteTaskNotification = ref(false)
    const operationHasStartTime = ref(false)
    const isLoading = ref(false)
    const didNotificationChanged = () => oldVals.onCompleteTaskNotification !== onCompleteTaskNotification.value || onUnCompleteTaskNotification.value !== oldVals.onUnCompleteTaskNotification

    const saveParams = () => {
      const notificationsToSave = { scopes: {} }
      notificationsToSave.scopes[OPERATION_SCHEDULE_MODEL] = [
        { field_name: 'operation_status_complete', field_value: operationId }
      ]
      if (operationHasStartTime.value) {
        notificationsToSave.scopes[OPERATION_SCHEDULE_MODEL].push({ field_name: 'operation_status_not_completed', field_value: operationId })
      }

      notificationsToSave[OPERATION_SCHEDULE_MODEL] = []
      if (onCompleteTaskNotification.value === true) {
        notificationsToSave[OPERATION_SCHEDULE_MODEL].push({ field_name: 'operation_status_complete', field_value: operationId, medium: 'sms' })
      }
      if (operationHasStartTime.value && onUnCompleteTaskNotification.value === true) {
        notificationsToSave[OPERATION_SCHEDULE_MODEL].push({ field_name: 'operation_status_not_completed', field_value: operationId, medium: 'sms' })
      }

      return notificationsToSave
    }

    // Methods
    const save = () => {
      if (!didNotificationChanged()) {
        close()
        return
      }
      isLoading.value = true
      const { saveNotifications } = useUserNotification(currentApiKey)
      saveNotifications({ params: saveParams(), apiKeys: currentApiKey })
        .then(() => close(true))
        .finally(() => (isLoading.value = false))
    }
    const close = (updated = false) => {
      dialog.value = false
      oldVals = {}
      operationId = null
      currentApiKey = null
      resolveFunc({ isUpdated: updated, onCompleteTaskNotification: onCompleteTaskNotification.value, onUnCompleteTaskNotification: onUnCompleteTaskNotification.value })
    }

    const openDialog = ({ operation, apiKey }) => {
      onCompleteTaskNotification.value = operation.mark_complete_notification
      onUnCompleteTaskNotification.value = operation.mark_uncomplete_notification
      operationHasStartTime.value = isString(operation.start_time) && operation.start_time.length > 0
      oldVals = { onCompleteTaskNotification: operation.mark_complete_notification, onUnCompleteTaskNotification: operation.mark_uncomplete_notification }
      operationId = operation.operation_id
      currentApiKey = apiKey
      dialog.value = true
      return new Promise((resolve) => {
        resolveFunc = resolve
      })
    }
    return {
      dialog,
      isLoading,
      onCompleteTaskNotification,
      onUnCompleteTaskNotification,
      operationHasStartTime,
      save,
      close,
      openDialog
    }
  }
}
</script>
