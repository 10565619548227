<template>
  <v-textarea
    class="description-text"
    :value="value"
    counter="240"
    :label="$t('devices_new_operation.op_desc')"
    auto-grow
    no-resize
    :prefix="descriptionPrefix"
    rows="1"
    @input="update"
  />
</template>
<script>

import { isObject, isString } from '@/helpers/Utils.js'
import { operationTypes, ROUTINE_OPERATION } from '@/composables/useOperationType.js'

import { computed } from 'vue'
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    operationType: {
      type: String,
      required: false,
      default: null
    }
  },
  setup (props, { emit }) {
    const update = (newVal) => emit('input', newVal)

    const opTypeOptions = computed(() => operationTypes.value.filter((operation) => !operation.stockOperation))

    const descriptionPrefix = computed(() => {
      const opType = props.operationType
      if (!isString(opType)) return null
      const foundType = opTypeOptions.value.find((operationType) => operationType.name === opType)
      if (!isObject(foundType)) return null
      if (foundType.name === ROUTINE_OPERATION) return null
      return `${foundType.translatedName} - `
    })
    return {
      descriptionPrefix,
      update
    }
  }
}
</script>
