<template>
  <v-dialog
    v-model="isDialogOpen" max-width="330" persistent
    content-class="rounded-xl"
  >
    <v-card :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-0">
        <span class="t-800-17 full-width">
          {{ dialogTitle }}
        </span>
      </v-card-title>
      <v-card-text class="py-0 popup-card">
        <v-form ref="newItemCategoryForm">
          <v-container grid-list-md class="pb-0">
            <v-row align="center" justify="center" no-gutters>
              <v-col cols="12">
                <v-textarea
                  v-model="itemCategoryName"
                  auto-grow
                  class="required"
                  rows="1"
                  :rules="[requiredField]"
                  single-line
                  background-color="white"
                  outlined
                  :counter="MAX_CATEGORY_NAME_LENGTH"
                  :label="$t('new_item_category.name')"
                />
              </v-col>
              <v-col cols="12" class="mt-3">
                <v-btn
                  block color="#0E4AFD"
                  class="white--text save-btn"
                  depressed
                  :loading="isLoading"
                  :disabled="isSaveDisabled"
                  @click="save"
                >
                  {{ $t('save') }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="mt-2 mb-4">
                <v-btn
                  block outlined text
                  :disabled="isLoading" @click="close"
                >
                  {{ $t('cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-snackbar
            v-model="errorSnackBar"
            :timeout="3000"
          >
            <div class="text-center">
              {{ $t('device_device_new_dialog.name_taken_err') }}
            </div>
          </v-snackbar>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { requiredField } from '@/classes/InputValidators.js'

import { create as itemCategoryClientCreate, update as itemCategoryClientUpdate } from '@/api_client/ItemCategory.js'

import { isString } from '@/helpers/Utils.js'

import { i18n, isRtl } from '@/i18n.js'

import { ref, computed, nextTick } from 'vue'

const DialogMode = { create: 0, update: 1 }
const MAX_CATEGORY_NAME_LENGTH = 80
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const isDialogOpen = ref(false)
    const newItemCategoryForm = ref(null)
    const itemCategoryName = ref('')
    const errorSnackBar = ref(false)
    const isLoading = ref(false)
    const dialogMode = ref(null)
    let itemCategoryId = null
    let resolveFunc = null

    const closeDialogAndReset = (updatedOrCreated, newItemCategoryId) => {
      newItemCategoryForm.value.reset()
      isDialogOpen.value = false
      errorSnackBar.value = false
      itemCategoryName.value = ''
      itemCategoryId = null
      resolveFunc({ updatedOrCreated, newItemCategoryId })
    }

    const createNewObject = () => {
      itemCategoryClientCreate(props.apiKey, { item_category: { name: itemCategoryName.value } })
        .then((resp) => closeDialogAndReset(true, resp.data.data.id))
        .catch((err) => {
          const errorsArray = err?.response?.data?.errors
          if (Array.isArray(errorsArray) && errorsArray.some((err) => err === 'name_taken')) {
            errorSnackBar.value = true
          }
          throw err
        })
        .finally(() => { isLoading.value = false })
    }

    const updateObject = () => {
      itemCategoryClientUpdate(props.apiKey, itemCategoryId, { item_category: { name: itemCategoryName.value } })
        .then(() => closeDialogAndReset(true))
        .finally(() => { isLoading.value = false })
    }

    const openDialog = (itemCategory) => {
      isDialogOpen.value = true
      itemCategoryName.value = itemCategory.name
      itemCategoryId = itemCategory.id
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    // Methods
    const close = () => closeDialogAndReset(false)

    const openForCreate = (itemCategory) => {
      dialogMode.value = DialogMode.create
      return openDialog({ name: '' })
    }

    const openForUpdate = (itemCategory) => {
      dialogMode.value = DialogMode.update
      return openDialog(itemCategory)
    }

    const save = () => {
      if (!newItemCategoryForm.value.validate()) return
      nextTick(() => {
        isLoading.value = true
        dialogMode.value === DialogMode.create ? createNewObject() : updateObject()
      })
    }

    // Computed
    const dialogTitle = computed(() => {
      if (dialogMode.value === DialogMode.create) {
        return i18n.t('new_item_category.create_title')
      }
      return i18n.t('new_item_category.update_title')
    })

    const isSaveDisabled = computed(() => {
      if (isLoading.value) return true
      const isNameValid = isString(itemCategoryName.value) &&
        itemCategoryName.value.length > 0 && itemCategoryName.value.length <= MAX_CATEGORY_NAME_LENGTH
      if (!isNameValid) return true
      return false
    })

    return {
      isDialogOpen,
      isSaveDisabled,
      dialogTitle,
      isRtl,
      newItemCategoryForm,
      itemCategoryName,
      errorSnackBar,
      isLoading,
      MAX_CATEGORY_NAME_LENGTH,
      openForCreate,
      openForUpdate,
      close,
      save,
      requiredField
    }
  }
}
</script>
<style lang="scss">
.save-btn.v-btn.v-btn--disabled {
  color: white !important;
}
</style>
