<template>
  <v-row
    align="start" justify="start" class="mt-15 mx-md-8"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <drive-new-folder-dialog ref="newFolderDialog" :api-key="apiKey" />
    <drive-new-file-dialog ref="newFileDialog" :api-key="apiKey" />
    <v-col cols="6" class="page-title texts-start mt-4 pps-6">
      {{ $t('drive.title') }}
    </v-col>
    <v-col cols="6" class="texts-end mt-4 ppe-6">
      <v-btn
        v-if="showCreateButtons" color="#CFD9F5" class="mme-4"
        :disabled="isLoading"
        @click="createFolderClick"
      >
        {{ $t('create') }}
        <v-icon class="material-icons-outlined mx-1">
          folder
        </v-icon>
      </v-btn>
      <v-btn
        v-if="showCreateButtons" color="#0040FF" class="white--text"
        :disabled="isLoading"
        @click="createFileClick"
      >
        {{ $t('drive.upload') }}
        <v-icon class=" mx-1">
          upload
        </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12">
      <drive-filter :api-key="apiKey" />
    </v-col>
    <v-col v-if="isEmpty" cols="12" class="mb-4 text-center color10 t-600-24">
      <div class="d-flex justify-center">
        <v-img :src="emptyDriveImage" max-height="240" max-width="240" />
      </div>
      {{ $t('drive.empty_drive') }}
    </v-col>
    <v-col cols="12" class="mb-4">
      <drive-folder-list
        :api-key="apiKey"
        @edit-folder="onEdit"
      />
    </v-col>
    <v-col cols="12" class="mb-4">
      <drive-file-list :api-key="apiKey" />
    </v-col>
  </v-row>
</template>
<script>
import DriveNewFolderDialog from '@/components/drive/DriveNewFolderDialog.vue'
import DriveNewFileDialog from '@/components/drive/DriveNewFileDialog.vue'
import DriveFilter from '@/components/drive/DriveFilter.vue'

import DriveFolderList from '@/components/drive/folders_grid_view/DriveFolderList.vue'
import DriveFileList from '@/components/drive/files_grid_view/DriveFileList.vue'

import useAuth from '@/composables/useAuth.js'
import useCompanyDriveFolder from '@/composables/useCompanyDriveFolder.js'
import useCompanyDriveFile from '@/composables/useCompanyDriveFile.js'

import { isRtl } from '@/i18n.js'
import { ref, computed } from 'vue'

export default {
  components: {
    'drive-new-folder-dialog': DriveNewFolderDialog,
    'drive-new-file-dialog': DriveNewFileDialog,
    'drive-folder-list': DriveFolderList,
    'drive-file-list': DriveFileList,
    'drive-filter': DriveFilter
  },
  setup () {
    const newFolderDialog = ref(null)
    const newFileDialog = ref(null)
    const { apiKey, loadCurrentUser, isAccountManagerOrAbove } = useAuth()
    const { isLoading: isLoadingFolder, isEmpty: isFoldersEmpty } = useCompanyDriveFolder(apiKey.value)
    const { isLoading: isLoadingFile, isEmpty: isFilesEmpty } = useCompanyDriveFile(apiKey.value)
    const emptyDriveImage = new URL('@/assets/empty_drive.png', import.meta.url).href
    // Methods

    const onEdit = (folder) => newFolderDialog.value.openForUpdate(folder.id)

    const createFolderClick = () => newFolderDialog.value.openDialog()
    const createFileClick = () => newFileDialog.value.openDialog()

    // Computed
    const isLoading = computed(() => isLoadingFolder.value || isLoadingFile.value)
    const isEmpty = computed(() => isFoldersEmpty.value && isFilesEmpty.value)
    const showCreateButtons = computed(() => isAccountManagerOrAbove.value)
    loadCurrentUser()
    return {
      isRtl,
      newFolderDialog,
      newFileDialog,
      apiKey,
      isLoading,
      isEmpty,
      emptyDriveImage,
      showCreateButtons,
      createFolderClick,
      createFileClick,
      onEdit
    }
  }
}
</script>
