
import {
  index,
  update as updatePushNotificationHistoryApiCall,
  destroy as destroyPushNotificationHistoryApiCall
} from '@/api_client/PushNotificationHistory.js'

import { dateToISO8601String } from '@/helpers/DateTime.js'
import { isString } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref } from 'vue'

let currentApiKey = null

const DEFAULT_PER_PAGE = 70
const DEFAULT_PAGE = 1

const defaultPagination = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, total: null })
const defaultFilters = () => ({
  typeTasks: null,
  typeTickets: null,
  typeBlog: null,
  typeChat: null,
  timeFilter: null,
  viewed: null
})

const pushHistoryList = ref([])
const isLoading = ref(false)
const isLoadingNextPage = ref(false)
const pushFilter = ref(defaultFilters())
const pagination = ref(defaultPagination())

export default function usePushNotificationsHistory (apiKey) {
  const resetValues = () => {
    isLoading.value = false
    isLoadingNextPage.value = false
    pushHistoryList.value = []
    pushFilter.value = defaultFilters()
    pagination.value = defaultPagination()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const apiFilterParams = () => {
    const pushFilterValue = pushFilter.value
    const pushTypes = []
    const filters = {}

    if (pushFilterValue.typeTasks) pushTypes.push('tasks')
    if (pushFilterValue.typeTickets) pushTypes.push('tickets')
    if (pushFilterValue.typeBlog) pushTypes.push('blog_posts')
    if (pushFilterValue.typeChat) pushTypes.push('app_chats')
    if (pushTypes.length > 0) filters.push_types = pushTypes

    if (pushFilterValue.timeFilter === 'today') {
      filters.start_date = dateToISO8601String(new Date())
      filters.end_date = dateToISO8601String(new Date())
    } else if (pushFilterValue.timeFilter === 'this_week') {
      filters.end_date = dateToISO8601String(new Date())
      const lastWeek = new Date()
      lastWeek.setDate(lastWeek.getDate() - 7)
      filters.start_date = lastWeek
    } else if (pushFilterValue.timeFilter === 'older') {
      const lastWeek = new Date()
      lastWeek.setDate(lastWeek.getDate() - 8)
      filters.end_date = lastWeek
    }

    if (pushFilterValue.viewed === true || pushFilterValue.viewed === false) {
      filters.viewed = pushFilterValue.viewed
    }

    return { page: pagination.value.page, per_page: pagination.value.perPage, filters }
  }

  // Methods

  const loadPushHistory = ({ reset = false }) => {
    if (reset === true) {
      pushHistoryList.value = []
    }

    isLoading.value = true
    return index({ apiKey: currentApiKey, params: apiFilterParams() })
      .then(({ data: { data, meta } }) => {
        data.forEach(item => (item.isLoading = false))
        pushHistoryList.value = pushHistoryList.value.concat(data)
        const paginationVal = pagination.value
        paginationVal.total = meta.total
        paginationVal.page = meta.page
        paginationVal.perPage = meta.per_page
      })
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const applyFilters = () => {
    pagination.value.page = DEFAULT_PAGE
    loadPushHistory({ reset: true })
  }

  const loadNextPage = () => {
    if (pagination.value.total > pushHistoryList.value.length) {
      pagination.value.page++
      loadPushHistory()
    }
  }

  const toggleViewed = (push) => {
    push.isLoading = true
    const newViewed = !push.viewed
    return updatePushNotificationHistoryApiCall({ apiKey: currentApiKey, id: push.id, params: { viewed: newViewed } })
      .then(() => (push.viewed = newViewed))
      .catch(errHandler)
      .finally(() => (push.isLoading = false))
  }

  const deletePush = (push) => {
    push.isLoading = true
    return destroyPushNotificationHistoryApiCall({ apiKey: currentApiKey, id: push.id })
      .then(() => {
        const index = pushHistoryList.value.indexOf(push)
        if (index > -1) pushHistoryList.value.splice(index, 1)
      })
      .catch(errHandler)
      .finally(() => (push.isLoading = false))
  }

  return {
    isLoading,
    isLoadingNextPage,
    pushHistoryList,
    pushFilter,
    pagination,
    loadPushHistory,
    applyFilters,
    loadNextPage,
    toggleViewed,
    deletePush
  }
}
