
import useCompanyDriveFilters from '@/composables/useCompanyDriveFilters.js'
import {
  create as createDriveFolder, update as updateDriveFolder, index as indexDriveFolder,
  destroy as destroyDriveFolder, show as showDriveFolder, removeImage as removeDriveFolderImage
} from '@/api_client/DriveFolder.js'
import { uploadImage as uploadImageToRemoteServer } from '@/general_client/CloudinaryImageUploader.js'

import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import Vue, { ref, computed } from 'vue'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 80
const defaultPagination = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, total: null })

const isLoading = ref(false)
const isFoldersLoading = ref(false)
const driveFolders = ref([])
const pagination = ref(defaultPagination())

let currentApiKey = null
export default function useCompanyDriveFolder (apiKey) {
  const { buildFilter } = useCompanyDriveFilters(apiKey)
  const resetValues = () => {
    isLoading.value = false
    isFoldersLoading.value = false
    driveFolders.value = []
    pagination.value = defaultPagination()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const setPaginationFromResponse = (responseMeta) => {
    const paginationVal = pagination.value
    paginationVal.total = responseMeta.total
    paginationVal.page = responseMeta.page
    paginationVal.perPage = responseMeta.per_page
  }

  const indexApiParams = () => {
    const result = {
      page: pagination.value.page,
      per_page: pagination.value.perPage
    }
    return Object.assign(result, { filters: buildFilter() })
  }

  const setFolderImage = ({ folderId, imageUrl }) => {
    const driveFoldersVal = driveFolders.value
    const index = driveFoldersVal.findIndex((folder) => folderId === folder.id)
    if (index === -1) return
    const folder = driveFoldersVal[index]
    if ('image_url' in folder) {
      folder.image_url = imageUrl
    } else {
      Vue.set(folder, 'image_url', imageUrl)
    }
  }
  // Methods

  const createFolder = async (params) => {
    isLoading.value = true
    try {
      const result = await createDriveFolder(apiKey, params)
      await loadFolders({ reset: true, useLoading: false, resetPage: false })
      return result.data
    } catch (error) {
      errHandler(error)
    } finally {
      isLoading.value = false
    }
  }

  const updateFolder = async ({ id, params }) => {
    isLoading.value = true
    try {
      const result = await updateDriveFolder({ apiKey, id, params })
      await loadFolders({ reset: true, useLoading: false, resetPage: false })
      return result.data
    } catch (error) {
      errHandler(error)
    } finally {
      isLoading.value = false
    }
  }

  const loadFolders = ({ reset, useLoading, resetPage } = { reset: false, useLoading: true, resetPage: true }) => {
    isLoading.value = true
    if (useLoading) {
      isFoldersLoading.value = true
    }
    if (reset) {
      driveFolders.value = []
    }
    if (resetPage) {
      pagination.value.page = DEFAULT_PAGE
    }

    return indexDriveFolder(apiKey, indexApiParams())
      .then(({ data: { data, meta } }) => {
        driveFolders.value = driveFolders.value.concat(data)
        setPaginationFromResponse(meta)
      })
      .catch(errHandler)
      .finally(() => {
        isLoading.value = false
        isFoldersLoading.value = false
      })
  }

  const deleteFolder = (folderId) => {
    isLoading.value = true
    return destroyDriveFolder(apiKey, folderId)
      .then(() => loadFolders({ reset: true, useLoading: false, resetPage: false }))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const loadNextPage = () => {
    if (driveFolders.value.length < pagination.value.total) {
      pagination.value.page++
      loadFolders({ resetPage: false })
    }
  }

  const loadSingleFolder = (folderId) => {
    return showDriveFolder(apiKey, folderId)
      .then(({ data: { data } }) => data)
      .catch(errHandler)
  }

  const removeFolderImage = (folderId) => {
    isLoading.value = true
    return removeDriveFolderImage({ apiKey, id: folderId })
      .then(() => setFolderImage({ folderId, imageUrl: null }))
      .finally(() => (isLoading.value = false))
  }

  const addFolderImage = async ({ folderId, imageUploadData, imageFile }) => {
    isLoading.value = true
    const imageUrl = await uploadImageToRemoteServer(imageFile, imageUploadData)
    setFolderImage({ folderId, imageUrl })
    isLoading.value = false
  }

  const onFilterChange = () => loadFolders({ reset: true })

  // Computed
  const isEmpty = computed(() => !isLoading.value && driveFolders.value.length === 0)

  return {
    isLoading,
    isFoldersLoading,
    driveFolders,
    pagination,
    isEmpty,
    createFolder,
    updateFolder,
    loadFolders,
    deleteFolder,
    loadNextPage,
    loadSingleFolder,
    onFilterChange,
    removeFolderImage,
    addFolderImage
  }
}
