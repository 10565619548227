<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="300"
  >
    <v-card>
      <v-card-text>
        <v-container>
          <v-row align="center" justify="space-between">
            <v-col
              v-for="(color, index) in colors" :key="index" cols="4"
              class="text-center"
            >
              <v-btn
                fab small :color="color"
                :input-value="isSelected(color)"
                @click="updateAndClose(color)"
              >
                <v-icon v-if="isSelected(color)">
                  done
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" class="text-right">
              <v-btn color="blue darken-1" text @click="clearColor">
                {{ $t('color_picker.clear') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import { ref } from 'vue'

const allColors = ['#E1CBF2', '#FDE9D4', '#F2E5CB', '#D6EDF0', '#CBD5F2', '#CBF2DB', '#F2CBCB']
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props, { emit }) {
    const dialog = ref(false)
    const openDialog = () => (dialog.value = true)
    const close = () => (dialog.value = false)
    const isSelected = (color) => props.value === color
    const updateAndClose = (colorValue) => {
      emit('input', colorValue)
      close()
    }
    const clearColor = () => {
      emit('clear')
      close()
    }

    return {
      dialog,
      colors: allColors,
      openDialog,
      close,
      updateAndClose,
      clearColor,
      isSelected
    }
  }
}
</script>
