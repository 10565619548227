<template>
  <v-row
    align="center" justify="center" justify-sm="start"
    justify-lg="center" class="text-center"
  >
    <date-picker-dialog
      ref="datePickerDialog" :disabled="isLoading"
      type="month"
      :value="dateRangeValue"
      :max-date="maxDate"
      :min-date="minDate"
      range
      show-current-date-button
      @change="applyDatePicker"
    />
    <v-col cols="6" class="text-left pb-0">
      <v-btn
        class="mx-1" icon color="black"
        :disabled="isLoading" @click="openDateDialog"
      >
        <v-icon>event</v-icon>
      </v-btn>
      <div class="font-weight-bold">
        {{ dateRangeString }}
      </div>
    </v-col>
    <v-col cols="5">
      <v-select
        v-if="companyContext"
        v-model="operationType"
        :items="operationTypes"
        item-text="name"
        item-value="id"
        single-line
        @change="changeOperationType"
      />
    </v-col>
    <v-col
      cols="11" sm="6" md="12"
      lg="11" class="pb-0 pb-sm-4"
    >
      <operations-history-search
        v-model="currentOperation"
        class="mt-0 pt-0"
        :api-key="apiKey"
        :operation-type="operationType"
        @change="applySearch"
      >
        :disabled="isLoading">
      </operations-history-search>
    </v-col>
    <v-col
      v-if="!companyContext" cols="11" sm="6"
      md="12" lg="11" class="pt-0"
    >
      <item-search
        v-model="relatedItem"
        :place-holder="$t('schedule_new_op_dialog.related_item')"
        :api-key="apiKey"
        load-all
        @change="applySearch"
      />
    </v-col>
    <v-col
      v-if="!companyContext" cols="11" sm="6"
      md="12" lg="11" class="pt-0"
    >
      <job-selection-search
        v-model="accountJobFilter"
        clearable
        :api-key="apiKey"
        :disabled="isLoading"
        :label="$t('job_op_schedule.choose_job')"
        @change="applySearch"
      />
    </v-col>
    <v-col
      cols="11" sm="6" md="12"
      lg="11" class="pt-0"
    >
      <operation-report-tag v-model="reportTagsFilter" multiple @change="applySearch" />
    </v-col>
    <v-col
      v-if="!companyContext" cols="11" sm="6"
      md="12" lg="11" class="pt-0"
    >
      <v-checkbox
        v-model="operationCreatedByMeFilter"
        :disabled="isLoading"
        :label="$t('operation_history.created_by_me')"
        @change="applySearch"
      />
    </v-col>
  </v-row>
</template>

<script>

import OperationsHistorySearch from '@/components/operations_history/operation_search/OperationsHistorySearch.vue'
import DatePickerDialog from '@/components/shared/DatePickerDialog.vue'
import ItemSearch from '@/components/shared/item_search/ItemSearch.vue'

import useOperationHistory from '@/composables/useOperationHistory.js'
import useAuth from '@/composables/useAuth.js'
import useAccount from '@/composables/useAccount.js'

import { translatedMonthAndYear as translatedMonthAndYearFromDate, dateToISO8601String } from '@/helpers/DateTime.js'

import { i18n } from '@/i18n.js'

import { ref, computed } from 'vue'

import { useRoute } from 'vue-router/composables'

export default {
  components: {
    'operations-history-search': OperationsHistorySearch,
    'date-picker-dialog': DatePickerDialog,
    'item-search': ItemSearch,
    'job-selection-search': () => import('@/components/job_selection_search/JobSelectionSearch.vue'),
    'operation-report-tag': () => import('@/components/operation_schedule_dialog/OperationReportTagField.vue')
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const datePickerDialog = ref(null)
    const router = useRoute()
    const { isCompanyAdminRegionOrAbove } = useAuth()
    const {
      isLoading, currentOperation, dateRange, relatedItem, operationTypes, operationType, accountJobFilter,
      operationCreatedByMeFilter, reportTagsFilter, resetPageAndReload, loadOperationHistory
    } = useOperationHistory(props.apiKey)

    const maxDate = new Date()
    maxDate.setDate(maxDate.getDate() - 1)
    const { accountCreationDate } = useAccount()
    const minDate = dateToISO8601String(accountCreationDate.value || new Date())

    // Methods
    const applySearch = resetPageAndReload
    const changeOperationType = resetPageAndReload
    const openDateDialog = () => datePickerDialog.value.openDialog()
    const applyDatePicker = (dates) => {
      dateRange.value = dates
      resetPageAndReload()
    }

    // Computed
    const companyContext = computed(() => isCompanyAdminRegionOrAbove.value && router.meta.staffOnly === true)

    const dateRangeString = computed(() => {
      const dateRangeVal = dateRange.value
      if (dateRangeVal.length === 0) {
        return i18n.t('operation_history.no_date_selected')
      }
      if (dateRangeVal.length === 1) {
        return translatedMonthAndYearFromDate(dateRangeVal[0])
      }
      return `${translatedMonthAndYearFromDate(dateRangeVal[0])} - ${translatedMonthAndYearFromDate(dateRangeVal[1])}`
    })

    const dateRangeValue = computed(() => {
      if (dateRange.value.length === 0) return []

      return dateRange.value.map(dateToISO8601String).map((date) => {
        const splitDate = date.split('-')
        return `${splitDate[0]}-${splitDate[1]}`
      })
    })

    loadOperationHistory()
    return {
      isLoading,
      currentOperation,
      dateRange,
      dateRangeString,
      relatedItem,
      accountJobFilter,
      reportTagsFilter,
      operationCreatedByMeFilter,
      operationTypes,
      operationType,
      dateRangeValue,
      maxDate: dateToISO8601String(maxDate),
      minDate,
      companyContext,
      datePickerDialog,
      applySearch,
      openDateDialog,
      applyDatePicker,
      changeOperationType
    }
  }
}
</script>
