<template>
  <span>
    <v-dialog v-if="showDialog" v-model="dialog" max-width="290">
      <v-card :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
        <v-card-title class="text-center pb-5 blue-grey lighten-5">
          <span class="headline full-width">
            {{ dialogTitle }}
          </span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container>
            <v-row align="center" justify="space-between">
              <v-col>
                <v-btn
                  dark fab bottom
                  color="blue darken-7" small :loading="isLoading.copy"
                  @click="onShareClick('copy')"
                >
                  <v-icon>content_copy</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  dark fab bottom
                  color="green" small :loading="isLoading.whatsapp"
                  @click="onShareClick('whatsapp')"
                >
                  <img src="@/assets/whatsapp-icon.svg">
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  dark fab bottom
                  color="tertiary" small :loading="isLoading.mail"
                  @click="onShareClick('mail')"
                >
                  <v-icon>email</v-icon>
                </v-btn>
              </v-col>
              <v-col v-if="showPdfShare">
                <v-btn
                  dark fab bottom
                  color="#E2340D" small :loading="isLoading.pdf"
                  @click="onShareClick('pdf')"
                >
                  <v-icon>picture_as_pdf</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" class="mt-3">
                <v-card-actions class="pt-0 pr-0">
                  <v-spacer />
                  <v-btn
                    color="blue darken-1" depressed tile
                    @click="closeDialog"
                  >
                    {{ $t('close') }}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-speed-dial
      v-else
      v-model="shareOpen" :absolute="absolute" right
      bottom :direction="direction" open-on-hover
      class="share"
    >
      <template #activator>
        <slot v-if="haveSlots" name="share" />
        <v-btn
          v-else fab bottom
          x-small color="black white--text"
        >
          <v-icon v-if="shareOpen" small>close</v-icon>
          <v-icon v-else small>share</v-icon>
        </v-btn>
      </template>
      <v-btn
        dark fab bottom
        color="blue darken-7" small @click="onShareClick('copy')"
      >
        <v-icon>content_copy</v-icon>
      </v-btn>
      <v-btn
        dark fab bottom
        color="green" small @click="onShareClick('whatsapp')"
      >
        <img src="@/assets/whatsapp-icon.svg">
      </v-btn>
      <v-btn
        dark fab bottom
        color="tertiary" small @click="onShareClick('mail')"
      >
        <v-icon>email</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-snackbar v-model="snackBarData.show" :timeout="snackBarData.timeout" color="success">
      <div class="text-center">
        {{ $t('share_content.clipboard_copy') }}
      </div>
    </v-snackbar>
  </span>
</template>
<script>
import useAuth from '@/composables/useAuth.js'
import { downloadPdf } from '@/classes/PdfReportDownloader.js'
import { isMobile } from '@/helpers/ClientDevice.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { isRtl } from '@/i18n'
import { ref, computed } from 'vue'
export default {
  props: {
    shareContent: {
      type: Function,
      required: true
    },
    showDialog: {
      type: Boolean,
      required: false,
      default: false
    },
    absolute: {
      type: Boolean,
      required: false,
      default: true
    },
    direction: {
      type: String,
      required: false,
      default: 'left'
    }
  },
  setup (props, { slots }) {
    const defaultIsLoading = () => ({ copy: false, whatsapp: false, mail: false, pdf: false })
    const dialog = ref(false)
    const dialogTitle = ref('')
    const isLoading = ref(defaultIsLoading())
    const shareOpen = ref(false)
    const snackBarData = ref({ show: false, timeout: 3000 })
    const showPdfShare = ref(false)
    const closeDialog = () => (dialog.value = false)
    const openDialog = ({ title = null, pdfShareEnabled = false }) => {
      dialogTitle.value = title
      dialog.value = true
      showPdfShare.value = pdfShareEnabled
      isLoading.value = defaultIsLoading()
    }
    const showSnackbar = () => (snackBarData.value.show = true)

    const onShareClick = async (medium) => {
      isLoading.value[medium] = true
      const shareContent = await Promise.resolve(props.shareContent(medium))
      isLoading.value[medium] = false
      if (medium === 'copy') {
        setTimeout(() => {
          navigator.clipboard.writeText(shareContent)
          showSnackbar()
        })
      } else if (medium === 'whatsapp') {
        const shareContentEncoded = encodeURIComponent(shareContent)
        let url = isMobile() ? 'whatsapp://send?text=' : 'https://wa.me/?text='
        url += shareContentEncoded
        // call window.open in setTimeout due to safari bug of calling window.open in async function raises
        setTimeout(() => window.open(url, '_blank').focus())
      } else if (medium === 'mail') {
        window.open(`mailto:?body=${encodeURIComponent(shareContent)}`, '_top')
      } else if (medium === 'pdf') {
        isLoading.value.pdf = true
        const { apiKey } = useAuth()
        downloadPdf({ data: shareContent, apiKey: apiKey.value })
          .catch(errHandler)
          .finally(() => {
            isLoading.value.pdf = false
          })
      }
    }

    // Computed
    const haveSlots = computed(() => !!slots.share)
    return {
      dialog,
      shareOpen,
      snackBarData,
      dialogTitle,
      isLoading,
      showPdfShare,
      haveSlots,
      isRtl,
      isMobile,
      onShareClick,
      openDialog,
      closeDialog
    }
  }
}
</script>
<style lang="scss">
.v-speed-dial.share {
  z-index: 3;
}
</style>
