<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card
      class="company-permissions"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="text-center pb-5 blue-grey lighten-5">
        <span class="headline full-width">
          {{ $t('menu_page.general_settings') }}
        </span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container>
          <v-row
            v-if="isLoadingData" align="center" justify="center"
            class="pb-3" no-gutters
          >
            <v-progress-circular indeterminate color="primary" />
          </v-row>
          <v-row
            v-else align="start" justify="start"
            class="pb-3" no-gutters
          >
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header class="header-border">
                  <div class="text-center t-700-16">
                    {{ $t('permissions') }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row
                    align="start" justify="start" class="pb-3"
                    no-gutters
                  >
                    <v-col cols="12" class="text-center text-h5">
                      {{ $t('company_permissions.create_object') }}
                    </v-col>
                    <v-col v-if="isCompanyAdminRegionOrAbove" cols="12" class="text-center text-subtitle-1 mt-2">
                      {{ $t('company_permissions.account_admin') }}
                    </v-col>
                    <v-col v-if="isCompanyAdminRegionOrAbove" cols="12">
                      <v-checkbox
                        v-model="enabledFeatures.adminCreate"
                        :disabled="isUserCompanyRegionAdmin"
                        :label="$t('create')"
                      />
                      <v-checkbox
                        v-model="enabledFeatures.adminEdit"
                        :disabled="isUserCompanyRegionAdmin" :label="$t('edit')"
                      />
                      <v-checkbox
                        v-model="enabledFeatures.adminDelete"
                        :disabled="isUserCompanyRegionAdmin" :label="$t('delete')"
                      />
                    </v-col>
                    <v-col cols="12" class="text-center text-subtitle-1">
                      {{ $t('company_permissions.account_manager') }}
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="enabledFeatures.managerCreate"
                        :disabled="isUserCompanyRegionAdmin" :label="$t('create')"
                      />
                    </v-col>
                    <v-col cols="12" class="text-center text-subtitle-1">
                      {{ $t('company_permissions.account_employee') }}
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="enabledFeatures.employeeCreate"
                        :disabled="isUserCompanyRegionAdmin" :label="$t('create')"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="isUserCompanyRegionAdmin" no-gutters align="end"
                    justify="end"
                    class="py-5 text-right"
                  >
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t('close') }}
                    </v-btn>
                  </v-row>
                  <v-row
                    v-else no-gutters align="end"
                    justify="end" class="pb-5 text-right"
                  >
                    <v-btn
                      color="blue darken-1" text :disabled="isLoading"
                      @click="close"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1" depressed tile
                      :loading="isLoading" :disabled="isLoading"
                      @click="save"
                    >
                      {{ $t('save') }}
                    </v-btn>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="header-border">
                  <div class="text-center t-700-16">
                    {{ $t('faults') }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row
                    align="start" justify="start" class="pb-3"
                    no-gutters
                  >
                    <v-col cols="12" class="text-center t-500-16 mt-2 mb-4">
                      {{ $t('default_deadline.high_priority') }}
                    </v-col>
                    <v-col cols="12" class="d-flex align-center justify-space-between">
                      {{ $t('default_deadline.deadline_time') }}
                      <v-text-field
                        v-model="deadlines.high.on_time.hours"
                        class="deadline-field flex-grow-0 flex-shrink-1"
                        :label="$t('hours')"
                        :disabled="isUserCompanyRegionAdmin"
                        height="50px"
                        placeholder="Placeholder"
                        hide-details
                        type="number"
                        min="1" max="300"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" class="mt-6 d-flex align-center justify-space-between">
                      {{ $t('default_deadline.deadline_notification') }}
                      <v-text-field
                        v-model="deadlines.high.additional.hours"
                        class="deadline-field flex-grow-0 flex-shrink-1"
                        :label="$t('hours')"
                        :disabled="isUserCompanyRegionAdmin"
                        height="50px"
                        placeholder="Placeholder"
                        hide-details
                        type="number"
                        min="1" max="300"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" class="text-center t-500-16 my-4">
                      {{ $t('default_deadline.low_priority') }}
                    </v-col>
                    <v-col cols="12" class="d-flex align-center justify-space-between">
                      {{ $t('default_deadline.deadline_time') }}
                      <v-text-field
                        v-model="deadlines.low.on_time.hours"
                        class="deadline-field flex-grow-0 flex-shrink-1"
                        :label="$t('hours')"
                        :disabled="isUserCompanyRegionAdmin"
                        height="50px"
                        placeholder="Placeholder"
                        hide-details
                        type="number"
                        min="1" max="300"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" class="mt-6 d-flex align-center justify-space-between">
                      {{ $t('default_deadline.deadline_notification') }}
                      <v-text-field
                        v-model="deadlines.low.additional.hours"
                        class="deadline-field flex-grow-0 flex-shrink-1"
                        :label="$t('hours')"
                        :disabled="isUserCompanyRegionAdmin"
                        height="50px"
                        placeholder="Placeholder"
                        hide-details
                        type="number"
                        min="1" max="300"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="isUserCompanyRegionAdmin" no-gutters align="end"
                    justify="end"
                    class="py-5 text-right"
                  >
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t('close') }}
                    </v-btn>
                  </v-row>
                  <v-row
                    v-else no-gutters align="end"
                    justify="end" class="py-5 text-right"
                  >
                    <v-btn
                      color="blue darken-1" text :disabled="isLoading"
                      @click="close"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1" depressed tile
                      :loading="isLoading" :disabled="isLoading"
                      @click="saveDeadlines"
                    >
                      {{ $t('save') }}
                    </v-btn>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-row
              no-gutters align="end" justify="end"
              class="py-5 text-right"
            >
              <v-btn
                color="blue darken-1" depressed tile
                :loading="isLoading" :disabled="isLoading"
                @click="close"
              >
                {{ $t('close') }}
              </v-btn>
            </v-row>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import { setCompanyFeatures } from '@/api_client/Feature.js'
import useFeatures from '@/composables/useFeatures.js'
import useAuth from '@/composables/useAuth.js'
import useBrokenItemDeadline from '@/composables/useBrokenItemDeadline.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { isRtl } from '@/i18n'

import { ref } from 'vue'

const deafultDeadlines = () => ({
  low: {
    on_time: {
      hours: null
    },
    additional: {
      hours: null
    }
  },
  high: {
    on_time: {
      hours: null
    },
    additional: {
      hours: null
    }
  }
})
const featureNamesMap = {
  account_admin_can_create_items: 'adminCreate',
  account_admin_can_edit_items: 'adminEdit',
  account_admin_can_delete_items: 'adminDelete',
  account_manager_can_create_items: 'managerCreate',
  account_employee_can_create_items: 'employeeCreate'
}

export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const dialog = ref(false)
    const isLoading = ref(false)
    const isLoadingData = ref(false)
    const enabledFeatures = ref({})
    const { isCompanyAdminRegionOrAbove, isUserCompanyRegionAdmin } = useAuth()
    const deadlines = ref(deafultDeadlines())
    const { hasFeatures, loadFeatures: loadFeaturesCall } = useFeatures(props.apiKey)

    const { brokenItemsDeadline, saveBrokenItemDeadlines, loadBrokenItemDeadlines: loadBrokenItemDeadlinesCall } = useBrokenItemDeadline(props.apiKey)
    const enabledFeatureNames = () => {
      const enabledFeaturesVal = enabledFeatures.value
      const enabledFeatureNames = []
      for (const featureName in featureNamesMap) {
        const modelName = featureNamesMap[featureName]
        if (enabledFeaturesVal[modelName]) {
          enabledFeatureNames.push(featureName)
        }
      }
      return enabledFeatureNames
    }
    // Methods
    const close = () => (dialog.value = false)

    const openDialog = () => {
      dialog.value = true
      isLoading.value = false
    }

    const save = () => {
      isLoading.value = true
      setCompanyFeatures({ apiKey: props.apiKey, features: enabledFeatureNames() })
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    const saveDeadlines = () => {
      isLoading.value = true
      const deadlinesVal = deadlines.value
      const deadlinesToSend = []
      for (const priority in deadlinesVal) {
        for (const deadlineType in deadlinesVal[priority]) {
          const hours = parseInt(deadlinesVal[priority][deadlineType].hours)
          if (Number.isInteger(hours) && hours > 0) {
            const minutes = deadlinesVal[priority][deadlineType].hours * 60
            deadlinesToSend.push({
              priority,
              deadline_type: deadlineType,
              minutes
            })
          }
        }
      }
      saveBrokenItemDeadlines(deadlinesToSend)
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    const loadFeatures = () =>
      loadFeaturesCall()
        .then(() => {
          const features = hasFeatures(Object.keys(featureNamesMap))
          const enabledFeaturesVal = enabledFeatures.value
          for (const featureName in features) {
            const modelName = featureNamesMap[featureName]
            enabledFeaturesVal[modelName] = features[featureName]
          }
        })

    const loadBrokenItemsDeadlines = () => {
      deadlines.value = deafultDeadlines()
      return loadBrokenItemDeadlinesCall().then(() => {
        const deadlinesVal = deadlines.value
        brokenItemsDeadline.value.forEach((deadline) => {
          const priority = deadline.priority
          const deadlineType = deadline.deadline_type

          deadlinesVal[priority][deadlineType].hours = deadline.minutes / 60
        })
      })
    }

    isLoadingData.value = true
    Promise.all([loadFeatures(), loadBrokenItemsDeadlines()])
      .finally(() => (isLoadingData.value = false))

    return {
      dialog,
      isLoading,
      isLoadingData,
      enabledFeatures,
      isRtl,
      deadlines,
      isCompanyAdminRegionOrAbove,
      isUserCompanyRegionAdmin,
      openDialog,
      close,
      save,
      saveDeadlines
    }
  }
}
</script>
<style lang="scss">
.company-permissions {
  .v-input--checkbox {
    margin-top: 0;
    padding-top: 0;
  }

  .header-border {
    border: 1px solid black;
  }

  .deadline-field {
    width: 90px;
    display: inline-block;
  }
}
</style>
