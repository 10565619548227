<template>
  <v-row align="start" justify="start" class="pt-5">
    <v-col cols="12" class="px-0">
      <v-row align="start" justify="start">
        <v-col cols="4" lg="2">
          {{ $t('user_notifications.staff_job.staff_task_daily') }}
        </v-col>
        <v-col
          cols="8" lg="10" class="ltr d-flex"
          :class="{'justify-end': isRtl}"
        >
          <v-switch
            v-model="notifications.staffOperation.medium"
            class="mt-0"
            :disabled="mediumSmsDisabled"
            false-value="null"
            true-value="sms"
          />
        </v-col>
        <v-col cols="4" lg="2">
          {{ $t('user_notifications.staff_job.staff_task_create') }}
        </v-col>
        <v-col
          cols="8" lg="10" class="ltr d-flex"
          :class="{'justify-end': isRtl}"
        >
          <v-switch
            v-model="notifications.staffOperationCreate.medium"
            class="mt-0"
            :disabled="mediumSmsDisabled"
            false-value="null"
            true-value="sms"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="mt-10">
      <v-footer padless fixed class="save-notifications">
        <v-col cols="12" class="text-right">
          <v-btn
            color="blue darken-1" depressed tile
            :loading="isLoading" :disabled="isLoading" @click="save"
          >
            <v-icon v-if="showNotificationsSaved">
              check
            </v-icon>
            <span v-else>
              {{ $t('save') }}
            </span>
          </v-btn>
        </v-col>
      </v-footer>
    </v-col>
  </v-row>
</template>
<script>
import useUserNotification from '@/composables/useUserNotification.js'

import { isObject } from '@/helpers/Utils.js'
import { isRtl } from '@/i18n.js'

import { ref } from 'vue'

const OPERATION_SCHEDULE_MODEL = 'OperationSchedule'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const {
      isLoading, mediumSmsDisabled,
      loadNotifications, saveStaffNotification
    } = useUserNotification(props.apiKey)
    const notifications = ref({
      staffOperation: { medium: 'null' },
      staffOperationCreate: { medium: 'null' }
    })
    const showNotificationsSaved = ref(false)
    isLoading.value = true

    loadNotifications([OPERATION_SCHEDULE_MODEL]).then(({ reponseByModel }) => {
      const notificationsVal = notifications.value
      const notificationsForOperationScheduleModel = reponseByModel[OPERATION_SCHEDULE_MODEL] || []
      const staffOperationNotification = notificationsForOperationScheduleModel.find((notification) => notification.field_name === 'staff_operation' &&
                                                                         notification.field_value === 'day_with_operations')

      const staffOperationCreateNotification = notificationsForOperationScheduleModel.find((notification) => notification.field_name === 'staff_operation' &&
                                                                         notification.field_value === 'create')

      if (isObject(staffOperationNotification) === true) {
        notificationsVal.staffOperation.medium = staffOperationNotification.medium
      }
      if (isObject(staffOperationCreateNotification) === true) {
        notificationsVal.staffOperationCreate.medium = staffOperationCreateNotification.medium
      }
    })
      .finally(() => (isLoading.value = false))

    // Methods
    const save = async () => {
      isLoading.value = true
      const editParams = { scopes: {} }
      editParams.scopes[OPERATION_SCHEDULE_MODEL] = [
        { field_name: 'staff_operation', field_vale: 'day_with_operations' },
        { field_name: 'staff_operation', field_vale: 'create' }
      ]
      const notificationsVal = notifications.value
      editParams[OPERATION_SCHEDULE_MODEL] = []

      if (notificationsVal.staffOperation.medium !== 'null') {
        const medium = notificationsVal.staffOperation.medium
        editParams[OPERATION_SCHEDULE_MODEL].push({ field_name: 'staff_operation', field_value: 'day_with_operations', medium })
      }

      if (notificationsVal.staffOperationCreate.medium !== 'null') {
        const medium = notificationsVal.staffOperationCreate.medium
        editParams[OPERATION_SCHEDULE_MODEL].push({ field_name: 'staff_operation', field_value: 'create', medium })
      }
      saveStaffNotification(editParams)
        .then(() => {
          showNotificationsSaved.value = true
          setTimeout(() => (showNotificationsSaved.value = false), 3000)
        })
        .finally(() => (isLoading.value = false))
    }
    return {
      isLoading,
      notifications,
      mediumSmsDisabled,
      showNotificationsSaved,
      isRtl,
      save
    }
  }
}
</script>
