<template>
  <login-layout>
    <template #main-part>
      <v-col cols="12" class="text-center t-400-32 white--text pt-0">
        {{ $t('login.enter_phone') }}
      </v-col>
      <v-col
        cols="12" lg="5" md="6"
        sm="8"
      >
        <v-form ref="loginForm" lazy-validation>
          <v-text-field
            v-model="phone"
            v-mask="'####################'"
            class="phone-input ltr"
            :rules="[requiredField]"
            autocomplete="phone"
            prepend-inner-icon="phone"
            name="login"
            single-line
            outlined
            :label="$t('login.phone')"
            type="tel"
          />
        </v-form>
      </v-col>
      <v-col cols="12" class="pa-0" />
    </template>
    <template #buttons>
      <v-col cols="12" class="t-500-12 white--text text-center py-0">
        {{ $t('login.send_to') }}
      </v-col>
      <v-col>
        <v-btn
          class="login-btn"
          :loading="isLoadingSms"
          :disabled="isSaveDisabled"
          depressed
          block
          :color="isSaveDisabled ? '#9E9E9E' : '#0040FF'"
          @click="submitLoginSms"
        >
          SMS
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          class="login-btn"
          :loading="isLoadingWhatsapp"
          :disabled="isSaveDisabled"
          depressed
          block
          :color="isSaveDisabled ? '#9E9E9E' : '#0040FF'"
          @click="submitLoginWhatsApp"
        >
          WHATSAPP
        </v-btn>
      </v-col>
      <v-snackbar v-model="userNotFound" :timeout="userNotFoundTimeout">
        <v-row justify="start" align="center" no-gutters>
          <v-col cols="8" offset="2" class="text-center">
            {{ $t("login.user_not_found") }}
          </v-col>
          <v-col cols="2">
            <v-icon color="red">
              warning
            </v-icon>
          </v-col>
        </v-row>
      </v-snackbar>
    </template>
  </login-layout>
</template>
<script>
import LoginLayout from '@/components/login/LoginLayout.vue'
import { sendVerificationCode as AuthClientSendVerificationCode } from '@/api_client/auth.js'

import { isValidPhone } from '@/helpers/Utils.js'

import { requiredField } from '@/classes/InputValidators.js'

import { mask } from 'vue-the-mask'
import { useRouter } from 'vue-router/composables'
import { ref, computed } from 'vue'
const notFoundStatus = 404

export default {
  components: {
    'login-layout': LoginLayout
  },
  directives: {
    mask
  },
  setup () {
    const imageLogoPath = new URL('@/assets/dex_logo_white.svg', import.meta.url).href
    const phone = ref(null)
    const loginForm = ref(null)
    const userNotFound = ref(false)
    const isLoadingSms = ref(false)
    const isLoadingWhatsapp = ref(false)
    const userNotFoundTimeout = ref(4000)
    const router = useRouter()
    const submitLogin = (channel) => {
      if (!loginForm.value.validate()) return

      AuthClientSendVerificationCode({ phone: phone.value, channel })
        .then((response) => router.push({ name: 'verify_user', params: { phone: phone.value, hasEmail: response.data.has_email } }))
        .catch((err) => {
          if (err.response.status !== notFoundStatus) return
          userNotFound.value = true
        })
        .finally(() => {
          isLoadingSms.value = false
          isLoadingWhatsapp.value = false
        })
    }

    // Methods
    const submitLoginWhatsApp = () => {
      isLoadingWhatsapp.value = true
      submitLogin('whatsapp')
    }

    const submitLoginSms = () => {
      isLoadingSms.value = true
      submitLogin('sms')
    }
    // Computed

    const isSaveDisabled = computed(() => {
      if (isLoadingSms.value || isLoadingWhatsapp.value || userNotFound.value === true) return true
      return !isValidPhone(phone.value)
    })
    return {
      imageLogoPath,
      loginForm,
      phone,
      isLoadingSms,
      isLoadingWhatsapp,
      userNotFound,
      userNotFoundTimeout,
      isSaveDisabled,
      submitLoginSms,
      submitLoginWhatsApp,
      requiredField
    }
  }
}
</script>
<style lang="scss">
.login-page {
  .center-box {
    .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
      background-color: #9E9E9E !important;
    }

    .phone-input {
      color: white !important;

      .v-input__slot {
        background-color: #212121;

        label,
        input {
          color: white !important;
        }
      }
    }
  }
}
</style>
