<template>
  <v-main class="black-bg">
    <v-container fill-height>
      <v-row align="center" justify="center" class="fill-height text-center">
        <v-col cols="12" class=" pb-0">
          <img src="/logo_and_name_white.svg" style="width: 120px">
        </v-col>
        <v-col class="pt-0" cols="12">
          <span class="come-a">
            Come on Board!
          </span>
          <br>
          <span class="desc">
            Join Dex to manage tasks,<br>
            communicate professionally with HQ, <br>
            managers and coworkers<br>
            and stay on top of business-critical information.
          </span>
        </v-col>
        <v-col
          cols="12" sm="8" md="6"
          class="align-self-start"
        >
          <v-row align="start" justify="space-between">
            <v-img
              src="/google-play-badge.svg"
              max-height="160"
              max-width="160"
              @click="googleApp"
            />
            <v-img
              src="/app_store_logo.svg"
              max-height="140"
              max-width="140"
              @click="iOSApp"
            />
          </v-row>
          <br>
          <span class="link-site pointer d-inline-block mt-3" @click="goToSite">
            Countinue to website
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import router from '@/router'
export default {
  setup () {
    // Methods
    const goToSite = () => router.push({ name: 'login' })
    const googleApp = () => window.open('https://play.google.com/store/apps/details?id=com.dex.world.app', '_blank')
    const iOSApp = () => window.open('https://apps.apple.com/us/app/dex-world/id1610117890', '_blank')
    return {
      goToSite,
      googleApp,
      iOSApp
    }
  }
}
</script>
<style lang="scss">
.black-bg {
 font-family: Inter;
 background-color: #111111;
 color: #FFFFFF;
 .come-a {
   font-size: 28px;
   font-style: normal;
   font-weight: 800;
   line-height: 40px;
   letter-spacing: 0.0025em;
   text-align: center;
   color: #FFFFFF;
 }
 .desc {
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 24px;
   letter-spacing: 0.0015em;
   text-align: center;
 }
 .link-download {
   background: #0368FF;
   box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
   border-radius: 8px;
   font-weight: 600;
   font-size: 20px;
   line-height: 28px;
 }
 .link-site {
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 28px;
 }
}
@font-face {
   font-family: 'Inter';
   src: url(https://fonts.googleapis.com/css?family=Inter:400,600,700,800&display=swap) format('truetype');
}
</style>
