<template>
  <v-container fluid class="full-height pb-0 px-0 app-chat my-0" :class="isRtl ? 'rtl' : 'ltr'">
    <chat-layout-big-screen v-if="$vuetify.breakpoint.mdAndUp" :api-key="apiKey" />
    <chat-layout-small-screen v-else :api-key="apiKey" />
  </v-container>
</template>

<script>

import ChatLayoutBigScreen from '@/components/app_chat/ChatLayoutBigScreen.vue'
import ChatLayoutSmallScreen from '@/components/app_chat/ChatLayoutSmallScreen.vue'

import { EventBus, NEW_APP_CHET_MESSAGE_RECEIVED } from '@/EventBus.js'

import useAppChat from '@/composables/useAppChat.js'
import useAuth from '@/composables/useAuth.js'

import { isRtl } from '@/i18n.js'

import { onUnmounted } from 'vue'

export default {
  components: {
    'chat-layout-big-screen': ChatLayoutBigScreen,
    'chat-layout-small-screen': ChatLayoutSmallScreen

  },
  setup () {
    const { apiKey } = useAuth()

    const { activeConversation, loadUnreadGroupCount } = useAppChat(apiKey.value)

    const onNewMessageReceived = ({ group_id: groupId = null }) => {
      if (activeConversation.value.group.id === groupId) return

      loadUnreadGroupCount(true)
    }

    EventBus.off(NEW_APP_CHET_MESSAGE_RECEIVED, onNewMessageReceived)
    EventBus.on(NEW_APP_CHET_MESSAGE_RECEIVED, onNewMessageReceived)
    onUnmounted(() => EventBus.off(NEW_APP_CHET_MESSAGE_RECEIVED, onNewMessageReceived))
    return {
      apiKey,
      isRtl
    }
  }
}
</script>

<style lang="scss">
.app-chat {
  @media only screen and (max-width: 960px) {
    padding-top: 44px;
  }

  padding-top: 66px;
}
</style>
