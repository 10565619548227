<template>
  <v-row
    v-if="$vuetify.breakpoint.mdAndUp" align="center" justify="start"
    no-gutters class="task-flters"
  >
    <v-col
      v-if="!departmentSelectedUiMode" cols="3" md="2"
      class="mme-auto"
    >
      <date-range-select :value="dateRangeScope" @change="onDateRangeScopeChanged" />
    </v-col>
    <v-col
      v-if="showAccountSelect" cols="12" :lg="showUiMode ? 4 : 3"
      :md="showUiMode ? 6 : 4" class="ppe-2"
    >
      <account-select
        v-model="selectedAccount"
        class="mme-2"
        :api-key="apiKey"
        :multiple="false"
        add-account-task-count
        :client-date="clientDate"
        @change="onChangeAccount"
      />
    </v-col>
    <v-col v-if="showUiMode" class="flex-grow-0 flex-shrink-1 mme-2">
      <v-select
        class="ui-mode-select"
        :value="uiMode"
        :items="uiModeOptions"
        outlined
        dense
        single-line
        @change="onUiModeChange"
      />
    </v-col>
    <v-col v-if="showTasksFilters" class="flex-grow-0 flex-shrink-1 mms-1 mms-auto">
      <tasks-filter
        ref="tasksFilterRef"
        :api-key="apiKey"
        :hidden-filters="hiddenTaskFilters"
        :date-range-scope="dateRangeScope"
        @apply-filter="filterChanged"
        @date-range-scope-changed="onDateRangeScopeChanged"
      />
    </v-col>
    <v-col v-if="departmentSelectedUiMode" class="flex-grow-0 flex-shrink-1 mms-1 mms-auto">
      <v-btn text color="#0040FF" @click="unSelectDepartment">
        <v-icon>
          {{ isRtl ? 'chevron_right' : 'chevron_left' }}
        </v-icon>
        <span class="t-700-16">
          {{ $t('back') }}
        </span>
      </v-btn>
    </v-col>
  </v-row>
  <v-row
    v-else align="center" justify="start"
    no-gutters class="task-flters"
  >
    <v-col v-if="showAccountSelect" cols="12" class="mb-2">
      <account-select
        v-model="selectedAccount"
        class="mme-2"
        :api-key="apiKey"
        :multiple="false"
        add-account-task-count
        :client-date="clientDate"
        @change="onChangeAccount"
      />
    </v-col>
    <v-col cols="5" class="mr-auto">
      <date-range-select :value="dateRangeScope" @change="onDateRangeScopeChanged" />
    </v-col>
    <v-col v-if="showUiMode" class="flex-grow-0 flex-shrink-1 mme-2">
      <v-select
        v-model="uiMode"
        class="ui-mode-select"
        :items="uiModeOptions"
        outlined
        dense
        single-line
        @change="onUiModeChange"
      />
    </v-col>
  </v-row>
</template>
<script>

import TasksFilter from '@/components/tasks/filters/TasksFilter.vue'
import AccountSelect from '@/components/shared/AccountSelect.vue'
import DateRangeSelect from '@/components/jobs_calander/DateRangeSelect.vue'

import useAuth from '@/composables/useAuth.js'

import useDailyJobs from '@/composables/useDailyJobs.js'

import { i18n, isRtl } from '@/i18n.js'

import { computed, ref } from 'vue'
export default {
  components: {
    'tasks-filter': TasksFilter,
    'account-select': AccountSelect,
    'date-range-select': DateRangeSelect
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    staffAccount: {
      type: Boolean,
      default: false
    },
    dateRangeScope: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const selectedAccount = ref(props.apiKey)
    const tasksFilterRef = ref(null)

    const { uiMode, uiModes, clientDate, departmentSelectedUiMode, hasDepartments, toggleUiMode, unSelectDepartment } = useDailyJobs(props.apiKey)
    const { isCompanyAdminRegionOrAbove, isUserAccountEmployee } = useAuth()

    // Methods
    const onChangeAccount = () => emit('change-account', selectedAccount.value)
    const onDateRangeScopeChanged = (dateRangeScope) => emit('date-range-scope-changed', dateRangeScope)
    const emitFilterChanged = () => tasksFilterRef.value.emitFilterChanged()

    const onUiModeChange = toggleUiMode
    const filterChanged = (filters) => emit('filters-changed', filters)
    const uiModeOptions = [
      { text: i18n.t('tasks_page.departments'), value: uiModes.departments },
      { text: i18n.t('tasks_page.jobs'), value: uiModes.jobs }
    ]

    // Computed
    const isDayDateRangeScope = computed(() => props.dateRangeScope === 'day')
    const showUiMode = computed(() => !isUserAccountEmployee.value && !props.staffAccount && hasDepartments.value &&
      isDayDateRangeScope.value && !departmentSelectedUiMode.value)
    const showTasksFilters = computed(() => !departmentSelectedUiMode.value)
    const showAccountSelect = computed(() => !props.staffAccount && isCompanyAdminRegionOrAbove.value && !departmentSelectedUiMode.value)
    const hiddenTaskFilters = computed(() => {
      if (props.staffAccount) return ['crossAccount', 'shift', 'department']
      if (isUserAccountEmployee.value) return ['department', 'myTasks']
      return []
    })

    return {
      uiMode,
      uiModes,
      tasksFilterRef,
      showUiMode,
      clientDate,
      isUserAccountEmployee,
      selectedAccount,
      showAccountSelect,
      hiddenTaskFilters,
      uiModeOptions,
      departmentSelectedUiMode,
      showTasksFilters,
      isRtl,
      onUiModeChange,
      onChangeAccount,
      filterChanged,
      onDateRangeScopeChanged,
      emitFilterChanged,
      unSelectDepartment
    }
  }
}
</script>
<style lang="scss">
.task-flters {
  .ui-mode-select {
    min-width: 170px;

    .v-text-field__details {
      display: none;
    }
  }

  .account-search,
  .ui-mode-select {
    border-radius: 10px;

    fieldset {
      border-color: black;
    }
  }
}
</style>
