<template>
  <tr :class="{ 'pointer': !readOnly }" @click="editJob">
    <v-row
      align="center" justify="start" class="table-row-border-top py-1"
      no-gutters
    >
      <v-col cols="3" />
      <v-col cols="6" class="text-center font-weight-bold">
        {{ job.name }}
        <div v-if="showAllowedExecutors" class="red--text">
          {{ job.allowedExecutors[0] }}
        </div>
      </v-col>
      <v-col cols="3" class="texts-end">
        <v-btn
          v-if="showExtraExecutors" icon class="mme-1"
          @click.stop="openExtraExecutors"
        >
          <v-icon color="red" medium>
            person
          </v-icon>
        </v-btn>
        <v-btn
          v-if="!readOnly" icon class="mme-1"
          @click.stop="deleteJob"
        >
          <v-icon color="black" medium>
            delete
          </v-icon>
        </v-btn>
        <v-btn
          v-if="!readOnly" icon class="mme-1"
          @click.stop="editJob"
        >
          <v-icon color="black" medium>
            edit
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </tr>
</template>
<script>
import { computed } from 'vue'
export default {
  props: {
    job: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const deleteJob = () => emit('delete-job', props.job.id)
    const editJob = () => {
      if (props.readOnly) return
      emit('edit-job', props.job)
    }
    const openExtraExecutors = () => emit('open-extra-executors', props.job)

    const showAllowedExecutors = computed(() => props.job.allowedExecutors.length > 0)
    const showExtraExecutors = computed(() => props.job.allowedExecutors.length > 1)
    return {
      showAllowedExecutors,
      showExtraExecutors,
      openExtraExecutors,
      deleteJob,
      editJob
    }
  }
}
</script>
