<template>
  <tr :class="rowDisabled ? '' : 'pointer'" @click="rowDisabled ? null : editEmployee">
    <v-row
      align="center" justify="start" class="font-weight-bold table-row-border-top pa-4"
      no-gutters
    >
      <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="4" class="texts-start">
        <v-btn
          v-if="showInviteButton"
          class="mx-2"
          color="red white--text"
          :disabled="isLoading || rowDisabled"
          width="150"
          :loading="isLoading"
          @click.stop="sendIvitation"
        >
          {{ $t("employees.invite") }}
        </v-btn>
        <v-chip
          v-if="showAcceptedChip"
          class="mx-2 invite-btn-width"
          color="green"
          text-color="white"
        >
          <div class="text-center invite-btn-width">
            <v-icon>done</v-icon>
          </div>
        </v-chip>
        <v-chip
          v-if="showPendingChip"
          class="mx-2"
          rounded
          width="150"
          color="primary"
          text-color="white"
        >
          {{ $t("employees.pending") }}
        </v-chip>
      </v-col>
      <v-col cols="12" sm="4" class="text-center pb-0 pt-2">
        {{ fullName }}
        <div>
          <v-chip
            rounded
            small
            width="150"
            color="primary"
            text-color="white"
          >
            {{ employee.roleText }}
          </v-chip>
        </div>
      </v-col>
      <v-col
        cols="12" sm="4"
        class="text-sm-right text-center my-2 my-sm-0"
        :class="$vuetify.breakpoint.smAndUp ? 'texts-end' : 'text-center'"
      >
        <v-btn
          v-if="!rowDisabled" icon class="mx-1"
          @click.stop="deleteEmployee"
        >
          <v-icon color="black" medium>
            delete
          </v-icon>
        </v-btn>
        <v-btn
          v-if="!rowDisabled" icon class="mx-1"
          @click="editEmployee"
        >
          <v-icon color="black" medium>
            edit
          </v-icon>
        </v-btn>
        <v-menu
          transition="slide-y-transition"
          bottom left
        >
          <template #activator="{ on }">
            <v-btn
              class="mx-1"
              icon
              @click.stop
              v-on="on"
            >
              <v-icon>menu</v-icon>
            </v-btn>
          </template>
          <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
            <v-list-item v-if="employee.email">
              <v-btn outlined block :href="`mailto:${employee.email}`">
                <span class="mr-2 font-weight-bold">
                  <v-icon color="black">email</v-icon>
                </span>
                <span class="blue--text mr-2 texts-start">
                  {{ employee.email }}
                </span>
              </v-btn>
            </v-list-item>
            <v-list-item v-if="employee.phone">
              <v-btn outlined block :href="`tel:${employee.phone}`">
                <span class="mr-2 font-weight-bold">
                  <v-icon color="black">phone</v-icon>
                </span>
                <span class="blue--text mr-2 texts-start">
                  {{ employee.phone }}
                </span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn outlined block class="no-cursor">
                <span class="mr-2 font-weight-bold">
                  <v-icon color="black">pan_tool</v-icon>
                </span>
                <span class="blue--text mr-2 texts-start">
                  {{ employee.roleText }}
                </span>
              </v-btn>
            </v-list-item>
            <v-list-item v-if="employee.birthdate">
              <v-btn outlined block>
                <span class="mr-2 font-weight-bold">
                  <v-icon color="black">cake</v-icon>
                </span>
                <span class="blue--text mr-2 texts-start">
                  {{ birthdateUserFormat }}
                </span>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12" class="text-center">
        <v-btn
          v-if="showInviteButton" class="mx-2" color="red white--text"
          :disabled="isLoading || rowDisabled"
          width="150"
          :loading="isLoading" @click.stop="sendIvitation"
        >
          {{ $t("employees.invite") }}
        </v-btn>
        <v-chip
          v-if="showAcceptedChip" class="mx-2 invite-btn-width" color="green"
          text-color="white"
        >
          <div class="text-center invite-btn-width">
            <v-icon>done</v-icon>
          </div>
        </v-chip>
        <v-chip
          v-if="showPendingChip" class="mx-2" rounded
          width="150" color="primary" text-color="white"
        >
          {{ $t("employees.pending") }}
        </v-chip>
      </v-col>
    </v-row>
  </tr>
</template>
<script>
import useEmployeeStore from '@/components/organization_structure/employee/useEmployeeStore.js'

import { isValidISO8601Date, getClientDateFormatted, ISO8601StringToDate } from '@/helpers/DateTime.js'
import { currentLanguage } from '@/composables/useLanguage.js'
import { isAccountAdminAndAbove } from '@/store/modules/EmployeeRole.js'
import useAuth from '@/composables/useAuth.js'
import useAccount from '@/composables/useAccount.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { isRtl } from '@/i18n.js'

import { computed, ref } from 'vue'

export default {
  props: {
    employee: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(false)
    const { apiKey, isUserAccountManager, isUserAccountEmployee } = useAuth()
    const { accountCountryCode } = useAccount()
    // Methods
    const deleteEmployee = () => emit('delete-employee', props.employee)
    const editEmployee = () => emit('edit-employee', props.employee)
    const sendIvitation = () => {
      const { inviteUser } = useEmployeeStore(apiKey.value)
      isLoading.value = true
      const requestParams = {
        userId: props.employee.id,
        apiKey: apiKey.value,
        language: currentLanguage.value
      }
      inviteUser(requestParams)
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    // Computed
    const fullName = computed(() => `${props.employee.first_name} ${props.employee.last_name}`)
    const showInviteButton = computed(() => props.employee.invitationNotInvited())
    const showPendingChip = computed(() => props.employee.invitationPending())
    const showAcceptedChip = computed(() => props.employee.invitationAccepted())
    const rowDisabled = computed(() => {
      if (isUserAccountEmployee.value === true || props.readOnly) return true

      return isUserAccountManager.value === true && isAccountAdminAndAbove(props.employee.role)
    })
    const birthdateUserFormat = computed(() => {
      const userBirthDate = props.employee.birthdate
      if (!isValidISO8601Date(userBirthDate)) return ''
      const userBirthDateObj = ISO8601StringToDate(userBirthDate)
      const countryCode = accountCountryCode.value
      return getClientDateFormatted({ countryCode, fromDate: userBirthDateObj })
    })
    return {
      isLoading,
      fullName,
      showInviteButton,
      showPendingChip,
      showAcceptedChip,
      birthdateUserFormat,
      rowDisabled,
      isRtl,
      deleteEmployee,
      editEmployee,
      sendIvitation
    }
  }
}
</script>
<style lang="scss" scoped>
.no-cursor {
  cursor: default;
}

.invite-btn-width {
  width: 150px
}
</style>
