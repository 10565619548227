<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card class="vdieo-preview">
      <v-card-text class="pa-0">
        <v-container>
          <v-row align="center" justify="center">
            <video controls autoplay>
              <source ref="videoSrc">
              Your browser does not support the video tag.
            </video>
          </v-row>
          <v-row
            no-gutters align="end" justify="end"
            class="pt-6 text-right"
          >
            <v-btn
              v-if="showDelete" color="blue darken-1" text
              @click="onDelete"
            >
              {{ $t('delete') }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="close">
              {{ $t('cancel') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import { nextTick, ref } from 'vue'

export default {
  props: {
    showDelete: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const dialog = ref(false)
    const videoSrc = ref(null)

    // Methods
    const onDelete = () => {
      emit('delete-click')
      close()
    }
    const close = () => (dialog.value = false)
    const openDialog = (videoUrl) => {
      dialog.value = true
      nextTick(() => (videoSrc.value.src = videoUrl))
    }

    return {
      dialog,
      videoSrc,
      openDialog,
      close,
      onDelete
    }
  }
}
</script>
<style lang="scss" scoped>
.vdieo-preview {
  video {
    width:100%;
    height:auto;
    max-height:60vh;
  }
}
</style>
