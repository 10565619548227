<template>
  <v-snackbar
    v-model="snackbarOpen"
    :timeout="timout"
    class="add-to-home"
    vertical
    :class="{ rtl: isRtl }"
  >
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12" class="text-right">
        <v-btn small icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-col>
      <!-- eslint-disable-next-line -->
      <v-col v-html="iosText" cols="12" class="text-center"></v-col>
      <v-col cols="12" class="text-center">
        {{ $t('install_prompt.ios1') }}
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn
          color="pink"
          class="mx-1 close-btm"
          text
          @click="close"
        >
          {{ $t('close') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>
<script>
import { isRtl, i18n } from '@/i18n.js'
import { ref } from 'vue'
export default {
  setup () {
    const imagePath = new URL('@/assets/ios_share_icon.svg', import.meta.url).href
    const iosText = i18n.t('install_prompt.ios', { shareIcon: `<img src="${imagePath}" class="mx-1" style="width:32px;">` })
    const timout = -1
    const snackbarOpen = ref(true)

    const close = () => (snackbarOpen.value = false)

    return {
      iosText,
      snackbarOpen,
      timout,
      isRtl,
      close
    }
  }
}
</script>
<style lang="scss">
.add-to-home {
  .v-snack__wrapper {
    min-width: 300px;
    max-width: 300px;
    .v-btn.close-btm {
      margin-top: 10px !important;
    }
  }
}
</style>
