<template>
  <v-row align="start" justify="start" no-gutters>
    <v-col class="flex-grow-0 flex-shrink-1">
      <single-blog-post-avatar :creator="post.creator" />
    </v-col>
    <v-col class="flex-grow-1 flex-shrink-0 mms-2">
      <v-card class="single-comment" color="#F4F6F8" elevation="0">
        <v-card-title>
          <single-blog-post-header
            hide-avatar
            :post="post"
            @delete-post="deletePost"
            @edit-post="editPost"
          />
        </v-card-title>
        <v-card-text>
          <single-blog-post-text :content="post.content" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  components: {
    'single-blog-post-avatar': () => import('@/components/blog/shared/SingleBlogPostAvatar.vue'),
    'single-blog-post-header': () => import('@/components/blog/shared/SingleBlogPostHeader.vue'),
    'single-blog-post-text': () => import('@/components/blog/shared/SingleBlogPostText.vue')
  },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const deletePost = () => emit('delete-post', props.post)
    const editPost = () => emit('edit-post', props.post)
    return {
      deletePost,
      editPost
    }
  }
}
</script>
