<template>
  <v-row
    align="start" justify="start" no-gutters
    class="pt-2 t-500-13"
  >
    <v-col v-if="hasCreator" cols="12">
      <v-icon color="black" class="mme-1">
        back_hand
      </v-icon>{{ $t('broken_item.created_by') }}
      <span class="t-700-13">
        {{ brokenItem.creator_first_name }} {{ brokenItem.creator_last_name }}, {{
          brokenItem.open_date_user_format }}
      </span>
      <span v-if="hasOpenTime" class="t-700-13">
        {{ brokenItem.openTimeFormatted }}
      </span>
      <div v-if="hasNumberOfDaysOpen" class="t-700-13">
        ( {{ $t('broken_item_dialog_dialog.open_days', { days: brokenItem.numberDaysOpen }) }})
      </div>
    </v-col>
    <v-col v-if="hasAssignees" cols="12" class="pt-2">
      <v-icon color="black" class="mme-1">
        how_to_reg
      </v-icon> {{ $t('broken_item.assigned_to') }}
      <v-tooltip top :disabled="!hasMultipleAssignees">
        <template #activator="{ on }">
          <span class="t-700-13" v-on="on">
            {{ assignedUserName }}
          </span>
        </template>
        <span v-if="hasMultipleAssignees">
          <div v-for="(assigned_user, index) in brokenItem.assigned_users" :key="index">
            {{ assigned_user.first_name }} {{ assigned_user.last_name }}
          </div>
        </span>
      </v-tooltip>
    </v-col>
    <v-col v-if="hasRelatedServiceProviders" cols="12" class="pt-2">
      <v-icon color="black" class="mme-1">
        build
      </v-icon> {{ $t('broken_item.service_provider') }}
      <v-tooltip top :disabled="!hasMultipleRelatedServiceProviders">
        <template #activator="{ on }">
          <span class="t-700-13" v-on="on">
            {{ serviceProviderName }}
          </span>
        </template>
        <span v-if="hasMultipleRelatedServiceProviders">
          <div v-for="provider in brokenItem.related_providers" :key="provider.id">
            {{ provider.name }}
          </div>
        </span>
      </v-tooltip>
    </v-col>
    <v-col v-if="hasDeadlineDate" cols="12" class="pt-2">
      <v-icon color="black" class="mme-1">
        today
      </v-icon>{{ $t('broken_item.repair_until') }}
      <span class="t-700-13">
        {{ brokenItem.deadlineUserFormatted }}
        <span v-if="hasDeadlineTime">
          &nbsp;-&nbsp;
          {{ brokenItem.deadlineTimeUserFormatted }}
        </span>
      </span>
    </v-col>
    <v-col v-if="showTimeLeft" cols="12" class="pt-2">
      <v-icon color="black" class="mme-1">
        schedule
      </v-icon>{{ $t('broken_item.time_left') }}
      <span class="t-700-13">
        {{ timeLeft }}
      </span>
    </v-col>
  </v-row>
</template>
<script>
import {
  isValidISO8601Date, isValidISO8601Time, dateAndTimeStringToDate,
  translateHoursAndMinutes, diffBetweenDatesInHoursAndMinutes
} from '@/helpers/DateTime.js'
import { isString } from '@/helpers/Utils.js'
import { computed } from 'vue'
export default {
  props: {
    brokenItem: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const hasCreator = computed(() => isString(props.brokenItem.creator_first_name) && isString(props.brokenItem.creator_last_name))
    const hasOpenTime = computed(() => isString(props.brokenItem.openTimeFormatted))
    const hasNumberOfDaysOpen = computed(() => Number.isInteger(props.brokenItem.numberDaysOpen))
    const hasDeadlineDate = computed(() => isValidISO8601Date(props.brokenItem.deadline_date))
    const hasDeadlineTime = computed(() => hasDeadlineDate.value && isValidISO8601Time(props.brokenItem.deadline_time))
    const showTimeLeft = computed(() => {
      if (!hasDeadlineDate.value) return false
      const deadlineDateObj = dateAndTimeStringToDate({
        dateString: props.brokenItem.deadline_date,
        timeString: props.brokenItem.deadline_time
      })
      return deadlineDateObj > new Date()
    })
    const timeLeft = computed(() => {
      const deadlineDateObj = dateAndTimeStringToDate({
        dateString: props.brokenItem.deadline_date,
        timeString: props.brokenItem.deadline_time
      })
      const { hours, minutes } = diffBetweenDatesInHoursAndMinutes(new Date(), deadlineDateObj)
      return translateHoursAndMinutes({ hours, minutes })
    })

    const hasAssignees = computed(() => {
      const assignedUsers = props.brokenItem.assigned_users
      return Array.isArray(assignedUsers) && assignedUsers.length > 0
    })

    const hasRelatedServiceProviders = computed(() => {
      const relatedServiceProviders = props.brokenItem.related_providers
      return Array.isArray(relatedServiceProviders) && relatedServiceProviders.length > 0
    })

    const hasMultipleRelatedServiceProviders = computed(() =>
      hasRelatedServiceProviders.value && props.brokenItem.related_providers.length > 1)

    const serviceProviderName = computed(() => {
      const providers = props.brokenItem.related_providers
      const serviceProvider = providers[0]
      if (providers.length > 1) {
        const providersLength = providers.length - 1
        return `${serviceProvider.name} (+${providersLength})`
      }
      return serviceProvider.name
    })

    const hasMultipleAssignees = computed(() => {
      const assignedUsers = props.brokenItem.assigned_users
      return Array.isArray(assignedUsers) && assignedUsers.length > 1
    })

    const assignedUserName = computed(() => {
      const assignedUser = props.brokenItem.assigned_users[0]
      const name = `${assignedUser.first_name} ${assignedUser.last_name}`
      if (props.brokenItem.assigned_users.length > 1) {
        const assigneesLength = props.brokenItem.assigned_users.length - 1
        return `${name} (+${assigneesLength})`
      }
      return `${assignedUser.first_name} ${assignedUser.last_name}`
    })
    return {
      hasCreator,
      hasOpenTime,
      hasAssignees,
      assignedUserName,
      hasNumberOfDaysOpen,
      hasDeadlineDate,
      hasDeadlineTime,
      hasMultipleAssignees,
      hasRelatedServiceProviders,
      hasMultipleRelatedServiceProviders,
      serviceProviderName,
      showTimeLeft,
      timeLeft
    }
  }
}
</script>
