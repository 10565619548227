<template>
  <v-avatar size="30" color="yellow darken-2 mme-2">
    <v-img v-if="showUserAvatar" :src="creator.avatar_url" />
    <span v-else class="white--text text-h5">
      {{ userInitials }}
    </span>
  </v-avatar>
</template>
<script>
import { isString } from '@/helpers/Utils.js'
import { computed } from 'vue'
export default {
  props: {
    creator: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    // Computed
    const showUserAvatar = computed(() => isString(props.creator.avatar_url))
    const userInitials = computed(() => `${props.creator.first_name.charAt(0)}${props.creator.last_name.charAt(0)}`)

    return {
      showUserAvatar,
      userInitials
    }
  }
}
</script>
