<template>
  <v-dialog
    v-model="dialog" :max-width="maxWidth" persistent
    content-class="rounded-xl"
  >
    <v-card>
      <div class="t-800-17 text-center pa-3">
        {{ titleRef }}
      </div>
      <v-card-actions class="px-2 popup-card">
        <v-row
          align="center" align-content="space-between" no-gutters
          class="text-center"
        >
          <v-col v-if="showContent" cols="12" class="px-2 t-400-14 word-break">
            {{ contentRef }}
          </v-col>
          <v-col cols="12" class="mt-3">
            <v-btn
              block color="#EC2328" class="white--text"
              depressed :loading="isLoading" :disabled="isLoading"
              @click="deleteOk"
            >
              {{ deleteBtnTextRef }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="mb-2 mt-3">
            <v-btn
              block outlined text
              :disabled="isLoading" @click="cancel"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isString } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'

import { ref, computed } from 'vue'

export default {
  props: {
    maxWidth: {
      type: String,
      required: false,
      default: '280'
    }
  },
  setup () {
    let resolveFunc
    const dialog = ref(false)
    const isLoading = ref(false)
    const titleRef = ref(null)
    const contentRef = ref(null)
    const deleteBtnTextRef = ref(null)

    const closeDialog = () => {
      isLoading.value = false
      dialog.value = false
      titleRef.value = null
      contentRef.value = null
    }
    // Methods

    const close = () => closeDialog()

    const open = ({ title, content, deleteBtnText } = { title: null, content: null }) => {
      isLoading.value = false
      dialog.value = true
      titleRef.value = isString(title) && title.length > 0 ? title : i18n.t('confirm_delete.title')
      contentRef.value = isString(content) && content.length > 0 ? content : null
      deleteBtnTextRef.value = isString(deleteBtnText) && deleteBtnText.length > 0 ? deleteBtnText : i18n.t('delete')
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }
    const cancel = () => {
      closeDialog()
      resolveFunc(false)
    }

    const deleteOk = () => {
      isLoading.value = true
      resolveFunc(true)
    }

    // Computed

    const showContent = computed(() => isString(contentRef.value) && contentRef.value.length > 0)
    return {
      dialog,
      isLoading,
      titleRef,
      contentRef,
      deleteBtnTextRef,
      showContent,
      open,
      cancel,
      deleteOk,
      close
    }
  },
  data () {
    return {
      resolve: null
    }
  }
}
</script>
