<template>
  <v-tooltip :disabled="!showRolesToolTip" bottom>
    <template #activator="{ on }">
      <span v-on="on">
        <v-icon color="rgba(0, 0, 0, 0.54)" class="material-icons-outlined pps-1">
          verified_user
        </v-icon>
        <span class="t-500-13 color5 pps-1">
          {{ rolesText }}
        </span>
      </span>
    </template>
    <div v-for="role in allRoles" :key="role.id">
      {{ role.name }}
    </div>
  </v-tooltip>
</template>
<script>
import { translateRole } from '@/store/modules/EmployeeRole.js'
import { i18n } from '@/i18n.js'
import { computed } from 'vue'
export default {
  props: {
    resource: {
      type: Object,
      required: true
    },
    showToolTip: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const showRolesToolTip = computed(() => props.showToolTip &&
      !props.resource.belongs_to_all_roles && props.resource.roles.length > 1)

    const rolesText = computed(() => {
      const belongsToAllRoles = props.resource.belongs_to_all_roles
      if (belongsToAllRoles) return i18n.t('drive.all_roles')

      const roles = props.resource.roles
      const totalRoles = roles.length
      const roleName = translateRole(roles[0])
      if (totalRoles === 1) return roleName
      return `${roleName} +${totalRoles - 1}`
    })
    const allRoles = computed(() => {
      if (props.resource.belongs_to_all_roles) return []
      return props.resource.roles.map((role, index) => ({ id: index, name: translateRole(role) }))
    })

    return {
      showRolesToolTip,
      rolesText,
      allRoles
    }
  }
}
</script>
