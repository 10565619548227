<template>
  <v-row
    align="start" justify="start" no-gutters
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <v-col cols="12" class="scrollable">
      <v-form ref="operationForm" class="cross-account-new-op">
        <color-picker-dialog
          ref="colorPickerDialog"
          v-model="newOperation.color"
          @clear="clearColor"
        />
        <item-operation-list-dialog
          v-if="apiKey"
          ref="itemOperationListDialog"
          :api-key="apiKey"
          :allow-change-item-order="false"
        />
        <operation-notification
          ref="operationNotificationDialog"
          :task-un-complete-disabled="!operationHasStartTime"
        />
        <v-row
          align="start" justify="start" no-gutters
          class="overflow-x-hidden"
        >
          <v-col v-if="dialogMode !== null" cols="9">
            <company-job-search
              ref="selectedJobs"
              v-model="accountJobs"
              :api-key="apiKey"
              :show-add-job-group="showAddNewJob"
              :disabled="isLoading"
              :operation-id="operationId"
            />
          </v-col>
          <v-col class="text-center pt-3" cols="3">
            <v-btn
              fab dark small
              color="pink" @click="openColorPicker"
            >
              <v-icon>color_lens</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="9">
            <operation-description-field
              v-model="newOperation.description"
              :operation-type="newOperation.operation_type"
            />
          </v-col>
          <v-col cols="3" class="text-center pt-6">
            <v-btn
              small icon :disabled="requireImage"
              @click="openOperationListItem"
            >
              <v-icon :color="hasListItem ? 'green' : 'black'">
                format_list_bulleted
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <operation-frequency-field
              :frequency-model.sync="frequencyModel"
              :frequency-exect-date.sync="frequencyExectDate"
              :frequency-every-year.sync="frequencyYearDate"
              :end-date.sync="endDate"
              single-every-year-date
            />
          </v-col>
          <v-col cols="9" class="d-flex align-start">
            <v-select
              v-model="shiftPart"
              :items="shiftParts"
              :menu-props="{ 'contentClass': isRtl ? 'rtl' : 'ltr' }"
              :disabled="shiftPartDisabled"
              item-text="translateName"
              item-value="name"
              class="ppe-2"
              single-line
              return-object
            />
            <time-picker-dialog v-model="startTime" />
          </v-col>
          <v-col class="text-center pt-1 pl-1" cols="3">
            <v-checkbox
              v-model="requireImage"
              color="black"
              class="d-inline-block"
              :disabled="hasListItem"
              off-icon="add_a_photo"
              on-icon="add_a_photo"
            />
          </v-col>
          <v-col cols="9">
            <operation-guidence-images
              ref="opImages"
              record-type="CrossAccountOperation"
            />
          </v-col>
          <v-col v-if="isCreateMode" class="text-center pt-3" cols="3">
            <v-btn
              fab dark small
              color="green" @click="openNotificationDialog"
            >
              <v-icon>notifications</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="9">
            <file-uploader
              ref="opFile"
              record-type="CrossAccountOperation"
            />
          </v-col>
          <v-col cols="9" class="mt-2">
            <operation-report-tag
              v-model="reportTag"
              :menu-props="{ top: true }"
            />
          </v-col>
          <v-col cols="12">
            <v-row
              v-if="isFrequencyTypeEveryXWeek" align="start" justify="start"
              class="mt-6" no-gutters
            >
              <v-col
                v-for="day in workingDays"
                :key="day.name" cols="3"
                class="mb-3"
              >
                <v-btn
                  :input-value="activeDays[day.name]"
                  active-class="teal darken-1"
                  @click="toggleActiveDay(day.name)"
                >
                  {{ day.translatedName }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-col cols="12">
      <v-card-actions class="pt-5 pr-0">
        <v-spacer />
        <v-btn
          color="blue darken-1" text :disabled="isLoading"
          @click="close()"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="blue darken-1" depressed tile
          :loading="isLoading" :disabled="saveDisabled"
          @click="save"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-col>
  </v-row>
</template>
<script>

import CompanyJobSearch from '@/components/operation_schedule_dialog/cross_account_operation/ScheduleNewCrossAccountOperationJobSearch.vue'
import ColorPickerDialog from '@/components/operation_schedule_dialog/ColorPickerDialog.vue'

import OperationDescriptionField from '@/components/operation_schedule_dialog/OperationDescriptionField.vue'
import OperationFrequencyField from '@/components/operation_schedule_dialog/OperationFrequencyField.vue'
import ItemOperationListDialog from '@/components/operation_schedule_dialog/item_operation_list/ItemOperationListDialog.vue'
import OperationGuidenceImages from '@/components/operation_schedule_dialog/OperationGuidenceImages.vue'
import FileUploader from '@/components/shared/FileUploader.vue'
import TimePickerDialog from '@/components/shared/TimePickerDialog.vue'
import OperationNotification from '@/components/operation_schedule_dialog/OperationNotification.vue'

import { shiftPartsForSelect as OpModuleShiftParts, newOperationScheduleModel } from '@/store/modules/OperationSchedule.js'
import { operationFrequencyOptionsForSelect, FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { newDeviceOperationModel } from '@/store/modules/DeviceOperation.js'
import { loadAllOperationTypes } from '@/composables/useOperationType.js'
import useAuth from '@/composables/useAuth.js'

import { create as operationBatchCreateCrossAccount, update as operationBatchUpdateCrossAccount, itemListOperationSetter } from '@/api_client/CrossAccountOperation.js'

import { daysOfTheWeek, isValidISO8601Date, dayNameFromISO8601String } from '@/helpers/DateTime.js'

import { EventBus, RESET_OPERATION_DIALOG_CONTENT, NEW_CROSS_ACCOUNT_OPERATION } from '@/EventBus.js'
import { isObject, isString } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { isRtl } from '@/i18n.js'
import { computed, ref, onUnmounted, nextTick } from 'vue'

const DialogMode = { create: 0, update: 1, copy: 2 }
const DefaultFrequnecy = { frequency: null, frequencyType: null }
const defaultTaskNotifications = () => ({ taskComplete: false, taskUncomplete: false })

export default {
  components: {
    'company-job-search': CompanyJobSearch,
    'color-picker-dialog': ColorPickerDialog,
    'operation-description-field': OperationDescriptionField,
    'operation-frequency-field': OperationFrequencyField,
    'item-operation-list-dialog': ItemOperationListDialog,
    'operation-guidence-images': OperationGuidenceImages,
    'file-uploader': FileUploader,
    'time-picker-dialog': TimePickerDialog,
    'operation-report-tag': () => import('@/components/operation_schedule_dialog/OperationReportTagField.vue'),
    'operation-notification': OperationNotification
  },
  setup (_, { emit }) {
    const dialogMode = ref(null)
    const frequencyExectDate = ref(null)
    const frequencyYearDate = ref([])
    const operationId = ref(null)
    const apiKey = ref(null)
    const requireImage = ref(false)
    const startTime = ref(null)
    const endDate = ref(null)
    const reportTag = ref(null)
    const taskNotifications = ref(defaultTaskNotifications())
    const selectedJobs = ref(null)
    const operationForm = ref(null)
    const opImages = ref(null)
    const opFile = ref(null)
    const newOperation = ref(newDeviceOperationModel())
    const isLoading = ref(false)
    const accountJobs = ref([])
    const newItemList = ref(null)
    const originalItemList = ref(null)
    const frequencyModel = ref(DefaultFrequnecy)
    const shiftPart = ref(null)
    const itemOperationListDialog = ref(null)
    const operationNotificationDialog = ref(null)
    const colorPickerDialog = ref(null)
    const shiftParts = ref(OpModuleShiftParts())

    const frequencyOptions = operationFrequencyOptionsForSelect()
    const dayOfTheWeekObj = {}
    const activeDaysVal = {}
    daysOfTheWeek().map((dayOfWeek) => (activeDaysVal[dayOfWeek.name] = false))
    const activeDays = ref(activeDaysVal)
    const reserContent = () => {
      dialogMode.value = null
      frequencyExectDate.value = null
      frequencyYearDate.value = []
      operationId.value = null
      apiKey.value = null
      requireImage.value = false
      startTime.value = null
      endDate.value = null
      reportTag.value = null
      taskNotifications.value = defaultTaskNotifications()
      if (selectedJobs.value) {
        selectedJobs.value.reset()
      }
      for (const day in activeDays.value) {
        activeDays.value[day] = false
      }
      operationForm.value.reset()
      opImages.value.reset()
      opFile.value.reset()
    }

    const isScheduleValid = () => {
      if (isFrequencyTypeExectDate.value) {
        return isValidISO8601Date(frequencyExectDate.value)
      }
      if (isFrequencyTypeEveryYear.value) {
        return isValidISO8601Date(frequencyYearDate.value[0])
      }
      if (isFrequencyTypeEveryDay.value) return true
      return Object.values(activeDays.value).some((isActive) => isActive === true)
    }

    const onOpenDialog = (dialogModeVal, originalItemListVal) => {
      dialogMode.value = dialogModeVal
      if (Array.isArray(originalItemListVal)) {
        if (dialogMode.value === DialogMode.update) {
          originalItemList.value = JSON.parse(JSON.stringify(originalItemListVal))
          newItemList.value = JSON.parse(JSON.stringify(originalItemListVal))
        } else if (dialogMode.value === DialogMode.copy) {
          newItemList.value = JSON.parse(JSON.stringify(originalItemListVal)).map((item) => ({ description: item.description, item_type: item.item_type }))
        }
      } else {
        newItemList.value = null
        originalItemList.value = null
      }
    }

    const upsertListItemRequestParams = () => {
      const oldList = originalItemList.value || []
      const newList = newItemList.value || []
      oldList.forEach((oldItem) => {
        const newItemIndex = newList.findIndex((item) => item.id === oldItem.id)
        if (newItemIndex === -1) {
          oldItem.is_deleted = true
          return
        }
        const newItem = newList[newItemIndex]
        if (newItem.description !== oldItem.description || newItem.item_type !== oldItem.item_type) {
          oldItem.is_updated = true
          oldItem.description = newItem.description
          oldItem.item_type = newItem.item_type
        }
      })
      const newItemListParam = newList.filter((item) => !Number.isInteger(item.id))
      return {
        update_params: oldList,
        create_params: newItemListParam
      }
    }

    const saveOperationImages = async ({ apiKey, operationId, imageUploadSignatures }) => {
      const shouldSaveImages = opImages.value.hasChanges()
      if (shouldSaveImages !== true) return

      return opImages.value.saveImages({ apiKey, operationId, imageUploadSignatures })
    }

    const saveOperationFile = async ({ apiKey, operationId, fileUploadSignature }) =>
      opFile.value.saveFile({ apiKey, operationId, fileUploadSignature })

    const saveAdditionalFiles = async ({ apiKey, operationId, imageUploadSignatures, fileUploadSignature }) =>
      Promise.all([
        saveOperationImages({ apiKey, operationId, imageUploadSignatures }),
        saveOperationFile({ apiKey, operationId, fileUploadSignature })
      ])

    const itemListChanged = () => {
      const oldList = originalItemList.value || []
      const newList = newItemList.value || []
      if (oldList.length !== newList.length) return true
      return newList.some((newItem, index) => {
        const oldItem = oldList[index] || {}
        return (newItem.id !== oldItem.id || newItem.description !== oldItem.description ||
          newItem.item_type !== oldItem.item_type)
      })
    }

    const saveListItem = async ({ apiKey, operationId }) => {
      if (!itemListChanged()) return
      const requestParams = upsertListItemRequestParams()
      return itemListOperationSetter(apiKey, operationId, requestParams)
    }

    const initFrequncyParams = (operationSchedule) => {
      const preDefinedFrequency = frequencyOptions.find((frequency) => frequency.frequencyType === operationSchedule.frequency_type &&
        frequency.frequency === operationSchedule.frequency)

      if (!isObject(preDefinedFrequency)) return

      frequencyModel.value = preDefinedFrequency
      if (frequencyModel.value.frequencyType === FrequencyTypes.ExectDate) {
        frequencyExectDate.value = operationSchedule.operation_next_date_raw
      }
      if (frequencyModel.value.frequencyType === FrequencyTypes.EveryYear) {
        frequencyYearDate.value[0] = operationSchedule.operation_next_date_raw
      }
    }

    const initUpdateModels = ({ operation, apiKeyVal }) => {
      const operationSchedule = newOperationScheduleModel(operation)
      requireImage.value = operationSchedule.require_image
      startTime.value = operationSchedule.start_time
      endDate.value = operationSchedule.end_date
      reportTag.value = operationSchedule.report_tag
      if (isString(operation.shift_part)) {
        shiftPart.value = { name: operation.shift_part }
      }
      newOperation.value = operationSchedule.getItemOperation()
      operationId.value = operation.operation_id
      apiKey.value = apiKeyVal
      if (dialogMode.value === DialogMode.update) {
        nextTick(() => {
          opImages.value.setImages(operationSchedule.guidance_images)
          opFile.value.setFile(operationSchedule.guidance_file)
        })
      }
      initFrequncyParams(operationSchedule)
      if (isFrequencyTypeEveryXWeek.value) {
        const dayName = dayNameFromISO8601String(operation.first_scheduled_date)
        if (dayName !== null) {
          activeDays.value[dayName] = true
        }
      }
    }

    const notificationParams = () => {
      const notificationsVal = taskNotifications.value
      const result = {}
      if (!notificationsVal.taskComplete && !notificationsVal.taskUncomplete) return result
      if (notificationsVal.taskComplete) result.task_complete = true
      if (operationHasStartTime.value && notificationsVal.taskUncomplete === true) result.task_uncomplete = true
      return result
    }

    const operationScheduleParams = () => {
      const frequencyParams = frequencyModel.value || {}
      const paramsResult = {
        frequency_type: frequencyParams.frequencyType || null,
        frequency: frequencyParams.frequency || null,
        require_image: requireImage.value,
        start_time: startTime.value,
        report_tag: reportTag.value,
        shift_part: shiftPart.value.name
      }
      if (isFrequencyTypeExectDate.value) {
        paramsResult.frequency = frequencyExectDate.value
        paramsResult.end_date = endDate.value
      } else if (isFrequencyTypeEveryYear.value) {
        paramsResult.frequency = frequencyYearDate.value[0]
      } else if (!isFrequencyTypeEveryDay.value) {
        for (const day in activeDays.value) {
          if (activeDays.value[day] === true) {
            paramsResult.day = day
            break
          }
        }
      }
      return paramsResult
    }

    const getRequestParams = () => {
      const itemOperation = newOperation.value.serialize()
      const operationSchedule = operationScheduleParams()
      const apiKeys = accountJobs.value.map((account) => account.account_api_key)
      const requestParams = {
        operation_schedule: { ...itemOperation, ...operationSchedule },
        accounts_api_keys: [...new Set(apiKeys)],
        account_job_ids: accountJobs.value.map((job) => job.id)
      }
      const uploadImageCount = opImages.value.uploadImageCount()
      if (Number.isInteger(uploadImageCount) && uploadImageCount > 0) {
        requestParams.with_images = uploadImageCount
      }
      const attachedFileName = opFile.value.attachedFileName()
      if (isString(attachedFileName) === true) {
        requestParams.with_file = attachedFileName
      }
      if (isCreateMode.value) {
        requestParams.operation_schedule.notifications = notificationParams()
      }
      return requestParams
    }

    const updateOrCreateCrossAccountOperationPromise = () => {
      const requestParams = getRequestParams()
      if (dialogMode.value === DialogMode.create || dialogMode.value === DialogMode.copy) {
        const { apiKey } = useAuth()
        return operationBatchCreateCrossAccount(apiKey.value, requestParams)
      }
      return operationBatchUpdateCrossAccount(apiKey.value, operationId.value, requestParams)
    }

    // Methods

    const openDialog = () => {
      onOpenDialog(DialogMode.create)
      newOperation.value = newDeviceOperationModel()
      const { apiKey: apiKeyVal } = useAuth()
      apiKey.value = apiKeyVal.value
      frequencyModel.value = DefaultFrequnecy
      shiftPart.value = shiftParts.value.find((shiftPart) => shiftPart.name === 'start')
    }

    const openForUpdate = (operation, apiKeyVal) => {
      onOpenDialog(DialogMode.update, operation.item_operation_list)
      initUpdateModels({ operation, apiKeyVal })
    }

    const openForCopy = (operation, apiKeyVal) => {
      onOpenDialog(DialogMode.copy, operation.item_operation_list)
      initUpdateModels({ operation, apiKeyVal })
    }

    const close = () => emit('close')
    const openColorPicker = () => colorPickerDialog.value.openDialog()
    const clearColor = () => newOperation.value.clearColor()

    const openOperationListItem = () => {
      let itemList = Array.isArray(newItemList.value) ? newItemList.value : originalItemList.value
      itemList = JSON.parse(JSON.stringify(itemList || []))
      const itemOperation = JSON.parse(JSON.stringify(newOperation.value))
      itemOperationListDialog.value.openDialogWithoutPersist(itemOperation.id, itemList)
        .then(({ isSaved, itemList }) => {
          if (!isSaved) return
          newItemList.value = itemList
        })
    }

    const openNotificationDialog = () => operationNotificationDialog.value.openDialog({
      taskComplete: taskNotifications.value.taskComplete,
      taskUncomplete: taskNotifications.value.taskUncomplete
    }).then(({ saved, taskComplete, taskUncomplete }) => {
      if (!saved) return
      taskNotifications.value.taskComplete = taskComplete
      taskNotifications.value.taskUncomplete = taskUncomplete
    })
    const toggleActiveDay = (dayName) => {
      for (const day in activeDays.value) {
        if (day === dayName) {
          activeDays.value[day] = !activeDays.value[day]
        } else {
          activeDays.value[day] = false
        }
      }
    }

    const save = () => {
      isLoading.value = true
      const operationData = {}
      updateOrCreateCrossAccountOperationPromise()
        .then(async (response) => {
          const responseData = response.data || {}
          operationData.imageUploadSignatures = responseData.image_upload_signature
          delete responseData.image_upload_signature
          operationData.fileUploadSignature = responseData.file_upload_signature
          delete responseData.file_upload_signature

          // in case all operations deleted an empty response will return
          if (Object.keys(responseData).length === 0) return

          const apiKey = Object.keys(responseData)[0]
          operationData.apiKey = apiKey
          operationData.operationId = responseData[apiKey].operation_schedule.id
          return saveListItem(operationData)
        })
        .then(() => saveAdditionalFiles(operationData))
        .then(() => {
          EventBus.emit(NEW_CROSS_ACCOUNT_OPERATION)
          close()
        })
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    // Computed
    const isCreateMode = computed(() => dialogMode.value === DialogMode.create)
    const isAtLeastOneAccountSelected = computed(() => accountJobs.value.length > 0)
    const operationHasStartTime = computed(() => isString(startTime.value))
    const showAddNewJob = computed(() => !Number.isInteger(operationId.value))
    const isOperationDesctiptionValid = computed(() => {
      const description = newOperation.value?.description
      if (!isString(description)) return true
      return description.trim().length <= 240
    })
    const isFrequencyTypeExectDate = computed(() => {
      if (!isObject(frequencyModel.value)) return false
      return frequencyModel.value.frequencyType === FrequencyTypes.ExectDate
    })
    const isFrequencyTypeEveryYear = computed(() => {
      if (!isObject(frequencyModel.value)) return false
      return frequencyModel.value.frequencyType === FrequencyTypes.EveryYear
    })

    const isFrequencyTypeEveryDay = computed(() => {
      if (!isObject(frequencyModel.value)) return false
      return frequencyModel.value.frequencyType === FrequencyTypes.EveryDay
    })

    const isFrequencyTypeEveryXWeek = computed(() =>
      isObject(frequencyModel.value) && frequencyModel.value.frequencyType === FrequencyTypes.EveryWeek)

    const hasListItem = computed(() => {
      if (!Array.isArray(newItemList.value)) {
        return Array.isArray(originalItemList.value) && originalItemList.value.length > 0
      }
      return newItemList.value.length > 0
    })
    const saveDisabled = computed(() => {
      if (isLoading.value === true) return true
      if (!isAtLeastOneAccountSelected.value && dialogMode.value === DialogMode.create) return true
      if (!isAtLeastOneAccountSelected.value && dialogMode.value === DialogMode.update) return false
      if (!isScheduleValid()) return true
      if (!isOperationDesctiptionValid.value) return true
      return false
    })

    const managersIntersectWorkingDays = computed(() => {
      const workingDays = []
      accountJobs.value.forEach((accountJob) => {
        const accountManagerWorkingDays = accountJob.working_days
        if (Array.isArray(accountManagerWorkingDays)) {
          workingDays.push(accountManagerWorkingDays)
        }
      })
      if (workingDays.length === 0) return []
      return workingDays.reduce((a, b) => a.filter(c => b.includes(c)))
    })

    const workingDays = computed(() => {
      if (!isAtLeastOneAccountSelected.value || isFrequencyTypeExectDate.value) return []
      const result = []
      managersIntersectWorkingDays.value.forEach((dayOfWork) => {
        const day = Object.assign({}, dayOfTheWeekObj[dayOfWork])
        result.push(day)
      })
      return result
    })
    const shiftPartDisabled = computed(() => isFrequencyTypeExectDate.value || isFrequencyTypeEveryYear.value)

    loadAllOperationTypes()
    EventBus.on(RESET_OPERATION_DIALOG_CONTENT, reserContent)
    daysOfTheWeek().forEach((dayOfWeek) => (dayOfTheWeekObj[dayOfWeek.name] = dayOfWeek))
    onUnmounted(() => EventBus.off(RESET_OPERATION_DIALOG_CONTENT, reserContent))

    return {
      dialogMode,
      isLoading,
      accountJobs,
      frequencyExectDate,
      frequencyYearDate,
      operationId,
      apiKey,
      requireImage,
      startTime,
      showAddNewJob,
      endDate,
      reportTag,
      taskNotifications,
      activeDays,
      selectedJobs,
      operationForm,
      opImages,
      opFile,
      newOperation,
      isCreateMode,
      saveDisabled,
      operationHasStartTime,
      hasListItem,
      isFrequencyTypeEveryXWeek,
      workingDays,
      frequencyModel,
      shiftPart,
      itemOperationListDialog,
      operationNotificationDialog,
      colorPickerDialog,
      shiftParts,
      shiftPartDisabled,
      isRtl,
      openDialog,
      openForUpdate,
      openForCopy,
      close,
      openColorPicker,
      clearColor,
      openOperationListItem,
      openNotificationDialog,
      toggleActiveDay,
      save
    }
  }
}
</script>
<style lang="scss">
.cross-account-new-op {
  .description-text.v-text-field.rtl {
    label.v-label.theme--light {
      left: 0 !important;
    }
  }

  .v-input {
    .v-label {
      color: black;
      font-weight: 400;
    }

    .v-input__slot ::placeholder {
      color: black;
    }
  }

  @media only screen and (min-width: 600px) {
    .scrollable {
      overflow: auto;
      height: 100%;
      max-height: 60vh;
    }
  }
}
</style>
