<template>
  <v-row
    align="center" justify="space-between" no-gutters
    class="pt-2"
  >
    <v-menu v-if="allowEdit" offset-y top>
      <template #activator="{ on, attrs }">
        <v-chip
          v-bind="attrs" small class="status-chip"
          v-on="on"
        >
          {{ statusName }}
          <v-icon>keyboard_arrow_down</v-icon>
        </v-chip>
      </template>
      <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
        <v-list-item v-for="status in statuses" :key="status.name" @click="onChangeStatus(status.name)">
          <v-list-item-title>{{ status.translatedName }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-chip
      v-if="showChat" class="pointer chip-border" small
      @click="onChatClick"
    >
      {{ $t('comments') }}
      <span v-if="hasMessages">
        &nbsp;({{ brokenItem.chat_messages_count }})
      </span>
    </v-chip>
    <share-content :absolute="false" :direction="`top`" :share-content="createShareContent">
      <template #share>
        <v-chip small class="pointer chip-border">
          {{ $t('share') }}
        </v-chip>
      </template>
    </share-content>
    <v-menu v-if="showOptions" offset-y top>
      <template #activator="{ on, attrs }">
        <v-chip
          small v-bind="attrs" class="chip-border"
          v-on="on"
        >
          {{ $t('broken_item.options') }}
        </v-chip>
      </template>
      <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
        <v-list-item v-if="allowEdit" class="px-2" @click="onEdit">
          <v-list-item-content class="px-1">
            {{ $t('edit') }}
          </v-list-item-content>
          <v-icon>edit</v-icon>
        </v-list-item>
        <v-list-item v-if="allowDelete" class="px-2" @click="onDelete">
          <v-list-item-content class="px-1">
            {{ $t('delete') }}
          </v-list-item-content>
          <v-icon>delete</v-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-row>
</template>

<script>
import { getAllStatuses } from '@/store/modules/BrokenItem.js'
import { createShareContent as createBrokenItemShareContent } from '@/classes/BrokenItemShareContent.js'
import { i18n, isRtl } from '@/i18n.js'
import { computed, watch, ref } from 'vue'
export default {
  components: {
    'share-content': () => import('@/components/shared/ShareContent.vue')
  },
  props: {
    brokenItem: {
      type: Object,
      required: true
    },
    allowDelete: {
      type: Boolean,
      required: true
    },
    allowEdit: {
      type: Boolean,
      required: true
    },
    showCloseOption: {
      type: Boolean,
      default: false
    },
    showChat: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    const statuses = ref([])
    const statusName = computed(() => {
      const status = props.brokenItem.status
      return statuses.value.find(statusRow => statusRow.name === status)?.translatedName
    })

    // Methods
    const onChatClick = () => emit('open-chat')
    const onEdit = () => emit('edit-broken-item')
    const onDelete = () => emit('delete-broken-item')
    const onChangeStatus = (newStatus) => {
      if (newStatus === props.brokenItem.status) return
      emit('change-status', newStatus)
    }
    const createShareContent = () =>
      createBrokenItemShareContent({ brokenItem: props.brokenItem, apiKey: props.brokenItem.account_api_key })

    // Computed
    const hasMessages = computed(() => Number.isInteger(props.brokenItem.chat_messages_count) && props.brokenItem.chat_messages_count > 0)
    const showOptions = computed(() => props.allowDelete || props.allowEdit)

    watch(() => props.showCloseOption, (isShow) => {
      const closeTranslation = i18n.t('broken_item.close_ticket')
      statuses.value = getAllStatuses({ addClosedStatus: isShow, closeTranslation })
    }, { immediate: true })
    return {
      statuses,
      statusName,
      hasMessages,
      showOptions,
      isRtl,
      onChangeStatus,
      onChatClick,
      createShareContent,
      onEdit,
      onDelete
    }
  }
}
</script>

<style lang="scss">
.single-broken-item {
  .v-speed-dial--bottom {
    bottom: unset;
    right: unset;
  }

  .chip-border {
    border: 1px #011D35 solid;
    background: unset !important;
  }

  .status-chip {
    border: 1.5px #FF1744 solid;
    background-color: #FFF !important;
  }
}
</style>
