const filedList = ['id', 'name', 'contact_name', 'email', 'phone', 'comment']
function CompanyProvider (src) {
  const srcOrEmpty = src || {}
  filedList.forEach((field) => {
    this[field] = srcOrEmpty[field]
  })
}

CompanyProvider.prototype.serialize = function () {
  const creationParams = JSON.parse(JSON.stringify(this))
  const result = filedList.reduce((initValue, currentValue) => {
    initValue[currentValue] = creationParams[currentValue]
    return initValue
  }, {})
  return result
}

const newCompanyProviderModel = (src) => {
  return new CompanyProvider(src)
}

export {
  newCompanyProviderModel
}
