<template>
  <v-row v-if="showLoading" align="center" justify="center">
    <v-progress-circular indeterminate color="primary" />
  </v-row>
  <v-row
    v-else align="start" justify="center"
    class="text-center job-calender"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <single-job-operations-dialog
      ref="operationsDialog"
      :api-key="apiKey"
      :staff-account="staffAccount"
    />
    <v-col v-if="showMonthRange" cols="12">
      <v-calendar-monthly
        v-if="dateRangeScope"
        :now="clientCurrentDate"
        :start="startDateIso"
        :end="endDateIso"
        :locale="isRtl ? 'he' : 'en'"
        color="primary"
        short-intervals
      >
        <template #day="{ date }">
          <job-calander-monthly-day-view
            :day-events="eventsByDate[date]"
            @click="onEventClick"
          />
        </template>
        <template #day-label="{ day, present }">
          <div class="px-2 py-1 t-700-14 color13 texts-end" :class="{ 'bg-color1 white--text': present }">
            {{ day }}
          </div>
        </template>
      </v-calendar-monthly>
    </v-col>
    <v-col v-else cols="12">
      <job-calander-weekly-view :week-start="dateRangeStart" :events="eventsByDate" @click="onEventClick" />
    </v-col>
  </v-row>
</template>
<script>

import JobCalanderWeeklyView from '@/components/jobs_calander/JobCalanderWeeklyView.vue'
import JobCalanderMonthlyDayView from '@/components/jobs_calander/JobCalanderMonthlyDayView.vue'
import SingleJobOperationsDialog from '@/components/jobs_calander/SingleJobOperationsDialog.vue'

import useJobCalander from '@/composables/useJobCalander.js'
import useAuth from '@/composables/useAuth.js'

import { ISO8601StringToDate, isValidISO8601Date, dateToISO8601String } from '@/helpers/DateTime.js'

import { isRtl } from '@/i18n'

import { computed, watch, ref } from 'vue'
export default {
  components: {
    'job-calander-weekly-view': JobCalanderWeeklyView,
    'job-calander-monthly-day-view': JobCalanderMonthlyDayView,
    'single-job-operations-dialog': SingleJobOperationsDialog
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    dateRangeScope: {
      type: String,
      required: true,
      validator: (value) => {
        return ['week', 'month'].includes(value)
      }
    },
    clientDate: {
      type: Date,
      required: true
    },
    staffAccount: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const operationsDialog = ref(null)
    const { isLoading, clientDate, eventsByDate, clientCurrentDate, dateRange, loadJobCount, changeApiKey } = useJobCalander(props.apiKey)
    const { isUserAccountEmployee } = useAuth()
    let lastFilter = {}

    // Methods
    const filterChanged = ({ filters, selectedDate }) => {
      lastFilter = filters
      clientDate.value = ISO8601StringToDate(selectedDate)
      loadJobCount({ filters, dateRangeScope: props.dateRangeScope })
    }
    const onEventClick = ({ event }) => {
      operationsDialog.value.openDialog({ jobId: event.jobId, date: ISO8601StringToDate(event.start) })
        .then(({ shouldReload }) => {
          if (!shouldReload) return
          loadJobCount({ dateRangeScope: props.dateRangeScope })
        })
    }

    // Computed
    const showMonthRange = computed(() => props.dateRangeScope === 'month')
    const dateRangeStart = computed(() => ISO8601StringToDate(dateRange.value.start))
    const startDateIso = computed(() => isValidISO8601Date(dateRange.value.start) ? dateRange.value.start : null)
    const endDateIso = computed(() => isValidISO8601Date(dateRange.value.end) ? dateRange.value.end : null)
    const isValidDateRange = computed(() => startDateIso.value !== null && endDateIso.value !== null)
    const showLoading = computed(() => !isValidDateRange.value || isLoading.value)
    watch(() => props.dateRangeScope, () => loadJobCount({ filters: lastFilter, dateRangeScope: props.dateRangeScope }))
    watch(() => props.apiKey, (newApiKey) => {
      lastFilter = {}
      changeApiKey(props.apiKey)
      loadJobCount({ filters: lastFilter, dateRangeScope: props.dateRangeScope })
    })
    watch(() => props.clientDate, (newDate) => {
      if (newDate === null) return
      if (dateToISO8601String(newDate) === dateToISO8601String(clientDate.value)) return

      clientDate.value = newDate
      loadJobCount({ filters: lastFilter, dateRangeScope: props.dateRangeScope })
    })
    return {
      operationsDialog,
      showLoading,
      dateRangeStart,
      clientCurrentDate,
      startDateIso,
      endDateIso,
      showMonthRange,
      eventsByDate,
      isUserAccountEmployee,
      isRtl,
      filterChanged,
      onEventClick
    }
  }
}
</script>
<style lang="scss">
.job-calender {
  margin-top: 0;

  .v-calendar-monthly {
    border: 2px solid #ADB2B6;

    .v-calendar-weekly__head-weekday {
      background-color: #F5F5F6;
      padding: 4px;
      font-size: 14px;
      color: #333F48;
      font-weight: 700;
      border-right: 1px solid #E0E0E0;
      border-bottom: 1px solid #E0E0E0;
    }
  }
}
</style>
