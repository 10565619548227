<template>
  <span>
    <add-to-home-screen-ios v-if="isDeviseIos" />
    <add-to-home-screen-chrome v-else />
  </span>
</template>
<script>
import { isIos } from '@/helpers/ClientDevice.js'
import AddToHomeScreenChrome from '@/components/install_prompt/AddToHomeScreenChrome.vue'
import AddToHomeScreenIOS from '@/components/install_prompt/AddToHomeScreenIOS.vue'

export default {
  components: {
    'add-to-home-screen-chrome': AddToHomeScreenChrome,
    'add-to-home-screen-ios': AddToHomeScreenIOS
  },
  data () {
    return {
      isDeviseIos: isIos()
    }
  }
}
</script>
