import { search as providersSearch } from '@/api_client/CompanyProvider.js'

import { isString } from '@/helpers/Utils.js'

import { ref } from 'vue'

const companyProvidersList = ref([])
const isLoading = ref(false)
const SEARCH_LIMIT = 100

let currentApiKey = null
let shouldReload = true

export default function CompanyProviderSearchStore (apiKey) {
  const resetValues = () => {
    isLoading.value = false
    companyProvidersList.value.splice(0, companyProvidersList.value.length)
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
    shouldReload = true
  }

  // Methods

  const loadCompanyProviders = async () => {
    if (shouldReload === false) return
    isLoading.value = true
    return providersSearch(apiKey, null, SEARCH_LIMIT)
      .then((response) => {
        const companyProvidersListVal = companyProvidersList.value
        companyProvidersListVal.splice(0, companyProvidersListVal.length)
        response.data.data.forEach((provider) => companyProvidersListVal.push(provider))
        shouldReload = false
      })
      .finally(() => (isLoading.value = false))
  }

  const invalidateCache = () => (shouldReload = true)
  return {
    isLoading,
    companyProvidersList,
    loadCompanyProviders,
    invalidateCache
  }
}
