<template>
  <v-row align="start" justify="center" class="full-height text-center">
    <v-col cols="8">
      <v-img
        :src="emptyTaskImage" contain max-width="250"
        class="mx-auto"
      />
      <div class="color10 t-700-16">
        {{ $t('tasks_page.empty_tasks') }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  setup () {
    const emptyTaskImage = new URL('@/assets/empty_tasks.png', import.meta.url).href
    return {
      emptyTaskImage
    }
  }
}
</script>
