<template>
  <v-menu v-if="showComponent" min-width="100">
    <template #activator="{ on }">
      <v-btn
        outlined small color="#212121"
        v-on="on"
      >
        <v-icon color="#212121" class="mr-1">
          more_vert
        </v-icon>
        {{ $t('blog_post.actions') }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-if="showEdit" :disabled="post.editInProgress" @click="editClick">
        <v-btn icon small :loading="post.editInProgress">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-list-item-content class="px-1">
          {{ $t('edit') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="showDelete" :disabled="post.deleteInProgress" @click="deleteClick">
        <v-btn icon small :loading="post.deleteInProgress">
          <v-icon>delete</v-icon>
        </v-btn>
        <v-list-item-content class="px-1">
          {{ $t('delete') }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import useAuth from '@/composables/useAuth.js'
import { computed } from 'vue'
export default {
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const { isCompanyAdminRegionOrAbove } = useAuth()

    // Methods
    const deleteClick = () => emit('delete-post')
    const editClick = () => emit('edit-post')

    // Computed
    const showDelete = computed(() => props.post.current_user_post || isCompanyAdminRegionOrAbove.value)
    const showEdit = computed(() => props.post.current_user_post)
    const showComponent = computed(() => showDelete.value || showEdit.value)
    return {
      showDelete,
      showEdit,
      showComponent,
      deleteClick,
      editClick
    }
  }
}
</script>
