<template>
  <v-snackbar
    v-model="snackBar"
    timeout="-1"
  >
    {{ $t('version_check.update_available') }}
    <template #action="{ attrs }">
      <v-btn
        color="pink"
        text
        v-bind="attrs"
        @click="reload"
      >
        {{ $t('version_check.reload') }}
      </v-btn>
      <v-btn
        color="pink"
        text
        v-bind="attrs"
        @click="close"
      >
        {{ $t('close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import axios from 'axios'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { ref } from 'vue'

const VERSION_CHECK_INTERVAL = 1000 * 60 * 60 * 12
const VERSION_CHECK_FIRST_CHECK = 1000 * 60 * 5

export default {
  setup () {
    let currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}'
    const snackBar = ref(false)
    const checkVersion = () => {
      axios.create({
        baseURL: import.meta.env.VITE_APP_FROONT_URL,
        headers: {
          'Content-type': 'application/json'
        }
      }).get('version.json' + '?t=' + new Date().getTime())
        .then((response) => {
          const newHash = response.data.hash
          if (currentHash !== newHash) {
            snackBar.value = true
            currentHash = newHash
          }
        })
        .catch(errHandler)
    }
    const initVersionCheck = () => {
      setTimeout(() => checkVersion(), VERSION_CHECK_FIRST_CHECK)

      setInterval(() => checkVersion(), VERSION_CHECK_INTERVAL)
    }

    const close = () => (snackBar.value = false)
    const reload = () => location.reload()

    initVersionCheck()
    return {
      snackBar,
      close,
      reload
    }
  }
}
</script>
