<template>
  <v-dialog v-model="dialog" :max-width="imageMaxWidth" @click:outside="onClose">
    <v-card class="op-guidance-images-display">
      <v-card-text class="pa-0">
        <v-btn
          v-if="isShowReset"
          color="black"
          class="ma-2 white--text"
          @click="reset"
        >
          {{ $t('reset') }}
          <v-icon>
            restart_alt
          </v-icon>
        </v-btn>
        <v-btn
          v-else-if="isShowDelete"
          fab
          color="black"
          class="ma-2 white--text"
          @click="deleteImage"
        >
          <v-icon>
            delete
          </v-icon>
        </v-btn>
        <v-carousel
          v-model="startIndex"
          :show-arrows="false" class="guidance-images-carousel" :height="imageMaxHeight"
        >
          <v-btn
            icon color="white" class="close-btn d-sm-none"
            @click="close"
          >
            <v-icon>highlight_off</v-icon>
          </v-btn>
          <v-carousel-item
            v-for="(imageUrl, index) in images"
            :key="index"
          >
            <v-row justify="center" align="center" class="full-height">
              <pinch-zoom>
                <v-col class="px-0">
                  <v-img
                    :src="betterImageQuality(imageUrl)" :max-height="imageMaxHeight" :max-width="imageMaxWidth"
                    contain
                  >
                    <template #placeholder>
                      <v-row
                        align="center" justify="center" class="fill-height"
                        no-gutters
                      >
                        <v-progress-circular indeterminate color="primary" />
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </pinch-zoom>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { betterImageQuality } from '@/helpers/Utils.js'

import Vuetify from '@/plugins/vuetify.js'
import PinchZoom from 'vue-pinch-zoom'
import { ref, computed } from 'vue'
const dialog = ref(false)
const images = ref([])
const startIndex = ref(0)
export default {
  components: {
    'pinch-zoom': PinchZoom
  },
  setup (_, { emit }) {
    const isShowReset = ref(false)
    const isShowDelete = ref(false)
    const model = ref(0)
    /// Methods
    function openDialog ({ imageUrls, index = 0, showReset = false, showDelete = false }) {
      dialog.value = true
      model.value = index
      isShowReset.value = showReset
      isShowDelete.value = showDelete
      images.value = imageUrls
      if (Number.isInteger(index) && index >= 0 && index < imageUrls.length) {
        startIndex.value = index
      }
    }

    const onClose = () => {
      images.value = []
      startIndex.value = 0
    }

    const close = () => {
      dialog.value = false
      onClose()
    }
    const reset = () => {
      emit('reset')
      close()
    }

    const deleteImage = () => {
      emit('delete-image', startIndex.value)
      close()
    }

    /// Computed
    const imageMaxWidth = computed(() => Vuetify.framework.breakpoint.xsOnly ? '90vw' : '70vw')
    const imageMaxHeight = computed(() => Vuetify.framework.breakpoint.xsOnly ? '80vh' : '70vh')
    return {
      dialog,
      model,
      images,
      startIndex,
      imageMaxWidth,
      imageMaxHeight,
      isShowReset,
      isShowDelete,
      betterImageQuality,
      openDialog,
      onClose,
      close,
      reset,
      deleteImage
    }
  }
}
</script>
<style lang="scss" scoped>
.op-guidance-images-display {
  .guidance-images-carousel {
    position: relative;

    .close-btn {
      position: absolute;
      z-index: 10;
      top: 8px;
      left: 8px
    }
  }
}
</style>
