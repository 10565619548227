import Client from '@/api_client/client.js'

import { invalidateCache } from '@/components/shared/user_search/UserSearchStore.js'

import { EventBus, USER_UPDATE } from '@/EventBus.js'

function show (apiKey, id) {
  const USER_SHOW_PATH = `/accounts/${apiKey}/users/${id}`
  return Client.get(USER_SHOW_PATH)
}

function index (apiKey, params) {
  const USER_INDEX_PATH = `/accounts/${apiKey}/users`
  return Client.get(USER_INDEX_PATH, { params })
}

function search ({ apiKey, searchTerm, limit, withHq } = {}) {
  const USER_SEARCH_PATH = `/accounts/${apiKey}/users/search`
  return Client.get(USER_SEARCH_PATH, { params: { name: searchTerm, per_page: limit, with_hq_role: withHq } })
}

function searchCrossAccount ({ apiKey, searchTerm, limit } = {}) {
  const USER_SEARCH_CROSS_ACCOUNT_PATH = `/accounts/${apiKey}/users/search_cross_account`
  return Client.get(USER_SEARCH_CROSS_ACCOUNT_PATH, { params: { name: searchTerm, per_page: limit } })
}

function searchForCompany ({ apiKey, searchTerm, limit } = {}) {
  const USER_SEARCH_FOR_COMPANY_PATH = `/accounts/${apiKey}/users/search_all_company_users`
  return Client.get(USER_SEARCH_FOR_COMPANY_PATH, { params: { name: searchTerm, per_page: limit } })
}

function addUserFromAnotherCompanyStore (apiKey, params) {
  const ADD_USERS_FROM_ANOTHER_COMPANY_STORE_PATH = `/accounts/${apiKey}/users/add_user_from_another_company_store`
  return Client.put(ADD_USERS_FROM_ANOTHER_COMPANY_STORE_PATH, { user: params })
}

async function create (apiKey, params) {
  const USERS_CREATE_PATH = `/accounts/${apiKey}/users`
  const response = await Client.post(USERS_CREATE_PATH, params)
  invalidUserSearchCache()
  return response
}

async function update (apiKey, userId, params) {
  const USERS_UPDATE_PATH = `/accounts/${apiKey}/users/${userId}`
  const response = await Client.put(USERS_UPDATE_PATH, params)
  invalidUserSearchCache()
  EventBus.emit(USER_UPDATE)
  return response
}

async function destroy (apiKey, userId) {
  const USERS_DESTROY_PATH = `/accounts/${apiKey}/users/${userId}`
  const response = await Client.delete(USERS_DESTROY_PATH)
  invalidUserSearchCache()
  return response
}

async function removeFile ({ apiKey, userId }) {
  const USERS_DELETE_ATTACHED_FILE_PATH = `/accounts/${apiKey}/users/${userId}/remove_file`
  const response = await Client.delete(USERS_DELETE_ATTACHED_FILE_PATH)
  invalidUserSearchCache()
  return response
}

async function addFiles ({ apiKey, userId, fileNames }) {
  const USERS_ADD_ATTACHED_FILES_PATH = `/accounts/${apiKey}/users/${userId}/add_files`
  const response = await Client.put(USERS_ADD_ATTACHED_FILES_PATH, { file_names: fileNames })
  invalidUserSearchCache()
  return response
}

async function removeFiles ({ apiKey, userId, fileIds }) {
  const USERS_DELETE_ATTACHED_FILES_PATH = `/accounts/${apiKey}/users/${userId}/remove_files`
  const response = await Client.delete(USERS_DELETE_ATTACHED_FILES_PATH, { params: { file_ids: fileIds } })
  invalidUserSearchCache()
  return response
}

function inviteUser (params) {
  const USERS_INVITE_PATH = '/auth/invitation'
  return Client.post(USERS_INVITE_PATH, params)
}

function me (apiKey) {
  return Client.get(`/accounts/${apiKey}/users/me`)
}

function invalidUserSearchCache () {
  invalidateCache()
}

export {
  search,
  searchCrossAccount,
  searchForCompany,
  show,
  addUserFromAnotherCompanyStore,
  index,
  create,
  update,
  destroy,
  inviteUser,
  me,
  removeFile,
  addFiles,
  removeFiles
}
