<template>
  <v-select
    :value="value"
    :items="frequencyOptions"
    :disabled="disabled"
    :menu-props="{ 'contentClass': isRtl ? 'rtl' : 'ltr' }"
    item-text="translatedName"
    :label="$t('op_schedule_frequency_dialog.placeholder')"
    single-line
    return-object
    @input="update"
    @change="change"
  >
    <template #selection="{ item }">
      <div class="v-select__selection v-select__selection--comma">
        {{ selectedText(item) }}
      </div>
    </template>
  </v-select>
</template>
<script>
import { operationFrequencyOptionsForSelect, FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { isValidISO8601Date, getClientDateFormatted, ISO8601StringToDate } from '@/helpers/DateTime.js'

import useAccount from '@/composables/useAccount.js'

import { i18n, isRtl } from '@/i18n.js'

import { computed } from 'vue'
export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: null
    },
    exectDate: {
      type: String,
      required: false,
      default: null
    },
    everyYearDates: {
      type: Array,
      required: false,
      default: () => []
    },
    allowedFrequencies: {
      type: Array,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    endDate: {
      type: String,
      required: false,
      default: null
    }
  },
  setup (props, { emit }) {
    const defaultFrequencyOptions = operationFrequencyOptionsForSelect()
    const { accountCountryCode } = useAccount()
    const countryCode = accountCountryCode.value

    // Methods
    const update = (newValue) => emit('input', newValue)
    const change = (newValue) => emit('change', newValue)
    const selectedText = (item) => {
      if (item.frequencyType === FrequencyTypes.ExectDate &&
        isValidISO8601Date(props.exectDate)) {
        const fromDate = ISO8601StringToDate(props.exectDate)

        const fromDateFormatted = getClientDateFormatted({ fromDate, countryCode })
        if (!isValidISO8601Date(props.endDate)) return fromDateFormatted

        const toDate = ISO8601StringToDate(props.endDate)
        const toDateFormatted = getClientDateFormatted({ fromDate: toDate, countryCode })
        return `${fromDateFormatted} - ${toDateFormatted}`
      }
      if (item.frequencyType === FrequencyTypes.EveryYear && props.everyYearDates.length > 0) {
        const datesCount = props.everyYearDates.length
        if (datesCount > 1) {
          return i18n.t('operation_frequency.every_year_x_selected', { datesCount })
        }
        if (datesCount === 1 && isValidISO8601Date(props.everyYearDates[0])) {
          const fromDate = ISO8601StringToDate(props.everyYearDates[0])
          return getClientDateFormatted({ fromDate, countryCode })
        }
      }

      return item.translatedName
    }

    // Computed
    const isAllowedFrequenciesSet = computed(() => Array.isArray(props.allowedFrequencies) && props.allowedFrequencies.length > 0)
    const frequencyOptions = computed(() => {
      if (!isAllowedFrequenciesSet.value) return defaultFrequencyOptions
      return defaultFrequencyOptions
        .filter((frequency) => props.allowedFrequencies.includes(frequency.frequencyType))
    })
    return {
      defaultFrequencyOptions,
      frequencyOptions,
      isRtl,
      update,
      change,
      selectedText
    }
  }
}
</script>
