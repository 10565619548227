<template>
  <v-menu close-on-content-click>
    <template #activator="{ on }">
      <slot v-if="haveSlots" name="button" :on="on" />
      <v-icon
        v-else color="black" class="pl-4"
        v-on="on"
      >
        more_vert
      </v-icon>
    </template>
    <v-list class="edit-operation">
      <v-list-item v-if="showEdit" ripple @click="onEdit">
        <v-list-item-icon>
          <v-icon small>
            edit
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="showDelete" ripple @click="onDelete">
        <v-list-item-icon>
          <v-icon small>
            delete
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="showCopy" ripple @click="onCopy">
        <v-list-item-icon>
          <v-icon small>
            content_copy
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('copy') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="showShare" ripple @click="onShare">
        <v-list-item-icon>
          <v-icon small>
            share
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('share') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="showNotification" ripple @click="onNotify">
        <v-list-item-icon>
          <v-icon small>
            notifications
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('notifications') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { computed } from 'vue'
export default {
  props: {
    showCopy: {
      type: Boolean,
      required: true,
      default: true
    },
    showDelete: {
      type: Boolean,
      required: true,
      default: true
    },
    showEdit: {
      type: Boolean,
      required: true,
      default: true
    },
    showShare: {
      type: Boolean,
      required: true,
      default: true
    },
    showNotification: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  setup (_, { emit, slots }) {
    const onEdit = () => emit('edit-task')
    const onDelete = () => emit('delete-task')
    const onCopy = () => emit('copy-task')
    const onShare = () => emit('share-task')
    const onNotify = () => emit('notify-task')
    const haveSlots = computed(() => !!slots.button)
    return {
      onEdit,
      onDelete,
      onCopy,
      onShare,
      onNotify,
      haveSlots
    }
  }
}
</script>
<style lang="scss" scoped>
.edit-operation {
  .v-list-item__icon {
    margin-right: 12px !important;
  }
}
</style>
