<template>
  <page-filters
    class="company-broken-items-filter"
    :any-filter-selected="anyFilterSelected"
    :class="isRtl ? 'rtl' : 'ltr'"
    :right="!isRtl"
    :left="isRtl"
    width="350"
    @apply-filters="applyFilters"
    @reset-filters="resetFilters"
  >
    <date-picker-dialog
      ref="datePickerDialog"
      :value="dateRangeValue"
      :min-date="minDate"
      :show-current-date-button="true"
      content-class="inside-drawer"
      @change="applyDatePicker"
    />
    <v-row align="start" justify="start" no-gutters>
      <v-col v-if="isCompanyAdminRegionOrAbove" cols="12">
        <company-district-select
          v-model="companyDistrictFilter"
          :api-key="apiKey"
          :menu-props="{ contentClass: 'inside-drawer' }"
          :show-empty-area="isUserCompanyAdmin"
        />
      </v-col>
      <v-col v-if="isMultipleMemberships" cols="12" class="mb-4">
        <account-select
          v-model="accountstFilter"
          :api-key="apiKey"
          :district-ids="companyDistrictFilter"
          outlined
          dense
          :menu-props="{ contentClass: 'inside-drawer' }"
          multiple
        />
      </v-col>
      <v-col cols="12" class="t-500-18 color10">
        {{ $t('account_fault_devices.open_date') }}
      </v-col>
      <v-col cols="12" class="row align-start justify-space-between no-gutters">
        <v-col>
          <v-text-field
            :value="openDateStartString"
            outlined
            dense
            class="mme-2"
            prepend-inner-icon="date_range"
            clearable
            :label="$t('from')"
            readonly
            @click="openDatePicker('openDateStart')"
            @click:clear="deleteDate('openDateStart')"
          />
        </v-col>
        <v-col>
          <v-text-field
            :value="openDateEndString"
            outlined
            dense
            prepend-inner-icon="date_range"
            clearable
            :label="$t('to')"
            :disabled="openDateEndDisabled"
            readonly
            @click="openDatePicker('openDateEnd')"
            @click:clear="deleteDate('openDateEnd')"
          />
        </v-col>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="textSearchFilter"
          outlined
          dense
          append-icon="search"
          clearable
          :label="$t('account_fault_devices.general_search')"
          @click:clear="clearSearchField"
        />
      </v-col>
      <v-col v-if="apiKeyDefined" cols="12">
        <company-provider-search
          v-model="companyProvidersFilter"
          :menu-props="{ contentClass: 'inside-drawer' }"
          :api-key="apiKey"
          :return-object="false"
          multiple
        />
      </v-col>
      <v-col cols="12">
        <broken-item-deadline-filter
          v-model="deadlineDateFilter"
          :menu-props="{ contentClass: filtersClasses }"
        />
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="brokenItemStatusFilter"
          clearable
          :items="availableStatuses"
          item-text="translatedName"
          item-value="name"
          single-line
          multiple
          :label="$t('broken_item_dialog_dialog.status')"
          :menu-props="{ contentClass: filtersClasses }"
        >
          <template #item="{ item, attrs }">
            <v-row no-gutters justify="start" align="center">
              <v-col cols="10" class="font-weight-bold">
                {{ item.translatedName }}
              </v-col>
              <v-col cols="2" class="texts-end">
                <v-icon v-if="attrs.inputValue">
                  check_box
                </v-icon>
                <v-icon v-else>
                  check_box_outline_blank
                </v-icon>
              </v-col>
            </v-row>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="brokenItemPriorityFilter" clearable :items="availablePriorities"
          item-text="translatedName"
          item-value="name" single-line :label="$t('broken_item_dialog_dialog.priority')"
          :menu-props="{ contentClass: filtersClasses }"
        />
      </v-col>
      <v-col cols="12">
        <v-checkbox
          v-model="onlyBrokenItemAssignedToMe"
          :label="$t('account_fault_devices.broken_items_assigned_to_me')"
        />
      </v-col>
      <v-col cols="12">
        <v-checkbox v-model="onlyBrokenItemOpenedByMe" :label="$t('account_fault_devices.broken_items_opened_by_me')" />
      </v-col>
    </v-row>
  </page-filters>
</template>

<script>
import DatePickerDialog from '@/components/shared/DatePickerDialog.vue'
import CompanyProviderSearch from '@/components/shared/company_provider_search/CompanyProviderSearch.vue'

import useCompany from '@/composables/useCompany.js'
import useCompanyBrokenItem from '@/composables/useCompanyBrokenItem.js'
import useAccount from '@/composables/useAccount.js'
import useAuth from '@/composables/useAuth.js'

import { dateToISO8601String, getClientDateFormatted } from '@/helpers/DateTime.js'
import { isDate, isString } from '@/helpers/Utils.js'
import { isRtl } from '@/i18n.js'

import { computed, ref, nextTick } from 'vue'

export default {
  components: {
    'page-filters': () => import('@/components/shared/PageFilters.vue'),
    'company-district-select': () => import('@/components/shared/CompanyDistrictSelect.vue'),
    'account-select': () => import('@/components/shared/AccountSelect.vue'),
    'broken-item-deadline-filter': () => import('@/components/shared/BrokenItemDeadlineFilter.vue'),
    'date-picker-dialog': DatePickerDialog,
    'company-provider-search': CompanyProviderSearch
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const {
      onlyBrokenItemAssignedToMe, onlyBrokenItemOpenedByMe, availableStatuses, availablePriorities,
      brokenItemStatusFilter, brokenItemPriorityFilter, companyDistrictFilter, accountstFilter, deadlineDateFilter,
      openDateStartFilter, openDateEndFilter, textSearchFilter, companyProvidersFilter,
      applyFilters
    } = useCompanyBrokenItem(props.apiKey)

    const { isMultipleMemberships } = useCompany(props.apiKey)
    const { accountCountryCode } = useAccount()
    const { isCompanyAdminRegionOrAbove, isUserCompanyAdmin } = useAuth()
    const dateType = ref(null)
    const datePickerDialog = ref(null)

    const dateInUserFormat = (date) => {
      if (!isDate(date)) return ''
      return getClientDateFormatted({ countryCode: accountCountryCode.value, fromDate: date })
    }

    // Methods
    const clearSearchField = () => { textSearchFilter.value = '' }

    const openDatePicker = (dateTypeVal) => {
      dateType.value = dateTypeVal
      nextTick(() => datePickerDialog.value.openDialog())
    }

    const applyDatePicker = (val) => {
      let dateRef = null
      if (dateType.value === 'openDateStart') {
        dateRef = openDateStartFilter
      } else if (dateType.value === 'openDateEnd') {
        dateRef = openDateEndFilter
      }
      dateRef.value = val
    }

    const deleteDate = (dateTypeVal) => {
      if (dateTypeVal === 'openDateStart') {
        openDateStartFilter.value = null
        openDateEndFilter.value = null
      } else if (dateTypeVal === 'openDateEnd') {
        openDateEndFilter.value = null
      }
    }

    const resetFilters = () => {
      onlyBrokenItemAssignedToMe.value = false
      onlyBrokenItemOpenedByMe.value = false
      brokenItemStatusFilter.value = []
      brokenItemPriorityFilter.value = null
      companyProvidersFilter.value = []
      companyDistrictFilter.value = []
      accountstFilter.value = []
      deadlineDateFilter.value = null
      openDateStartFilter.value = null
      openDateEndFilter.value = null
      textSearchFilter.value = null
      applyFilters()
    }

    // Computed
    const filtersClasses = computed(() => (`inside-drawer ${isRtl.value ? 'rtl' : 'ltr'}`))
    const openDateStartString = computed(() => dateInUserFormat(openDateStartFilter.value))
    const openDateEndString = computed(() => dateInUserFormat(openDateEndFilter.value))
    const openDateEndDisabled = computed(() => isDate(openDateStartFilter.value) === false)
    const apiKeyDefined = computed(() => isString(props.apiKey) && props.apiKey.trim().length > 10)
    const minDate = computed(() => {
      if (dateType.value === 'openDateEnd' && isDate(openDateStartFilter.value)) {
        return dateToISO8601String(openDateStartFilter.value)
      }
      return null
    })
    const dateRangeValue = computed(() => {
      if (dateType.value === 'openDateStart') {
        return openDateStartFilter.value
      } else if (dateType.value === 'openDateEnd') {
        return openDateEndFilter.value
      } else {
        return null
      }
    })

    const anyFilterSelected = computed(() =>
      onlyBrokenItemAssignedToMe.value ||
      onlyBrokenItemOpenedByMe.value || brokenItemStatusFilter.value.length > 0 ||
      brokenItemPriorityFilter.value !== null || companyDistrictFilter.value.length > 0 ||
      deadlineDateFilter.value !== null || accountstFilter.value.length > 0 ||
      isDate(openDateStartFilter.value) || isDate(openDateEndFilter.value) ||
      (isString(textSearchFilter.value) && textSearchFilter.value.trim().length > 0) ||
      companyProvidersFilter.value.length > 0
    )
    return {
      anyFilterSelected,
      availableStatuses,
      availablePriorities,
      brokenItemStatusFilter,
      brokenItemPriorityFilter,
      onlyBrokenItemAssignedToMe,
      onlyBrokenItemOpenedByMe,
      companyDistrictFilter,
      textSearchFilter,
      companyProvidersFilter,
      accountstFilter,
      deadlineDateFilter,
      isCompanyAdminRegionOrAbove,
      isUserCompanyAdmin,
      isMultipleMemberships,
      isRtl,
      openDateEndDisabled,
      filtersClasses,
      datePickerDialog,
      openDateStartString,
      openDateEndString,
      dateRangeValue,
      apiKeyDefined,
      minDate,
      applyFilters,
      resetFilters,
      openDatePicker,
      applyDatePicker,
      deleteDate,
      clearSearchField
    }
  }
}
</script>

<style lang="scss">
.company-broken-items-filter {
  @media only screen and (max-width: 600px) {
    top: 168px;
  }
}
</style>
