<template>
  <v-row class="item-dialog-top-part">
    <v-col cols="12" class="color10 t-700-16">
      <span v-if="hasCategory">
        {{ item.category_name }}
        <v-divider vertical class="area-divider" />
      </span>
      {{ itemNameAndDepartment }}
    </v-col>
    <v-col
      cols="12"
      class="stats py-4 mt-2 row no-gutters align-start justify-space-between text-center white--text"
      :class="{ 'all-closed': allClosed }"
    >
      <v-col cols="4" class="t-500-13">
        {{ $t('items_page.total_calls') }}
      </v-col>
      <v-col cols="4" class="t-500-13 ">
        {{ $t('items_page.off_time') }}
      </v-col>
      <v-col cols="4" class="t-500-13">
        {{ $t('items_page.total_cost') }}
      </v-col>
      <v-col cols="12" class="my-2" />
      <v-col cols="4" class="t-700-14">
        {{ item.broken_item_stats.total }}
      </v-col>
      <v-col cols="4" class="t-700-14">
        {{ item.broken_item_stats.total_days_open }}
      </v-col>
      <v-col cols="4" class="t-700-14">
        {{ item.broken_item_stats.total_price }}
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
import { isString } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'

import { computed } from 'vue'
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const hasCategory = computed(() => isString(props.item.category_name) && props.item.category_name.length > 0)
    const allClosed = computed(() => props.item.broken_item_stats.close_count === props.item.broken_item_stats.total)
    const itemNameAndDepartment = computed(() => {
      if (props.item.general === true) return i18n.t('items_page.general')
      const departmentName = props.item.department?.name
      return isString(departmentName) ? `${props.item.name} - ${departmentName}` : props.item.name
    })
    return {
      itemNameAndDepartment,
      allClosed,
      hasCategory
    }
  }
}
</script>
<style lang="scss">
.item-dialog-top-part {
  .stats {
    background-color: #FF1744;
    border-radius: 8px;

    &.all-closed {

      background-color: #0040FF;
    }
  }

  .area-divider {
    width: 1px;
    height: 20px;
    margin: 0 2px;
    background-color: #C9C9C9;
  }

}
</style>
