
import useCompanyDriveFilters from '@/composables/useCompanyDriveFilters.js'
import {
  create as createDriveFile, index as indexDriveFile, update as updateDriveFile,
  destroy as destroyDriveFile
} from '@/api_client/DriveFile.js'
import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed } from 'vue'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 80
const defaultPagination = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, total: null })
const isLoading = ref(false)
const isFilesLoading = ref(false)
const driveFiles = ref([])
const pagination = ref(defaultPagination())

let currentApiKey = null
export default function useCompanyDriveFile (apiKey) {
  const { buildFilter } = useCompanyDriveFilters(apiKey)
  const resetValues = () => {
    isLoading.value = false
    isFilesLoading.value = false
    driveFiles.value = []
    pagination.value = defaultPagination()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const setPaginationFromResponse = (responseMeta) => {
    const paginationVal = pagination.value
    paginationVal.total = responseMeta.total
    paginationVal.page = responseMeta.page
    paginationVal.perPage = responseMeta.per_page
  }

  const indexApiParams = (parentFolderId) => {
    const result = {
      page: pagination.value.page,
      per_page: pagination.value.perPage
    }
    if (Number.isInteger(parseInt(parentFolderId))) {
      result.parent_folder_id = parentFolderId
    }
    return Object.assign(result, { filters: buildFilter() })
  }

  // Methods

  const createFile = (params) => {
    isLoading.value = true
    const parentFolderId = params.drive_folder_id
    return createDriveFile(apiKey, params)
      .then(async (response) => {
        await loadFiles({ reset: true, useLoading: false, parentFolderId, resetPage: false })
        return response
      })
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const updateFile = ({ id, params }) => {
    isLoading.value = true
    return updateDriveFile({ apiKey, id, params })
      .then(() => loadFiles({ reset: true, useLoading: false, resetPage: false }))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const loadFiles = ({ reset, useLoading, parentFolderId, resetPage } = { reset: false, useLoading: true, parentFolderId: null, resetPage: true }) => {
    isLoading.value = true
    if (useLoading) {
      isFilesLoading.value = true
    }
    if (reset) {
      driveFiles.value = []
    }
    if (resetPage) {
      pagination.value.page = DEFAULT_PAGE
    }
    return indexDriveFile(apiKey, indexApiParams(parentFolderId))
      .then(({ data: { data, meta } }) => {
        driveFiles.value = driveFiles.value.concat(data)
        setPaginationFromResponse(meta)
      })
      .catch(errHandler)
      .finally(() => {
        isLoading.value = false
        isFilesLoading.value = false
      })
  }

  const deleteFile = (fileId, parentFolderId) => {
    isLoading.value = true
    return destroyDriveFile(apiKey, fileId)
      .then(() => loadFiles({ reset: true, useLoading: false, parentFolderId }))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const loadNextPage = (parentFolderId) => {
    if (driveFiles.value.length < pagination.value.total) {
      pagination.value.page++
      loadFiles({ parentFolderId, resetPage: false })
    }
  }

  const onFilterChange = () => loadFiles({ reset: true })

  // Computed
  const isEmpty = computed(() => !isLoading.value && driveFiles.value.length === 0)

  return {
    DEFAULT_PER_PAGE,
    isLoading,
    isFilesLoading,
    driveFiles,
    pagination,
    isEmpty,
    createFile,
    updateFile,
    loadFiles,
    deleteFile,
    loadNextPage,
    onFilterChange
  }
}
