<template>
  <v-dialog v-model="dialog" max-width="350" persistent>
    <v-card
      class="edit-chat-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="text-center pb-5 blue-grey lighten-5">
        <span class="headline full-width">
          {{ dialogTitle }}
        </span>
      </v-card-title>
      <v-card-text class="pa-2">
        <v-row
          v-if="isLoadingFolder" align="center" justify="center"
          class="my-5"
        >
          <v-progress-circular indeterminate color="primary" />
        </v-row>
        <v-row v-else align="center" justify="start">
          <v-col cols="12">
            <v-text-field
              v-model="folder.name"
              single-line
              :rules="[requiredField]"
              :loading="isLoading"
              :disabled="isLoading"
              counter="256"
              :label="$t('drive.folder_name')"
            />
          </v-col>
          <v-col cols="12">
            <single-image-uploader ref="folderImage" />
          </v-col>
          <v-col cols="12" class="text-center t-700-16 py-0">
            {{ $t('drive.access') }}
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-select
              v-model="folder.roles"
              multiple
              single-line
              item-text="translatedName"
              item-value="name"
              :items="rolesOptions"
              :menu-props="{ contentClass: isRtl ? 'rtl' : '' }"
              :label="$t('drive.roles_access')"
              :rules="[requiredField]"
              @change="onRoleChange"
            />
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-switch
              v-if="showAllAccountsSelect" v-model="folder.allAccounts"
              :disabled="isLoading || isCompanyAdminFolder" class="d-inline-block ltr"
              :label="$t('drive.all_accounts')"
              @change="onAllAccountsChange"
            />
            <account-select
              v-if="showAccountSelect"
              v-model="folder.accounts"
              :api-key="apiKey"
              multiple
              :disabled="isLoading || folder.allAccounts || isCompanyAdminFolder"
              :show-select-all="false"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-2 pr-2 pb-4">
        <v-spacer />
        <v-btn
          color="blue darken-1" text :disabled="isLoading"
          :loading="isLoading" @click="close"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="blue darken-1" depressed tile
          :disabled="saveDisabled"
          @click="save"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AccountSelect from '@/components/shared/AccountSelect.vue'
import SingleImageUploader from '@/components/shared/SingleImageUploader.vue'

import { requiredField } from '@/classes/InputValidators.js'
import { isString } from '@/helpers/Utils.js'
import { roleAndBelowRoles } from '@/store/modules/EmployeeRole.js'

import { show as showDriveFolder } from '@/api_client/DriveFolder.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import useCompany from '@/composables/useCompany.js'
import useAuth from '@/composables/useAuth.js'
import useCompanyDriveFolder from '@/composables/useCompanyDriveFolder.js'

import { isRtl, i18n } from '@/i18n.js'

import { ref, computed } from 'vue'
const DialogModes = { create: 1, update: 2 }
const defaultFolderParams = () => ({ id: null, name: '', allAccounts: false, roles: [], accounts: [] })
export default {
  components: {
    'account-select': AccountSelect,
    'single-image-uploader': SingleImageUploader
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { isUserCompanyAdmin, currentUserRole } = useAuth()
    const { isMultipleMemberships } = useCompany(props.apiKey)
    const dialog = ref(false)
    const isLoading = ref(false)
    const dialogMode = ref()
    const folder = ref(defaultFolderParams())
    const rolesOptions = roleAndBelowRoles(currentUserRole.value)
    const isLoadingFolder = ref(false)
    const folderImage = ref(null)

    const { createFolder, updateFolder, removeFolderImage, addFolderImage } = useCompanyDriveFolder(props.apiKey)

    const apiParams = () => {
      const folderVal = folder.value
      const result = {
        name: folderVal.name,
        roles: folderVal.roles
      }
      result.belongs_to_all_accounts = showAllAccountsSelect.value ? folderVal.allAccounts : false
      result.api_keys = showAccountSelect.value ? folderVal.accounts : props.apiKey

      if (folderImage.value.shouldAddImage()) {
        result.with_image = true
      }
      return result
    }

    const loadFolder = async (folderId) => {
      isLoadingFolder.value = true
      const loadedFolder = (await showDriveFolder(props.apiKey, folderId)).data.data
      const folderVal = folder.value
      folderVal.id = loadedFolder.id
      folderVal.name = loadedFolder.name
      folderVal.image_url = loadedFolder.image_url
      if (loadedFolder.belongs_to_all_roles) {
        folderVal.roles = rolesOptions.map(role => role.name)
      } else {
        folderVal.roles = loadedFolder.roles
      }
      if (loadedFolder.belongs_to_all_accounts) {
        folderVal.allAccounts = loadedFolder.belongs_to_all_accounts
      } else {
        folderVal.accounts = loadedFolder.accounts.map(account => account.api_key)
      }
      isLoadingFolder.value = false
    }

    const updateImageIfNeeded = async (responseData) => {
      if (folderImage.value.shouldRemoveImage()) {
        return removeFolderImage(folder.value.id)
      } else if (folderImage.value.shouldAddImage()) {
        const imageUploadData = responseData.image_upload_data
        const folderId = dialogMode.value === DialogModes.create ? responseData.id : folder.value.id
        return addFolderImage({ folderId, imageUploadData, imageFile: folderImage.value.getImageFile() })
      }
    }

    // Methods
    const onRoleChange = () => {
      if (!isCompanyAdminFolder.value) return
      folder.value.allAccounts = true
    }
    const openDialog = (mode = DialogModes.create) => {
      dialog.value = true
      dialogMode.value = mode
    }

    const openForUpdate = async (folderId) => {
      await loadFolder(folderId)
      const imageUrl = folder.value.image_url
      if (folderImage.value) {
        folderImage.value.setImageUrl(imageUrl)
      } else {
        setTimeout(() => folderImage.value.setImageUrl(imageUrl))
      }
      openDialog(DialogModes.update)
    }

    const onAllAccountsChange = (newAllAccounts) => {
      if (!newAllAccounts) return
      folder.value.accounts = []
    }

    const save = () => {
      isLoading.value = true
      const saveParams = apiParams()
      const apiCall = dialogMode.value === DialogModes.create
        ? createFolder(saveParams)
        : updateFolder({ id: folder.value.id, params: saveParams })
      apiCall
        .then((response) => updateImageIfNeeded(response))
        .then(close)
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    const close = () => {
      dialog.value = false
      isLoading.value = false
      folder.value = defaultFolderParams()
      isLoadingFolder.value = false
      if (folderImage.value) folderImage.value.reset()
    }

    // Computed
    const isCompanyAdminFolder = computed(() => isUserCompanyAdmin.value &&
      folder.value.roles.length === 1 &&
      folder.value.roles[0] === 'company_admin')

    const dialogTitle = computed(() => dialogMode.value === DialogModes.create
      ? i18n.t('drive.create_folder')
      : i18n.t('drive.update_folder')
    )
    const showAccountSelect = computed(() => isMultipleMemberships.value)
    const showAllAccountsSelect = computed(() => isUserCompanyAdmin.value)

    const saveDisabled = computed(() => {
      if (isLoading.value) return true
      const folderVal = folder.value
      const folderName = folderVal.name
      if (!isString(folderName) || folderName.trim().length === 0) return true
      if (!folderVal.belongs_to_all_roles && (folderVal.roles || []).length === 0) return true
      if (showAccountSelect.value && folderVal.accounts.length === 0 && !folderVal.allAccounts) return true

      return false
    })
    return {
      isRtl,
      dialog,
      dialogTitle,
      folder,
      isLoading,
      isLoadingFolder,
      folderImage,
      saveDisabled,
      rolesOptions,
      showAccountSelect,
      showAllAccountsSelect,
      isCompanyAdminFolder,
      requiredField,
      onRoleChange,
      openDialog,
      openForUpdate,
      onAllAccountsChange,
      save,
      close
    }
  }
}
</script>
