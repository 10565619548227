<template>
  <v-row
    align="start" justify="start"
    class="account-jobs"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <new-job-dialog ref="jobDialog" />
    <confirm-delete ref="deleteDialog" max-width="300" />
    <v-btn
      fab small color="black white--text"
      class="add-button"
      :disabled="isLoading" @click="createNewJob"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-col cols="12" class="page-title">
      {{ $t('org_structure_jobs.page_title') }}
    </v-col>
    <v-col
      v-if="isCompanyAdminRegionOrAbove" cols="12" md="4"
      sm="6" class="mt-4"
    >
      <page-account-select />
    </v-col>
    <v-col cols="12">
      <account-jobs-filters :api-key="apiKey" />
    </v-col>
    <v-col cols="12" class="mt-4">
      <div class="texts-start t-700-16 pt-2">
        <span>{{ $t("org_structure_jobs.total_jobs") }}: &nbsp;</span>
        <span class="blue--text"> {{ pagination.total }}</span>
      </div>
    </v-col>
    <v-col v-if="isLoading" cols="12">
      <div v-for="i in 3" :key="i" class="mb-4">
        <v-skeleton-loader type="card" elevation="2" />
      </div>
    </v-col>
    <v-col
      v-for="job in jobList" :key="job.id"
      cols="12" class="py-0 px-4"
    >
      <single-job-row
        :job="job"
        @edit-job="editJob"
        @delete-job="onDeleteJob"
      />
      <div class="job-divider my-4" />
    </v-col>
    <v-col v-if="isLoadingNextPage" cols="12">
      <div v-for="i in 3" :key="i" class="mb-4">
        <v-skeleton-loader type="card" elevation="2" />
      </div>
    </v-col>
    <v-col v-if="showLoadMorePages" v-intersect="onIntersect" />
  </v-row>
</template>
<script>

import NewJobDialog from '@/components/account_jobs/account_job_create_dialog/AccountJobsCreateDialog.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import AccountJobsFilters from '@/components/account_jobs/AccountJobsFilters.vue'
import PageAccountSelect from '@/components/shared/PageAccountSelect.vue'
import SingleJobRow from '@/components/account_jobs/SingleJobRow.vue'
import useAccountJob from '@/composables/useAccountJob.js'
import useAuth from '@/composables/useAuth.js'

import { i18n, isRtl } from '@/i18n.js'
import { ref } from 'vue'

export default {
  components: {
    'new-job-dialog': NewJobDialog,
    'confirm-delete': ConfirmDelete,
    'page-account-select': PageAccountSelect,
    'account-jobs-filters': AccountJobsFilters,
    'single-job-row': SingleJobRow
  },
  setup () {
    const { apiKey, isCompanyAdminRegionOrAbove } = useAuth()
    const jobDialog = ref(null)
    const deleteDialog = ref(null)

    const {
      isLoading, pagination, currentDay, jobList, showLoadMorePages, isLoadingNextPage,
      deleteJob, loadNextPage, resetAndLoad
    } = useAccountJob(apiKey.value)

    // Methods
    const onIntersect = loadNextPage
    const createNewJob = () => jobDialog.value.openForCreate().then((jobCreated) => {
      if (jobCreated) {
        resetAndLoad()
      }
    })

    const editJob = (jobToEdit) => jobDialog.value.openForUpdate(jobToEdit)
      .then((jobUpdated) => {
        if (jobUpdated) {
          resetAndLoad()
        }
      })

    const onDeleteJob = (jobToDelete) => {
      const title = i18n.t('org_structure_jobs.delete_job_title')
      const content = i18n.t('org_structure_jobs.delete_job_content')
      deleteDialog.value.open({ title, content })
        .then((shouldDelete) => {
          if (!shouldDelete) return

          deleteJob(jobToDelete.id)
            .finally(() => deleteDialog.value.close())
        })
    }
    return {
      apiKey,
      isLoading,
      pagination,
      jobList,
      currentDay,
      jobDialog,
      deleteDialog,
      isCompanyAdminRegionOrAbove,
      isRtl,
      showLoadMorePages,
      isLoadingNextPage,
      onIntersect,
      createNewJob,
      editJob,
      onDeleteJob
    }
  }
}
</script>
<style lang="scss">
.account-jobs {
  padding-top: 80px;
  background-color: #F4F6F8;

  @media only screen and (max-width: 600px) {
    padding-top: 0;
    margin-top: 60px;
    padding-bottom: 20px;
  }

  .job-divider {
    height: 1px;
    background-color: #C9C9C9;
  }
}
</style>
