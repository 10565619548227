<template>
  <v-row align="start" justify="start" no-gutters>
    <v-col cols="12" class="mt-2">
      <v-badge
        :color="messageCountColor"
        overlap
        :value="hasUnreadChatMessages"
        bordered
        :content="task.operationRef.total_messages_count"
      >
        <v-icon
          color="#0000008a"
          class="pointer"
          @click="onChatClick"
        >
          {{ chatMessageIcon }}
        </v-icon>
      </v-badge>
      <v-icon
        v-if="showGuidanceImages"
        color="#0000008a"
        class="mms-3 pointer material-icons-outlined"
        @click="onGuidanceImageClick"
      >
        image
      </v-icon>
      <v-icon
        v-if="showGuidanceFile"
        class="mms-3 pointer"
        color="#0000008a"
        @click="onGuidanceFileClick"
      >
        attach_file
      </v-icon>
      <v-icon
        v-if="showNotification"
        class="mms-3 pointer"
        color="#0000008a"
        @click="onNotifyClick"
      >
        notifications
      </v-icon>
    </v-col>
    <v-col v-if="showAvgStars" cols="12" class="mt-2">
      <reported-item-list-star-avg :items-list="task.operationRef.item_operation_list" />
    </v-col>
  </v-row>
</template>
<script>
import ReportedItemListAvarage from '@/components/shared/daily_work_sheet/ReportedItemListAvarage.vue'
import { itemTypes } from '@/models/ItemOperationListItemTypes.js'

import { isString } from '@/helpers/Utils.js'

import { EventBus, TASK_CHAT_CLICK, TASK_NOTIFICATION_CLICK, TASK_GUIDANCE_IMAGE_CLICK } from '@/EventBus.js'

import { computed } from 'vue'
export default {
  components: {
    'reported-item-list-star-avg': ReportedItemListAvarage
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    // Methods
    const onChatClick = () => EventBus.emit(TASK_CHAT_CLICK, props.task)
    const onGuidanceImageClick = () => EventBus.emit(TASK_GUIDANCE_IMAGE_CLICK, props.task)
    const onGuidanceFileClick = () => window.open(props.task.operationRef.guidance_file, '_blank').focus()
    const onNotifyClick = () => EventBus.emit(TASK_NOTIFICATION_CLICK, props.task)
    // Computed

    const hasUnreadChatMessages = computed(() => Number.isInteger(props.task.operationRef.unread_messages_count) && props.task.operationRef.unread_messages_count > 0)
    const chatMessageIcon = computed(() => {
      if (hasUnreadChatMessages.value) return 'chat_bubble'
      const haveAnyMessages = Number.isInteger(props.task.operationRef.total_messages_count) && props.task.operationRef.total_messages_count > 0
      return haveAnyMessages ? 'mark_chat_read' : 'chat_bubble'
    })
    const showAvgStars = computed(() => props.task.tasksList().some((item) => item.item_type === itemTypes.rating))
    const showNotification = computed(() => props.task.operationRef.mark_complete_notification || props.task.operationRef.mark_uncomplete_notification)
    const showGuidanceImages = computed(() => {
      const guidanceImages = props.task.operationRef.guidance_images
      return Array.isArray(guidanceImages) && guidanceImages.length > 0
    })
    const showGuidanceFile = computed(() => {
      const guidanceFileUrl = props.task.operationRef.guidance_file
      return isString(guidanceFileUrl) && guidanceFileUrl.length > 0
    })
    const messageCountColor = computed(() => Number.isInteger(props.task.operationRef.unread_messages_count) && props.task.operationRef.unread_messages_count > 0 ? '#EC2328' : 'green')
    return {
      hasUnreadChatMessages,
      chatMessageIcon,
      showGuidanceImages,
      showGuidanceFile,
      showAvgStars,
      showNotification,
      messageCountColor,
      onChatClick,
      onGuidanceImageClick,
      onGuidanceFileClick,
      onNotifyClick
    }
  }
}

</script>
