<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <operation-image-overlay
      :show-image-overlay="departmentImage.showImageOverlay"
      :image-src="departmentImage.url" @cancel-report="removeImage" @hide-image="hideImageOverlay"
    />
    <v-card class="new-account-department-dialog">
      <v-card-title class="text-center pb-0">
        <span class="headline full-width">
          {{ dialogTitle }}
        </span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12">
            <v-textarea
              v-model="departmentName" class="required" auto-grow
              rows="1" :rules="[requiredField]" single-line
              counter="80" :label="$t('name')"
            />
          </v-col>
          <v-col cols="12" class="mt-5">
            <v-img
              v-if="showImagePreview" max-height="150" :src="departmentImage.url"
              @click="previewImageClick"
              @load="onImageLoaded" @error="onImageLoadError"
            >
              <template #placeholder>
                <v-row
                  align="center" justify="center" class="fill-height"
                  no-gutters
                >
                  <v-progress-circular indeterminate color="primary" />
                </v-row>
              </template>
            </v-img>
            <label v-else for="store_custom_image" class="pointer">
              <v-icon class="font-weight-black" color="black"> add_photo_alternate </v-icon>
              <input
                id="store_custom_image" type="file" accept="image/*"
                :disabled="departmentImage.isLoading"
                @change="uploadImagePreview"
              >
            </label>
          </v-col>
          <v-col cols="12" class="mt-3">
            <v-card-actions class="pt-0 pr-0">
              <v-spacer />
              <v-btn
                color="blue darken-1" text :disabled="isLoading"
                @click="close(false)"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                color="blue darken-1" depressed tile
                :loading="isLoading" :disabled="isSaveDisabled" @click="save"
              >
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-snackbar v-model="errorSnackBar" :timeout="3000">
          <div class="text-center">
            {{ $t('account_department_select.error_name_taken') }}
          </div>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import OperationImageOverlay from '@/components/shared/OperationImageOverlay.vue'

import { requiredField } from '@/classes/InputValidators.js'

import {
  create as accountDepartmentCreate, update as accountDepartmentUpdate,
  addImage as accountDepartmentAddImage, removeImage as accountDepartmentRemoveImage
} from '@/api_client/AccountDepartment.js'

import { uploadImage as uploadImageToRemoteServer } from '@/general_client/CloudinaryImageUploader.js'

import { isString, isFile } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n } from '@/i18n.js'

import { ref, computed } from 'vue'

const DialogMode = { create: 0, update: 1 }
let departmentId = null
let uploadedImagePreviewUrl = null
let originalUploadedFile = null

const defaultDepartmentImage = () => {
  return {
    isLoading: false,
    url: '',
    hasImage: false,
    showImageOverlay: false,
    hadImage: false
  }
}

export default {
  components: {
    'operation-image-overlay': OperationImageOverlay
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const isLoading = ref(false)
    const dialog = ref(false)
    const departmentName = ref(null)
    const dialogMode = ref(null)
    const errorSnackBar = ref(false)
    const departmentImage = ref(defaultDepartmentImage())
    let resolveFunc = null
    let newDepartmentId = null

    const onOpenDialog = ({ name = null, mode } = {}) => {
      dialog.value = true
      isLoading.value = false
      departmentName.value = name
      dialogMode.value = mode
      newDepartmentId = null
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }
    const shouldAddImage = () => departmentImage.value.hasImage && isFile(originalUploadedFile)
    const shouldRemoveImage = () => !departmentImage.value.hasImage && departmentImage.value.hadImage === true

    const updaloadImage = async (imageUploadData, id) => {
      await uploadImageToRemoteServer(originalUploadedFile, imageUploadData)

      return accountDepartmentAddImage({ apiKey: props.apiKey, imageName: imageUploadData.public_id, id })
    }

    const releaseImageMemory = () => {
      if (!uploadedImagePreviewUrl) return
      URL.revokeObjectURL(uploadedImagePreviewUrl)
      uploadedImagePreviewUrl = null
    }

    const removeImage = () => {
      releaseImageMemory()
      hideImageOverlay()
      const departmentImageVal = departmentImage.value
      departmentImageVal.url = ''
      departmentImageVal.hasImage = false
      departmentImageVal.isLoading = false
      originalUploadedFile = null
    }

    // Methods
    const hideImageOverlay = () => (departmentImage.value.showImageOverlay = false)
    const openDialog = () => onOpenDialog({ mode: DialogMode.create })

    const openForUpdate = (department) => {
      departmentId = department.id
      const departmentImageFromParam = department.image_url
      if (isString(departmentImageFromParam) && departmentImageFromParam.length > 0) {
        const departmentImageVal = departmentImage.value
        departmentImageVal.hadImage = true
        departmentImageVal.hasImage = true
        departmentImageVal.url = departmentImageFromParam
      }
      return onOpenDialog({ name: department.name, mode: DialogMode.update })
    }

    const close = (isCreated) => {
      dialog.value = false
      departmentId = null
      originalUploadedFile = null
      departmentImage.value = defaultDepartmentImage()
      resolveFunc({ isCreated, departmentId: newDepartmentId })
    }

    const save = () => {
      isLoading.value = true
      let promise = null
      const requestParams = saveRequestParams()
      if (dialogMode.value === DialogMode.create) {
        promise = accountDepartmentCreate(requestParams)
      } else {
        promise = accountDepartmentUpdate(requestParams)
      }
      promise
        .then(async (response) => {
          if (dialogMode.value === DialogMode.create) {
            newDepartmentId = response.data.department_id
          }
          if (shouldAddImage() === true) {
            const responseData = response.data
            const imageUploadData = response.data.image_upload_data
            const id = dialogMode.value === DialogMode.create ? responseData.department_id : departmentId
            await updaloadImage(imageUploadData, id)
          } else if (shouldRemoveImage() === true) {
            await accountDepartmentRemoveImage({ apiKey: props.apiKey, id: departmentId })
          }
        })
        .then(() => close(true))
        .catch((err) => {
          const errorsArray = err?.response?.data?.errors
          if (Array.isArray(errorsArray) && errorsArray.some((err) => err === 'name_taken')) {
            errorSnackBar.value = true
          }
          errHandler(err)
        })
        .finally(() => (isLoading.value = false))
    }

    const saveRequestParams = () => {
      const params = { apiKey: props.apiKey, name: departmentName.value }
      if (dialogMode.value === DialogMode.update) {
        params.id = departmentId
      }
      if (shouldAddImage()) {
        params.withImage = true
      }
      return params
    }

    const uploadImagePreview = (event) => {
      const file = event.target.files[0]
      if (!file) return

      releaseImageMemory()
      departmentImage.value.isLoading = true
      uploadedImagePreviewUrl = URL.createObjectURL(file)
      originalUploadedFile = file
      departmentImage.value.url = uploadedImagePreviewUrl
    }

    const previewImageClick = () => {
      if (departmentImage.value.hasImage !== true) return

      departmentImage.value.showImageOverlay = true
    }

    const onImageLoaded = () => {
      departmentImage.value.isLoading = false
      departmentImage.value.hasImage = true
    }

    const onImageLoadError = () => {
      departmentImage.value.isLoading = false
      departmentImage.value.hasImage = false
    }

    // Computed

    const dialogTitle = computed(() => dialogMode.value === DialogMode.create ? i18n.t('account_department_select.create') : i18n.t('account_department_select.update'))
    const showImagePreview = computed(() => departmentImage.value.isLoading || departmentImage.value.hasImage)
    const isSaveDisabled = computed(() => {
      const nameVal = departmentName.value
      if (isString(nameVal) !== true) return true
      return !(nameVal.trim().length > 0 && nameVal.trim().length <= 80)
    })
    return {
      isLoading,
      dialogTitle,
      dialog,
      departmentImage,
      departmentName,
      isSaveDisabled,
      showImagePreview,
      errorSnackBar,
      openDialog,
      openForUpdate,
      close,
      removeImage,
      hideImageOverlay,
      uploadImagePreview,
      previewImageClick,
      onImageLoaded,
      onImageLoadError,
      save,
      requiredField
    }
  }
}
</script>
<style lang="scss">
.new-account-department-dialog {
  input[type="file"] {
    display: none;
  }
}
</style>
