<template>
  <v-container
    fluid class="login-page d-flex align-center justify-center"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <v-row align="center" justify="center">
      <v-col
        cols="12" lg="8" md="10"
        class="center-box row align-start justify-center"
      >
        <v-btn
          v-if="showBackButton"
          class="back-btn"
          icon
          color="white"
          @click="backClick"
        >
          <v-icon>chevron_left</v-icon>
        </v-btn>
        <v-col class="flex-grow-0 flex-shrink-1 pb-0">
          <v-img :src="imageLogoPath" contain width="80" />
        </v-col>
        <slot name="main-part" />
        <v-col
          cols="12" lg="5" md="6"
          sm="8" class="row justify-space-between"
        >
          <slot name="buttons" />
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { isRtl } from '@/i18n'
export default {
  props: {
    showBackButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (_, { emit }) {
    const backClick = () => emit('back-click')

    return {
      imageLogoPath: new URL('@/assets/dex_logo_white.svg', import.meta.url).href,
      isRtl,
      backClick
    }
  }
}
</script>
<style lang="scss">
.login-page {
  background-image: url(@/assets/login_cover.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;

  .center-box {
    border-radius: 12px;
    border: 2px solid rgba(255, 255, 255, 0.10);
    background: #111;
    box-shadow: 0px 16px 24px 0px rgba(255, 255, 255, 0.06), 0px 2px 6px 0px rgba(255, 255, 255, 0.04), 0px 0px 1px 0px rgba(255, 255, 255, 0.04);
    position: relative;

    .login-btn {
      color: white !important;
    }

    .back-btn {
      position: absolute;
      top: 30px;
      left: 10px;
    }
  }
}
</style>
