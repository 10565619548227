<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card class="org-struct-provider-new">
      <v-card-title class="text-center pb-0">
        <span class="headline" style="width: 100%">
          {{ dialogTitle }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form ref="newProviderForm">
          <v-container grid-list-md>
            <v-row align="start" justify="space-around">
              <v-col cols="12" sm="5">
                <v-text-field
                  v-model="newProvider.name"
                  :rules="[requiredField]"
                  single-line
                  counter="120"
                  :label="$t('org_provider_new_dialog.provider_name')"
                />
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  v-model="newProvider.contact_name"
                  single-line
                  counter="120"
                  :label="$t('org_provider_new_dialog.contact_name')"
                />
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  v-model="newProvider.email"
                  single-line
                  counter="320"
                  :label="$t('email')"
                />
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  v-model="newProvider.phone"
                  single-line
                  counter="20"
                  :label="$t('org_provider_new_dialog.provider_phone')"
                />
              </v-col>
              <v-col cols="11">
                <v-textarea
                  v-model="newProvider.comment"
                  counter="500"
                  auto-grow
                  rows="1"
                  single-line
                  :label="$t('org_provider_new_dialog.comments')"
                />
              </v-col>
              <v-col cols="11" class="mr-4">
                <v-card-actions class="pt-0 pr-2">
                  <v-spacer />
                  <v-btn
                    color="blue darken-1" text :disabled="isLoading"
                    @click="close"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1" depressed tile
                    :loading="isLoading" :disabled="isSaveDisabled"
                    @click="save"
                  >
                    {{ $t('save') }}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { requiredField } from '@/classes/InputValidators.js'
import { newCompanyProviderModel } from '@/store/modules/CompanyProvider.js'
import useAuth from '@/composables/useAuth.js'

import { create as createProviderClient, update as updateProviderClient } from '@/api_client/CompanyProvider.js'

import { i18n } from '@/i18n.js'

import { ref, computed } from 'vue'
const DialogMode = { create: 0, update: 1 }

export default {
  setup () {
    let resolveFunc = null
    const dialogMode = ref(null)
    const dialog = ref(false)
    const newProvider = ref({})
    const isLoading = ref(false)
    const newProviderForm = ref(null)

    const openDialog = (provider, mode) => {
      dialog.value = true
      dialogMode.value = mode
      newProvider.value = newCompanyProviderModel(provider)
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const createNewProvider = () => {
      const { apiKey } = useAuth()
      const creationParams = newProvider.value.serialize()
      createProviderClient(apiKey.value, { company_provider: creationParams })
        .then(() => close(true))
        .finally(() => (isLoading.value = false))
    }

    const updateProvider = () => {
      const { apiKey } = useAuth()
      const updateParams = newProvider.value.serialize()
      updateProviderClient(apiKey.value, newProvider.value.id, { company_provider: updateParams })
        .then(() => close(true))
        .finally(() => (isLoading.value = false))
    }
    // Methods

    const openForUpdate = (provider) => openDialog(provider, DialogMode.update)
    const openForCreate = (provider) => openDialog({}, DialogMode.create)
    const close = (resolveResult) => {
      dialogMode.value = null
      dialog.value = false
      newProviderForm.value.reset()
      resolveFunc(resolveResult)
    }
    const save = () => {
      if (!newProviderForm.value.validate()) return

      isLoading.value = true
      if (dialogMode.value === DialogMode.update) {
        updateProvider()
      } else {
        createNewProvider()
      }
    }

    // Computed

    const dialogTitle = computed(() => {
      return dialogMode.value === DialogMode.update
        ? i18n.t('org_provider_new_dialog.update_title')
        : i18n.t('org_provider_new_dialog.create_title')
    })

    const isSaveDisabled = computed(() => {
      if (isLoading.value) return true
      if (!newProvider.value.name) return true
      return false
    })
    return {
      dialogMode,
      dialog,
      newProvider,
      isLoading,
      newProviderForm,
      isSaveDisabled,
      dialogTitle,
      openForUpdate,
      openForCreate,
      close,
      save,
      requiredField
    }
  }
}
</script>
