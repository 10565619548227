<template>
  <v-timeline-item
    small
    fill-dot
    :color="isOperationDone? 'green': 'red'"
    :icon="isOperationDone ? 'check_circle' : ''"
  >
    <job-daily-worksheet-list-item
      :list-item="operation"
      :current-date="operation.report_date"
      disabled
      :more-select-options="moreSelectOptions"
      :style="{ 'background-color': defaultColor }"
      @open-chat="openChatDialog"
      @open-item-operation-list="openItemOperationList"
      @image-click="showImage"
      @share-click="onShareClick"
    >
      <template #info="{ item, frequencyText }">
        <div :class="{ rtl: isRtl, 'text-left': isRtl }">
          <div class="text-left font-weight-bold caption">
            {{ operation.account_name }}
          </div>
          <div class="pl-1 show-line-break">
            {{ descriptionAndType }}
          </div>
          <div v-if="item.operation_next_date" class="font-weight-medium caption red--text">
            {{ $t('account_operations.op_original_date', { date: item.operation_next_date }) }}
          </div>
          <div v-if="hasDeparment" class="font-weight-bold caption">
            {{ item.account_job_department_name }}
          </div>
          <div label outlined class="font-weight-medium caption">
            {{ $t('account_operations.assigned_to', { executor: item.account_job_name }) }}
            |
            {{ translateShift(item.account_job_shift) }}
          </div>
          <span v-if="frequencyText" class="font-weight-medium font-italic red--text">
            {{ frequencyText }}
          </span>
          <div class="font-weight-bold mb-1 caption">
            {{ item.reporter_first_name }} {{ item.reporter_last_name }}
          </div>
        </div>
      </template>
    </job-daily-worksheet-list-item>
  </v-timeline-item>
</template>

<script>
import JobDailyWorksheetListItem from '@/components/shared/daily_work_sheet/DailyWorkSheetListItem.vue'

import { defaultColor } from '@/store/modules/DeviceOperation.js'

import { isRtl } from '@/i18n.js'

import { translatedOperation as OpTypeTranslatedOperation, ROUTINE_OPERATION } from '@/composables/useOperationType.js'

import { OperationStatuses } from '@/store/modules/OperationSchedule.js'

import { translateShift } from '@/store/modules/OrganizationJob.js'

import { isString } from '@/helpers/Utils.js'

import { computed } from 'vue'

export default {
  components: {
    'job-daily-worksheet-list-item': JobDailyWorksheetListItem
  },
  props: {
    operation: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const moreSelectOptions = { share: true }
    // Methods
    const openChatDialog = () => emit('open-chat-dialog', props.operation.operation_id)
    const openItemOperationList = () => emit('open-item-operation-list', props.operation)
    const showImage = () => emit('show-image', props.operation.image_url)
    const onShareClick = (operation) => emit('share-click', operation)

    // Computed

    const isOperationDone = computed(() => props.operation.status === OperationStatuses.done)
    const hasDeparment = computed(() => isString(props.operation.account_job_department_name) && props.operation.account_job_department_name.length > 0)
    const descriptionAndType = computed(() => {
      const operation = props.operation
      const description = operation.operation_description || ''
      if (ROUTINE_OPERATION === operation.operation_type) return description

      const translatedOp = OpTypeTranslatedOperation(operation.operation_type)

      if (description.length > 0) return `${translatedOp} - ${description}`

      return translatedOp
    })
    return {
      defaultColor,
      descriptionAndType,
      isOperationDone,
      hasDeparment,
      moreSelectOptions,
      isRtl,
      translateShift,
      openChatDialog,
      openItemOperationList,
      showImage,
      onShareClick
    }
  }
}
</script>

<style>

</style>
