import { getCurrentLocalISODate } from '@/helpers/DateTime.js'

export const deadlineApiFilter = (deadlineFilterValue) => {
  if (deadlineFilterValue === 'no_deadline') {
    return { without_deadline: true }
  }
  if (deadlineFilterValue === 'today') {
    const startDate = new Date()
    startDate.setHours(0, 0, 0, 0)
    const endDate = new Date()
    endDate.setHours(23, 59, 59)
    return {
      deadline_start: getCurrentLocalISODate(startDate),
      deadline_end: getCurrentLocalISODate(endDate)
    }
  }
  if (deadlineFilterValue === 'passed') {
    return { deadline_end: getCurrentLocalISODate(new Date()) }
  }
  if (deadlineFilterValue === 'this_week') {
    const startDate = new Date()
    startDate.setHours(0, 0, 0, 0)
    const endDate = new Date()
    endDate.setDate(endDate.getDate() + 7)
    endDate.setHours(23, 59, 59)
    return {
      deadline_start: getCurrentLocalISODate(startDate),
      deadline_end: getCurrentLocalISODate(endDate)
    }
  }
  return null
}
