<template>
  <v-row align="start" justify="center" class="full-height mt-0">
    <v-col cols="12" class="d-flex align-self-stretch pb-0 px-0">
      <chat-groups-list
        v-if="isViewModeGroups"
        :api-key="apiKey"
        @click-chat-group="onClickChatGroup"
        @search-selected="onSearchSelected"
      />
      <group-conversation-layout
        v-else
        show-back-arrow
        :api-key="apiKey"
        @click-back="changeToGroupMode"
      />
    </v-col>
  </v-row>
</template>
<script>
import ChatGroupsList from '@/components/app_chat/ChatGroupsList.vue'
import GroupConversationLayout from '@/components/app_chat/group_conversation/GroupConversationLayout.vue'

import useAppChat from '@/composables/useAppChat.js'

import { computed, ref } from 'vue'

const viewModes = { chatGroups: 1, groupConversation: 2 }

export default {
  components: {
    'chat-groups-list': ChatGroupsList,
    'group-conversation-layout': GroupConversationLayout
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const viewMode = ref(viewModes.chatGroups)
    const { changeActiveGroup, startPrivateChat } = useAppChat(props.apiKey)
    // Methods

    const onClickChatGroup = (chatGroup) => {
      changeActiveGroup(chatGroup)
      viewMode.value = viewModes.groupConversation
    }

    const onSearchSelected = ({ isGroup, eventData }) => {
      if (isGroup === true) {
        onClickChatGroup(eventData)
        return
      }
      viewMode.value = viewModes.groupConversation
      startPrivateChat(eventData)
    }

    const changeToGroupMode = () => (viewMode.value = viewModes.chatGroups)
    // Computed

    const isViewModeGroups = computed(() => viewMode.value === viewModes.chatGroups)
    return {
      viewModes,
      isViewModeGroups,
      onClickChatGroup,
      changeToGroupMode,
      onSearchSelected
    }
  }
}
</script>
