<template>
  <v-row align="center" justify="space-between" no-gutters>
    <v-col cols="12" md="6" class="texts-start t-500-16">
      <group-or-user-avatar rounded="pill" :chat-group="chatGroup" />
      {{ chatTitle }}
    </v-col>
    <v-col cols="12" lg="5" md="6">
      <v-row align="center" justify="space-between" no-gutters>
        <v-col cols="2">
          <v-btn
            v-if="showBackArrow" text icon
            class="black--text mt-2" @click="onBackClick"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8">
          <translation-select
            v-model="userLanguage" class="translation-select" :disabled="isLoading"
            @change="onLnaguageChange"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>

import GroupOrUserAvatar from '@/components/app_chat/GroupOrUserAvatar.vue'
import TranslationSelect from '@/components/shared/TranslationSelect.vue'
import useAppChat from '@/composables/useAppChat.js'

import { computed, ref } from 'vue'

export default {
  components: {
    'translation-select': TranslationSelect,
    'group-or-user-avatar': GroupOrUserAvatar
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    showBackArrow: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const { activeConversation, isLoading, changeActiveGroupTranslation } = useAppChat(props.apiKey)
    const userLanguage = ref(activeConversation.value.group.targetLanguage)
    const chatGroup = activeConversation.value.group
    const onBackClick = () => emit('click-back')

    const chatTitle = computed(() => {
      const { id, name } = activeConversation.value.group
      if (Number.isInteger(id) !== true) return ''
      return name
    })

    const onLnaguageChange = changeActiveGroupTranslation
    return {
      userLanguage,
      chatTitle,
      isLoading,
      chatGroup,
      onBackClick,
      onLnaguageChange
    }
  }
}
</script>
<style lang="scss">
.translation-select {
  div.v-input__slot {
    background-color: white !important;
  }
}
</style>
