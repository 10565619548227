import { getJobWorkingDays } from '@/store/modules/AccountJobWorkDays.js'
import { newOperationScheduleModel } from '@/store/modules/OperationSchedule.js'
import { operationFrequencyOptionsForSelect, FrequencyTypes } from '@/store/modules/OperationFrequency.js'

import {
  createWithItem as jobOpScheduleCreateWithItem,
  updateWithItem as jobOpScheduleUpdateWithItem
} from '@/api_client/JobOperationSchedule.js'
import { batchUpsert as itemOperationListItemClientBatchUpsert } from '@/api_client/ItemOperationListItem.js'

import { daysOfTheWeek, isValidISO8601Date, dayNameFromISO8601String } from '@/helpers/DateTime.js'
import { isObject, isString } from '@/helpers/Utils.js'

import { newDeviceOperationModel } from '@/store/modules/DeviceOperation.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed } from 'vue'

const generalItemId = 'general'

const defaultTaskNotifications = () => ({ taskComplete: false, taskUncomplete: false })

export default function useNewOperation (apiKey) {
  const isLoading = ref(false)
  const newOperation = ref(newDeviceOperationModel())
  const requireImage = ref(false)
  const currentJobSearchResult = ref(null)
  const dayOfTheWeekObj = ref({})
  const activeDays = ref([])
  const opItemList = ref([])
  const shiftPart = ref(null)
  const startTime = ref(null)
  const endDate = ref(null)
  const frequencyExectDate = ref(null)
  const frequencyYearDate = ref([])
  const frequencyModel = ref({ frequency: null, frequencyType: null })
  const preExistingListItem = ref(false)
  const operationScheduleId = ref(null)
  const reportTag = ref(null)
  const relatedItem = ref({})
  const taskNotifications = ref(defaultTaskNotifications())

  let originalJobId = null

  daysOfTheWeek().forEach((dayOfWeek) => (dayOfTheWeekObj.value[dayOfWeek.name] = dayOfWeek))

  const initFrequncyParams = (operationSchedule) => {
    const preDefinedFrequency = operationFrequencyOptionsForSelect().find((frequency) => frequency.frequencyType === operationSchedule.frequency_type &&
      frequency.frequency === operationSchedule.frequency)

    if (!isObject(preDefinedFrequency)) return

    frequencyModel.value = preDefinedFrequency
    if (frequencyModel.value.frequencyType === FrequencyTypes.ExectDate) {
      frequencyExectDate.value = operationSchedule.operation_next_date_raw
      endDate.value = operationSchedule.end_date
    }
    if (frequencyModel.value.frequencyType === FrequencyTypes.EveryYear) {
      frequencyYearDate.value[0] = operationSchedule.operation_next_date_raw
    }
  }

  const initUpdateOperation = ({ operation, shiftPartName, copyMode }) => {
    const operationSchedule = newOperationScheduleModel(operation)
    if (copyMode !== true) {
      operationScheduleId.value = operationSchedule.id
    }
    requireImage.value = operationSchedule.require_image
    newOperation.value = operationSchedule.getItemOperation()
    startTime.value = operationSchedule.start_time
    reportTag.value = operationSchedule.report_tag
    initFrequncyParams(operationSchedule)

    if (isFrequencyTypeEveryXWeek.value) {
      const dayName = dayNameFromISO8601String(operation.first_scheduled_date)
      const activeDay = activeDays.value.find((day) => day.name === dayName)
      if (isObject(activeDay)) activeDay.isActive = true
    }
    if (isString(shiftPartName)) {
      shiftPart.value = { name: shiftPartName }
    }
    if (Number.isInteger(operationSchedule.related_item_id)) {
      relatedItem.value = { id: operationSchedule.related_item_id }
    }
    return operationSchedule
  }

  const createOrUpdateItemOperationParams = ({ uploadImageCount, attachedFileName }) => {
    const itemOperation = newOperation.value.serialize()
    const requestParams = itemOperation
    if (Number.isInteger(uploadImageCount) && uploadImageCount > 0) {
      requestParams.with_images = uploadImageCount
    }
    if (isString(attachedFileName) === true) {
      requestParams.with_file = attachedFileName
    }
    return requestParams
  }

  const baseUpdateAndCreateParams = () => {
    const operationScheduleParams = operationFrequencyParams()
    operationScheduleParams.require_image = requireImage.value
    operationScheduleParams.start_time = startTime.value
    operationScheduleParams.end_date = endDate.value
    if (isObject(relatedItem.value) && Number.isInteger(relatedItem.value.id)) {
      operationScheduleParams.related_item_id = relatedItem.value.id
    }
    if (isString(reportTag.value)) {
      operationScheduleParams.report_tag = reportTag.value
    }
    if (isFrequencyTypeExectDate.value) return operationScheduleParams
    operationScheduleParams.shift_part = shiftPart.value.name
    return operationScheduleParams
  }

  const createOperationScheduleParams = () => {
    const operationScheduleParams = baseUpdateAndCreateParams()
    if (isFrequencyTypeExectDate.value) return operationScheduleParams

    if (isFrequencyTypeEveryDay.value) return operationScheduleParams
    if (isFrequencyTypeEveryYear.value === true) {
      operationScheduleParams.dates = frequencyYearDate.value
    } else {
      operationScheduleParams.days = activeDays.value.filter((dayOfWork) => dayOfWork.isActive === true)
        .map((dayOfWork) => dayOfWork.name)
    }
    return operationScheduleParams
  }

  const updateOperationScheduleParams = () => {
    const operationScheduleParams = baseUpdateAndCreateParams()
    if (Number.isInteger(originalJobId) && originalJobId !== currentJobSearchResult.value.id) {
      operationScheduleParams.account_job_id = currentJobSearchResult.value.id
    }
    if (isFrequencyTypeExectDate.value || isFrequencyTypeEveryDay.value) return operationScheduleParams

    if (isFrequencyTypeEveryYear.value === true) {
      operationScheduleParams.date = frequencyYearDate.value[0]
    } else {
      operationScheduleParams.day = activeDays.value.filter((dayOfWork) => dayOfWork.isActive === true)
        .map((dayOfWork) => dayOfWork.name)[0]
    }
    return operationScheduleParams
  }

  const update = ({ apiKey, uploadImageCount, attachedFileName }) => {
    const operationScheduleParams = updateOperationScheduleParams()
    const itemOperationParams = createOrUpdateItemOperationParams({ uploadImageCount, attachedFileName })
    delete itemOperationParams.id
    const requestParams = Object.assign(operationScheduleParams, itemOperationParams)
    return jobOpScheduleUpdateWithItem(apiKey, originalJobId, operationScheduleId.value, requestParams)
  }

  const create = ({ apiKey, uploadImageCount, attachedFileName }) => {
    const operationScheduleParams = createOperationScheduleParams()
    const itemOperationParams = createOrUpdateItemOperationParams({ uploadImageCount, attachedFileName })
    const requestParams = Object.assign(operationScheduleParams, itemOperationParams, notificationParams())
    return jobOpScheduleCreateWithItem(apiKey, currentJobSearchResult.value.id, requestParams)
  }

  const notificationParams = () => {
    const notificationsVal = taskNotifications.value
    const result = {}
    if (!notificationsVal.taskComplete && !notificationsVal.taskUncomplete) return result
    if (notificationsVal.taskComplete) result.task_complete = true
    if (operationHasStartTime.value && notificationsVal.taskUncomplete === true) result.task_uncomplete = true
    return { notifications: result }
  }
  // Methods
  const clearColor = () => newOperation.value?.clearColor && newOperation.value.clearColor()
  const resetDayOfWork = () => activeDays.value.splice(0, activeDays.value.length)

  const reset = () => {
    newOperation.value = newDeviceOperationModel()
    requireImage.value = false
    currentJobSearchResult.value = null
    originalJobId = null
    opItemList.value = []
    frequencyYearDate.value.splice(0, frequencyYearDate.value.length)
    frequencyModel.value = { frequency: null, frequencyType: null }
    preExistingListItem.value = false
    operationScheduleId.value = null
    relatedItem.value = {}
    startTime.value = ''
    reportTag.value = ''
    endDate.value = ''
    taskNotifications.value = defaultTaskNotifications()
    resetDayOfWork()
  }

  const setOperation = ({ operation, jobId, shiftPartName, hasItemList, copyMode = false }) => {
    isLoading.value = true
    reset()
    originalJobId = jobId
    preExistingListItem.value = hasItemList
    currentJobSearchResult.value = ({ id: jobId })
    return onJobSelect()
      .then(() => initUpdateOperation({ operation, jobId, shiftPartName, copyMode }))
      .finally(() => (isLoading.value = false))
  }

  const assignDaysOfWork = (jobDaysOfWork = []) => {
    resetDayOfWork()
    jobDaysOfWork.forEach((dayOfWork) => {
      const day = Object.assign({}, dayOfTheWeekObj.value[dayOfWork], { isActive: false })
      activeDays.value.push(day)
    })
  }

  const toggleActiveDay = ({ index, withReset = false }) => {
    const dayOfWork = activeDays.value[index]
    dayOfWork.isActive = !dayOfWork.isActive
    if (withReset !== true) return
    activeDays.value.forEach((day, dayIndex) => {
      if (dayIndex !== index) day.isActive = false
    })
  }

  const onJobSelect = async () => {
    if (isJobSelected.value !== true) {
      resetDayOfWork()
      return
    }

    const currentJobId = currentJobSearchResult.value.id
    return getJobWorkingDays(apiKey, currentJobId)
      .then((jobDaysOfWork) => assignDaysOfWork(jobDaysOfWork))
      .catch(errHandler)
  }

  const operationFrequencyParams = () => {
    const frequencyParams = frequencyModel.value
    const frequencyParamsResult = {
      frequency_type: frequencyParams.frequencyType || null,
      frequency: frequencyParams.frequency || null
    }
    if (isFrequencyTypeExectDate.value) {
      frequencyParamsResult.frequency = frequencyExectDate.value
    }
    return frequencyParamsResult
  }

  const itemOperationListCreatePromise = (operationId) => {
    if (hasListItem.value !== true || isUpdateMode.value === true) return null
    return itemOperationListItemClientBatchUpsert(apiKey, generalItemId, operationId, { create_params: opItemList.value })
  }

  const saveOperationImages = async ({ imageUploadData, saveImageCall }) => {
    imageUploadData.itemId = generalItemId
    imageUploadData.apiKey = apiKey
    return saveImageCall(imageUploadData)
  }

  const saveOperationFile = async ({ fileUploadData, saveFileCall }) => {
    if (saveFileCall === null) return

    fileUploadData.itemId = generalItemId
    fileUploadData.apiKey = apiKey
    return saveFileCall(fileUploadData)
  }

  const saveAdditionalFiles = async ({ imageUploadData, saveImageCall, fileUploadData, saveFileCall }) => {
    return Promise.all([
      saveOperationImages({ imageUploadData, saveImageCall }),
      saveOperationFile({ fileUploadData, saveFileCall })
    ])
  }

  const save = async ({ uploadImageCount, saveImageCall, attachedFileName = null, saveFileCall = null }) => {
    isLoading.value = true
    const imageUploadData = {}
    const fileUploadData = {}
    const promise = isUpdateMode.value
      ? update({ apiKey, uploadImageCount, attachedFileName })
      : create({ apiKey, uploadImageCount, attachedFileName })
    return promise
      .then((response) => {
        const itemOperationId = isUpdateMode.value ? newOperation.value.id : response.data.data.id
        imageUploadData.itemOperationId = itemOperationId
        imageUploadData.imageUploadSignatures = response.data.image_upload_signature
        fileUploadData.itemOperationId = itemOperationId
        fileUploadData.fileUploadSignature = response.data.file_upload_signature

        return itemOperationListCreatePromise(itemOperationId)
      })
      .then(() => saveAdditionalFiles({ imageUploadData, saveImageCall, fileUploadData, saveFileCall }))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  // Computed
  const isOperationValid = computed(() => (newOperation.value.operation_type || '').length > 0)
  const hasListItem = computed(() => (Array.isArray(opItemList.value) && opItemList.value.length > 0) || preExistingListItem.value)
  const isUpdateMode = computed(() => Number.isInteger(operationScheduleId.value) && operationScheduleId.value > 0)
  const shiftPartDisabled = computed(() => isFrequencyTypeExectDate.value || isFrequencyTypeEveryYear.value)
  const operationHasStartTime = computed(() => isString(startTime.value) && startTime.value.length > 0)

  const isScheduleValid = computed(() => {
    if (isFrequencyTypeEveryDay.value === true) return true
    if (isFrequencyTypeExectDate.value === true) {
      return isValidISO8601Date(frequencyExectDate.value)
    }
    if (isFrequencyTypeEveryYear.value === true) {
      if (frequencyYearDate.value.length === 0) return false
      return frequencyYearDate.value.every((date) => isValidISO8601Date(date))
    }
    return Object.values(activeDays.value).some((activeDay) => activeDay.isActive === true)
  })

  const isFrequencyTypeExectDate = computed(() => {
    if (!isObject(frequencyModel.value)) return false
    return frequencyModel.value.frequencyType === FrequencyTypes.ExectDate
  })

  const isFrequencyTypeEveryYear = computed(() => {
    if (!isObject(frequencyModel.value)) return false
    return frequencyModel.value.frequencyType === FrequencyTypes.EveryYear
  })

  const isFrequencyTypeEveryDay = computed(() => {
    if (!isObject(frequencyModel.value)) return false
    return frequencyModel.value.frequencyType === FrequencyTypes.EveryDay
  })

  const isFrequencyTypeEveryXWeek = computed(() =>
    isObject(frequencyModel.value) && frequencyModel.value.frequencyType === FrequencyTypes.EveryWeek)

  const isJobSelected = computed(() => {
    const jobId = (currentJobSearchResult.value || {}).id
    return Number.isInteger(jobId)
  })

  const isOperationDesctiptionValid = computed(() => {
    const description = newOperation.value?.description
    if (!isString(description)) return true
    return description.trim().length <= 240
  })

  const saveDisabled = computed(() => {
    if (isLoading.value === true) return true
    if (isJobSelected.value !== true) return true
    if (isOperationValid.value !== true) return true
    if (isScheduleValid.value !== true) return true
    if (isOperationDesctiptionValid.value !== true) return true
    return false
  })

  return {
    newOperation,
    isLoading,
    currentJobSearchResult,
    activeDays,
    shiftPart,
    startTime,
    endDate,
    reportTag,
    opItemList,
    relatedItem,
    frequencyExectDate,
    frequencyYearDate,
    frequencyModel,
    shiftPartDisabled,
    isFrequencyTypeExectDate,
    requireImage,
    saveDisabled,
    hasListItem,
    taskNotifications,
    operationHasStartTime,
    isFrequencyTypeEveryXWeek,
    onJobSelect,
    save,
    reset,
    toggleActiveDay,
    clearColor,
    setOperation
  }
}
