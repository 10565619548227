<template>
  <v-row
    align="start" justify="start" no-gutters
    class="drive-folder pointer" @click="onFolderClick"
  >
    <v-col cols="12" class="mb-2 image-container" :class="{ 'no-image': !hasImage }">
      <v-img
        v-if="hasImage" :src="folder.image_url"
        class="folder-image"
        height="90px"
      />
      <span class="folder-count t-500-13">
        <v-icon color="white" class="material-icons-outlined" size="20">
          description
        </v-icon>
        <span class="pps-1">
          {{ filesCount }}
        </span>
      </span>
    </v-col>
    <v-col class="flex-grow-1 flex-shrink-0 px-4">
      <v-icon color="#111">
        folder
      </v-icon>
      <span class="t-700-18 pps-1 color20">
        {{ folder.name }}
      </span>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1 px-4">
      <v-menu close-on-content-click>
        <template #activator="{ on }">
          <v-icon color="black" v-on="on">
            more_vert
          </v-icon>
        </template>
        <v-list class="edit-operation">
          <v-list-item v-if="showDeleteAndEditOptions" ripple @click.stop="onEdit">
            <v-list-item-icon>
              <v-icon small>
                edit
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="showDeleteAndEditOptions" ripple @click.stop="onDelete">
            <v-list-item-icon>
              <v-icon small>
                delete
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
          <v-list-item ripple @click.stop="onShare">
            <v-list-item-icon>
              <v-icon small>
                share
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('share') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="12" class="px-4">
      <div class="mt-2 folder-roles">
        <drive-resource-roles :resource="folder" />
      </div>
      <div class="mt-2">
        <drive-resource-accounts :resource="folder" />
      </div>
    </v-col>
    <v-col cols="12" class="py-2">
      <v-divider class="color18" />
    </v-col>
    <v-col cols="12" class="row no-gutters align-center justify-start px-4 mb-2">
      <v-col class="flex-grow-1 flex-shrink-0">
        <div>{{ createdAtString }}</div>
        <div class="t-500-13 color2 pps-1">
          {{ $t('drive.created_by', { firstName: folder.creator?.first_name, lastName: folder.creator?.last_name }) }}
        </div>
      </v-col>
      <v-col class="flex-grow-0 flex-shrink-1">
        <v-avatar color="indigo" size="36">
          <img v-if="showUserAvatarImage" :src="userAvatarUrl">
          <span v-else class="white--text text-h5">{{ userInitials }}</span>
        </v-avatar>
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
import DriveResourceAccounts from '@/components/drive/shared/DriveResourceAccounts.vue'
import DriveResourceRoles from '@/components/drive/shared/DriveResourceRoles.vue'

import useAuth from '@/composables/useAuth.js'

import { dateToLocalString } from '@/helpers/DateTime.js'
import { isString, isObject } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'

import { computed } from 'vue'
export default {
  components: {
    'drive-resource-roles': DriveResourceRoles,
    'drive-resource-accounts': DriveResourceAccounts
  },
  props: {
    folder: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const { isUserCompanyAdmin, currentUser } = useAuth()
    // Methods
    const onDelete = () => emit('delete-folder', props.folder)
    const onEdit = () => emit('edit-folder', props.folder)
    const onFolderClick = () => emit('click-folder', props.folder)
    const onShare = () => emit('share-folder', props.folder)

    // Computed
    const userAvatarUrl = computed(() => props.folder.creator?.avatar_url)
    const showUserAvatarImage = computed(() => isString(userAvatarUrl.value))
    const createdAtString = computed(() => dateToLocalString(new Date(props.folder.created_at)))
    const userInitials = computed(() => {
      const creator = props.folder.creator
      if (!isObject(creator)) return ''
      return `${creator.first_name.charAt(0)}${creator.last_name.charAt(0)}`
    })

    const filesCount = computed(() => {
      const totalFiles = props.folder.total_files
      if (!Number.isInteger(totalFiles) || totalFiles === 0) return i18n.t('drive.no_files')
      if (totalFiles === 1) return i18n.t('drive.one_file')
      return i18n.t('drive.many_files', { count: totalFiles })
    })

    const showDeleteAndEditOptions = computed(() => {
      if (isUserCompanyAdmin.value) return true
      const currentUserId = currentUser.value?.id
      return Number.isInteger(currentUserId) && currentUserId === props.folder.creator?.user_id
    })

    const hasImage = computed(() => isString(props.folder.image_url) && props.folder.image_url.length > 0)
    return {
      showUserAvatarImage,
      userAvatarUrl,
      userInitials,
      filesCount,
      showDeleteAndEditOptions,
      createdAtString,
      hasImage,
      onFolderClick,
      onDelete,
      onEdit,
      onShare
    }
  }
}
</script>
<style lang="scss">
.drive-folder {
  background-color: white;
  height: 100%;
  border-radius: 16px;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);

  .image-container {
    position: relative;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .folder-image {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .folder-count {
      background-color: black;
      color: white;
      border-radius: 16px;
      position: absolute;
      bottom: 8px;
      right: 8px;
      padding: 4px;
    }

    &.no-image {
      height: 90px;
      background: linear-gradient(95deg, #B3C6FF -2.72%, #3E8BFE 103.03%);
    }
  }

  .folder-roles {
    margin-right: -4px;
    margin-left: -4px;
  }
}

.rtl {
  .drive-folder {
    .folder-count {
      left: 8px;
      right: auto;
    }
  }
}
</style>
