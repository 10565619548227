<template>
  <v-dialog
    v-model="dialog" max-width="650" persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="org-struct-job-new">
      <v-card-title class="text-center pb-0">
        <span class="headline full-width">
          {{ dialogTitle }}
        </span>
      </v-card-title>
      <div class="white scrollable">
        <v-card-text class="py-0 px-0 px-sm-4">
          <v-form ref="newJobForm">
            <v-container class="pb-0">
              <v-row align="center" justify="start">
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="newJob.name"
                    class="required"
                    :rules="[requiredField]"
                    single-line
                    counter="120"
                    :label="$t('org_job_new_dialog.job_name')"
                  />
                </v-col>
                <v-col cols="8" sm="2" offset-sm="1">
                  <v-select
                    v-model="newJob.shift_id"
                    class="required"
                    :items="shiftSelectionOptions"
                    :rules="[requiredField]"
                    :label="$t('org_job_new_dialog.choose_shift')"
                    item-value="id"
                    item-text="translatedName"
                    single-line
                  >
                    <template #selection="data">
                      <div class="text-center">
                        <div>{{ data.item.translatedName }}</div>
                      </div>
                    </template>
                    <template #item="data">
                      <div class="text-center">
                        <div>{{ data.item.translatedName }}</div>
                      </div>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="8" md="4" offset-sm="1"
                  sm="4"
                >
                  <account-department-select
                    v-model="newJob.account_department.id" :api-key="apiKey"
                    :is-required="isUserAccountManager" :show-add-department="isAccountAdminOrAbove"
                  />
                </v-col>
              </v-row>
              <weekly-time-select
                v-if="dialog" :weekly-schedule="newJob.weekly_schedule"
                @update="onUpdateWeeklySchedule"
              />
            </v-container>
          </v-form>
        </v-card-text>
      </div>
      <v-card-actions class="pb-3 pr-8 white">
        <v-spacer />
        <v-btn
          color="blue darken-1" text :disabled="isLoading"
          @click="close(false)"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          color="blue darken-1" depressed tile
          :loading="isLoading" :disabled="saveDisabled" @click="save"
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AccountDepartmentSelect from '@/components/shared/account_department/AccountDepartmentSelect.vue'
import AccountJobsCreateWeeklyTimeSelect from '@/components/account_jobs/account_job_create_dialog/AccountJobsCreateWeeklyTimeSelect.vue'

import {
  newJobModel as OrgJobNewJobModel,
  getShiftSortOrderMap, translateShift as OrgJobTranslateShift
} from '@/store/modules/OrganizationJob.js'

import useAuth from '@/composables/useAuth.js'

import { create as JobClientCreate, update as JobClientUpdate } from '@/api_client/AccountJob.js'
import { requiredField } from '@/classes/InputValidators.js'

import { i18n } from '@/i18n.js'

import { ref, nextTick, computed } from 'vue'
const DialogMode = { create: 0, update: 1 }

export default {
  components: {
    'account-department-select': AccountDepartmentSelect,
    'weekly-time-select': AccountJobsCreateWeeklyTimeSelect
  },
  setup () {
    const { apiKey, isUserAccountManager, isAccountAdminOrAbove } = useAuth()
    const shiftSelection = []
    const shiftSortOrderMap = getShiftSortOrderMap()
    for (const shiftName in shiftSortOrderMap) { // eslint-disable-line no-unused-vars
      shiftSelection.push({
        id: shiftSortOrderMap[shiftName],
        translatedName: OrgJobTranslateShift(shiftName),
        name: shiftName
      })
    }

    const dialogMode = ref(null)
    const dialog = ref(false)
    const shiftSelectionOptions = ref(shiftSelection)
    const newJob = ref(OrgJobNewJobModel())
    const isLoading = ref(false)
    const newJobForm = ref(null)
    let resolveFunc = null

    const openDialog = (job, dialogModeVal) => {
      dialog.value = true
      dialogMode.value = dialogModeVal
      newJob.value = OrgJobNewJobModel(job)
      if (newJob.value.shift && newJob.value.shift.name) {
        newJob.value.shift_id = shiftSelectionOptions.value.find((item) => item.name === newJob.value.shift.name).id
      }

      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const setTimeForDay = (day, isStartTime, timeValue) => {
      const currentDay = newJob.value.weekly_schedule[day]
      if (isStartTime) {
        currentDay.start_time = timeValue
      } else {
        currentDay.end_time = timeValue
      }
    }

    const createNewJob = () => {
      const creationParams = newJob.value.serialize()
      JobClientCreate(apiKey.value, { account_job: creationParams })
        .then(() => close(true))
        .finally(() => (isLoading.value = false))
    }

    const updateJob = () => {
      const updateParams = newJob.value.serialize()
      JobClientUpdate(apiKey.value, newJob.value.id, { account_job: updateParams })
        .then(() => close(true))
        .finally(() => (isLoading.value = false))
    }

    const allweeklyScheduleValid = (weeklySchedule) => {
      for (const day in weeklySchedule) { // eslint-disable-line no-unused-vars
        const startTime = weeklySchedule[day].start_time || ''
        const endTime = weeklySchedule[day].end_time || ''
        if (startTime.length !== endTime.length) return false
      }
      return true
    }

    // Methods

    const openForUpdate = (job) => openDialog(job, DialogMode.update)
    const openForCreate = () => openDialog({}, DialogMode.create)
    const onUpdateWeeklySchedule = ({ day, isStartTime, time }) => setTimeForDay(day, isStartTime, time)

    const close = (resolveResult) => {
      dialogMode.value = null
      dialog.value = false
      newJobForm.value.reset()
      resolveFunc(resolveResult)
    }
    const save = () => {
      nextTick(() => {
        isLoading.value = true
        if (dialogMode.value === DialogMode.update) {
          updateJob()
        } else {
          createNewJob()
        }
      })
    }

    // Computed
    const saveDisabled = computed(() => {
      if (!allweeklyScheduleValid(newJob.value.weekly_schedule)) return true
      if (!newJob.value.name ||
        !Number.isInteger(+newJob.value.shift_id)) return true
      if (isUserAccountManager.value === true && !Number.isInteger(newJob.value.account_department?.id)) return true
      if (isLoading.value) return true
      return false
    })

    const dialogTitle = computed(() =>
      dialogMode.value === DialogMode.update
        ? i18n.t('org_job_new_dialog.update_title')
        : i18n.t('org_job_new_dialog.create_title')
    )

    return {
      dialog,
      apiKey,
      shiftSelectionOptions,
      newJob,
      isLoading,
      isUserAccountManager,
      isAccountAdminOrAbove,
      newJobForm,
      saveDisabled,
      dialogTitle,
      openForUpdate,
      openForCreate,
      close,
      onUpdateWeeklySchedule,
      requiredField,
      save
    }
  }
}
</script>
<style lang="scss">
.org-struct-job-new {
  .time-select {
    display: inline-block;
    padding-right: 6px;
    padding-left: 6px;
    width: calc(100% / 7);
  }

  @media only screen and (max-width: 600px) {
    .time-select {
      width: calc(100% / 3);
      margin-top: 10px;
      padding-right: 8px;
      padding-left: 8px;

      .v-input.v-text-field.start-time {
        padding-top: 0;
      }

      .v-text-field__details {
        display: none;
      }
    }

    .scrollable {
      overflow: auto;
      height: 80vh;
      max-height: 80vh;
    }
  }

  .v-input.v-text-field {
    margin-top: 0px
  }
}
</style>
