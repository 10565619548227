import Client from '@/api_client/client.js'

function indexByJobIds (apiKey, accountJobIds) {
  const INDEX_BY_ACCOUNT_JOB_IDS_PATH = `/accounts/${apiKey}/user_allowed_jobs/index_by_job_id`
  return Client.put(INDEX_BY_ACCOUNT_JOB_IDS_PATH, { account_job_ids: accountJobIds })
}

export {
  indexByJobIds
}
