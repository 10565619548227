<template>
  <v-row v-if="isLoading" align="start" justify="start">
    <v-col v-for="i in 6" :key="i" cols="4">
      <v-skeleton-loader
        class="my-4"
        type="card"
      />
    </v-col>
  </v-row>
  <v-row
    v-else align="start" justify="start"
    class="mt-4"
  >
    <v-col cols="6" md="4" lg="3">
      <div class="row justify-space-between px-1 pb-4 t-500-16">
        <span>{{ $t('tasks_page.progress') }}</span>
        <span class="ltr d-inline-block">{{ stats.finishedTasksCount }} / {{ stats.tasksCount }}</span>
      </div>
      <tasks-progress
        hide-numbers with-border
        :is-loading="isLoading"
        :tasks-count="stats.tasksCount"
        :finished-tasks-count="stats.finishedTasksCount"
      />
    </v-col>
    <v-col cols="12" class="pa-0" />
    <v-col
      v-for="department in departmentsList" :key="department.department_id" cols="6"
      md="4"
      lg="3"
    >
      <single-department
        :department="department" class="pointer"
        @department-click="onDepartmentClick"
      />
    </v-col>
  </v-row>
</template>
<script>

import SingleDepartment from '@/components/tasks/departments_list/SingleDepartment.vue'
import TasksProgress from '@/components/tasks/TasksProgress.vue'

import useDailyJobs from '@/composables/useDailyJobs.js'

import { ref } from 'vue'
export default {
  components: {
    'single-department': SingleDepartment,
    'tasks-progress': TasksProgress
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const panel = ref(null)
    const { isLoading, stats, departmentsList, activeDepartmentJobs, changeActiveDepartment } = useDailyJobs(props.apiKey)
    const onDepartmentClick = changeActiveDepartment

    return {
      panel,
      isLoading,
      departmentsList,
      activeDepartmentJobs,
      stats,
      onDepartmentClick
    }
  }
}
</script>
