<template>
  <v-row align="start" justify="start">
    <v-col v-if="isLoading" cols="12">
      <v-skeleton-loader
        v-for="i in 5" :key="i" class="mt-3"
        type="list-item-three-line"
      />
    </v-col>
    <v-col v-else-if="isEmptyJobsList" cols="12">
      <empty-task-page />
    </v-col>
    <v-col v-else cols="12">
      <v-expansion-panels
        v-model="panel"
        class="tasks-layout"
      >
        <v-expansion-panel
          v-for="(job, index) in jobsList"
          :key="job.id"
          class="mt-3"
          @click="onJobClick(job)"
        >
          <v-expansion-panel-header>
            <single-job
              :job="job"
              :staff-account="staffAccount"
              :selected="job.id === activeJobId"
              @toggle-shift-complete="onToggleJobShiftComplete"
            />
            <template #actions>
              &nbsp;
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="tasks-expansion">
            <slot v-if="panel === index" name="tasks-list" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-snackbar v-model="shiftCompleteSnakBar" timeout="2000">
      <div class="text-center">
        {{ $t('account_operations.mark_shift_completed') }}
      </div>
    </v-snackbar>
  </v-row>
</template>
<script>
import SingleJob from '@/components/tasks/jobs_list/SingleJob.vue'
import EmptyTaskPage from '@/components/shared/EmptyTaskPage.vue'

import useDailyJobs from '@/composables/useDailyJobs.js'

import { ref, computed } from 'vue'
export default {
  components: {
    'single-job': SingleJob,
    'empty-task-page': EmptyTaskPage
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    staffAccount: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const shiftCompleteSnakBar = ref(false)
    const { isLoading, jobsList, activeJobId, changeActiveJob, toggleJobShiftComplete } = useDailyJobs(props.apiKey)
    const panel = ref(null)

    // Methods
    const onJobClick = changeActiveJob
    const onToggleJobShiftComplete = (job) => toggleJobShiftComplete(job)
      .then((isShiftCompleted) => {
        if (isShiftCompleted) shiftCompleteSnakBar.value = true
      })

    // Computed
    const isEmptyJobsList = computed(() => !isLoading.value && jobsList.value.length === 0)
    return {
      isLoading,
      jobsList,
      activeJobId,
      shiftCompleteSnakBar,
      panel,
      isEmptyJobsList,
      onJobClick,
      onToggleJobShiftComplete
    }
  }
}
</script>
<style lang="scss">
.tasks-layout {
  .v-expansion-panel {
    border-radius: 8px;
  }

  .tasks-expansion {

    .v-expansion-panel-content__wrap,
    .v-expansion-panel-header {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
</style>
