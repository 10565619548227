<template>
  <v-row
    align="start" justify="start" no-gutters
    class="single-job-row pa-4"
  >
    <v-col class="flex-grow-1 flex-shrink-0">
      <span class="t-700-16">
        {{ job.name }} - {{ translatedShiftName }}
      </span>
      <span v-if="showDepartment" class="t-400-14 color10 pps-4">
        {{ job.account_department.name }}
      </span>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1">
      <v-menu close-on-content-click>
        <template #activator="{ on }">
          <v-icon color="black" v-on="on">
            more_vert
          </v-icon>
        </template>
        <v-list class="edit-operation">
          <v-list-item ripple @click="onEdit">
            <v-list-item-icon>
              <v-icon small>
                edit
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
          </v-list-item>
          <v-list-item ripple @click="onDelete">
            <v-list-item-icon>
              <v-icon small>
                delete
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="12" class="row align-start justify-space-between no-gutters">
      <v-col v-for="weekDay in daysOfTheWeekArray" :key="weekDay.name" class="px-2 single-day-block">
        <single-job-row-day
          :day-name="weekDay.translatedName" :day-schedule="job.weekly_schedule[weekDay.name]"
          :style="{ 'background-color': shiftColor }"
        />
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
import SingleJobRowDay from '@/components/account_jobs/SingleJobRowDay.vue'

import useAccount from '@/composables/useAccount.js'
import { translateShift, getShiftColor } from '@/store/modules/OrganizationJob.js'

import { daysOfTheWeek } from '@/helpers/DateTime.js'
import { isString, isObject } from '@/helpers/Utils'

import { computed } from 'vue'
export default {
  components: {
    'single-job-row-day': SingleJobRowDay
  },
  props: {
    job: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    // Methods
    const onEdit = () => emit('edit-job', props.job)
    const onDelete = () => emit('delete-job', props.job)

    // Computed
    const translatedShiftName = computed(() => translateShift(props.job.shift.name))
    const showDepartment = computed(() => isObject(props.job.account_department) && isString(props.job.account_department.name))
    const { weekStartDay } = useAccount()
    const daysOfTheWeekArray = daysOfTheWeek(weekStartDay.value)
    const shiftColor = computed(() => getShiftColor(props.job.shift.name))
    return {
      translatedShiftName,
      showDepartment,
      daysOfTheWeekArray,
      shiftColor,
      onEdit,
      onDelete
    }
  }
}
</script>
<style lang="scss">
.single-job-row {
  background-color: white;

  .single-day-block {
    flex: 1 0 calc(100% / 7);
    max-width: calc(100% / 7);
  }
}
</style>
