<template>
  <v-row
    align="start" justify="start" no-gutters
    class="weekly-calender align-stretch"
  >
    <v-col
      v-for="weekDay in weekHeader"
      :key="weekDay.dateIso" class="day"
    >
      <div class="day-name pa-1 t-700-14" :class="{ 'current-day': weekDay.isToday }">
        {{ weekDay.dayName }}
      </div>
      <div class="px-2 py-1 t-700-14 color13 texts-end">
        {{ weekDay.dayNumber }}
      </div>
      <div class="justify-center align-center row py-2 no-gutters">
        <div
          v-for="(event, index) in events[weekDay.dateIso] || []" :key="index" v-ripple
          class="single-event text-truncate mb-2 texts-start"
          :style="{ 'background-color': event.color }" @click="onClick(event)"
        >
          <job-calander-single-event :event="event" />
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import JobCalanderSingleEvent from '@/components/jobs_calander/JobCalanderSingleEvent.vue'

import { isRtl } from '@/i18n.js'

import { translateDayFromDate, dateToISO8601String } from '@/helpers/DateTime.js'

import { ref } from 'vue'

const weekArrayFromWeekStart = (weekStart) => {
  const currentDate = dateToISO8601String(new Date())
  const weekArray = []
  for (let i = 0; i < 7; i++) {
    const date = new Date(weekStart)
    date.setDate(date.getDate() + i)
    const dateIso = dateToISO8601String(date)
    const isToday = dateToISO8601String(date) === currentDate
    const dateData = {
      dayName: translateDayFromDate(date),
      dayNumber: date.getDate(),
      dateIso,
      isToday
    }
    weekArray.push(dateData)
  }
  return weekArray
}
export default {
  components: {
    'job-calander-single-event': JobCalanderSingleEvent
  },
  props: {
    events: {
      type: Object,
      required: false,
      default: () => { }
    },
    weekStart: {
      type: Date,
      required: true
    }
  },
  setup (props, { emit }) {
    const weekHeader = ref(weekArrayFromWeekStart(props.weekStart))

    // Methods
    const onClick = (event) => emit('click', { event })

    return {
      isRtl,
      weekHeader,
      onClick
    }
  }
}
</script>
<style lang="scss">
.weekly-calender {
  border: 2px solid #ADB2B6;
  background-color: white;

  .day {
    border-right: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    flex: 1 1 auto;
    width: 0;
    padding-bottom: 8px !important;

    .day-name {
      color: #333F48;
      text-transform: uppercase;
      background-color: #F5F5F6;
      border-bottom: 1px solid rgba(51, 63, 72, 0.20);

      &.current-day {
        background-color: #0040FF;
        color: white;
      }
    }

    .single-event {
      border-radius: 4px;
      background-color: black;
      color: white;
      font-size: 12px;
      cursor: pointer;
      line-height: 20px;
      width: 95%;
      margin-bottom: 1px;
    }
  }
}
</style>
