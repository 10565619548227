<template>
  <v-badge
    color="red"
    overlap
    :value="showBadge ? 1 : 0"
    :bordered="bordered"
    :offset-x="offsetX"
    :offset-y="offsetY"
    :content="unreadGroupCount"
  >
    <slot />
  </v-badge>
</template>
<script>
import useAppChat from '@/composables/useAppChat.js'

import { EventBus, NEW_APP_CHET_MESSAGE_RECEIVED } from '@/EventBus.js'

import { computed, onUnmounted } from 'vue'
import { useRoute } from 'vue-router/composables'

const APP_CHAT_ROUTE_NAME = 'chat'
const STAFF_APP_CHAT_ROUTE_NAME = 'staff/chat'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    offsetX: {
      type: Number,
      default: undefined
    },
    offsetY: {
      type: Number,
      default: undefined
    },
    bordered: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const router = useRoute()
    const { unreadGroupCount, loadUnreadGroupCount } = useAppChat(props.apiKey)
    loadUnreadGroupCount()

    const onNewMessageReceived = () => {
      const routeName = router.name
      if (routeName === APP_CHAT_ROUTE_NAME || routeName === STAFF_APP_CHAT_ROUTE_NAME) return

      loadUnreadGroupCount(true)
    }

    const showBadge = computed(() => Number.isInteger(unreadGroupCount.value) && unreadGroupCount.value > 0)

    EventBus.off(NEW_APP_CHET_MESSAGE_RECEIVED, onNewMessageReceived)
    EventBus.on(NEW_APP_CHET_MESSAGE_RECEIVED, onNewMessageReceived)
    onUnmounted(() => EventBus.off(NEW_APP_CHET_MESSAGE_RECEIVED, onNewMessageReceived))
    return {
      showBadge,
      unreadGroupCount
    }
  }
}
</script>
