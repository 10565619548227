import Client from '@/api_client/client.js'
import useItemSearchStore from '@/components/shared/item_search/useItemSearchStore.js'

function show (apiKey, id) {
  const DEVICES_SHOW_PATH = `/accounts/${apiKey}/items/${id}/`
  return Client.get(DEVICES_SHOW_PATH)
}

function showGeneral (apiKey) {
  const DEVICES_SHOW_GENERAL_PATH = `/accounts/${apiKey}/items/show_general/`
  return Client.get(DEVICES_SHOW_GENERAL_PATH)
}

function indexWithBrokenItems (apiKey, params) {
  const DEVICES_INDEX_PATH = `/accounts/${apiKey}/items/`
  return Client.get(DEVICES_INDEX_PATH, { params })
}

async function create (apiKey, params) {
  const DEVICES_CREATE_PATH = `/accounts/${apiKey}/items`
  const response = await Client.post(DEVICES_CREATE_PATH, params)
  invalidItemSearchCache(apiKey)
  return response
}

async function update (apiKey, id, params) {
  const DEVICES_PUT_PATH = `/accounts/${apiKey}/items/${id}/`
  const response = await Client.put(DEVICES_PUT_PATH, params)
  invalidItemSearchCache()
  return response
}

async function destroy (apiKey, id) {
  const DEVICES_DELETE_PATH = `/accounts/${apiKey}/items/${id}/`
  const response = await Client.delete(DEVICES_DELETE_PATH)
  invalidItemSearchCache()
  return response
}

function search (apiKey, { term, withBrokenItems = null, withBrokenItemsWithOpenStatus = null, categoryId = null }) {
  const DEVICES_SEARCH_PATH = `/accounts/${apiKey}/items/search/`
  const requestParams = { term }
  if (withBrokenItems === true) requestParams.with_broken_items = true
  if (categoryId) requestParams.category_id = categoryId
  if (withBrokenItemsWithOpenStatus === true) {
    requestParams.broken_item_status = 'open'
    requestParams.with_broken_items = true
  }
  return Client.get(DEVICES_SEARCH_PATH, { params: requestParams })
}

function searchAll (apiKey, { withBrokenItems = null, withBrokenItemsWithOpenStatus = null } = {}) {
  const DEVICES_SEARCH_PATH = `/accounts/${apiKey}/items/search/`
  const requestParams = { search_all: true }
  if (withBrokenItems === true) requestParams.with_broken_items = true
  if (withBrokenItemsWithOpenStatus === true) {
    requestParams.broken_item_status = 'open'
    requestParams.with_broken_items = true
  }
  return Client.get(DEVICES_SEARCH_PATH, { params: requestParams })
}

function invalidItemSearchCache (apiKey) {
  const { invalidateCache } = useItemSearchStore(apiKey)
  invalidateCache()
}

export {
  show,
  showGeneral,
  indexWithBrokenItems,
  create,
  update,
  destroy,
  search,
  searchAll
}
