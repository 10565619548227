import Client from '@/api_client/client.js'

const index = (apiKey) => {
  const BROKEN_ITEM_DEADLINE_INDEX_PATH = `/accounts/${apiKey}/broken_items_default_deadline/`
  return Client.get(BROKEN_ITEM_DEADLINE_INDEX_PATH)
}

const batchUpsert = (apiKey, params) => {
  const BROKEN_ITEM_DEADLINE_BATCH_UPSERT_PATH = `/accounts/${apiKey}/broken_items_default_deadline/batch_upsert`
  return Client.put(BROKEN_ITEM_DEADLINE_BATCH_UPSERT_PATH, params)
}

export {
  index,
  batchUpsert
}
