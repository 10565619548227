import { search as tagSearch } from '@/api_client/Tags.js'

import { isString } from '@/helpers/Utils.js'

import { EventBus, UPDATE_TAGS_IF_NEEDED } from '@/EventBus.js'

import Vue, { ref } from 'vue'

let currentApiKey = null
const tagsCache = ref({})

export default function useTagChipSearch (apiKey) {
  const resetValues = () => {
    tagsCache.value = {}
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const reloadTagsIfNeeded = ({ context, tag, apiKey }) => {
    if (apiKey !== currentApiKey) return

    const contextTags = tagsCache.value[context] || []
    const tagExists = contextTags.includes(tag)
    if (tagExists) return

    loadTags({ context })
  }

  // Methods
  const loadTags = async ({ context, searchTerm = null }) => {
    const requestParams = { apiKey: currentApiKey, context }
    if (isString(searchTerm) && searchTerm.trim().length > 0) requestParams.term = searchTerm.trim()
    const response = await tagSearch(requestParams)

    if (!tagsCache.value[context]) Vue.set(tagsCache.value, context, [])
    const itemsVal = tagsCache.value[context]
    itemsVal.splice(0, itemsVal.length)
    response.data.data.forEach((tag) => itemsVal.push(tag))
    return itemsVal
  }

  const tagsForContext = (context) => tagsCache.value[context] || []

  const changeApiKey = (newApiKey) => {
    if (isString(newApiKey) && currentApiKey !== newApiKey) {
      currentApiKey = newApiKey
      resetValues()
    }
  }
  EventBus.on(UPDATE_TAGS_IF_NEEDED, reloadTagsIfNeeded)
  return {
    loadTags,
    tagsForContext,
    changeApiKey
  }
}
