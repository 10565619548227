<template>
  <page-filters
    :any-filter-selected="providerSelected"
    :right="!isRtl"
    :left="isRtl"
    @apply-filters="applyFilters"
    @reset-filters="resetFilters"
  >
    <company-provider-search
      v-model="curentProviderSearch"
      :api-key="apiKey"
      :menu-props="{ contentClass: 'inside-drawer' }"
    />
  </page-filters>
</template>
<script>

import CompanyProviderSearch from '@/components/shared/company_provider_search/CompanyProviderSearch.vue'
import PageFilters from '@/components/shared/PageFilters.vue'
import { isRtl } from '@/i18n.js'

import useCompanyProvider from '@/composables/useCompanyProvider.js'

import { isObject } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'
export default {
  components: {
    'company-provider-search': CompanyProviderSearch,
    'page-filters': PageFilters
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const curentProviderSearch = ref({})
    const { providerSearchChange, clearSearch } = useCompanyProvider(props.apiKey)

    // Methods
    const applyFilters = () => {
      if (providerSelected.value === true) {
        providerSearchChange(curentProviderSearch.value)
      } else {
        clearSearch()
      }
    }

    const resetFilters = () => {
      curentProviderSearch.value = {}
      applyFilters()
    }

    // Computed
    const providerSelected = computed(() => isObject(curentProviderSearch.value) && Number.isInteger(curentProviderSearch.value.id))
    return {
      curentProviderSearch,
      providerSelected,
      isRtl,
      applyFilters,
      resetFilters
    }
  }
}
</script>
