<template>
  <v-expand-transition>
    <v-row
      v-show="enabled" align="start" justify="center"
      class="text-center"
    >
      <v-col ref="qrContainer" cols="12" />
      <v-col cols="12" class="pt-0">
        <v-btn color="blue darken-1" tile @click="downloadQrCode">
          {{ $t('download') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-expand-transition>
</template>
<script>
import QRCodeStyling from 'qr-code-styling'

import { isString } from '@/helpers/Utils.js'

import { ref, computed, onMounted } from 'vue'

const options = {
  width: 250,
  height: 250,
  type: 'svg',
  data: null,
  image: new URL('@/assets/dex_logo_and_name_black.svg', import.meta.url).href,
  margin: 0,
  cornersSquareOptions: {
    color: '#35495E',
    type: 'extra-rounded'
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.4,
    margin: 1
  },
  cornersDotOptions: {
    color: '#35495E',
    type: 'dot'
  },
  qrOptions: {
    typeNumber: 5,
    mode: 'Byte',
    errorCorrectionLevel: 'Q'
  }
}
export default {
  setup () {
    const qrContainer = ref(null)
    const qrCodeData = ref(null)
    const qrCode = new QRCodeStyling(options)

    // Methods
    const updateQrCodeData = (data) => {
      qrCodeData.value = data
      qrCode.update({ data: qrCodeData.value })
    }
    const downloadQrCode = () => qrCode.download({ extension: 'png', name: 'dex-faults-qr' })

    // Computed
    const enabled = computed(() => isString(qrCodeData.value) && qrCodeData.value.trim().length > 0)

    onMounted(() => qrCode.append(qrContainer.value))
    return {
      qrContainer,
      enabled,
      updateQrCodeData,
      downloadQrCode
    }
  }
}
</script>
