<template>
  <v-container fluid fill-height class="account-faulty-device-show">
    <confirm-delete ref="deleteDialog" />
    <new-broken-item-dialog
      ref="newBrokenItemDialog"
      :show-close-option="showCloseTicket"
    />
    <chat-dialog
      ref="chatDialog"
      record-type="BrokenItem"
      @mark-read-all="markReadAllChatMessages"
      @update-message-count="updateMessageCount"
    />
    <media-carousel-dialog ref="mediaCarouselRef" />
    <v-row align="center" justify="center">
      <v-col
        v-if="showAccountName"
        cols="12" class="text-center mb-5 account-name font-weight-bold"
      >
        {{ accountName }}
      </v-col>
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <p v-else-if="faultyDevice === null">
        {{ $t('show_faulty_device.empty') }}
      </p>
      <v-col v-else cols="3" class="faulty-device-item">
        <broken-item-layout
          :broken-item="faultyDevice"
          :show-account-data="showAccountName"
          allow-edit
          :allow-delete="showDelete"
          :show-close-option="showCloseTicket"
          @open-chat="openChatDialog"
          @delete-broken-item="deleteFaultyDevice"
          @edit-broken-item="editFaultyDevice"
          @change-status="onChangeStatus"
          @media-click="onMediaClick"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewBrokenItemDialog from '@/components/broken_item_dialog/NewBrokenItemDialog.vue'
import ChatDialog from '@/components/chat_dialog/ChatDialog.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import { newBrokenItemModel, statuses } from '@/store/modules/BrokenItem.js'
import useAuth from '@/composables/useAuth.js'
import useAccount from '@/composables/useAccount.js'
import useCurrentUserPermissions from '@/composables/useCurrentUserPermissions.js'

import {
  show as BrokenItemClientShow,
  update as BrokenItemClientUpdate,
  destroy as BrokenItemClientDestroy
} from '@/api_client/BrokenItem.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n } from '@/i18n.js'

import { useRoute } from 'vue-router/composables'

import { computed, ref } from 'vue'

export default {
  components: {
    'new-broken-item-dialog': NewBrokenItemDialog,
    'confirm-delete': ConfirmDelete,
    'chat-dialog': ChatDialog
  },
  async setup () {
    const isLoading = ref(true)
    const faultyDevice = ref(null)
    const deleteDialog = ref(null)
    const newBrokenItemDialog = ref(null)
    const chatDialog = ref(null)
    const closeBrokenItemFeature = ref(false)
    const mediaCarouselRef = ref(null)

    const { apiKey, isAccountManagerOrAbove, isCompanyAdminRegionOrAbove, isAccountAdminOrAbove } = useAuth()
    const { canCloseBrokenItem, loadPermissions } = useCurrentUserPermissions(apiKey.value)
    const { accountName } = useAccount(apiKey.value)
    const route = useRoute()

    let itemId = null
    let brokenItemId = null
    isLoading.value = true

    const loadFaultyDevice = () => {
      const { accountLocaleCode } = useAccount()
      const locale = accountLocaleCode.value
      BrokenItemClientShow(apiKey.value, itemId, brokenItemId)
        .then((response) => (faultyDevice.value = newBrokenItemModel({ src: response.data.data, locale })))
        .finally(() => (isLoading.value = false))
    }

    const updateFaultyDevice = (updatedFaultyDevice) => faultyDevice.value.update(updatedFaultyDevice)

    // Methods

    const markReadAllChatMessages = () => faultyDevice.value.markAllMessagesAsRead()
    const updateMessageCount = ({ recordId, messageCount }) => faultyDevice.value.updateMessageCount(messageCount)
    const onMediaClick = (mediaArray) => mediaCarouselRef.value.openDialog(mediaArray)

    const openChatDialog = () => chatDialog.value.openDialog({ recordId: faultyDevice.value.id, apiKey: apiKey.value })
    const deleteFaultyDevice = () => {
      isLoading.value = true
      BrokenItemClientDestroy(apiKey.value, faultyDevice.value.item_id, faultyDevice.value.id)
        .then(() => (faultyDevice.value = null))
        .catch(errHandler)
        .finally(() => { isLoading.value = false })
    }

    const setCloseDateForToday = async () => {
      const title = i18n.t('devices_faulty_device_dialog.close_ticket')
      const content = i18n.t('devices_faulty_device_dialog.close_ticket_approve')
      const deleteBtnText = i18n.t('devices_faulty_device_dialog.send')
      const shouldDelete = await deleteDialog.value.open({ title, content, deleteBtnText })
      if (!shouldDelete) return

      isLoading.value = true
      faultyDevice.value.setClosingTime()
      BrokenItemClientUpdate(apiKey.value, faultyDevice.value.item_id,
        faultyDevice.value.id, { broken_item: faultyDevice.value.serialize() })
        .then(() => (faultyDevice.value = null))
        .catch(errHandler)
        .finally(() => {
          isLoading.value = false
          deleteDialog.value.close()
        })
    }
    const onChangeStatus = ({ brokenItem, status }) => {
      if (status === statuses.close) {
        return setCloseDateForToday(brokenItem)
      }
      brokenItem.updateStatus({ newStatus: status, apiKey: apiKey.value })
    }

    const editFaultyDevice = () => {
      isLoading.value = true
      newBrokenItemDialog.value.openForUpdate(faultyDevice)
        .then(({ updatedOrCreated, response, isItemIdChanged }) => {
          if (isItemIdChanged) {
            loadFaultyDevice()
          } else if (updatedOrCreated) {
            updateFaultyDevice(response)
          }
        })
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    // Computed

    const showAccountName = computed(() => isCompanyAdminRegionOrAbove.value && route.meta.staffOnly && !isLoading.value)
    const showCloseTicket = computed(() => isAccountAdminOrAbove.value || closeBrokenItemFeature.value)

    const routeParams = route.params
    itemId = routeParams.itemId
    brokenItemId = routeParams.brokenItemId

    if (route.meta.staffOnly) {
      const { allAccounts, loadAllAccounts, changeAccount } = useAccount()
      const apiKeyFromParams = routeParams.apiKey

      await loadAllAccounts()
      const selectedAccount = allAccounts.value.find((account) => account.api_key === apiKeyFromParams)
      await changeAccount(selectedAccount)
    }

    loadPermissions().then(() => (closeBrokenItemFeature.value = canCloseBrokenItem.value))
    loadFaultyDevice()

    return {
      isLoading,
      faultyDevice,
      deleteDialog,
      chatDialog,
      accountName,
      isAccountManagerOrAbove,
      showAccountName,
      showCloseTicket,
      mediaCarouselRef,
      onChangeStatus,
      deleteFaultyDevice,
      onMediaClick,
      loadFaultyDevice,
      updateFaultyDevice,
      editFaultyDevice,
      openChatDialog,
      markReadAllChatMessages,
      updateMessageCount
    }
  }
}
</script>

<style lang="scss">
.account-faulty-device-show {
  .account-name {
    font-size: 20px;
  }
}
</style>
