import { currencyCodeToSign, timeZoneToCountryCode, timeZoneToBCP47, defaultCountryCode, defaultLocaleCode } from '@/helpers/TimeZone.js'

import useItemSearchStore from '@/components/shared/item_search/useItemSearchStore.js'
import useAuth from '@/composables/useAuth.js'
import useItem from '@/composables/useItem.js'

import { show as AccountClientShow, update as AccountClientUpdate, index as AccountClientIndex } from '@/api_client/AccountClient.js'

import { isString } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'
const ACCOUNT_DATA_CACHE_KEY = 'ACCOUNT_DATA_CACHE_KEY'

const accountNameRef = ref('')
const accountCurrencyRef = ref('')
const accountCurrencySignRef = ref('')
const accountCreationDateRef = ref('')
const accountTimeZoneRef = ref('')
const accountCountryCodeRef = ref('')
const accountLocaleCodeRef = ref('')
const weekStartDayRef = ref('')
const allAccountsRef = ref([])

let currentApiKey = null

export default function useAccount (apiKey) {
  const resetValues = () => {
    accountNameRef.value = ''
    accountCurrencyRef.value = ''
    accountCurrencySignRef.value = ''
    accountCreationDateRef.value = ''
    accountTimeZoneRef.value = ''
    accountCountryCodeRef.value = ''
    accountLocaleCodeRef.value = ''
    weekStartDayRef.value = ''
    allAccountsRef.value = []
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const accountDataCacheKey = () => `${ACCOUNT_DATA_CACHE_KEY}_${currentApiKey}`

  const loadGeneralItem = (forceLoad = false) => {
    const { loadGeneralItem: loadGeneralItemImpl } = useItem(currentApiKey)
    loadGeneralItemImpl(forceLoad)
  }

  const getAccountDataFromCache = () => {
    const accountData = localStorage.getItem(accountDataCacheKey())
    if (!isString(accountData)) return null
    try {
      return JSON.parse(accountData)
    } catch (err) {
      return null
    }
  }
  const saveAccountDataToCache = (accountData) => {
    try {
      localStorage.setItem(accountDataCacheKey(), JSON.stringify(accountData))
    } catch (err) { }
  }

  const setAccountData = ({ accountData, saveToCache = false }) => {
    accountTimeZoneRef.value = accountData.time_zone
    accountCurrencyRef.value = accountData.currency_code
    accountCountryCodeRef.value = timeZoneToCountryCode[accountData.time_zone] || defaultCountryCode
    accountNameRef.value = accountData.name
    accountLocaleCodeRef.value = timeZoneToBCP47[accountData.time_zone] || defaultLocaleCode
    weekStartDayRef.value = accountData.week_start_day || 'sunday'
    accountCreationDateRef.value = new Date(accountData.created_at)
    const currencySign = currencyCodeToSign[accountCurrencyRef.value]
    if (currencySign) {
      accountCurrencySignRef.value = currencySign
    }

    if (saveToCache === true) {
      saveAccountDataToCache(accountData)
    }
  }
  // Methods

  const loadAccount = async () => {
    const accountData = getAccountDataFromCache()
    if (accountData !== null) {
      setAccountData({ accountData, saveToCache: false })
    }
    await AccountClientShow(apiKey).then(resp => {
      setAccountData({ accountData: resp.data.data, saveToCache: true })
      loadGeneralItem()
    })
      .catch(err => {
        throw new Error(err)
      })
  }

  const updateAccount = async (accountName) => {
    const response = await AccountClientUpdate(currentApiKey, { name: accountName })
    setAccountData({ accountData: response.data.data, saveToCache: true })
  }

  const changeAccount = async (payload) => {
    const { setApiKey, setUserRole } = useAuth()
    setApiKey(payload.api_key)
    if (isString(payload.role) && payload.role.trim().length > 0) {
      setUserRole(payload.role)
    }

    const { invalidateCache } = useItemSearchStore(payload.api_key)
    invalidateCache()
    currentApiKey = payload.api_key
    const accountData = Object.assign({ apiKey: payload.api_key }, payload)
    delete accountData.api_key
    setAccountData({ accountData, saveToCache: true })
    loadGeneralItem(true)
  }

  const loadAllAccounts = async () => {
    if (allAccountsRef.value.length > 0) return allAccountsRef.value
    const response = await AccountClientIndex(currentApiKey)
    allAccountsRef.value = []
    response.data.data.forEach(account => allAccountsRef.value.push(account))
    return response.data.data
  }

  // Computed

  const accountName = computed(() => accountNameRef.value)
  const accountCurrency = computed(() => accountCurrencyRef.value)
  const accountCurrencySign = computed(() => accountCurrencySignRef.value)
  const accountCreationDate = computed(() => accountCreationDateRef.value)
  const accountTimeZone = computed(() => accountTimeZoneRef.value)
  const accountCountryCode = computed(() => accountCountryCodeRef.value)
  const accountLocaleCode = computed(() => isString(accountLocaleCodeRef.value) && accountLocaleCodeRef.value.length > 1 ? accountLocaleCodeRef.value : defaultLocaleCode)
  const weekStartDay = computed(() => weekStartDayRef.value)
  const allAccounts = computed(() => allAccountsRef.value)

  return {
    accountName,
    accountCurrency,
    accountCurrencySign,
    accountCreationDate,
    accountTimeZone,
    accountCountryCode,
    accountLocaleCode,
    weekStartDay,
    allAccounts,
    loadAccount,
    updateAccount,
    changeAccount,
    loadAllAccounts
  }
}
