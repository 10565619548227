<template>
  <v-row
    v-if="!apiKeyLoaded" align="center" justify="center"
    class="mt-15"
  >
    <v-progress-circular indeterminate color="primary" />
  </v-row>
  <v-row
    v-else-if="$vuetify.breakpoint.xsOnly" align="center" justify="center"
    class="mt-10"
  >
    <store-layout />
  </v-row>
  <v-row
    v-else class="account-select" align="start"
    justify="center" no-gutters
  >
    <v-tabs
      class="mt-3 sticky elevation-2 mt-sm-15 a-s-tabs"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
      slider-color="black"
      value="stores"
      color="white"
    >
      <v-tab
        class="font-weight-black"
        href="#stores"
      >
        {{ $t('account_selection.stores') }} ({{ total }})
      </v-tab>
      <v-spacer />
      <span class="t-500-14 black--text d-flex align-center mme-8">
        <date-button-navigation
          :disabled="tableIsLoading"
          :value="clientDate"
          @change="applyDatePicker"
        />
      </span>
    </v-tabs>
    <v-tabs-items class="full-width" value="stores">
      <v-tab-item value="stores">
        <store-layout />
      </v-tab-item>
    </v-tabs-items>
  </v-row>
</template>
<script>
import StoreLayout from '@/components/company_stores/StoreLayout.vue'
import DateButtonNavigation from '@/components/shared/DateButtonNavigation.vue'

import useCompany from '@/composables/useCompany.js'
import useAuth from '@/composables/useAuth.js'

import {
  getIsLoading as tableIsLoading, getClientDateComp as clientDate,
  setClientDate, updateOperationsList, getPagination as pagination
} from '@/components/company_stores/AccountSelectionStore.js'

import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { isRtl } from '@/i18n.js'

import { computed } from 'vue'

export default {
  components: {
    'store-layout': StoreLayout,
    'date-button-navigation': DateButtonNavigation
  },
  setup () {
    const { apiKey } = useAuth()
    const { staffAccountApiKey } = useCompany(apiKey.value)

    // Methods
    const applyDatePicker = (newDate) => {
      setClientDate(newDate)
      updateOperationsList({ apiKey: apiKey.value })
        .catch(errHandler)
    }

    // Computed
    const apiKeyLoaded = computed(() => isString(staffAccountApiKey.value) === true &&
      staffAccountApiKey.value.length > 0)
    const total = computed(() => pagination.value.total)
    return {
      apiKeyLoaded,
      isRtl,
      tableIsLoading,
      clientDate,
      total,
      applyDatePicker
    }
  }
}
</script>
<style lang="scss">
.account-select {
  .a-s-tabs {
    .v-slide-group__prev {
      display: none !important;
    }
  }

  @media only screen and (max-width: 960px) {
    .sticky {
      top: 56px !important;
    }
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    z-index: 5;
  }
}
</style>
