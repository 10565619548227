<template>
  <v-row
    class="tasks-page" align="start" justify="center"
    no-gutters
    :class="{ 'rtl': isRtl && !$vuetify.breakpoint.xsOnly, 'ltr': !isRtl }"
  >
    <v-tabs
      v-model="selectedTab"
      class="mt-3 sticky elevation-2 mt-sm-15"
      slider-color="black"
      color="white"
      @change="tabChange"
    >
      <v-tab v-if="isCompanyAdminRegionOrAbove" href="#staffTask">
        {{ $vuetify.breakpoint.xsOnly ? $t('account_selection.my_tasks_tab_small') :
          $t('account_selection.my_tasks_tab') }}
      </v-tab>
      <v-tab v-if="isCompanyAdminRegionOrAbove" href="#hqTasks">
        {{ $vuetify.breakpoint.xsOnly ? $t('account_selection.hq_tasks_tab_small') :
          $t('account_selection.hq_tasks_tab') }}
      </v-tab>
      <v-tab href="#accountsTasks">
        {{ $vuetify.breakpoint.xsOnly ? $t('account_selection.accounts_tasks_tab_small') :
          $t('account_selection.accounts_tasks_tab') }}
      </v-tab>
      <v-spacer />
      <span class="t-500-14 black--text d-flex align-center mme-8">
        <date-button-navigation
          :value="clientDate"
          :offset="dateChangeOffset"
          @change="applyDatePicker"
        />
      </span>
    </v-tabs>
    <v-tabs-items
      v-model="selectedTab" touchless class="tabs-content"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-tab-item
        v-if="isCompanyAdminRegionOrAbove" value="staffTask" :transition="false"
        class="full-height"
      >
        <tasks-layout
          v-if="showStaffTasksContent"
          :api-key="staffAccountApiKey"
          :client-date="clientDate"
          :date-range-scope="dateRangeScope"
          staff-account
          @change-date="onChangeDate"
          @date-range-scope-changed="onDateRangeScopeChanged"
        />
      </v-tab-item>
      <v-tab-item
        v-if="isCompanyAdminRegionOrAbove" value="hqTasks" :transition="false"
        class="full-height"
      >
        <cross-account-tasks-layout
          v-if="hqTasksSelected"
          :client-date="clientDate"
        />
      </v-tab-item>
      <v-tab-item value="accountsTasks" :transition="false" class="full-height">
        <tasks-layout
          v-if="accountsTasksSelected"
          :api-key="apiKey"
          :client-date="clientDate"
          :date-range-scope="dateRangeScope"
          @change-date="onChangeDate"
          @date-range-scope-changed="onDateRangeScopeChanged"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-row>
</template>
<script>
import TasksLayout from '@/components/tasks/layout/TasksLayout.vue'
import CrossAccountTasksLayout from '@/components/corss_account_tasks/layout/CrossAccountTasksLayout.vue'
import DateButtonNavigation from '@/components/shared/DateButtonNavigation.vue'

import { dateToISO8601String, isValidISO8601Date, ISO8601StringToDate } from '@/helpers/DateTime.js'

import { loadTab, saveTab } from '@/helpers/TabPersist.js'

import useAuth from '@/composables/useAuth.js'
import useCompany from '@/composables/useCompany.js'

import { isString } from '@/helpers/Utils.js'
import { isRtl } from '@/i18n.js'

import { useRoute } from 'vue-router/composables'

import { ref, computed } from 'vue'

const getCurrentDate = (date = new Date()) => {
  const currentDate = dateToISO8601String(date)
  return new Date(`${currentDate}T00:00:00`)
}
const TABS = {
  staffTask: 'staffTask',
  hqTasks: 'hqTasks',
  accountsTasks: 'accountsTasks'
}

const getInitialDate = () => {
  const now = new Date()
  const hour = now.getHours()
  const isBetweenMidnightAndFive = hour >= 0 && hour < 5
  return isBetweenMidnightAndFive ? new Date(now.setDate(now.getDate() - 1)) : now
}
export default {
  components: {
    'tasks-layout': TasksLayout,
    'cross-account-tasks-layout': CrossAccountTasksLayout,
    'date-button-navigation': DateButtonNavigation
  },
  setup () {
    const dateRangeScope = ref('day')

    const { isCompanyAdminRegionOrAbove, apiKey } = useAuth()
    const route = useRoute()
    const routeTab = route.params.tab
    if (isString(routeTab) && Object.values(TABS).includes(routeTab)) {
      saveTab({ currentTab: routeTab })
    }
    const dateFromRoute = route.params.clientDate
    const initialDate = isValidISO8601Date(dateFromRoute) ? ISO8601StringToDate(dateFromRoute) : getInitialDate()
    const clientDate = ref(getCurrentDate(initialDate))
    const defaultTab = isCompanyAdminRegionOrAbove.value ? TABS.staffTask : TABS.accountsTasks
    const selectedTab = ref(loadTab({ defaultTab }))

    const { staffAccountApiKey } = useCompany(apiKey.value)

    // Methods
    const tabChange = () => {
      saveTab({ currentTab: selectedTab.value })
      dateRangeScope.value = 'day'
    }
    const onChangeDate = (newDate) => (clientDate.value = newDate)
    const applyDatePicker = (date) => (clientDate.value = date)
    const onDateRangeScopeChanged = (newDateRangeScope) => (dateRangeScope.value = newDateRangeScope)
    // Computed

    const accountsTasksSelected = computed(() => selectedTab.value === TABS.accountsTasks)
    const hqTasksSelected = computed(() => selectedTab.value === TABS.hqTasks)
    const staffApiKeyLoaded = computed(() => isString(staffAccountApiKey.value) === true &&
      staffAccountApiKey.value.length > 0)
    const showStaffTasksContent = computed(() => isCompanyAdminRegionOrAbove.value &&
      staffApiKeyLoaded.value &&
      selectedTab.value === TABS.staffTask)
    const dateChangeOffset = computed(() => {
      if (dateRangeScope.value === 'day') return 1
      if (dateRangeScope.value === 'week') return 7
      if (dateRangeScope.value === 'month') return 30
      return 1
    })

    return {
      isRtl,
      selectedTab,
      clientDate,
      isCompanyAdminRegionOrAbove,
      apiKey,
      staffApiKeyLoaded,
      staffAccountApiKey,
      showStaffTasksContent,
      accountsTasksSelected,
      hqTasksSelected,
      dateRangeScope,
      dateChangeOffset,
      onChangeDate,
      applyDatePicker,
      tabChange,
      onDateRangeScopeChanged
    }
  }
}
</script>
<style lang="scss">
.tasks-page {
  height: 100%;

  .tabs-content {
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 960px) {
    .sticky {
      top: 56px !important;
    }
  }

  .sticky {
    position: sticky;
    top: 60px;
    z-index: 5;
  }
}
</style>
