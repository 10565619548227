<template>
  <v-row align="start" justify="start" no-gutters>
    <v-col cols="9">
      <operation-frequency-select
        :exect-date="frequencyExectDate" :every-year-dates="frequencyEveryYear"
        :end-date="endDate" :value="frequencyModel" @change="onFrequencyChange"
        @input="updateFrequencyModel"
      />
    </v-col>
    <v-col cols="3" class="text-center pt-5">
      <v-dialog v-model="datesDialogOpen" persistent width="290px">
        <template #activator="{ on }">
          <v-btn
            icon color="black" :disabled="datesDialogDisabled"
            v-on="on"
          >
            <v-icon>event</v-icon>
          </v-btn>
        </template>
        <v-date-picker
          :value="frequencyDateValue" :multiple="isDatePickerMultiple" type="date"
          scrollable
          show-current :min="datesDialogMinDate" :range="isFrequencyTypeExact"
          @input="updateFrequencyDate"
        >
          <v-row no-gutters align="end">
            <v-col v-if="isFrequencyTypeExact" cols="12" class="caption red--text mb-3">
              {{ $t('schedule_new_op_dialog.select_end_date') }}
            </v-col>
            <v-col cols="12" class="d-flex justify-space-between">
              <v-btn text color="primary" @click="cancelDatePicker">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                depressed tile color="primary"
                @click="applyDatePicker"
              >
                {{ $t('ok') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import OperationFrequencySelect from '@/components/shared/OperationFrequencySelect.vue'

import { FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { isObject } from '@/helpers/Utils.js'
import { dateToISO8601String, ISO8601StringToDate, isValidISO8601Date } from '@/helpers/DateTime.js'

import { ref, computed } from 'vue'
export default {
  components: {
    'operation-frequency-select': OperationFrequencySelect
  },
  props: {
    frequencyModel: {
      type: Object,
      required: false,
      default: () => ({ frequency: null, frequencyType: null })
    },
    frequencyExectDate: {
      type: String,
      required: false,
      default: null
    },
    frequencyEveryYear: {
      type: Array,
      required: false,
      default: () => []
    },
    singleEveryYearDate: {
      type: Boolean,
      required: false,
      default: false
    },
    endDate: {
      type: String,
      required: false,
      default: null
    }
  },
  setup (props, { emit }) {
    const datesDialogOpen = ref(false)
    const datesDialogMinDate = dateToISO8601String(new Date())

    // Methods
    const openDatesDialog = () => (datesDialogOpen.value = true)
    const updateFrequencyDate = (newFrequencyDate) => {
      if (isFrequencyTypeEveryYear.value === true) return updateFrequencyEveryYear(newFrequencyDate)
      if (isFrequencyTypeExact.value !== true) return
      let startDate = null; let endDate = null

      if (newFrequencyDate.length === 1) {
        startDate = newFrequencyDate[0]
      }
      if (newFrequencyDate.length === 2 && newFrequencyDate.every(isValidISO8601Date)) {
        const dateArrays = newFrequencyDate.map(ISO8601StringToDate)
        if (dateArrays[0] <= dateArrays[1]) {
          startDate = newFrequencyDate[0]
          endDate = newFrequencyDate[1]
        } else {
          startDate = newFrequencyDate[1]
          endDate = newFrequencyDate[0]
        }
      }
      updateFrequencyExectDate(startDate)
      updateEndDate(endDate)
    }
    const updateFrequencyModel = (newFrequencyModel) => emit('update:frequencyModel', newFrequencyModel)
    const updateFrequencyExectDate = (newFrequencyExectDate) => emit('update:frequencyExectDate', newFrequencyExectDate)
    const updateEndDate = (newEndDate) => emit('update:endDate', newEndDate)
    const updateFrequencyEveryYear = (newFrequencyDate) => {
      const newFrequencyDateArr = Array.isArray(newFrequencyDate) ? newFrequencyDate : [newFrequencyDate]
      emit('update:frequencyEveryYear', newFrequencyDateArr)
    }
    const onFrequencyChange = (newFrequency) => {
      const frequencyType = newFrequency.frequencyType
      if (frequencyType === FrequencyTypes.ExectDate || frequencyType === FrequencyTypes.EveryYear) openDatesDialog()
    }
    const cancelDatePicker = () => {
      datesDialogOpen.value = false
      updateFrequencyExectDate(null)
      updateFrequencyEveryYear([])
    }
    const applyDatePicker = () => (datesDialogOpen.value = false)
    // Computed

    const isFrequencyTypeExact = computed(() => isObject(props.frequencyModel) && props.frequencyModel.frequencyType === FrequencyTypes.ExectDate)
    const isFrequencyTypeEveryYear = computed(() => isObject(props.frequencyModel) && props.frequencyModel.frequencyType === FrequencyTypes.EveryYear)
    const datesDialogDisabled = computed(() => {
      const isEnabled = isFrequencyTypeExact.value || isFrequencyTypeEveryYear.value
      return !isEnabled
    })
    const frequencyDateValue = computed(() => {
      if (isFrequencyTypeEveryYear.value === true) {
        return props.singleEveryYearDate === true ? props.frequencyEveryYear[0] : props.frequencyEveryYear
      }
      if (isFrequencyTypeExact.value === true) {
        if (isValidISO8601Date(props.frequencyExectDate) && isValidISO8601Date(props.endDate)) return [props.frequencyExectDate, props.endDate]
        if (isValidISO8601Date(props.frequencyExectDate)) return [props.frequencyExectDate]
        return []
      }
      return null
    })
    const isDatePickerMultiple = computed(() => isFrequencyTypeEveryYear.value && !props.singleEveryYearDate)

    return {
      datesDialogOpen,
      datesDialogMinDate,
      datesDialogDisabled,
      isFrequencyTypeEveryYear,
      frequencyDateValue,
      isDatePickerMultiple,
      isFrequencyTypeExact,
      updateFrequencyModel,
      updateFrequencyDate,
      onFrequencyChange,
      cancelDatePicker,
      applyDatePicker
    }
  }
}
</script>
