<template>
  <v-row v-if="isCompanyAdminRegionOrAbove" align="start" justify="start">
    <v-col cols="12">
      <accounts-list-layout
        :api-key="apiKey"
      />
    </v-col>
    <v-col cols="12" class="mt-4">
      <items-list-layout :api-key="apiKey" />
    </v-col>
  </v-row>
  <v-row v-else align="start" justify="start">
    <items-list-layout :api-key="apiKey" />
  </v-row>
</template>

<script>
import AccountListLayout from '@/components/items/account_list/AccountListLayout.vue'
import ItemsListLayout from '@/components/items/item_list/ItemsListLayout.vue'

import useAuth from '@/composables/useAuth.js'

export default {
  components: {
    'accounts-list-layout': AccountListLayout,
    'items-list-layout': ItemsListLayout
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const { isCompanyAdminRegionOrAbove } = useAuth()

    return {
      isCompanyAdminRegionOrAbove
    }
  }
}
</script>
