<template>
  <v-row align="start" justify="start" class="full-height">
    <v-col lg="3" md="4" class="d-flex align-self-stretch">
      <div class="d-flex flex-column full-width">
        <v-col class="flex-grow-1 flex-shrink-0 mb-8 overflow-y-auto">
          <template v-if="isLoading">
            <v-skeleton-loader
              v-for="i in 3" :key="i" class="mt-3"
              type="card"
            />
          </template>
          <template v-else>
            <single-cross-account-task
              v-for="task in crossAccountOperationsTotals"
              :key="task.cross_account_operation_id"
              class="mb-4"
              :task="task"
              :client-date="clientDateISO"
              :selected="task.cross_account_operation_id === activeCrossAccountTaskId"
              @edit-click="onEditCrossAccountTaskClick"
              @delete-click="onDeleteCrossAccountTaskClick"
              @copy-click="onCopyCrossAccountTaskClick"
              @task-click="onTaskClick"
            />
          </template>
        </v-col>
      </div>
    </v-col>
    <v-col lg="9" md="8" class="d-flex align-self-stretch">
      <div class="d-flex flex-column full-width mb-8">
        <v-col class="flex-grow-0 flex-shrink-1 pt-0 pt-1 px-0">
          <cross-account-filter :api-key="apiKey" />
        </v-col>
        <empty-task-page v-if="isNoTasks" />
        <v-col v-else class="flex-grow-0 flex-shrink-1 pt-0 pt-1 px-0">
          <v-row align="start" justify="start" no-gutters>
            <v-col cols="12" md="4">
              <div class="row justify-space-between px-1 pb-4 t-500-16">
                <span>{{ $t('tasks_page.progress') }}</span>
                <span class="ltr">{{ stats.finishedTasksCount }} / {{ stats.tasksCount }}</span>
              </div>
              <tasks-progress
                hide-numbers with-border :is-loading="isLoading"
                :tasks-count="stats.tasksCount"
                :finished-tasks-count="stats.finishedTasksCount"
              />
            </v-col>
          </v-row>
        </v-col>
        <tasks-list :api-key="apiKey" />
      </div>
    </v-col>
  </v-row>
</template>
<script>
import SingleCrossAccountTask from '@/components/corss_account_tasks/cross_account_task_list/SingleCrossAccountTask.vue'
import TasksList from '@/components/corss_account_tasks/TasksList.vue'
import CrossAccountFilter from '@/components/corss_account_tasks/CrossAccountFilter.vue'
import EmptyTaskPage from '@/components/shared/EmptyTaskPage.vue'
import TasksProgress from '@/components/tasks/TasksProgress.vue'

import useCrossAccountDailyTask from '@/composables/useCrossAccountDailyTask.js'

import { dateToISO8601String } from '@/helpers/DateTime.js'

import { computed } from 'vue'
export default {
  components: {
    'single-cross-account-task': SingleCrossAccountTask,
    'tasks-list': TasksList,
    'cross-account-filter': CrossAccountFilter,
    'empty-task-page': EmptyTaskPage,
    'tasks-progress': TasksProgress
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const { isLoading, stats, clientDate, crossAccountOperationsTotals, activeCrossAccountTaskId } = useCrossAccountDailyTask(props.apiKey)

    // Methods
    const onEditCrossAccountTaskClick = (task) => emit('edit-cross-account-task-click', task)
    const onDeleteCrossAccountTaskClick = (task) => emit('delete-cross-account-task-click', task)
    const onCopyCrossAccountTaskClick = (task) => emit('copy-cross-account-task-click', task)
    const onTaskClick = (task) => emit('task-click', task)

    // Computed
    const clientDateISO = computed(() => dateToISO8601String(clientDate.value))
    const isNoTasks = computed(() => crossAccountOperationsTotals.value.length === 0)
    return {
      isLoading,
      clientDateISO,
      crossAccountOperationsTotals,
      activeCrossAccountTaskId,
      isNoTasks,
      stats,
      onEditCrossAccountTaskClick,
      onDeleteCrossAccountTaskClick,
      onCopyCrossAccountTaskClick,
      onTaskClick
    }
  }
}
</script>
