<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <v-card>
      <v-card-title class="text-center">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="full-width text-subtitle-1" v-html="title" />
      </v-card-title>
      <v-card-text>
        <v-spacer />
        <v-row align="center" justify="center">
          <v-col class="flex-grow-0 flex-shrink-1">
            <v-btn
              v-if="hidePassShift !== true" tile outlined
              color="black" block class="mb-3"
              @click="passShift"
            >
              {{ passShiftText }}
            </v-btn>
            <v-btn
              tile outlined color="black"
              block @click="dismiss"
            >
              {{ $t('out_of_shift_action.dismiss') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { isObject } from '@/helpers/Utils.js'
import { translateDayFromDate } from '@/helpers/DateTime.js'

function closeDialog (result) {
  this.dialog = false
  return this.resolve(result)
}

export default {
  data () {
    return {
      dialog: false,
      startTime: '',
      endTime: '',
      isPreviousDay: null,
      defaultRoute: null,
      hidePassShift: null
    }
  },
  computed: {
    title () {
      if (this.isPreviousDay === true) {
        return this.$i18n.t('out_of_shift_action.title_before_start', { startTime: this.startTime, startDay: translateDayFromDate(this.startTimeDate) })
      }
      return this.$i18n.t('out_of_shift_action.title')
    },
    passShiftText () {
      if (this.defaultRoute === true) {
        return this.$i18n.t('out_of_shift_action.return_work_panel')
      }
      return this.$i18n.t('out_of_shift_action.pass_shift')
    }
  },
  methods: {
    openDialog ({ nextRoute, startTime, endTime, startTimeDate }) {
      this.dialog = true
      this.nextRoute = nextRoute
      this.isPreviousDay = this.nextRoute.isPreviousDay
      this.defaultRoute = this.nextRoute.defaultRoute
      this.hidePassShift = this.nextRoute.hidePassShift
      this.startTime = startTime
      this.endTime = endTime
      this.startTimeDate = startTimeDate
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    dismiss () {
      return closeDialog.call(this)
    },
    passShift () {
      if (isObject(this.nextRoute.route)) {
        this.$router.push(this.nextRoute.route)
        location.reload()
        return
      }
      return closeDialog.call(this, { passShift: true })
    }
  }
}
</script>
