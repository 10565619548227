<template>
  <v-progress-circular v-if="reloadingAudio" indeterminate color="black" />
  <audio
    v-else ref="audioPlayerRef" controls
    :src="audioFileUrl" class="full-width" controlsList="nodownload"
  />
</template>
<script>
import { ref, onMounted } from 'vue'

const TIME_TO_RELOAD_AUDIO = 4 * 1000
export default {
  props: {
    audioFileUrl: {
      type: String,
      required: true
    }
  },
  setup () {
    const audioPlayerRef = ref(null)
    const reloadingAudio = ref(false)
    onMounted(() => {
      if (audioPlayerRef.value === null) return

      audioPlayerRef.value.addEventListener('error', (e) => {
        reloadingAudio.value = true
        setTimeout(() => (reloadingAudio.value = false), TIME_TO_RELOAD_AUDIO)
      }, true)
    })
    return {
      reloadingAudio
    }
  }
}
</script>
