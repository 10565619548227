<template>
  <v-row justify="start" align="start">
    <v-col cols="12" class="texts-end">
      <v-badge
        bordered
        :value="anyFilterSelected ? 1 : 0"
        color="green"
        icon="filter_list"
        overlap
      >
        <v-btn
          small fab
          color="black"
          class="inside-drawer"
          @click="openSideDrawer"
        >
          <v-icon color="white" class="inside-drawer">
            tune
          </v-icon>
        </v-btn>
      </v-badge>
    </v-col>
    <v-navigation-drawer
      v-model="drawer"
      mobile-breakpoint="0"
      color="#F4F6F8"
      width="300"
      :left="isRtl"
      class="inside-drawer"
      :right="!isRtl"
      fixed
    >
      <v-row
        v-click-outside="onClickOutside" justify="start" align="start"
        class="mt-5"
      >
        <v-col cols="12">
          <account-select
            v-model="filters.accountApiKeys"
            :api-key="apiKey"
            multiple
            :menu-props="{ contentClass: 'inside-drawer' }"
          />
        </v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="filters.myOperations"
            :label="$t('cross_account_page.my_operations')"
          />
        </v-col>
        <v-col cols="12" class="d-flex justify-space-between align-self-end">
          <v-col cols="6">
            <v-btn
              block outlined color="black"
              :disabled="isLoading" :loading="isLoading"
              @click="resetFilter"
            >
              {{ $t('account_operations.cancel_filter') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              block color="black white--text" :disabled="isLoading"
              :loading="isLoading" @click="applyFilters"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </v-row>
</template>
<script>

import AccountSelect from '@/components/shared/AccountSelect.vue'

import useCrossAccountDailyTask from '@/composables/useCrossAccountDailyTask.js'

import { isRtl } from '@/i18n.js'

import { isObject, isString, hasAncestorWithClass } from '@/helpers/Utils.js'

import { ref, computed, onBeforeUnmount } from 'vue'

const SESSION_FILTER_KEY = 'cross-account-filters-session'

const loadFilterFromLocalCache = () => {
  const filter = localStorage.getItem(SESSION_FILTER_KEY)
  if (isString(filter) !== true) return
  try {
    return JSON.parse(filter)
  } catch (e) {
  }
}

export default {
  components: {
    'account-select': AccountSelect
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const drawer = ref(false)
    const { isLoading, filters, applyFiltersForTotalOperations, initPageData, resetFiltersForTotalOperations, clientDate } = useCrossAccountDailyTask(props.apiKey)

    const closeDrawer = () => (drawer.value = false)
    const saveFilterToLocalCache = () => {
      const filter = JSON.stringify(filters.value)
      localStorage.setItem(SESSION_FILTER_KEY, filter)
    }

    const setFilterFromSession = () => {
      const filtersFromLocalCache = loadFilterFromLocalCache()
      if (isObject(filtersFromLocalCache) !== true) return
      filters.value = filtersFromLocalCache
    }

    // Methods
    const openSideDrawer = () => (drawer.value = true)
    const applyFilters = () => {
      closeDrawer()
      saveFilterToLocalCache()
      applyFiltersForTotalOperations()
    }
    const resetFilter = () => {
      closeDrawer()
      resetFiltersForTotalOperations()
      saveFilterToLocalCache()
    }

    const onClickOutside = (e) => {
      const hasClass = hasAncestorWithClass(e.target, 'inside-drawer')
      if (hasClass) return

      closeDrawer()
    }

    // Computed
    const anyFilterSelected = computed(() => filters.value.accountApiKeys.length > 0 ||
      filters.value.myOperations)

    onBeforeUnmount(saveFilterToLocalCache)
    setFilterFromSession()
    initPageData(clientDate.value)
    return {
      drawer,
      isRtl,
      filters,
      isLoading,
      anyFilterSelected,
      applyFilters,
      resetFilter,
      onClickOutside,
      openSideDrawer
    }
  }
}
</script>
