<template>
  <v-row
    class="app-footer" align="center" justify="space-around"
    no-gutters :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <v-btn
      v-if="showAccountsButton" :to="{ name: accountsRoute }" text
      :class="{ 'item-selected': currentRouteName === accountsRoute }"
      class="item-menu"
    >
      <v-icon>group</v-icon>
      <span class="item-name">{{ $t('bottom_navigation.accounts') }}</span>
    </v-btn>
    <v-btn
      :to="{ name: 'tasks' }" text class="item-menu"
      :class="{ 'item-selected': currentRouteName === 'tasks' }"
    >
      <v-icon>assignment_turned_in</v-icon>
      <span class="item-name"> {{ $t('bottom_navigation.tasks') }}</span>
    </v-btn>
    <account-faulty-device-count
      class="v-btn text-center"
      :count-mode="companyContext ? 'company' : 'account'"
    >
      <template #default="{ faultyDeviceCount }">
        <v-badge
          color="red"
          :offset-x="isRtl ? 24 : 116"
          offset-y="12"
          :value="faultyDeviceCount && faultyDeviceCount > 0"
          :content="faultyDeviceCount"
          overlap
        >
          <v-btn
            :to="{ name: brokenItemRoute }" text class="item-menu"
            :class="{ 'item-selected': currentRouteName === brokenItemRoute }"
          >
            <v-icon>assignment_late</v-icon>
            <span class="item-name">{{ $t('bottom_navigation.faulty_device') }}</span>
          </v-btn>
        </v-badge>
      </template>
    </account-faulty-device-count>
    <app-chat-icon-badge
      class="v-btn text-center" :api-key="apiKey" :bordered="false"
      :offset-x="isRtl ? 22 : 102"
      :offset-y="12"
    >
      <v-btn
        :to="{ name: chatRoute }" text class="item-menu"
        :class="{ 'item-selected': currentRouteName === chatRoute }"
      >
        <v-icon>chat_bubble</v-icon>
        <span class="item-name">{{ $t('bottom_navigation.chat') }}</span>
      </v-btn>
    </app-chat-icon-badge>
    <v-badge
      class="v-btn text-center" color="red" :offset-x="isRtl ? 26 : 172"
      offset-y="12"
      :content="unreadPostsCount"
      :value="getHaveUnreadPosts ? 1 : 0" overlap
    >
      <v-btn
        :to="{ name: blogRoute }" text class="item-menu"
        :class="{ 'item-selected': currentRouteName === blogRoute }"
      >
        <v-icon>import_contacts</v-icon>
        <span class="item-name">{{ $t('community') }}</span>
      </v-btn>
    </v-badge>
    <v-badge
      class="v-btn text-center" color="red" dot
      :offset-x="isRtl ? 22 : 100" offset-y="12"
      :value="showMenuBadge ? 1 : 0" overlap
    >
      <v-btn
        :to="{ name: menuRoute }" text class="item-menu"
        :class="{ 'item-selected': currentRouteName === menuRoute }"
      >
        <v-icon>menu</v-icon>
        <span class="item-name">{{ $t('menu') }}</span>
      </v-btn>
    </v-badge>
  </v-row>
</template>

<script>

import useBottomNavigation from '@/composables/useBottomNavigation.js'
import useAuth from '@/composables/useAuth.js'

import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import { isRtl } from '@/i18n.js'

export default {
  components: {
    'account-faulty-device-count': () => import('@/components/shared/AccountFaultyDeviceCount.vue'),
    'app-chat-icon-badge': () => import('@/components/shared/AppChatIconBadge.vue')
  },
  setup () {
    const route = useRoute()
    const { apiKey } = useAuth()
    const {
      getHaveUnreadPosts, unreadPostsCount,
      brokenItemRoute, chatRoute, blogRoute, accountsRoute,
      menuRoute, showAccountsButton, companyContext, showMenuBadge
    } =
      useBottomNavigation()

    const currentRouteName = computed(() => route.name)
    return {
      companyContext,
      brokenItemRoute,
      chatRoute,
      blogRoute,
      accountsRoute,
      menuRoute,
      showAccountsButton,
      getHaveUnreadPosts,
      unreadPostsCount,
      showMenuBadge,
      apiKey,
      isRtl,
      currentRouteName
    }
  }
}
</script>

<style lang="scss">
.v-main {
  padding-bottom: 65px !important;
}

.app-footer {
  height: 65px;
  background-color: #212121;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 6;

  .item-menu {
    background-color: transparent !important;

    .item-name {
      font-size: 18px !important;
      font-weight: 700 !important;

      @media only screen and (max-width: 960px) {
        font-size: 14px !important;
      }
    }

    .v-icon {
      font-size: 24px !important;
    }

    .item-name,
    .v-icon {
      color: #9E9E9E !important;

    }
  }

  .item-selected {

    .item-name,
    .v-icon {
      color: white !important;
    }

  }

  &.rtl {
    .item-name {
      margin-right: 8px;
    }
  }

  &.ltr {
    .item-name {
      margin-left: 8px;
    }
  }

  @media only screen and (max-width: 720px) {
    &.rtl {
      .item-name {
        margin-right: 0px;
      }
    }

    &.ltr {
      .item-name {
        margin-left: 0px;
      }
    }
  }
}
</style>
