<template>
  <v-row v-if="isLoadingFirstPage" align="center" justify="center">
    <v-progress-circular indeterminate color="primary" />
  </v-row>
  <div v-else class="d-flex flex-column chat-groups-list">
    <v-col class="flex-grow-0 flex-shrink-1 group-title">
      <group-title
        :api-key="apiKey"
        :show-back-arrow="showBackArrow"
        @click-back="onBackClick"
      />
    </v-col>
    <v-col class="flex-grow-1 flex-shrink-0 group-messages pa-0">
      <group-messag-list :api-key="apiKey" />
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1 align-self-end chat-input">
      <message-input-layout
        :api-key="apiKey"
      />
    </v-col>
  </div>
</template>

<script>

import GroupTitle from '@/components/app_chat/group_conversation/GroupTitle.vue'
import GroupMessagList from '@/components/app_chat/group_conversation/GroupMessagList.vue'
import MessageInputLayout from '@/components/app_chat/message_imput/MessageInputLayout.vue'

import useAppChat from '@/composables/useAppChat.js'

import { computed } from 'vue'

export default {
  components: {
    'group-title': GroupTitle,
    'message-input-layout': MessageInputLayout,
    'group-messag-list': GroupMessagList
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    showBackArrow: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const { activeConversation } = useAppChat(props.apiKey)

    // Methods
    const onBackClick = () => emit('click-back')

    const isLoadingFirstPage = computed(() => activeConversation.value.isLoading && activeConversation.value.messages.length === 0)
    return {
      isLoadingFirstPage,
      onBackClick
    }
  }
}
</script>

<style lang="scss">
.chat-groups-list {
  width: 100%;

  .group-messages {
    overflow-y: auto;
    background-image: url(@/assets/chat_list_bg.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .group-title,
  .chat-input {
    background-color: #F4F6F8;
  }
}
</style>
