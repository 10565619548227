<template>
  <v-row
    align="start" justify="start" no-gutters
    class="single-department"
    @click="onDepartmentClick"
  >
    <v-col cols="12">
      <v-img
        :src="department.image_url"
        class="dep-image"
        height="100px"
      />
    </v-col>
    <v-col cols="12" class="d-flex align-center justify-space-between px-4 pt-2">
      <span class="t-600-18">
        {{ department.department_name }}
      </span>
    </v-col>
    <v-col cols="12" class="d-flex align-start justify-start px-4 pt-2">
      <span class="t-500-16 color9 mme-1">
        {{ department.total_reported }}
      </span>
      <span class="t-400-16 mme-4">
        {{ $t('completed') }}
      </span>
      <span class="t-500-16 color12 mme-1">
        {{ department.total_tasks - department.total_reported }}
      </span>
      <span class="t-400-16">
        {{ $t('on_going') }}
      </span>
    </v-col>
    <v-col cols="12" class="px-4 texts-end t-400-12 color3">
      {{ taskPercentDone }}%
    </v-col>
    <v-col cols="12" class="mb-4 mt-2 px-4">
      <v-progress-linear
        :value="taskPercentDone"
        height="9"
        rounded
        background-color="#DADADA"
        color="#011D35"
      />
    </v-col>
  </v-row>
</template>
<script>

import { computed } from 'vue'
export default {
  props: {
    department: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const onDepartmentClick = () => emit('department-click', props.department)

    // Computed
    const taskPercentDone = computed(() => {
      const total = props.department.total_tasks
      const completed = props.department.total_reported
      if (total === 0 || !Number.isInteger(total) || !Number.isInteger(completed)) return 0

      return Math.ceil((completed / total) * 100)
    })
    return {
      onDepartmentClick,
      taskPercentDone
    }
  }
}
</script>
<style lang="scss">
.single-department {
  overflow: hidden;
  margin-right: -3px !important;
  border-radius: 16px;
  border: 1px solid #111;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);

  .dep-image {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.rtl {
  .single-department {
    margin-right: 0 !important;
    margin-left: -3px !important;
  }
}
</style>
