<template>
  <tr>
    <v-row
      align="center" justify="start" class="font-weight-bold table-row-border-top pa-4"
      no-gutters
    >
      <v-col v-if="$vuetify.breakpoint.smAndUp" cols="4" />
      <v-col cols="12" sm="4" class="text-center pb-0 pt-2">
        {{ department.name }}
      </v-col>
      <v-col
        cols="12" sm="4" class="my-2 my-sm-0"
        :class="$vuetify.breakpoint.smAndUp ? 'texts-end' : 'text-center'"
      >
        <v-btn icon class="mx-1" @click.stop="deleteDepartment">
          <v-icon color="black" medium>
            delete
          </v-icon>
        </v-btn>
        <v-btn icon class="mx-1" @click="editDepartment">
          <v-icon color="black" medium>
            edit
          </v-icon>
        </v-btn>
        <v-btn text icon class="black--text drag-handle">
          <v-icon>drag_indicator</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </tr>
</template>
<script>
export default {
  props: {
    department: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    // Methods
    const editDepartment = () => emit('update-department', props.department)
    const deleteDepartment = () => emit('delete-department', props.department)

    return {
      editDepartment,
      deleteDepartment
    }
  }
}
</script>
