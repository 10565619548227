<template>
  <v-row v-if="isListLoading" align="center" justify="center">
    <v-col cols="12">
      <v-skeleton-loader type="card" />
    </v-col>
  </v-row>
  <v-row v-else align="start" justify="start">
    <confirm-delete ref="deleteDialog" />
    <chat-dialog
      ref="chatDialog"
      record-type="BrokenItem"
      @mark-read-all="markReadAllChatMessages"
      @update-message-count="updateMessageCount"
    />
    <new-broken-item-dialog
      ref="newBrokenItemDialog"
      :show-close-option="closeEnabled"
    />
    <v-col
      v-for="brokenItem in brokenItemsList" :key="brokenItem.id" cols="12"
      class="px-0"
    >
      <item-dialog-single-broken-item
        :broken-item="brokenItem"
        :close-enabled="closeEnabled"
        :delete-enabled="deleteEnabled"
        :edit-enabled="editEnabled"
        @open-chat="openChatDialog"
        @delete-click="onDeleteClick"
        @edit-click="onEditClick"
        @change-status="onChangeStatus"
      />
    </v-col>
    <v-col v-if="showLoadMorePages" v-intersect.once="onIntersect" />
    <v-col v-if="isListLoadingNextPage">
      <v-skeleton-loader type="card" />
    </v-col>
  </v-row>
</template>
<script>
import ItemDialogSingleBrokenItem from '@/components/items/item_dialog/ItemDialogSingleBrokenItem.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import NewBrokenItemDialog from '@/components/broken_item_dialog/NewBrokenItemDialog.vue'

import useBrokenItemDialog from '@/composables/useBrokenItemDialog.js'
import { statuses } from '@/store/modules/BrokenItem.js'

import { i18n } from '@/i18n.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { isObject } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'
export default {
  components: {
    'item-dialog-single-broken-item': ItemDialogSingleBrokenItem,
    'confirm-delete': ConfirmDelete,
    'new-broken-item-dialog': NewBrokenItemDialog
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    deleteEnabled: {
      type: Boolean,
      default: false
    },
    editEnabled: {
      type: Boolean,
      default: false
    },
    closeEnabled: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props) {
    const chatDialog = ref(null)
    const deleteDialog = ref(null)
    const newBrokenItemDialog = ref(null)

    const {
      isListLoading, isListLoadingNextPage, brokenItemsList, pagination, initPageData,
      deleteBrokenItem, changeBrokenItemAfterUpdate, setBrokenItemStatusToClose, loadNextPage
    } = useBrokenItemDialog({ apiKey: props.apiKey, itemId: props.item.id })

    const setCloseDateForToday = async (brokenItem) => {
      const title = i18n.t('devices_faulty_device_dialog.close_ticket')
      const content = i18n.t('devices_faulty_device_dialog.close_ticket_approve')
      const deleteBtnText = i18n.t('devices_faulty_device_dialog.send')
      const shouldDelete = await deleteDialog.value.open({ title, content, deleteBtnText })
      if (!shouldDelete) return

      setBrokenItemStatusToClose(brokenItem)
        .finally(() => deleteDialog.value.close())
    }

    // Methods
    const onIntersect = loadNextPage
    const onDeleteClick = async (brokenItem) => {
      const shouldDelete = await deleteDialog.value.open()
      if (!shouldDelete) return
      deleteBrokenItem(brokenItem)
        .finally(() => deleteDialog.value.close())
    }

    const onEditClick = (brokenItem) => {
      newBrokenItemDialog.value.openForUpdate(brokenItem)
        .then(({ updatedOrCreated, response }) => {
          if (updatedOrCreated) {
            changeBrokenItemAfterUpdate(brokenItem.id, response)
          }
        })
        .catch(errHandler)
    }

    const onChangeStatus = ({ brokenItem, status }) => {
      if (status === statuses.close) {
        return setCloseDateForToday(brokenItem)
      }
      brokenItem.updateStatus({ newStatus: status, apiKey: brokenItem.account_api_key })
    }

    const openChatDialog = (brokenItem) =>
      chatDialog.value.openDialog({ recordId: brokenItem.id, apiKey: brokenItem.account_api_key })

    const updateMessageCount = ({ recordId, messageCount }) => {
      const brokenItem = brokenItemsList.value.find((brokenItem) => brokenItem.id === recordId)
      if (isObject(brokenItem)) brokenItem.updateMessageCount(messageCount)
    }

    const markReadAllChatMessages = (recordId) => {
      const brokenItem = brokenItemsList.value.find((brokenItem) => brokenItem.id === recordId)
      if (isObject(brokenItem)) brokenItem.markAllMessagesAsRead()
    }

    // Computed
    const showLoadMorePages = computed(() => {
      if (isListLoading.value === true || isListLoadingNextPage.value) return false

      const { page, totalPages } = pagination.value
      return page < totalPages
    })

    initPageData(props.filters)
    return {
      isListLoading,
      chatDialog,
      deleteDialog,
      newBrokenItemDialog,
      brokenItemsList,
      showLoadMorePages,
      isListLoadingNextPage,
      openChatDialog,
      onIntersect,
      markReadAllChatMessages,
      updateMessageCount,
      onDeleteClick,
      onEditClick,
      onChangeStatus
    }
  }
}
</script>
