<template>
  <v-container
    fluid class="px-0 language-selection"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <v-row
      align="start" justify="start" justify-sm="center"
      no-gutters
    >
      <v-col
        cols="12" sm="6" md="4"
        class="row align-start justify-start"
      >
        <v-col cols="12" class="page-title py-4">
          {{ $t('languages.language') }}
        </v-col>
        <v-row align="start" justify="start" class="px-4 mt-15 aa">
          <v-col
            cols="12" class="first language d-flex align-center justify-space-between"
            :class="{ 'selected': isSelected('en') }" @click="switchLanguage('en')"
          >
            <span>
              {{ $t('languages.en') }}
            </span>
            <v-icon v-if="isSelected('en')">
              check
            </v-icon>
          </v-col>
          <hr class="solid">
          <v-col
            cols="12" class="language d-flex align-center justify-space-between" :class="{ 'selected': isSelected('he') }"
            @click="switchLanguage('he')"
          >
            <span>
              {{ $t('languages.he') }}
            </span>
            <v-icon v-if="isSelected('he')">
              check
            </v-icon>
          </v-col>
          <hr class="solid">
          <v-col
            v-if="showSpanish" cols="12"
            class="last language d-flex align-center justify-space-between"
            :class="{ 'selected': isSelected('es') }"
            @click="switchLanguage('es')"
          >
            <span>
              {{ $t('languages.es') }}
            </span>
            <v-icon v-if="isSelected('es')">
              check
            </v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import { currentLanguage, setLanguage } from '@/composables/useLanguage.js'
import { isRtl } from '@/i18n'

export default {
  setup () {
    // Methods

    const switchLanguage = (language) => {
      if (language === currentLanguage.value) return
      setLanguage({ language, reload: true })
    }
    const isSelected = (language) => currentLanguage.value === language
    const showSpanish = import.meta.env.MODE !== 'production'

    return {
      showSpanish,
      isRtl,
      switchLanguage,
      isSelected
    }
  }
}
</script>
<style lang="scss">
.language-selection {
  background-color: #F4F6F8;
  height: 100%;
  padding-top: 112px;
  margin: 0;

  .first {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .last {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .language {
    box-sizing: border-box;
    background: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    height: 72px;
    cursor: pointer;
  }

  .selected {
    font-weight: 700;
  }

  .solid {
    width: 100%;
    border-top: 1px solid #E8E8E8;
  }
}
</style>
