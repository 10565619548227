
import {
  index as UserNotificationIndex,
  batchEdit as UserNotificationClientBatchEdit
} from '@/api_client/UserNotification.js'

import useAuth from '@/composables/useAuth.js'
import useCompany from '@/composables/useCompany.js'

import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { computed, ref } from 'vue'

const isLoading = ref(false)
const userInfo = ref({})

let currentApiKey = null
const OPERATION_SCHEDULE_MODEL = 'OperationSchedule'

export default function useUserNotification (apiKey) {
  const { isCompanyAdminRegionOrAbove, isAccountAdminOrAbove } = useAuth()

  const resetValues = () => {
    userInfo.value = {}
    isLoading.value = false
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const loadNotificationsEmptyResponse = (notifiedModels) => {
    const notifiedModelsArray = Array.isArray(notifiedModels) ? notifiedModels : [notifiedModels]
    const response = {}
    notifiedModelsArray.forEach((model) => (response[model] = []))
    return response
  }

  const isStaffAccountNotificaitions = (notification) => {
    return notification.notified_model_name === OPERATION_SCHEDULE_MODEL &&
      notification.field_name === 'staff_operation' &&
      (notification.field_value === 'day_with_operations' || notification.field_value === 'create')
  }

  const getApiKeysFromNotifications = (reponseByModel) => {
    if (isCompanyAdminRegionOrAbove.value === true) {
      const apiKeysSet = new Set()
      for (const model in reponseByModel) {
        reponseByModel[model]
          .filter((notification) => isStaffAccountNotificaitions(notification) !== true && isString(notification.api_key))
          .forEach((notification) => apiKeysSet.add(notification.api_key))
      }
      return [...apiKeysSet]
    }
  }
  // Methods
  const loadNotifications = (notifiedModels) => {
    return UserNotificationIndex(currentApiKey, notifiedModels)
      .then((response) => {
        const reponseByModel = loadNotificationsEmptyResponse(notifiedModels)
        response.data.data.forEach((notification) => {
          const notifiedModel = notification.notified_model_name
          reponseByModel[notifiedModel].push(notification)
        })
        const apiKeys = getApiKeysFromNotifications(reponseByModel)
        return { reponseByModel, apiKeys }
      })
      .catch(errHandler)
  }

  const loadCurrentUser = () => {
    isLoading.value = true
    const { loadCurrentUser } = useAuth()
    return loadCurrentUser()
      .then((response) => (userInfo.value = response))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const saveNotifications = ({ params, apiKeys }) => {
    const requestParams = Object.assign({}, { account_api_keys: apiKeys }, params)

    return UserNotificationClientBatchEdit(currentApiKey, requestParams)
      .catch(errHandler)
  }

  const saveStaffNotification = (requestParams) => {
    const { staffAccountApiKey } = useCompany(currentApiKey)
    if (isString(staffAccountApiKey.value) !== true) return

    requestParams.account_api_keys = staffAccountApiKey.value

    return UserNotificationClientBatchEdit(currentApiKey, requestParams)
      .catch(errHandler)
  }

  // Computed
  const userDisplayName = computed(() => `${userInfo.value.first_name} ${userInfo.value.last_name}`)

  const mediumSmsDisabled = computed(() => {
    const userPhone = userInfo.value.phone
    const isvalidPhone = isString(userPhone) && userPhone.length > 0
    return !isvalidPhone
  })

  const mediumEmaildisabled = computed(() => {
    const userEmail = userInfo.value.email
    const isValidEmail = isString(userEmail) && userEmail.length > 0
    return !isValidEmail
  })

  return {
    isLoading,
    isAccountAdminOrAbove,
    userDisplayName,
    mediumSmsDisabled,
    mediumEmaildisabled,
    loadNotifications,
    loadCurrentUser,
    saveNotifications,
    saveStaffNotification

  }
}
