<template>
  <v-menu v-if="showMoreSelect" close-on-content-click>
    <template #activator="{ on }">
      <v-icon color="black" v-on="on">
        more_vert
      </v-icon>
    </template>
    <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-list-item v-if="showEditItem" ripple @click="onEdit">
        <v-list-item-content class="px-1">
          {{ $t('edit') }}
        </v-list-item-content>
        <v-btn icon small>
          <v-icon small>
            edit
          </v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item v-if="showDeleteItem" ripple @click="onDelete">
        <v-list-item-content class="px-1">
          {{ $t('delete') }}
        </v-list-item-content>
        <v-btn icon small>
          <v-icon small>
            delete
          </v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item v-if="showCopyItem" ripple @click="onCopy">
        <v-list-item-content class="px-1">
          {{ $t('copy') }}
        </v-list-item-content>
        <v-btn icon small>
          <v-icon small>
            content_copy
          </v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { isRtl } from '@/i18n'
import { FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { ISO8601StringToDate, isDateInThePast } from '@/helpers/DateTime.js'

import { computed } from 'vue'
export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    // Methods
    const onEdit = () => emit('edit-click')
    const onDelete = () => emit('delete-click')
    const onCopy = () => emit('copy-click')

    // Computed
    const showEditItem = computed(() => !props.task.staff_operation)
    const showDeleteItem = computed(() => !props.task.staff_operation)
    const showCopyItem = computed(() => {
      const task = props.task
      if (task.item_type !== 'Item' || task.staff_operation) return false
      if (task.frequency_type !== FrequencyTypes.ExectDate && task.frequency_type !== FrequencyTypes.EveryYear) return true

      const dateIso = task.operation_next_date_raw || task.first_scheduled_date
      const date = ISO8601StringToDate(dateIso)
      return !isDateInThePast(date)
    })
    const showMoreSelect = computed(() => showCopyItem.value || showDeleteItem.value || showEditItem.value)
    return {
      isRtl,
      showEditItem,
      showDeleteItem,
      showCopyItem,
      showMoreSelect,
      onEdit,
      onDelete,
      onCopy
    }
  }
}
</script>
