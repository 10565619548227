<template>
  <v-row
    align="start" justify="start" class="pt-5"
    :class="{ rtl: isRtl }"
  >
    <v-col cols="4" lg="2">
      {{ $t('user_notifications.app_chat.join_group') }}
    </v-col>
    <v-col
      cols="8" lg="10" class="ltr d-flex"
      :class="{ 'justify-end': isRtl }"
    >
      <v-switch
        v-model="notifications.userJoinedToGroup.medium"
        class="mt-0"
        :disabled="mediumSmsDisabled"
        false-value="null"
        true-value="sms"
      />
    </v-col>
    <v-col cols="4" lg="2">
      {{ $t('user_notifications.app_chat.new_message') }}
    </v-col>
    <v-col
      cols="8" lg="10" class="ltr d-flex"
      :class="{ 'justify-end': isRtl }"
    >
      <v-switch
        v-model="notifications.newMessage.medium"
        class="mt-0"
        :disabled="mediumSmsDisabled"
        false-value="null"
        true-value="sms"
      />
    </v-col>
    <v-col cols="12" class="mt-10">
      <v-footer padless fixed class="save-notifications">
        <v-col cols="12" class="text-right">
          <v-btn
            color="blue darken-1" depressed tile
            :loading="isLoading" :disabled="isLoading" @click="save"
          >
            <v-icon v-if="showNotificationsSaved">
              check
            </v-icon>
            <span v-else>
              {{ $t('save') }}
            </span>
          </v-btn>
        </v-col>
      </v-footer>
    </v-col>
  </v-row>
</template>
<script>
import useUserNotification from '@/composables/useUserNotification.js'

import { isRtl } from '@/i18n.js'

import { isObject } from '@/helpers/Utils.js'

import { ref } from 'vue'
const CHAT_GROUP_PARTICIPANT_MODEL = 'ChatGroupParticipant'
const CHAT_MESSAGE_MODEL = 'ChatMessage'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const {
      loadNotifications, saveNotifications, isLoading, mediumSmsDisabled
    } = useUserNotification(props.apiKey)
    const showNotificationsSaved = ref(false)
    const notifications = ref({
      userJoinedToGroup: { medium: 'null' },
      newMessage: { medium: 'null' }
    })

    const models = [CHAT_GROUP_PARTICIPANT_MODEL, CHAT_MESSAGE_MODEL]

    isLoading.value = true
    loadNotifications(models).then(({ reponseByModel }) => {
      const joinGroupNotification = reponseByModel[CHAT_GROUP_PARTICIPANT_MODEL] || []
      const messageNotification = reponseByModel[CHAT_MESSAGE_MODEL] || []

      const userJoinedToGroup = joinGroupNotification.find((notification) => notification.field_name === 'user' && notification.field_value === 'added_to_group')
      const newMessage = messageNotification.find((notification) => notification.field_name === 'message' && notification.field_value === 'created')
      const notificationsVal = notifications.value

      if (isObject(userJoinedToGroup) === true) {
        notificationsVal.userJoinedToGroup.medium = userJoinedToGroup.medium
      }
      if (isObject(newMessage) === true) {
        notificationsVal.newMessage.medium = newMessage.medium
      }
    })
      .finally(() => (isLoading.value = false))

    const saveNotificationsParams = () => {
      const notificationsToSave = { scopes: {} }
      notificationsToSave.scopes[CHAT_GROUP_PARTICIPANT_MODEL] = [
        { field_name: 'user', field_value: 'added_to_group' }
      ]
      notificationsToSave.scopes[CHAT_MESSAGE_MODEL] = [
        { field_name: 'message', field_value: 'created' }
      ]
      const notificationsVal = notifications.value

      notificationsToSave[CHAT_GROUP_PARTICIPANT_MODEL] = []
      notificationsToSave[CHAT_MESSAGE_MODEL] = []

      if (notificationsVal.userJoinedToGroup.medium !== 'null') {
        notificationsToSave[CHAT_GROUP_PARTICIPANT_MODEL].push({ field_name: 'user', field_value: 'added_to_group', medium: notificationsVal.userJoinedToGroup.medium })
      }
      if (notificationsVal.newMessage.medium !== 'null') {
        notificationsToSave[CHAT_MESSAGE_MODEL].push({ field_name: 'message', field_value: 'created', medium: notificationsVal.newMessage.medium })
      }
      return notificationsToSave
    }

    // Methods
    const save = () => {
      isLoading.value = true
      const notificationsToSave = saveNotificationsParams()
      const apiKeys = props.apiKey
      saveNotifications({ params: notificationsToSave, apiKeys })
        .then(() => {
          showNotificationsSaved.value = true
          setTimeout(() => (showNotificationsSaved.value = false), 3000)
        })
        .finally(() => (isLoading.value = false))
    }

    return {
      isLoading,
      notifications,
      mediumSmsDisabled,
      showNotificationsSaved,
      isRtl,
      save
    }
  }
}
</script>
