<template>
  <v-row>
    <v-col
      v-for="post in postsFeed"
      :key="post.id"
      cols="12"
    >
      <single-blog-post
        :post="post"
        @reply-click="replyClick"
        @share-click="shareClick"
        @image-click="imageClick"
        @delete-post="deletePost"
        @edit-post="editPost"
        @open-likes-dialog="openLikesDialogClick"
      />
    </v-col>
  </v-row>
</template>
<script>

import useAuth from '@/composables/useAuth.js'
import useBlogPost from '@/composables/useBlogPost.js'
export default {
  components: {
    'single-blog-post': () => import('@/components/blog/single_blog_post/SingleBlogPost.vue')
  },
  setup (_, { emit }) {
    const { apiKey } = useAuth()
    const { postsFeed, postsFeedLoading } = useBlogPost(apiKey.value)

    // Methods
    const replyClick = (post) => emit('reply-click', post)
    const shareClick = (post) => emit('share-click', post)
    const imageClick = (params) => emit('image-click', params)
    const deletePost = (post) => emit('delete-post', post)
    const editPost = (post) => emit('edit-post', post)
    const openLikesDialogClick = (post) => emit('open-likes-dialog', post)

    return {
      postsFeedLoading,
      postsFeed,
      replyClick,
      shareClick,
      imageClick,
      deletePost,
      editPost,
      openLikesDialogClick
    }
  }
}
</script>
