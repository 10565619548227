<template>
  <v-row align="start" justify="start" no-gutters>
    <v-col cols="12" class="t-500-16">
      {{ notificationText }} <span v-if="addDepartment"> | {{ additionalData.department_name }}</span>
    </v-col>
    <v-col v-if="showAdditionalContent" cols="12">
      {{ additionalData.content }}
    </v-col>
    <v-col v-if="showDescriptionText" class="t-400-14">
      {{ $t('broken_item_share.description', { description: additionalData.description }) }}
    </v-col>
  </v-row>
</template>
<script>
import useAccount from '@/composables/useAccount.js'
import { translateShift } from '@/store/modules/OrganizationJob.js'

import { isString } from '@/helpers/Utils.js'

import { getClientDateFormatted, ISO8601StringToDate, isValidISO8601Date } from '@/helpers/DateTime.js'

import { i18n } from '@/i18n'

import { computed } from 'vue'

export default {
  props: {
    pushNotification: {
      type: Object,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const brokenItemsText = () => {
      const accountName = additionalData.value.account_name
      const itemName = additionalData.value.item_name
      switch (pushAction.value) {
        case 'create':
          return i18n.t('push_history.new_ticket', { accountName, itemName })
        case 'update': {
          const updateAttributes = props.pushNotification.push_data.updated_attributes
          if (isString(updateAttributes) && updateAttributes.includes('assigned_user')) {
            return i18n.t('push_history.assigned_ticket', { accountName, itemName })
          }
          return i18n.t('push_history.updated_ticket', { accountName, itemName })
        }
        case 'new_chat_message':
          return i18n.t('push_history.ticket_new_message', { accountName, itemName })
        case 'deadline_date': {
          const deadlineDate = props.pushNotification.push_data.client_date
          if (isValidISO8601Date(deadlineDate)) {
            const { accountCountryCode } = useAccount()
            const countryCode = accountCountryCode.value
            const fromDate = ISO8601StringToDate(deadlineDate)
            const dateFormmated = getClientDateFormatted({ fromDate, countryCode })
            return i18n.t('push_history.ticket_deadline', { accountName, itemName, deadlineDate: dateFormmated })
          }
          return i18n.t('push_history.updated_ticket', { accountName, itemName })
        }
        case 'timed_notification': {
          const fromDate = new Date(props.pushNotification.created_at)
          const { accountCountryCode } = useAccount()
          const countryCode = accountCountryCode.value
          const dateFormmated = getClientDateFormatted({ fromDate, countryCode })
          return i18n.t('push_history.ticket_timed_notification', { accountName, itemName, openDate: dateFormmated })
        }
        default:
          return ''
      }
    }

    const blogPostText = () => {
      switch (pushAction.value) {
        case 'create': {
          const firstName = additionalData.value.creator_first_name
          const lastName = additionalData.value.creator_last_name
          return i18n.t('push_history.new_blog_post', { firstName, lastName })
        }
        default:
          return ''
      }
    }

    const appChatText = () => {
      const groupName = additionalData.value.group_name
      switch (pushAction.value) {
        case 'new_message': {
          const firstName = additionalData.value.sender_first_name
          const lastName = additionalData.value.sender_last_name
          if (isString(groupName)) {
            return i18n.t('push_history.new_app_chat_group_message', { groupName, firstName, lastName })
          }
          return i18n.t('push_history.new_app_chat_message', { firstName, lastName })
        }
        case 'user_joined_group':
          return i18n.t('push_history.app_chat_join_group', { groupName })
        default:
          return ''
      }
    }

    const taskText = () => {
      const staffTask = props.pushNotification.push_data.is_staff_task === 'true'
      const jobName = additionalData.value.account_job_name
      const accountName = staffTask ? '' : additionalData.value.account_name
      const jobShift = translateShift(additionalData.value.account_job_shift)
      switch (pushAction.value) {
        case 'new_chat_message':
          return i18n.t('push_history.task_chat_message', { accountName, jobName, jobShift })
        case 'create': {
          const pushData = props.pushNotification.push_data
          if (pushData.is_staff_task === 'true') {
            const firstName = additionalData.value.creator_first_name
            const lastName = additionalData.value.creator_last_name
            return i18n.t('push_history.new_staff_task', { jobName, firstName, lastName })
          } else if (pushData.is_cross_account === 'true') {
            return i18n.t('push_history.new_corss_account_task', { jobName, accountName, jobShift })
          }
          return i18n.t('push_history.new_task', { jobName, accountName, jobShift })
        }
        case 'mark_uncompleted':
          return i18n.t('push_history.task_not_completed', { jobName, accountName, jobShift })
        case 'mark_completed':
          return i18n.t('push_history.task_completed', { jobName, accountName, jobShift })
        default:
          return ''
      }
    }

    const shifCompleteText = () => {
      const staffTask = props.pushNotification.push_data.is_staff_task === 'true'
      const jobName = additionalData.value.account_job_name
      const accountName = staffTask ? '' : additionalData.value.account_name
      const jobShift = translateShift(additionalData.value.account_job_shift)
      switch (pushAction.value) {
        case 'completed':
          return i18n.t('push_history.shift_completed', { accountName, jobName, jobShift })
        default:
          return ''
      }
    }

    // Computed
    const pushModel = computed(() => props.pushNotification.push_data.model)
    const pushAction = computed(() => props.pushNotification.push_data.action)
    const additionalData = computed(() => props.pushNotification.additional_data || {})
    const showDescriptionText = computed(() => {
      if (props.dense) return false
      return (pushModel.value === 'broken_item' || pushModel.value === 'operation_schedule') &&
        isString(additionalData.value.description) && additionalData.value.description.length > 0
    })
    const addDepartment = computed(() => pushModel.value === 'operation_schedule' &&
      isString(additionalData.value.department_name) && additionalData.value.department_name.length > 0)
    const showAdditionalContent = computed(() => {
      if ((pushModel.value === 'app_chat' && pushAction.value === 'new_message') ||
        (pushModel.value === 'blog_post' && pushAction.value === 'create') ||
        (pushModel.value === 'broken_item' && pushAction.value === 'new_chat_message') ||
        (pushModel.value === 'operation_schedule' && pushAction.value === 'new_chat_message')) {
        return isString(additionalData.value.content) && additionalData.value.content.length > 0
      }
      return false
    })
    const notificationText = computed(() => {
      switch (pushModel.value) {
        case 'app_chat':
          return appChatText()
        case 'blog_post':
          return blogPostText()
        case 'broken_item':
          return brokenItemsText()
        case 'operation_schedule':
          return taskText()
        case 'account_job_completed_shift':
          return shifCompleteText()
        default:
          return ''
      }
    })

    return {
      notificationText,
      showAdditionalContent,
      addDepartment,
      showDescriptionText,
      additionalData
    }
  }
}
</script>
