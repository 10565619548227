<template>
  <v-overflow-btn
    :value="value"
    :label="$t('company_broken_items.deadline_date_label')"
    dense
    clearable
    :items="options"
    :menu-props="menuProps"
    filled
    @input="update"
    @change="change"
    @click:clear="clear"
  />
</template>
<script>

import { i18n } from '@/i18n.js'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    }
  },
  setup (_, { emit }) {
    const options = [
      { value: 'passed', text: i18n.t('company_broken_items.deadline_date_passed') },
      { value: 'today', text: i18n.t('company_broken_items.deadline_date_today') },
      { value: 'this_week', text: i18n.t('company_broken_items.deadline_date_coming_week') },
      { value: 'no_deadline', text: i18n.t('company_broken_items.deadline_date_no_deadline') }
    ]
    // Methods
    const update = (val) => emit('input', val)
    const change = (val) => emit('change', val)
    const clear = () => emit('input')

    return {
      options,
      update,
      change,
      clear
    }
  }
}
</script>
