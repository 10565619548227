<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <v-card>
      <v-card-title class="text-center pb-0">
        <span class="text-subtitle-2 text-sm-h6 full-width">
          {{ $t('employees_other_store_dialog.title') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form ref="newEmployeeForm">
          <v-container>
            <v-row align="start" justify="start" no-gutters>
              <v-col cols="12">
                <user-search
                  v-model="user"
                  :api-key="apiKey"
                  corss-account-users
                  @change="userSearchChange"
                  @click:clear="clearUserSearch"
                />
              </v-col>
              <v-col cols="12" sm="5">
                <v-select
                  v-model="selectedRole"
                  :items="availableRoles"
                  item-text="translatedName"
                  item-value="name"
                  disabled
                  single-line
                  :rules="[requiredField]"
                  :label="$t('employees_dialog.permission')"
                />
              </v-col>
              <v-col cols="12" sm="6" offset-sm="1">
                <job-selection-search
                  v-model="selectedAllowedJobs"
                  :multiple="true"
                  :disabled="jobSelectionDisabled"
                  :api-key="apiKey"
                  :label="$t('employees_dialog.choose_jobs_placeholder')"
                />
              </v-col>
              <v-col v-if="showDepartmentSelect" cols="12">
                <account-department-select
                  v-model="accountDepartments"
                  :api-key="apiKey"
                  :is-required="isAccountManagerRole"
                  multiple
                />
              </v-col>
              <v-col cols="12" class="mr-4 mt-5">
                <v-card-actions class="pt-2 pr-2">
                  <v-spacer />
                  <v-btn
                    color="blue darken-1" text :disabled="isLoading"
                    @click="close(false)"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1" depressed tile
                    :loading="isLoading" :disabled="isSaveDisabled"
                    @click="save"
                  >
                    {{ $t('save') }}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import UserSearch from '@/components/shared/user_search/UserSearch.vue'
import JobSelectionSearch from '@/components/job_selection_search/JobSelectionSearch.vue'
import AccountDepartmentSelect from '@/components/shared/account_department/AccountDepartmentSelect.vue'

import useEmployeeStore from '@/components/organization_structure/employee/useEmployeeStore.js'

import { accountAdminAndBelow, isAccountEmployee, isAccountManager } from '@/store/modules/EmployeeRole.js'

import { isString, isObject } from '@/helpers/Utils.js'
import { requiredField } from '@/classes/InputValidators.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed } from 'vue'

function defaultUser () {
  return { id: null, role: null, allowedJobs: [] }
}
export default {
  components: {
    'user-search': UserSearch,
    'job-selection-search': JobSelectionSearch,
    'account-department-select': AccountDepartmentSelect
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const isLoading = ref(false)
    const dialog = ref(false)
    const user = ref(defaultUser())
    const accountDepartments = ref([])
    const newEmployeeForm = ref(null)

    const { addUserFromAnotherCompany } = useEmployeeStore(props.apiKey)
    // Methods
    const openDialog = () => {
      dialog.value = true
      user.value = defaultUser()
    }
    const close = () => {
      dialog.value = false
    }
    const userSearchChange = (newUser) => {
      if (isObject(newUser) !== true) return
      user.value.id = newUser.id
      user.value.role = newUser.role
    }
    const clearUserSearch = () => (user.value = defaultUser())
    const save = async () => {
      if (newEmployeeForm.value.validate() !== true) return

      const params = { user_id: user.value.id, role: user.value.role }
      if (isAccountEmployee(params.role)) {
        params.user_allowed_jobs = user.value.allowedJobs
      }
      if (showDepartmentSelect.value === true && accountDepartments.value.length > 0) {
        params.account_department_ids = accountDepartments.value
      }
      isLoading.value = true
      addUserFromAnotherCompany(params)
        .then(() => close())
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    // Computed
    const isUserSelected = computed(() => Number.isInteger(user.value?.id))
    const isRoleSelected = computed(() => isString(user?.value?.role) && user.value.role.length > 0)
    const selectedRole = computed(() => user?.value?.role)
    const selectedAllowedJobs = computed(() => user?.value?.allowedJobs)

    const isAccountManagerRole = computed(() => {
      if (isRoleSelected.value !== true) return false
      const role = user.value.role
      return isAccountManager(role)
    })
    const showDepartmentSelect = computed(() => {
      if (isRoleSelected.value !== true) return false
      const role = user.value.role
      return isAccountManager(role)
    })
    const jobSelectionDisabled = computed(() => {
      if (isUserSelected.value !== true) return true
      return isAccountEmployee(user.value.role) !== true
    })
    const isSaveDisabled = computed(() => {
      if (isLoading.value) return true
      if (!isUserSelected.value) return true
      if (!isRoleSelected.value) return true
      if (isAccountManagerRole.value === true && !accountDepartments.value.length > 0) return true
      return false
    })

    return {
      isLoading,
      dialog,
      user,
      availableRoles: accountAdminAndBelow(),
      isUserSelected,
      isSaveDisabled,
      jobSelectionDisabled,
      showDepartmentSelect,
      accountDepartments,
      isAccountManagerRole,
      newEmployeeForm,
      selectedRole,
      selectedAllowedJobs,
      openDialog,
      close,
      userSearchChange,
      clearUserSearch,
      save,
      requiredField
    }
  }
}
</script>
