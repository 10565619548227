import {
  show as UserClientShow,
  index as UserClientIndex, create as UserClientCreate, addUserFromAnotherCompanyStore,
  update as UserClientUpdate, destroy as UserClientDestroy, inviteUser as UserClientInvite
} from '@/api_client/User.js'

import { indexForUsers as indexUsersFeatures, setUserFeatures } from '@/api_client/Feature.js'

import UserSearchStore from '@/components/shared/user_search/UserSearchStore.js'
import useAuth from '@/composables/useAuth.js'

import { isString } from '@/helpers/Utils.js'
import { newEmployeeModel } from '@/store/modules/Employee.js'

import { ref } from 'vue'
const DEFAULT_PER_PAGE = 70
const DEFAULT_PAGE = 1

const users = ref([])
const totalUsers = ref(0)
const pagination = ref({ page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE })
const totalPages = ref(1)
const isLoading = ref(false)
let userFeatures = Object.create(null)

let onlyCompanyAdmins = false
let currentApiKey = null

export default function useEmployeeStore (apiKey) {
  const setUsers = (usersData) => (users.value = usersData.map((user) => newEmployeeModel(user)))

  const setMetaData = (metaData) => {
    totalUsers.value = metaData.total
    pagination.value.page = metaData.page
    const total = totalUsers.value || 0
    if (total === 0 || total <= pagination.value.per_page) {
      totalPages.value = 0
    } else {
      totalPages.value = Math.ceil(total / pagination.value.per_page)
    }
  }
  const resetValues = () => {
    users.value.splice(0)
    totalUsers.value = 0
    pagination.value = { page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE }
    totalPages.value = 1
    isLoading.value = false
  }

  const loadAllUsersForSearch = (apiKey) => {
    const { loadAllUsers } = UserSearchStore(apiKey)
    loadAllUsers()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const loadUsersFeatures = async () => {
    const { isAccountAdminOrAbove } = useAuth()
    if (!isAccountAdminOrAbove.value) return

    const userIds = users.value.map((user) => user.id)
    if (userIds.length === 0) return

    const features = await indexUsersFeatures({ apiKey, userIds })
    userFeatures = features.data
  }

  // Methods
  const setOnlyCompanyAdmins = (value) => (onlyCompanyAdmins = value)
  const featureForUser = (userId) => userFeatures[userId] || []

  const updateUserFeatures = async (userId, features) => {
    await setUserFeatures({ apiKey, userId, features })
    userFeatures[userId] = features
  }

  const loadUsers = async (page) => {
    isLoading.value = true
    const requestParams = Object.assign({}, pagination.value, { only_company_admins: onlyCompanyAdmins })
    if (Number.isInteger(page)) {
      requestParams.page = page
    }
    const response = await UserClientIndex(apiKey, requestParams)
    setUsers(response.data.data)
    setMetaData(response.data.meta)
    loadUsersFeatures()
    isLoading.value = false
  }

  const showSingleUser = async (userId) => {
    isLoading.value = true
    const response = await UserClientShow(apiKey, userId)
    setUsers([response.data.data])
    setMetaData({ total: 1, page: DEFAULT_PAGE })
    isLoading.value = false
  }

  const createUser = async (user) => {
    const response = await UserClientCreate(apiKey, user)
    loadAllUsersForSearch(apiKey)
    await loadUsers()
    return response.data.data
  }

  const updateUser = async (payload) => {
    const response = await UserClientUpdate(apiKey, payload.userId, { user: payload.user })
    loadAllUsersForSearch(payload.apiKey)
    await loadUsers()
    return response?.data?.data
  }

  const deleteUser = async (userId) => {
    isLoading.value = true
    await UserClientDestroy(apiKey, userId)
    loadAllUsersForSearch(apiKey)
    await loadUsers()
    isLoading.value = false
  }

  const inviteUser = async (payload) => {
    isLoading.value = true
    await UserClientInvite({ user_id: payload.userId, api_key: apiKey, language: payload.language })
    await loadUsers()

    isLoading.value = false
  }
  const addUserFromAnotherCompany = async (params) => {
    await addUserFromAnotherCompanyStore(apiKey, params)
    await loadUsers()
  }

  return {
    DEFAULT_PER_PAGE,
    users,
    totalUsers,
    pagination,
    totalPages,
    isLoading,
    loadUsers,
    showSingleUser,
    createUser,
    updateUser,
    deleteUser,
    inviteUser,
    addUserFromAnotherCompany,
    setOnlyCompanyAdmins,
    featureForUser,
    updateUserFeatures
  }
}
