const validColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/i
const digitsRegex = /^\d{6,12}$/
const imageUrlLowQualitFlags = 'image/upload/f_auto,q_auto,dpr_auto,c_limit,w_400,h_400'
const imageUrlHighQualitFlags = 'image/upload/f_auto,q_auto,dpr_auto'
export function isObject (val) {
  return typeof val === 'object' && val !== null
}

export function isString (val) {
  return typeof val === 'string' || val instanceof String
}

export function isDate (val) {
  return val instanceof Date && !isNaN(val)
}

export function isStringValidNumber (val) {
  return isString(val) && !isNaN(val) && !isNaN(parseFloat(val))
}

export function isFile (val) {
  return val instanceof File
}

export function isBlob (val) {
  return val instanceof Blob
}

export function isHexColor (val) {
  if (!isString(val)) return false
  return validColorRegex.test(val)
}

export function isFloat (val) {
  return (Number(val) === val && val % 1 !== 0)
}

export const isFunction = (val) => typeof val === 'function'

export function isValidPhone (val) {
  if (!isString(val)) return false
  return digitsRegex.test(val)
}
export function hasAncestorWithClass (el, cls) {
  while (el !== null) {
    if (el.classList.contains(cls)) return true
    el = el.parentElement
  }
  return false
}

export function decimalPercision ({ number, digitsAfterPoint }) {
  if (!isFloat(number)) return number
  const parsedNumber = Number.parseFloat(number).toFixed(digitsAfterPoint)

  const splitNum = parsedNumber.split('.')
  const decimalPart = splitNum[1]
  if (!isString(decimalPart)) return parsedNumber
  return /^0*$/.test(decimalPart) ? splitNum[0] : parsedNumber
}

export function flattenObject (obj) {
  const toReturn = {}
  for (const i in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue

    if ((typeof obj[i]) === 'object' && obj[i] !== null) {
      const flatObject = flattenObject(obj[i])
      for (const x in flatObject) {
        if (!Object.prototype.hasOwnProperty.call(flatObject, x)) continue

        toReturn[i + '.' + x] = flatObject[x]
      }
    } else {
      toReturn[i] = obj[i]
    }
  }
  return toReturn
}

export const base64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export function debounce (fn, wait) {
  let timer
  return function (...args) {
    if (timer) clearTimeout(timer)

    const context = this
    timer = setTimeout(() => fn.apply(context, args), wait)
  }
}

export const betterImageQuality = (url) => url.replace(imageUrlLowQualitFlags, imageUrlHighQualitFlags)
