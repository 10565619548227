<template>
  <v-card
    rounded="xxl" width="456"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <v-card-text class="top-bar-push-list px-0">
      <v-row align="start" justify="space-between" no-gutters>
        <v-col cols="6" class="t-700-17 black--text texts-start pa-4">
          {{ $t('notifications') }}
        </v-col>
        <v-col cols="6" class="pt-2 texts-end px-4">
          <v-btn
            v-if="showMoreButton" text color="#0040FF"
            class="px-0"
            @click="showMoreClick"
          >
            {{ $t('show_more') }}
          </v-btn>
        </v-col>
        <v-col v-if="isLoading" cols="12">
          <v-skeleton-loader
            v-for="n in 4" :key="n" class="mt-3"
            type="list-item-two-line"
          />
        </v-col>
        <v-col v-else-if="hasPushHistory" cols="12" class="my-4">
          <push-notifications-history-list
            :api-key="apiKey"
            :push-history-list="pushHistoryList"
            dense
          />
        </v-col>
        <v-col v-else cols="12" class="my-4 align-self-center text-center t-500-17">
          {{ $t('push_history.no_notifications') }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import PushNotificationsHistoryList from '@/components/shared/PushNotificationsHistoryList.vue'

import usePushNotificationHistoryTopBar from '@/composables/usePushNotificationHistoryTopBar.js'

import { useRouter } from 'vue-router/composables'

import { isRtl } from '@/i18n'

import { computed } from 'vue'
export default {
  components: {
    'push-notifications-history-list': PushNotificationsHistoryList
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const router = useRouter()
    const { isLoading, pushHistoryList, loadPushHistory } = usePushNotificationHistoryTopBar(props.apiKey)
    // Methods
    const showMoreClick = () => router.push({ name: 'push_history' })

    // Computed
    const hasPushHistory = computed(() => pushHistoryList.value.length > 0)
    const showMoreButton = computed(() => hasPushHistory.value && !isLoading.value)
    loadPushHistory()
    return {
      isRtl,
      isLoading,
      pushHistoryList,
      hasPushHistory,
      showMoreButton,
      showMoreClick
    }
  }
}
</script>
<style lang="scss">
.top-bar-push-list {
  overflow: auto;
  height: 80vh;
  max-height: 80vh;
}
</style>
