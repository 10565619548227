<template>
  <v-row align="start" justify="start" class="post-comments">
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col cols="12" class="count-comments">
      {{ post.comments_count }} {{ $t('comments') }}
    </v-col>
    <v-col v-if="isLoading" cols="12">
      <v-skeleton-loader
        v-if="postsFeedLoading"
        type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
      />
    </v-col>
    <v-col v-else cols="12">
      <v-row align="start" justify="end" no-gutters>
        <v-col
          v-for="comment in post.comments" :key="comment.id" cols="12"
          md="10"
        >
          <single-blog-post-comment
            class="mb-4"
            :post="comment"
            @delete-post="deletePost"
            @edit-post="editPost"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import useAuth from '@/composables/useAuth.js'
import useBlogPost from '@/composables/useBlogPost.js'
import { ref } from 'vue'
export default {
  components: {
    'single-blog-post-comment': () => import('@/components/blog/single_blog_post_comment/SingleBlogPostComment.vue')
  },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(false)

    const { apiKey } = useAuth()
    const { loadCommentsFeed } = useBlogPost(apiKey.value)

    // Methods

    const deletePost = (post) => emit('delete-post', post)
    const editPost = (post) => emit('edit-post', post)
    loadCommentsFeed(props.post)
    return {
      isLoading,
      deletePost,
      editPost
    }
  }
}
</script>
<style lang="scss">
.post-comments {
  .single-comment {
    border-radius: 8px !important;
  }

  .count-comments {
    font-weight: 600;
    font-size: 16px;
    color: #212121;
  }
}
</style>
