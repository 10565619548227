<template>
  <span>
    <date-picker-dialog
      ref="datePickerDialog"
      :value="value"
      :disabled="disabled"
      @change="applyDatePicker"
    />
    <div class="ltr row no-gutters align-center justify-start">
      <span v-if="isRtl" @click="openDateDialog">
        {{ currentTranslatedDay }}
        {{ formmatedDate }}
      </span>
      <v-btn icon @click="moveBack">
        <v-icon color="black">chevron_left</v-icon>
      </v-btn>
      <v-btn
        fab x-small color="white"
        class="elevation-2" @click="openDateDialog"
      >
        <v-icon color="black">calendar_today</v-icon>
      </v-btn>
      <v-btn icon @click="moveNext">
        <v-icon color="black">chevron_right</v-icon>
      </v-btn>
      <span v-if="!isRtl" @click="openDateDialog">
        {{ currentTranslatedDay }}
        {{ formmatedDate }}
      </span>
    </div>
  </span>
</template>

<script>
import DatePickerDialog from '@/components/shared/DatePickerDialog.vue'
import useAccount from '@/composables/useAccount.js'

import { dateToISO8601String, translateDayFromDate, getClientDateFormatted } from '@/helpers/DateTime.js'

import { i18n, isRtl } from '@/i18n.js'
import { ref, computed } from 'vue'
export default {
  components: {
    'date-picker-dialog': DatePickerDialog
  },
  props: {
    value: {
      type: Date,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    offset: {
      type: Number,
      default: 1,
      required: false
    }
  },
  setup (props, { emit }) {
    const datePickerDialog = ref(null)
    const { accountCountryCode } = useAccount()

    const emitChange = (date) => {
      emit('input', date)
      emit('change', date)
    }

    const changeDateByOffset = (offset) => {
      const newDate = new Date(props.value)
      newDate.setDate(newDate.getDate() + offset)
      emitChange(newDate)
    }

    // Methods
    const moveNext = () => changeDateByOffset(isRtl.value ? -props.offset : props.offset)
    const moveBack = () => changeDateByOffset(isRtl.value ? props.offset : -props.offset)
    const openDateDialog = () => datePickerDialog.value.openDialog()
    const applyDatePicker = (date) => {
      const currentDate = dateToISO8601String(date)
      const newDate = new Date(`${currentDate}T00:00:00`)
      emitChange(newDate)
    }

    // Computed
    const currentTranslatedDay = computed(() =>
      i18n.t('currnent_day', { dayString: translateDayFromDate(props.value) })
    )

    const formmatedDate = computed(() => {
      const countryCode = accountCountryCode.value
      return getClientDateFormatted({ countryCode, fromDate: props.value })
    })
    return {
      datePickerDialog,
      currentTranslatedDay,
      formmatedDate,
      isRtl,
      applyDatePicker,
      openDateDialog,
      moveNext,
      moveBack
    }
  }
}
</script>

<style></style>
