<template>
  <v-row align="start" justify="center" no-gutters>
    <confirm-delete ref="deleteDialog" />
    <schedule-new-staff-operation-dialog ref="scheduleNewStaffOperation" />
    <schedule-new-operation-dialog
      v-if="showTaskEdit"
      ref="scheduleNewOperation"
      :api-key="apiKeyForEditDialogs"
    />
    <v-col
      cols="12"
      class="page-title mms-4 mt-15 mt-sm-8"
    >
      {{ $t('task_bank.title') }}
    </v-col>
    <v-col
      cols="12" lg="8" md="10"
      class="mms-4 mt-2"
    >
      <task-bank-filter
        :hq-context="hqContext"
        :show-account-select="showAccountSelect"
        :staff-account="staffAccount"
        :api-key="apiKey"
      />
    </v-col>
    <v-col v-if="isStatsLoading" cols="12" class="text-center">
      <v-progress-circular indeterminate color="primary" />
    </v-col>
    <v-col v-else-if="isEmpty" cols="12" class="t-800-20 text-center mt-8">
      {{ $t('task_bank.no_tasks') }}
    </v-col>
    <v-col v-else cols="12">
      <v-row
        align="start" justify="center" no-gutters
        class="task-bank-layout mx-4"
      >
        <v-col
          cols="12" lg="8" md="10"
          class="mt-6"
        >
          <v-expansion-panels v-model="panels">
            <v-expansion-panel
              v-for="(frequencyStat, index) in stats"
              :key="`${frequencyStat.name}_${staffAccount}_${hqContext}`"
              class="mb-2"
              @change="onChange(frequencyStat.name)"
            >
              <v-expansion-panel-header
                class="pa-2"
                :class="{ 'open-collapse': panels === index }"
              >
                <v-row align="start" justify="start" no-gutters>
                  <v-col cols="12" class="t-700-18  texts-start">
                    <v-icon color="#111111">
                      schedule
                    </v-icon>
                    {{ frequencyStat.translatedName }} ({{ frequencyStat.total }})
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="bg-color18">
                <tasks-bank-list
                  class="mt-4"
                  :tasks-info="frequencyStat"
                  :hq-context="hqContext"
                  :staff-account="staffAccount"
                  @load-next-page="onLoadNextPage(frequencyStat.name)"
                  @edit-click="onEditClick"
                  @delete-click="onDeleteClick"
                  @copy-click="onCopyClick"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import TasksBankList from '@/components/task_bank/TasksBankList.vue'
import TaskBankFilter from '@/components/task_bank/TaskBankFilter.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import ScheduleNewStaffOperationDialog from '@/components/operation_schedule_dialog/ScheduleNewStaffOperationDialog.vue'
import ScheduleNewOperationDialog from '@/components/operation_schedule_dialog/ScheduleNewOperationDialog.vue'

import {
  EventBus, NEW_ACCOUNT_OPERATION, NEW_CROSS_ACCOUNT_OPERATION
} from '@/EventBus.js'

import useAuth from '@/composables/useAuth.js'
import useTaskBank from '@/composables/useTaskBank.js'
import { isString } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'
import { computed, ref, nextTick, onUnmounted } from 'vue'
export default {
  components: {
    'tasks-bank-list': TasksBankList,
    'task-bank-filter': TaskBankFilter,
    'confirm-delete': ConfirmDelete,
    'schedule-new-staff-operation-dialog': ScheduleNewStaffOperationDialog,
    'schedule-new-operation-dialog': ScheduleNewOperationDialog
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    staffAccount: {
      type: Boolean,
      required: false,
      default: false
    },
    hqContext: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const panels = ref([])
    const deleteDialog = ref(null)
    const scheduleNewStaffOperation = ref(null)
    const scheduleNewOperation = ref(null)
    const apiKeyForEditDialogs = ref(null)
    const showTaskEdit = ref(false)
    const { isCompanyAdminRegionOrAbove } = useAuth()
    const {
      stats, isStatsLoading, loadTasksForFrequency, deleteTask, loadStats,
      loadNextPageForTaskFrequency
    } = useTaskBank(props.apiKey, props.hqContext)

    const showDeleteTaskDialog = (task) => {
      const description = task.operation_description || ''
      const deleteDialogParams = { title: i18n.t('job_operation_edit.delete_operation') }
      if (isString(description) && description.trim().length > 0) {
        deleteDialogParams.content = description
      }
      return deleteDialog.value.open(deleteDialogParams)
    }

    const showStaffAccountEditOperationDialog = ({ task, shiftPartName }) => {
      const jobId = task.account_job_id
      const hasItemList = Array.isArray(task.item_operation_list)
      return scheduleNewStaffOperation.value.openStaffAccountUpdate({ operation: task, jobId, shiftPartName, hasItemList })
    }

    const showCrossAccountEditOperationDialog = ({ task }) =>
      scheduleNewStaffOperation.value.openCrossAccountUpdate(task, props.apiKey)

    const showEditOperationDialog = ({ task }) =>
      scheduleNewOperation.value.openForUpdate({ operation: task })

    const onNewOperation = () => {
      showTaskEdit.value = false
      loadStats()
      panels.value = []
    }
    // Methods
    const onChange = (frequency) => {
      const statsToLoad = stats.value.find((stat) => stat.name === frequency)
      if (statsToLoad.tasks.length > 0) return

      loadTasksForFrequency({ frequency })
    }

    const onDeleteClick = async (task) => {
      const isDeleted = await showDeleteTaskDialog(task)
      if (!isDeleted) return false
      await deleteTask(task)

      deleteDialog.value.close()
      panels.value = []
    }

    const onEditClick = async (task) => {
      const shiftPartName = task.shift_part
      apiKeyForEditDialogs.value = props.hqContext ? props.apiKey : task.account_api_key
      showTaskEdit.value = true
      nextTick(() => {
        if (props.staffAccount) return showStaffAccountEditOperationDialog({ task, shiftPartName })
        if (props.hqContext === true) return showCrossAccountEditOperationDialog({ task })
        if (task.item_type === 'Item') return showEditOperationDialog({ task })
      })
    }

    const onCopyClick = async (task) => {
      if (props.hqContext) {
        return scheduleNewStaffOperation.value.openCrossAccountCopy(task, props.apiKey)
      }
      apiKeyForEditDialogs.value = task.account_api_key
      showTaskEdit.value = true
      nextTick(() => scheduleNewOperation.value.openForCopy({ operation: task }))
    }

    const onLoadNextPage = (frequencyName) => loadNextPageForTaskFrequency(frequencyName)

    // Computed
    const isEmpty = computed(() => !isStatsLoading.value && Object.keys(stats.value).length === 0)
    const showAccountSelect = computed(() => isCompanyAdminRegionOrAbove.value && !props.staffAccount && !props.hqContext)

    EventBus.on(NEW_ACCOUNT_OPERATION, onNewOperation)
    EventBus.on(NEW_CROSS_ACCOUNT_OPERATION, onNewOperation)
    onUnmounted(() => {
      EventBus.off(NEW_ACCOUNT_OPERATION, onNewOperation)
      EventBus.off(NEW_CROSS_ACCOUNT_OPERATION, onNewOperation)
    })
    return {
      panels,
      deleteDialog,
      scheduleNewStaffOperation,
      scheduleNewOperation,
      isStatsLoading,
      apiKeyForEditDialogs,
      showTaskEdit,
      isEmpty,
      stats,
      showAccountSelect,
      onEditClick,
      onDeleteClick,
      onCopyClick,
      onLoadNextPage,
      onChange
    }
  }
}
</script>
<style lang="scss">
.task-bank-layout {
  .open-collapse {
    border: 1px solid #F5409B;
    background-color: rgba(245, 64, 155, 0.08);
  }
}
</style>
