<template>
  <v-row
    align="start" justify="start" class="single-task py-2 px-4"
    :class="{ 'changin-status': pendingStatusChange }"
  >
    <single-task-side-part
      :task="task"
      :disabled="disabled"
      :allow-toggle-complete="allowToggleComplete"
    />
    <v-col class="flex-grow-1 flex-shrink-0 py-2 ppe-0">
      <single-task-top-part
        :task="task"
        :client-date="clientDate"
        :task-enabled-options="taskEnabledOptions"
        :hq-view="hqView"
      />
      <single-task-bottom-part
        :task="task"
      />
    </v-col>
  </v-row>
</template>
<script>
import SingleTaskSidePart from '@/components/tasks/tasks_list/single_task/SingleTaskSidePart.vue'
import SingleTaskTopPart from '@/components/tasks/tasks_list/single_task/SingleTaskTopPart.vue'
import SingleTaskBottomPart from '@/components/tasks/tasks_list/single_task/SingleTaskBottomPart.vue'

import { computed } from 'vue'
export default {
  components: {
    'single-task-side-part': SingleTaskSidePart,
    'single-task-top-part': SingleTaskTopPart,
    'single-task-bottom-part': SingleTaskBottomPart
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    clientDate: {
      type: String,
      required: true
    },
    taskEnabledOptions: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowToggleComplete: {
      type: Function,
      required: false,
      default: null
    },
    hqView: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const pendingStatusChange = computed(() => props.task.operationRef.pendingStatusChange)
    return {
      pendingStatusChange
    }
  }
}
</script>
<style lang="scss">
.single-task {
  background-color: white;
  border-right: 1px solid #DADADA;
  border-left: 1px solid #DADADA;
  margin-top: 0 !important;
  margin-bottom: 0;

  &.changin-status {
    animation: fadeOutAndMoveDown 1500ms ease-in-out forwards;
  }

  .account-image-preview {
    border-radius: 50%;
  }

  .image-preview {
    border-radius: 12px;
  }

  @keyframes fadeOutAndMoveDown {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(calc(100vh - 200px));
    }
  }

  .side-part {
    border-radius: 8px;
    align-items: center;

    &.reported {
      background-color: unset;
      align-items: start;
    }

    .side-content {
      height: 100%;
      width: 100%;
      display: flex;
      padding: 0 5px;
      align-items: center;
      justify-content: center;

      .v-icon {
        padding: 0 5px;
      }
    }
  }

  .task-tag {
    padding: 4px 8px;
    border-radius: 8px;
    color: white;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
