<template>
  <v-select
    :items="shiftSelectionOptions"
    :value="value"
    :disabled="disabled"
    clearable
    :height="height"
    :multiple="multiple"
    :label="$t('org_job_new_dialog.choose_shift')"
    item-value="name"
    item-text="translatedName"
    :menu-props="menuProps"
    single-line
    @input="onUpdate"
    @change="onChange"
  >
    <template #selection="{ item, index }">
      <div v-if="multiple && index === 0" class="v-select__selection v-select__selection--comma">
        {{ $t('job_shift_select.x_shifts_selected', { shift_count: value.length }) }}
      </div>
      <span v-else-if="!multiple">
        {{ item.translatedName }}
      </span>
    </template>
    <template v-if="multiple" #item="{ item, attrs }">
      <v-row
        no-gutters justify="start" align="center"
        :class="{ 'rtl': isRtl }"
      >
        <v-col cols="10" class="font-weight-bold">
          {{ item.translatedName }}
        </v-col>
        <v-col cols="2" class="texts-end">
          <v-icon v-if="attrs.inputValue">
            check_box
          </v-icon>
          <v-icon v-else>
            check_box_outline_blank
          </v-icon>
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>
<script>
import {
  getShiftSortOrderMap,
  translateShift as OrgJobTranslateShift
} from '@/store/modules/OrganizationJob.js'

import { isRtl } from '@/i18n.js'
import { ref } from 'vue'
export default {
  props: {
    value: {
      type: [String, Array],
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    },
    height: {
      type: [Number, String],
      required: false,
      default: undefined
    }
  },
  setup (_, { emit }) {
    const shiftSelection = ref([])
    const shiftSortOrderMap = getShiftSortOrderMap()
    for (const shiftName in shiftSortOrderMap) { // eslint-disable-line no-unused-vars
      shiftSelection.value.push({
        id: shiftSortOrderMap[shiftName],
        translatedName: OrgJobTranslateShift(shiftName),
        name: shiftName
      })
    }
    // Methods
    const onUpdate = (newValue) => { emit('input', newValue) }
    const onChange = (newValue) => { emit('change', newValue) }

    return {
      shiftSelectionOptions: shiftSelection,
      isRtl,
      onUpdate,
      onChange
    }
  }
}
</script>
