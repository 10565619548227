<template>
  <v-container
    fluid class="px-0 user-profile"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <confirm-delete ref="deleteDialog" />
    <v-row
      align="start" justify="start" justify-sm="center"
      no-gutters
    >
      <v-col
        cols="12" sm="6" md="4"
        class="row align-start justify-start"
      >
        <v-col cols="12" class="page-title py-4">
          {{ $t('menu_page.profile') }}
        </v-col>
        <v-row align="start" justify="start" class="px-4">
          <v-col cols="12" class="text-center mt-4">
            <v-avatar color="indigo" size="66">
              <img v-if="showUserAvatarImage" :src="userAvatarUrl">
              <span v-else class="white--text text-h5">{{ userInitials }}</span>
            </v-avatar>
          </v-col>
          <v-col cols="12" class="profile-item mt-8">
            <div class="sub-title-item">
              {{ $t('user_profile.full_name') }}
            </div>
            <div class="title-item">
              {{ userFullName }}
            </div>
            <div />
          </v-col>
          <v-col cols="12" class="profile-item mt-4">
            <div class="sub-title-item">
              {{ $t('user_profile.phone') }}
            </div>
            <div class="title-item">
              {{ userPhoneNumber }}
            </div>
            <div />
          </v-col>
          <v-col cols="12" class="profile-item mt-4">
            <div class="sub-title-item">
              {{ $t('email') }}
            </div>
            <div class="title-item">
              {{ userEmail }}
            </div>
            <div />
          </v-col>
          <v-col cols="12" class="px-0 pt-4">
            <multiple-file-uploader ref="userFilesUploader" record-type="User" :max-files="5" />
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-btn
              color="blue darken-1" depressed tile
              :loading="isLoading" :disabled="saveDisabled" @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="pt-4 pl-0">
            <v-btn icon color="red" @click="deleteUser">
              <v-icon>delete</v-icon>
            </v-btn>
            {{ $t('user_profile.delete_account') }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import MultipleFileUploader from '@/components/shared/MultipleFileUploader.vue'

import { me as userClientCurrentUser, destroy as userClientDelete } from '@/api_client/User.js'

import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import useAuth from '@/composables/useAuth.js'

import { i18n, isRtl } from '@/i18n.js'

import { computed, ref } from 'vue'

export default {
  components: {
    'confirm-delete': ConfirmDelete,
    'multiple-file-uploader': MultipleFileUploader
  },
  setup () {
    const isLoading = ref(true)
    const user = ref(null)
    const userFilesUploader = ref(null)
    const { apiKey } = useAuth()
    const deleteDialog = ref(null)

    const loadUser = () => {
      isLoading.value = true
      userClientCurrentUser(apiKey.value)
        .then(response => {
          user.value = response.data.data
          isLoading.value = false
          userFilesUploader.value.setFiles(user.value.attached_files)
        })
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    const deleteUser = () => {
      const title = i18n.t('user_profile.delete_account')
      const content = i18n.t('user_profile.delete_confirm')
      const deleteBtnText = i18n.t('user_profile.confirm')
      deleteDialog.value.open({ title, content, deleteBtnText })
        .then((shouldDelete) => {
          if (!shouldDelete) return
          const { logoutUser } = useAuth()
          userClientDelete(apiKey.value, user.value.id)
            .then(() => {
              deleteDialog.value.close()
              logoutUser()
            })
            .catch(errHandler)
        })
    }

    const save = async () => {
      isLoading.value = true
      userFilesUploader.value.saveFiles({ apiKey: apiKey.value, userId: user.value.id })
        .then(loadUser)
        .finally(() => (isLoading.value = false))
    }
    // Computed
    const showUserAvatarImage = computed(() => isString(user.value?.avatar_url))
    const userAvatarUrl = computed(() => user.value?.avatar_url)
    const userInitials = computed(() => {
      if (user.value === null) return ''
      return `${user.value.first_name.charAt(0)}${user.value.last_name.charAt(0)}`
    })

    const userFullName = computed(() => {
      if (user.value === null) return ''
      return `${user.value.first_name} ${user.value.last_name}`
    })

    const userPhoneNumber = computed(() => {
      if (user.value === null) return ''
      return user.value.phone
    })

    const userEmail = computed(() => {
      if (user.value === null) return ''
      return user.value.email
    })

    const saveDisabled = computed(() => {
      if (isLoading.value === true) return true
      return userFilesUploader.value?.hasChanges !== true
    })

    loadUser()
    return {
      userInitials,
      userFullName,
      userPhoneNumber,
      userEmail,
      deleteDialog,
      userFilesUploader,
      isLoading,
      saveDisabled,
      showUserAvatarImage,
      userAvatarUrl,
      isRtl,
      deleteUser,
      save
    }
  }
}
</script>
<style lang="scss">
.user-profile {
  padding: 0;
  background-color: #E5E5E5;
  height: 100%;
  padding-top: 126px;
  padding-bottom: 26px;
  margin: 0;

  .profile-item {
    border-radius: 8px;
    border: 1px solid #E8E8E8;
    background-color: #FFFFFF;

    .sub-title-item {
      font-size: 12px;
      font-weight: 400;
    }

    .title-item {
      font-size: 16px;
      font-weight: 400;
    }
  }
}
</style>
