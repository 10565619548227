
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { newCompanyProviderModel } from '@/store/modules/CompanyProvider.js'

import {
  show as showProviderClient,
  index as indexProviderClient,
  destroy as destroyProviderClient
} from '@/api_client/CompanyProvider.js'

import { isString, isObject } from '@/helpers/Utils.js'
import { ref } from 'vue'

const DEFAULT_PER_PAGE = 60
const DEFAULT_PAGE = 1

const isLoading = ref(false)
const pagination = ref({ page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE })
const totalProviders = ref(0)
const providersTableList = ref([])
const totalPages = ref(0)

let currentApiKey = null

export default function useCompanyProvider (apiKey) {
  const resetValues = () => {
    isLoading.value = false
    pagination.value = { page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE }
    totalProviders.value = 0
    providersTableList.value.splice(0, providersTableList.value.length)
    totalPages.value = 0
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const getTotalPages = () => {
    const total = pagination.value.total || 0
    if (total === 0 || total <= pagination.value.per_page) {
      return 0
    }
    return Math.ceil(total / pagination.value.per_page)
  }

  const indexResponseHandler = (response) => {
    const providersTableListVal = providersTableList.value
    providersTableListVal.splice(0, providersTableListVal.length)
    response.data.data.forEach((provider) => providersTableListVal.push(newCompanyProviderModel(provider)))
    const paginationResponse = response.data.meta
    pagination.value = Object.assign({}, pagination.value, paginationResponse)
    totalProviders.value = pagination.value.total || 0
    totalPages.value = getTotalPages()
  }

  const loadTablePage = () => {
    isLoading.value = true
    return indexProviderClient(currentApiKey, pagination.value)
      .then((response) => indexResponseHandler(response))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  // methods
  const deleteProvider = (providerId) => {
    return destroyProviderClient(currentApiKey, providerId)
      .then(() => {
        if (pagination.value.page > 1 && providersTableList.value.length === 1) {
          pagination.value.page = pagination.value.page - 1
        }
        loadTablePage()
      })
      .catch(errHandler)
  }

  const providerSearchChange = (provider) => {
    if (!isObject(provider)) return

    const providerId = provider.id
    if (!Number.isInteger(providerId)) return
    isLoading.value = true
    showProviderClient(currentApiKey, providerId).then((response) => {
      providersTableList.value.splice(0, providersTableList.value.length)
      providersTableList.value.push(newCompanyProviderModel(response.data.data))
      pagination.value.page = 1
      pagination.value.total = 1
      totalProviders.value = 1
      totalPages.value = getTotalPages()
    })
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const clearSearch = () => {
    loadTablePage()
  }

  return {
    isLoading,
    pagination,
    totalProviders,
    totalPages,
    DEFAULT_PER_PAGE,
    providersTableList,
    deleteProvider,
    providerSearchChange,
    clearSearch,
    loadTablePage
  }
}
