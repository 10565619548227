import useCompany, { deleteIsMultipleMemberships } from '@/composables/useCompany.js'
import useAccount from '@/composables/useAccount.js'

import { login as AuthClientLogin, logout as AuthClientLogout } from '@/api_client/auth.js'
import { me as userClientCurrentUser } from '@/api_client/User.js'

import { isString } from '@/helpers/Utils.js'
import { getPageQueryParamsObject, deleteQueryParamsFromUrl } from '@/helpers/Url.js'

import { EventBus, USER_CONNECTED, USER_DISCONNECTED } from '@/EventBus.js'

import router from '@/router.js'
import { ref, computed } from 'vue'
const apiKeyRef = ref(null)
const userRoleRef = ref(null)
const currentUserRef = ref(null)

const API_KEY = 'API_KEY'
const USER_ROLE = 'USER_ROLE'

const USER_ROLES = {
  companyAdmin: 'company_admin',
  companyRegionAdmin: 'company_region_admin',
  accountAdmin: 'account_admin',
  accountManager: 'account_manager',
  accountEmployee: 'account_employee'
}
export const isAuthenticated = computed(() => isString(apiKeyRef.value) && isString(userRoleRef.value))

export default function useAuth () {
  const setUserRole = (userRole) => {
    userRoleRef.value = userRole
    localStorage.setItem(USER_ROLE, userRole)
  }

  const deleteUserData = () => {
    apiKeyRef.value = null
    userRoleRef.value = null
    currentUserRef.value = null
    localStorage.removeItem(API_KEY)
    localStorage.removeItem(USER_ROLE)
  }

  const deleteLoginData = () => {
    deleteUserData()
    deleteIsMultipleMemberships()
    EventBus.emit(USER_DISCONNECTED)
  }

  const routeToLogin = () => {
    if (router.history.current.name === 'login') return

    router.push({ name: 'login' })
  }

  // Methods

  // AUTH_LOGIN_REQUEST
  const loginUser = (params) => AuthClientLogin(params)
    .then(resp => {
      const apiKey = resp.data.api_key
      const userRole = resp.data.role
      const { setIsMultipleMemberships } = useCompany(apiKey)
      const multipleMemberships = resp.data.multiple_memberships
      setUserData({ apiKey, userRole })
      setIsMultipleMemberships(multipleMemberships)
    })

  // AUTH_LOGOUT_REQUEST
  const logoutUser = (params) => new Promise((resolve, reject) => {
    AuthClientLogout()
      .then(() => sessionStorage.clear())
      .catch(() => { })
      .finally(() => {
        deleteLoginData()
        routeToLogin()
        resolve()
      })
  })

  // AUTH_LOGIN_ERROR
  const loginError = () => {
    return new Promise((resolve, reject) => {
      deleteLoginData()
      routeToLogin()
      resolve()
    })
  }

  // AUTH_LOAD_CURRENT_USER
  const loadCurrentUser = async () => {
    if (currentUserRef.value !== null) return currentUserRef
    const response = await userClientCurrentUser(apiKeyRef.value)
    currentUserRef.value = response.data.data
    return response.data.data
  }

  // LOAD_LOGIN_DATA
  const loadLoginData = () => {
    const { loadAccountData } = useCompany(apiKeyRef.value)
    const { loadAccount } = useAccount(apiKeyRef.value)

    return Promise.all([
      loadAccount(),
      loadAccountData()
    ])
  }

  // AFTER_LOGIN
  const afterLogin = () => {
    const redirect = router.history.current.query.redirect
    let nextRoute
    if (isString(redirect) && redirect.length > 0) {
      nextRoute = redirect
    } else if (isCompanyAdminRegionOrAbove.value === true) {
      nextRoute = { name: 'company_stores' }
    } else {
      nextRoute = { name: 'tasks' }
    }
    router.push(nextRoute)

    EventBus.emit(USER_CONNECTED)
  }

  // AUTH_INIT_USER_DATA
  const initUserData = () => {
    apiKeyRef.value = localStorage.getItem(API_KEY)
    userRoleRef.value = localStorage.getItem(USER_ROLE)
  }
  // AUTH_SET_API_KEY
  const setApiKey = (apiKey) => {
    apiKeyRef.value = apiKey
    localStorage.setItem(API_KEY, apiKey)
  }

  // AUTH_SET_USER_DATA
  const setUserData = ({ apiKey, userRole }) => {
    setApiKey(apiKey)
    setUserRole(userRole)
  }

  const tryAdminLogin = () => {
    const queryObject = getPageQueryParamsObject()
    const { api_key: apiKey, role: userRole, is_logged_in: isLoggedIn } = queryObject
    if (isLoggedIn !== 'true') return
    if ((apiKey || '').trim().length === 0 || (userRole || '').trim().length === 0) return
    setUserData({ apiKey, userRole })
    deleteQueryParamsFromUrl()
    return true
  }
  const tryRegularLogin = () => {
    initUserData()
  }
  const tryLoginUser = () => {
    tryAdminLogin()
    if (isAuthenticated.value === true) return

    tryRegularLogin()
  }
  // Computed

  const apiKey = computed(() => apiKeyRef.value)
  const isUserCompanyAdmin = computed(() => userRoleRef.value === USER_ROLES.companyAdmin)
  const isUserCompanyRegionAdmin = computed(() => userRoleRef.value === USER_ROLES.companyRegionAdmin)
  const isUserAccountAdmin = computed(() => userRoleRef.value === USER_ROLES.accountAdmin)
  const isUserAccountManager = computed(() => userRoleRef.value === USER_ROLES.accountManager)
  const isUserAccountEmployee = computed(() => userRoleRef.value === USER_ROLES.accountEmployee)
  const isCompanyAdminRegionOrAbove = computed(() => userRoleRef.value === USER_ROLES.companyRegionAdmin || userRoleRef.value === USER_ROLES.companyAdmin)
  const isAccountAdminOrAbove = computed(() => userRoleRef.value === USER_ROLES.accountAdmin || userRoleRef.value === USER_ROLES.companyRegionAdmin || userRoleRef.value === USER_ROLES.companyAdmin)
  const isAccountManagerOrAbove = computed(() => userRoleRef.value === USER_ROLES.accountManager || userRoleRef.value === USER_ROLES.accountAdmin || userRoleRef.value === USER_ROLES.companyRegionAdmin || userRoleRef.value === USER_ROLES.companyAdmin)
  const currentUser = computed(() => currentUserRef.value)
  const currentUserRole = computed(() => userRoleRef.value)

  return {
    apiKey,
    isUserCompanyAdmin,
    isUserCompanyRegionAdmin,
    isUserAccountAdmin,
    isUserAccountManager,
    isUserAccountEmployee,
    isCompanyAdminRegionOrAbove,
    isAccountAdminOrAbove,
    isAccountManagerOrAbove,
    currentUser,
    currentUserRole,
    loginUser,
    logoutUser,
    loadCurrentUser,
    loadLoginData,
    afterLogin,
    initUserData,
    setApiKey,
    setUserRole,
    setUserData,
    tryLoginUser,
    loginError
  }
}
