<template>
  <v-app>
    <top-bar />
    <bottom-navigation-small-devices v-if="$vuetify.breakpoint.xsOnly" />
    <bottom-navigation-big-devices v-else />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
export default {
  components: {
    'top-bar': () => import('@/components/layout/TopBar.vue'),
    'bottom-navigation-big-devices': () => import('@/components/layout/bottom_navigation/BottomNavigationBigDevices.vue'),
    'bottom-navigation-small-devices': () => import('@/components/layout/bottom_navigation/BottomNavigationSmallDevices.vue')
  }
}
</script>
