<template>
  <v-menu close-on-content-click>
    <template #activator="{ on }">
      <v-icon color="black" v-on="on">
        more_vert
      </v-icon>
    </template>
    <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-list-item ripple @click="onEdit">
        <v-list-item-content class="px-1">
          {{ $t('edit') }}
        </v-list-item-content>
        <v-btn icon small>
          <v-icon small>
            edit
          </v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item ripple @click="onDelete">
        <v-list-item-content class="px-1">
          {{ $t('delete') }}
        </v-list-item-content>
        <v-btn icon small>
          <v-icon small>
            delete
          </v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item ripple @click="onCopy">
        <v-list-item-content class="px-1">
          {{ $t('copy') }}
        </v-list-item-content>
        <v-btn icon small>
          <v-icon small>
            content_copy
          </v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>

import { isRtl } from '@/i18n'
export default {
  setup (_, { emit }) {
    // Methods
    const onEdit = () => emit('edit-click')
    const onDelete = () => emit('delete-click')
    const onCopy = () => emit('copy-click')

    return {
      isRtl,
      onEdit,
      onDelete,
      onCopy
    }
  }
}
</script>
<style lang="scss" scoped>
.edit-operation {
  .v-list-item__icon {
    margin-right: 12px !important;
  }
}
</style>
