<template>
  <v-row align="start" justify="start">
    <v-col
      cols="4" lg="2" sm="3"
      class="py-0"
    >
      <account-department-select
        v-model="filters.accountDepartments"
        class="filter-select"
        :api-key="apiKey"
        show-selection-count
        return-object
        outer-class="filter-select"
        :menu-props="{ offsetY: true }"
        multiple
        @change="onFilterChange"
      />
    </v-col>
    <v-col
      cols="4" lg="2" sm="3"
      class="pps-2 py-0"
    >
      <job-selection-search
        v-model="filters.accountJobs"
        class="filter-select"
        :disabled="isLoading"
        multiple
        return-object
        :api-key="apiKey"
        :menu-props="{ offsetY: true }"
        :enabled-department-ids="enabledDepartmentIds"
        :label="$t('job_op_schedule.choose_job')"
        @change="onFilterChange"
      />
    </v-col>
    <v-col
      cols="4" lg="2" sm="3"
      class="pps-2 py-0"
    >
      <account-job-shift-select
        v-model="filters.shifts" class="filter-select"
        :menu-props="{ offsetY: true }" :multiple="true" :disabled="isLoading"
        @change="onFilterChange"
      />
    </v-col>

    <v-col v-if="showChips" cols="12" class="mt-5">
      <v-chip
        v-for="activeChip in activeChips" :key="activeChip.name" class="color5 t-500-14 mme-2"
        color="#CBD5F2"
        outlined
      >
        {{ activeChip.text }}
        <v-icon size="25" class="pointer" @click="clearTag(activeChip)">
          close
        </v-icon>
      </v-chip>
      <v-chip
        v-if="showResetAll" class="color5 t-500-14 mme-2 pointer" color="#CBD5F2"
        outlined
        @click="onResetFilters"
      >
        {{ $t('reset_all') }}
      </v-chip>
    </v-col>
  </v-row>
</template>
<script>
import JobSelectionSearch from '@/components/job_selection_search/JobSelectionSearch.vue'
import AccountDepartmentSelect from '@/components/shared/account_department/AccountDepartmentSelect.vue'
import AccountJobShiftSelect from '@/components/shared/AccountJobShiftSelect.vue'

import { translateShift } from '@/store/modules/OrganizationJob.js'

import useAccountJob from '@/composables/useAccountJob.js'

import { isObject, isString } from '@/helpers/Utils.js'
import { isRtl } from '@/i18n'

import { computed, onDeactivated } from 'vue'

const SESSION_FILTER_KEY = 'account-jobs-filters-session'

const sessionFilterKey = (apiKey) => `${SESSION_FILTER_KEY}-${apiKey}`

const loadFilterFromLocalCache = (apiKey) => {
  const filter = localStorage.getItem(sessionFilterKey(apiKey))
  if (isString(filter) !== true) return
  try {
    return JSON.parse(filter)
  } catch (e) {
  }
}

const setFilterFromLocalCache = (apiKey, filters) => {
  const filtersFromLocalCache = loadFilterFromLocalCache(apiKey)
  if (isObject(filtersFromLocalCache) !== true) return

  if ('accountJobs' in filtersFromLocalCache) {
    filters.accountJobs = filtersFromLocalCache.accountJobs
  }
  if ('accountDepartments' in filtersFromLocalCache) {
    filters.accountDepartments = filtersFromLocalCache.accountDepartments
  }
  if ('shifts' in filtersFromLocalCache) {
    filters.shifts = filtersFromLocalCache.shifts
  }
}

export default {
  components: {
    'job-selection-search': JobSelectionSearch,
    'account-department-select': AccountDepartmentSelect,
    'account-job-shift-select': AccountJobShiftSelect
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { isLoading, filters, loadTablePage, applyFilters, resetFilters } = useAccountJob(props.apiKey)

    const saveFilterToLocalCache = () => localStorage.setItem(sessionFilterKey(props.apiKey), JSON.stringify(filters.value))

    // Methods

    const onResetFilters = () => {
      resetFilters()
      onFilterChange()
    }

    const onFilterChange = () => {
      saveFilterToLocalCache()
      applyFilters()
    }

    const clearTag = (tag) => {
      const filtersVal = filters.value
      if (tag.type === 'shifts') {
        filtersVal[tag.type] = filtersVal[tag.type].filter((item) => item !== tag.name)
      } else {
        filtersVal[tag.type] = filtersVal[tag.type].filter((item) => item.id !== tag.name)
      }
      onFilterChange()
    }

    // Computed
    const showResetAll = computed(() => activeChips.value.length > 1)
    const showChips = computed(() => activeChips.value.length > 0)
    const anyDepartmentSelected = computed(() => Array.isArray(filters.value.accountDepartments) && filters.value.accountDepartments.length > 0)
    const enabledDepartmentIds = computed(() => {
      if (anyDepartmentSelected.value !== true) return []
      return filters.value.accountDepartments.map((department) => department.id)
    })

    const activeChips = computed(() => {
      const result = []
      const filtersVal = filters.value
      if (Array.isArray(filtersVal.accountDepartments) && filtersVal.accountDepartments.length > 0) {
        filtersVal.accountDepartments.forEach((department) => result.push({
          name: department.id,
          text: department.name,
          type: 'accountDepartments'
        }))
      }
      if (Array.isArray(filtersVal.accountJobs) && filtersVal.accountJobs.length > 0) {
        filtersVal.accountJobs.forEach((job) => result.push({
          name: job.id,
          text: job.name,
          type: 'accountJobs'
        }))
      }
      if (filtersVal.shifts.length > 0) {
        filtersVal.shifts.forEach((shift) => result.push({
          name: shift,
          text:
            translateShift(shift),
          type: 'shifts'
        }))
      }
      return result
    })

    onDeactivated(saveFilterToLocalCache)
    setFilterFromLocalCache(props.apiKey, filters.value)
    loadTablePage()
    return {
      filters,
      isLoading,
      enabledDepartmentIds,
      isRtl,
      activeChips,
      showResetAll,
      showChips,
      onFilterChange,
      clearTag,
      onResetFilters
    }
  }
}
</script>
