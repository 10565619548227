<template>
  <div class="progress-with-avatar">
    <v-img
      :src="userAvatarImageUrl" contain max-height="200"
      :style="{ backgroundColor: imageBackGroundColor }"
    />
    <tasks-progress
      class="progress-wrap px-1 pb-1"
      :is-loading="isLoading"
      :tasks-count="stats.tasksCount"
      :finished-tasks-count="stats.finishedTasksCount"
    />
  </div>
</template>
<script>
import TasksProgress from '@/components/tasks/TasksProgress.vue'

import useDailyJobs from '@/composables/useDailyJobs.js'

import { isString } from '@/helpers/Utils.js'

import useAuth from '@/composables/useAuth.js'

import { computed } from 'vue'

const avatarBackgrounds = {
  'default_user_avatar.png': [116, 146, 200],
  'ic_avatar28.png': [187, 91, 33],
  'ic_avatar14.png': [246, 69, 97],
  'ic_avatar15.png': [255, 120, 83],
  'ic_avatar29.png': [77, 109, 192],
  'ic_avatar17.png': [80, 83, 100],
  'ic_avatar16.png': [239, 90, 57],
  'ic_avatar12.png': [171, 216, 219],
  'ic_avatar13.png': [57, 72, 217],
  'ic_avatar11.png': [246, 172, 233],
  'ic_avatar39.png': [74, 133, 151],
  'ic_avatar38.png': [59, 76, 94],
  'ic_avatar10.png': [58, 211, 154],
  'ic_avatar7.png': [7, 12, 42],
  'ic_avatar6.png': [32, 28, 25],
  'ic_avatar4.png': [208, 55, 50],
  'ic_avatar5.png': [194, 41, 124],
  'ic_avatar1.png': [116, 146, 200],
  'ic_avatar2.png': [70, 82, 32],
  'ic_avatar3.png': [255, 177, 58],
  'ic_avatar41.png': [255, 82, 52],
  'ic_avatar40.png': [255, 123, 108],
  'ic_avatar8.png': [170, 113, 122],
  'ic_avatar9.png': [228, 143, 106],
  'ic_avatar35.png': [93, 144, 85],
  'ic_avatar21.png': [255, 107, 56],
  'ic_avatar20.png': [215, 77, 67],
  'ic_avatar34.png': [21, 132, 90],
  'ic_avatar22.png': [186, 188, 149],
  'ic_avatar36.png': [122, 144, 155],
  'ic_avatar37.png': [131, 183, 161],
  'ic_avatar23.png': [255, 229, 200],
  'ic_avatar27.png': [255, 130, 69],
  'ic_avatar33.png': [104, 146, 254],
  'ic_avatar32.png': [183, 185, 234],
  'ic_avatar26.png': [191, 132, 255],
  'ic_avatar30.png': [118, 104, 253],
  'ic_avatar24.png': [210, 137, 45],
  'ic_avatar18.png': [10, 27, 43],
  'ic_avatar19.png': [246, 98, 60],
  'ic_avatar25.png': [255, 84, 40],
  'ic_avatar31.png': [63, 51, 37]
}

const defaultBackgroundColor = [33, 33, 33]
export default {
  components: {
    'tasks-progress': TasksProgress
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { currentUser, loadCurrentUser } = useAuth()
    const { isLoading, stats } = useDailyJobs(props.apiKey)
    loadCurrentUser()

    const defaultAvatarUrl = new URL('@/assets/default_user_avatar.png', import.meta.url).href
    const userAvatarImageUrl = computed(() => {
      const avatarUrl = currentUser.value?.avatar_url
      if (isString(avatarUrl) && avatarUrl.length > 0) return currentUser.value.avatar_url

      return defaultAvatarUrl
    })

    const imageBackGroundColor = computed(() => {
      const imageName = userAvatarImageUrl.value.split('/').pop()
      let color = null
      if (imageName === defaultAvatarUrl.split('/').pop()) {
        color = avatarBackgrounds['default_user_avatar.png']
      } else {
        color = avatarBackgrounds[imageName] || defaultBackgroundColor
      }
      return `rgb(${color.join(',')})`
    })

    return {
      userAvatarImageUrl,
      imageBackGroundColor,
      isLoading,
      stats
    }
  }
}

</script>
<style lang="scss">
.progress-with-avatar {
  position: relative;
  min-height: 23px;

  .progress-wrap {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
</style>
