<template>
  <div class="video-uploader">
    <video-preview-dialog
      ref="videoPreviewDialog"
      show-delete
      @delete-click="onResetClick"
    />
    <v-icon
      v-if="showIconPreview" color="green"
      @click="previewVideoClick"
    >
      play_circle
    </v-icon>
    <label v-else :for="randomId">
      <v-icon class="font-weight-black pointer" :color="iconColor" :class="{ 'disabled': disabled }"> videocam </v-icon>
      <input
        :id="randomId" type="file" accept="video/mp4,video/x-m4v,video/*"
        :disabled="isDisabled"
        @input="onInput" @change="uploadVideoPreview"
      >
    </label>
    <v-snackbar
      v-model="showSizeError"
      :timeout="10000"
    >
      <div class="text-center">
        {{ $t('video_upload.max_size') }}
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import { isFile } from '@/helpers/Utils.js'

import { ref, computed, watch } from 'vue'
const MAX_VIDEO_SIZE = 100000000 // 100MB

export default {
  components: {
    'video-preview-dialog': () => import('@/components/shared/VideoPreviewDialog.vue')
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: File,
      required: false,
      default: null
    },
    iconColor: {
      type: String,
      required: false,
      default: 'black'
    }
  },
  setup (props, { emit }) {
    const defaultVideo = () => {
      return {
        isLoading: false,
        url: '',
        hasVideo: false,
        originalUploadedFile: null
      }
    }
    const showSizeError = ref(false)
    const video = ref(defaultVideo())
    const videoPreviewDialog = ref(null)

    const randomId = (Math.random() + 1).toString(36).substring(2)

    const releaseVideoMemory = () => {
      const videoVal = video.value
      if (!videoVal.url) return
      URL.revokeObjectURL(videoVal.url)
      videoVal.url = null
    }

    // Methods
    const onInput = (event) => emit('input', event.target.files[0])
    const previewVideoClick = () => videoPreviewDialog.value.openDialog(video.value.url)

    const resetVideo = () => {
      releaseVideoMemory()
      video.value = defaultVideo()
    }

    const onResetClick = () => {
      resetVideo()
      onInput({ target: { files: [] } })
    }

    const uploadVideoPreview = (event) => {
      resetVideo()
      const videoFiles = event.target.files
      if (!Number.isInteger(videoFiles.length) || videoFiles.length === 0) return

      const videoVal = defaultVideo()
      videoVal.isLoading = true
      videoVal.originalUploadedFile = videoFiles[0]
      videoVal.url = URL.createObjectURL(videoFiles[0])
      videoVal.isLoading = false
      videoVal.hasVideo = true
      video.value = videoVal
    }

    // Computed
    const isDisabled = computed(() => props.disabled === true || video.value.isLoading === true)
    const showIconPreview = computed(() => video.value.isLoading || video.value.hasVideo)
    watch(() => props.value, (newValue) => {
      if (!isFile(newValue)) {
        resetVideo()
        return
      }
      if (MAX_VIDEO_SIZE < newValue.size) {
        showSizeError.value = true
        resetVideo()
      }
    })
    return {
      randomId,
      isDisabled,
      showIconPreview,
      videoPreviewDialog,
      showSizeError,
      previewVideoClick,
      uploadVideoPreview,
      onResetClick,
      onInput
    }
  }
}
</script>
<style lang="scss">
.video-uploader {
  input[type="file"] {
    display: none;
  }

  .v-icon.disabled {
    opacity: 0.2;
  }
}
</style>
