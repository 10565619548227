<template>
  <v-snackbar
    v-model="showSnackBar"
  >
    <!--  eslint-disable-next-line vue/no-v-html -->
    <div class="text-center" v-html="snackBarText" />
  </v-snackbar>
</template>
<script>
import { EventBus, APP_CHAT_USER_JOINED_GROUP } from '@/EventBus.js'

import { ref } from 'vue'
import { i18n } from '@/i18n.js'

const showSnackBarDuration = 5000
export default {
  setup () {
    const showSnackBar = ref(false)
    const snackBarText = ref('')

    const resetValues = () => {
      showSnackBar.value = false
      snackBarText.value = ''
    }

    const userJoinedGroup = ({ group_name: groupName = null } = {}) => {
      if (groupName === null) return

      const messageText = i18n.t('user_live_notifications.new_group_html', { groupName })
      showNotification(messageText)
    }

    const showNotification = (text) => {
      showSnackBar.value = true
      snackBarText.value = text
      setTimeout(() => resetValues, showSnackBarDuration)
    }

    EventBus.on(APP_CHAT_USER_JOINED_GROUP, userJoinedGroup)

    return {
      showSnackBar,
      snackBarText
    }
  }
}
</script>
