<template>
  <v-row
    align="center" justify="start" class="audio-rec"
    no-gutters
  >
    <v-col cols="12">
      <v-row
        v-if="isRecording" align="center" justify="start"
        no-gutters
      >
        <v-col class="flex-grow-1 flex-shrink-0 text-h6">
          <v-icon
            class="record-animation"
            color="red"
          >
            fiber_manual_record
          </v-icon>
          {{ recordingText }}
        </v-col>
        <v-col class="flex-grow-0 flex-shrink-1">
          <v-btn icon color="red" @click="stopRecording">
            <v-icon>pause</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-else align="center" justify="space-between"
        no-gutters
      >
        <audio
          controls class="audio-control" :src="audioSrc"
          controlsList="nodownload"
        />
        <v-btn
          icon color="red" class=""
          :disabled="!hasAudio" @click="deleteAudio"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </v-row>
    </v-col>
    <v-snackbar
      v-model="showError"
      :timeout="3000"
    >
      <div class="text-center">
        {{ $t('audio_recorder.permission_denied') }}
      </div>
    </v-snackbar>
  </v-row>
</template>
<script>
import MessageInputState from '@/components/app_chat/message_imput/MessageInputState.js'

import AudioRecorder from '@/classes/AudioRecorder.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed, onMounted, onUnmounted } from 'vue'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { closeAudio, attachedAudio } = new MessageInputState(props.apiKey)
    const audioSrc = ref(null)
    const recorder = new AudioRecorder()
    const isRecording = recorder.getIsRecording
    const showError = ref(false)
    const recordingTimer = ref(0)
    let timerInterval = null
    // Methods

    const deleteAudio = () => {
      if (audioSrc.value !== null) URL.revokeObjectURL(audioSrc.value)

      audioSrc.value = null
      attachedAudio.value = null
      recorder.delete()
      timerInterval = null
      closeAudio()
    }
    const startRecording = () => {
      recorder.start()
        .then(() => {
          timerInterval = setInterval(() => (recordingTimer.value += 1), 1000)
        })
        .catch((err) => {
          if (err.name === 'NotAllowedError') {
            showError.value = true
            return
          }

          errHandler(err)
        })
    }
    const stopRecording = () => {
      clearInterval(timerInterval)
      recorder.stop().then((blob) => {
        audioSrc.value = window.URL.createObjectURL(blob)
        attachedAudio.value = blob
      })
    }

    // Computed
    const hasAudio = computed(() => audioSrc.value !== null)
    onMounted(startRecording)
    onUnmounted(deleteAudio)

    const recordingText = computed(() => {
      const secondElapsed = recordingTimer.value
      let minutes = Math.floor((secondElapsed / 60) % 60)
      minutes = String(minutes).padStart(2, '0')
      let seconds = Math.floor(secondElapsed % 60)
      seconds = String(seconds).padStart(2, '0')
      return `${minutes}:${seconds}`
    })
    return {
      audioSrc,
      isRecording,
      hasAudio,
      showError,
      recordingText,
      startRecording,
      stopRecording,
      deleteAudio
    }
  }
}
</script>
<style scoped lang="scss">
.audio-rec {
  .audio-control {
    max-width: 60vw;
  }
  @keyframes blink {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
  }

  .record-animation {
    animation: blink 1.7s infinite;
  }
}
</style>
