<template>
  <div class="single-image-uploader">
    <operation-image-overlay
      :show-image-overlay="image.showImageOverlay"
      :image-src="image.url"
      @cancel-report="removeImage"
      @hide-image="hideImageOverlay"
    />
    <v-img
      v-if="showImagePreview" max-height="150"
      :src="image.url"
      @click="previewImageClick"
      @load="onImageLoaded"
      @error="onImageLoadError"
    >
      <template #placeholder>
        <v-row
          align="center" justify="center" class="fill-height"
          no-gutters
        >
          <v-progress-circular indeterminate color="primary" />
        </v-row>
      </template>
    </v-img>
    <v-icon
      v-else-if="showIconPreview" color="green"
      @click="previewImageClick"
    >
      add_photo_alternate
    </v-icon>
    <label v-else :for="randomId" :class="{'pointer': !isDisabled}">
      <v-icon class="font-weight-black" color="black" :class="{'disabled': isDisabled}"> add_a_photo </v-icon>
      <input
        :id="randomId" type="file" accept="image/*"
        :disabled="isDisabled" @change="uploadImagePreview"
      >
    </label>
  </div>
</template>
<script>
import OperationImageOverlay from '@/components/shared/OperationImageOverlay.vue'

import { isFile, isString } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'

export default {
  components: {
    'operation-image-overlay': OperationImageOverlay
  },
  props: {
    hideImagePreview: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const defaultImage = () => {
      return {
        isLoading: false,
        url: '',
        hasImage: false,
        showImageOverlay: false,
        hadImage: false
      }
    }
    const image = ref(defaultImage())
    const randomId = (Math.random() + 1).toString(36).substring(2)

    let uploadedImagePreviewUrl = null
    let originalUploadedFile = null

    const releaseImageMemory = () => {
      if (!uploadedImagePreviewUrl) return
      URL.revokeObjectURL(uploadedImagePreviewUrl)
      uploadedImagePreviewUrl = null
    }

    // Methods

    const hideImageOverlay = () => (image.value.showImageOverlay = false)
    const removeImage = () => {
      releaseImageMemory()
      hideImageOverlay()
      const imageVal = image.value
      imageVal.url = ''
      imageVal.hasImage = false
      imageVal.isLoading = false
      originalUploadedFile = null
      emit('image-removed')
    }

    const previewImageClick = () => {
      const imageVal = image.value
      if (imageVal.hasImage !== true) return

      imageVal.showImageOverlay = true
    }

    const onImageLoaded = () => {
      const imageVal = image.value
      imageVal.isLoading = false
      imageVal.hasImage = true
    }

    const onImageLoadError = () => {
      const imageVal = image.value
      imageVal.isLoading = false
      imageVal.hasImage = false
    }

    const uploadImagePreview = (event) => {
      const file = event.target.files[0]
      if (!file) return

      releaseImageMemory()
      const imageVal = image.value
      imageVal.isLoading = true
      uploadedImagePreviewUrl = URL.createObjectURL(file)
      originalUploadedFile = file
      imageVal.url = uploadedImagePreviewUrl
      if (props.hideImagePreview === true) {
        imageVal.isLoading = false
        imageVal.hasImage = true
      }
      emit('image-uploaded')
    }

    const shouldAddImage = () => image.value.hasImage && isFile(originalUploadedFile)

    const shouldRemoveImage = () => {
      const imgaeVal = image.value
      return !imgaeVal.hasImage && imgaeVal.hadImage === true
    }

    const setImageUrl = (imageUrl) => {
      if (!isString(imageUrl) || imageUrl.length === 0) return

      const imgaeVal = image.value
      imgaeVal.hadImage = true
      imgaeVal.hasImage = true
      imgaeVal.url = imageUrl
    }

    const reset = () => {
      if (hasImage.value === true) removeImage()
      image.value = defaultImage()
      originalUploadedFile = null
      releaseImageMemory()
    }

    const getImageFile = () => originalUploadedFile

    // Computed
    const showImagePreview = computed(() => (image.value.isLoading || image.value.hasImage) && !props.hideImagePreview)
    const showIconPreview = computed(() => (image.value.isLoading || image.value.hasImage) && props.hideImagePreview)
    const isDisabled = computed(() => image.value.isLoading === true || props.disabled === true)
    const hasImage = computed(() => {
      const imageVal = image.value
      return imageVal.isLoading || imageVal.hasImage
    })

    return {
      randomId,
      image,
      hasImage,
      showImagePreview,
      showIconPreview,
      isDisabled,
      removeImage,
      previewImageClick,
      onImageLoaded,
      onImageLoadError,
      uploadImagePreview,
      shouldAddImage,
      shouldRemoveImage,
      setImageUrl,
      reset,
      getImageFile,
      hideImageOverlay
    }
  }
}
</script>
<style lang="scss">
.single-image-uploader {
  input[type="file"] {
      display: none;
  }
  .v-icon.disabled{
    opacity: 0.2;
  }
}
</style>
