
import useAccount from '@/composables/useAccount.js'

import { newBrokenItemModel } from '@/store/modules/BrokenItem.js'

import {
  update as BrokenItemClientUpdate,
  destroy as BrokenItemClientDestroy,
  index as BrokenItemClientIndex
} from '@/api_client/BrokenItem.js'

import { deadlineApiFilter } from '@/classes/DeadlineFilter.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { dateToISO8601String } from '@/helpers/DateTime.js'

import { isString, isObject, isDate } from '@/helpers/Utils.js'
import { ref } from 'vue'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 40
const defaultPagination = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, total: null, totalPages: null })

const isLoading = ref(false)
const isListLoading = ref(false)
const isListLoadingNextPage = ref(false)
const pagination = ref(defaultPagination())
const brokenItemsList = ref([])

let onlyBrokenItemAssignedToMe = false
let onlyBrokenItemOpenedByMe = false
let brokenItemStatusFilter = []
let brokenItemPriorityFilter = null
let deadlineDateFilter = null
let openDateStartFilter = null
let openDateEndFilter = null
let closeDateStartFilter = null
let closeDateEndFilter = null
let textSearchFilter = null
let companyProvidersFilter = []

let currentApiKey = null
let currentItemId = null

export default function useBrokenItemDialog ({ apiKey, itemId }) {
  const { accountLocaleCode } = useAccount()
  const locale = accountLocaleCode.value

  const resetValues = () => {
    isLoading.value = false
    isListLoading.value = false
    isListLoadingNextPage.value = false
    pagination.value = defaultPagination()
    brokenItemsList.value = []
    onlyBrokenItemAssignedToMe = false
    onlyBrokenItemOpenedByMe = false
    brokenItemStatusFilter = []
    brokenItemPriorityFilter = null
    deadlineDateFilter = null
    openDateStartFilter = null
    openDateEndFilter = null
    closeDateStartFilter = null
    closeDateEndFilter = null
    textSearchFilter = null
    companyProvidersFilter = []
  }

  if (isString(apiKey) && Number.isInteger(itemId) && (currentApiKey !== apiKey || currentItemId !== itemId)) {
    resetValues()
    currentApiKey = apiKey
    currentItemId = itemId
  }

  const getTotalPages = ({ total, perPage }) => {
    const totalItems = total || 0
    if (totalItems === 0 || totalItems <= perPage) {
      return 0
    }
    return Math.ceil(totalItems / perPage)
  }

  const setPaginationFromResponse = (paginationVal, response) => {
    paginationVal.total = response.total
    paginationVal.page = response.page
    paginationVal.perPage = response.per_page
    paginationVal.totalPages = getTotalPages(paginationVal)
  }

  const addDeadlineDateFilter = (requestParams) => {
    const apiParams = deadlineApiFilter(deadlineDateFilter)
    if (apiParams === null) return
    requestParams = Object.assign(requestParams, apiParams)
  }

  const apiFilterParams = () => {
    const filterParams = {}
    if (onlyBrokenItemAssignedToMe === true) filterParams.assigned_to_me = true
    if (onlyBrokenItemOpenedByMe === true) filterParams.opened_by_me = true
    if (brokenItemStatusFilter.length > 0) filterParams.status = brokenItemStatusFilter
    if (brokenItemPriorityFilter !== null) filterParams.priority = brokenItemPriorityFilter
    if (isString(textSearchFilter) && textSearchFilter.trim().length > 0) filterParams.text_search = textSearchFilter
    if (isDate(openDateStartFilter)) filterParams.open_date = [dateToISO8601String(openDateStartFilter)]
    if (isDate(openDateEndFilter) && isDate(openDateStartFilter)) filterParams.open_date.push(dateToISO8601String(openDateEndFilter))

    if (isDate(closeDateStartFilter)) filterParams.close_date = [dateToISO8601String(closeDateStartFilter)]
    if (isDate(closeDateEndFilter) && isDate(closeDateStartFilter)) filterParams.close_date.push(dateToISO8601String(closeDateEndFilter))
    if (Array.isArray(companyProvidersFilter) && companyProvidersFilter.length > 0) filterParams.company_providers_ids = companyProvidersFilter.map((provider) => provider.id)
    addDeadlineDateFilter(filterParams)
    return filterParams
  }

  const indexParams = () => {
    const requestParams = apiFilterParams()
    const paginationVal = pagination.value
    requestParams.page = paginationVal.page
    requestParams.per_page = paginationVal.perPage
    return requestParams
  }

  const removeItemFromList = (brokenItemId) => {
    const index = brokenItemsList.value.findIndex((brokenItem) => brokenItem.id === brokenItemId)
    if (index !== -1) brokenItemsList.value.splice(index, 1)
  }

  const loadBrokenItems = () => {
    if (pagination.value.page === 1) {
      isListLoading.value = true
    } else {
      isListLoadingNextPage.value = true
    }

    return BrokenItemClientIndex(currentApiKey, currentItemId, indexParams())
      .then(({ data: { data, meta } }) => {
        const newBrokenItems = data.map((brokenItem) => newBrokenItemModel({ src: brokenItem, locale }))
        brokenItemsList.value = brokenItemsList.value.concat(newBrokenItems)

        setPaginationFromResponse(pagination.value, meta)
      })
      .finally(() => {
        isListLoading.value = false
        isListLoadingNextPage.value = false
      })
  }

  const setFilters = (filters) => {
    if (isObject(filters)) {
      onlyBrokenItemAssignedToMe = filters.onlyBrokenItemAssignedToMe === true
      onlyBrokenItemOpenedByMe = filters.onlyBrokenItemOpenedByMe === true
      brokenItemStatusFilter = filters.brokenItemStatusFilter || []
      brokenItemPriorityFilter = filters.brokenItemPriorityFilter || null
      deadlineDateFilter = filters.deadlineDateFilter || null
      openDateStartFilter = filters.openDateStartFilter || null
      openDateEndFilter = filters.openDateEndFilter || null
      closeDateStartFilter = filters.closeDateStartFilter || null
      closeDateEndFilter = filters.closeDateEndFilter || null
      textSearchFilter = filters.textSearchFilter || null
      companyProvidersFilter = filters.companyProvidersFilter || []
    }
  }

  // Methods
  const initPageData = (filters) => {
    resetValues()
    setFilters(filters)
    return loadBrokenItems()
  }

  const changeBrokenItemAfterUpdate = (brokenItemId, updateBrokenItem) => {
    const index = brokenItemsList.value.findIndex((brokenItem) => brokenItem.id === brokenItemId)
    if (index !== -1) brokenItemsList.value[index].update(updateBrokenItem, locale)
  }

  const deleteBrokenItem = (brokenItem) => {
    isLoading.value = true
    return BrokenItemClientDestroy(brokenItem.account_api_key, brokenItem.item_id, brokenItem.id)
      .then(() => removeItemFromList(brokenItem.id))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const setBrokenItemStatusToClose = (brokenItem) => {
    isLoading.value = true
    brokenItem.setClosingTime()
    return BrokenItemClientUpdate(brokenItem.account_api_key, brokenItem.item_id,
      brokenItem.id, { broken_item: brokenItem.serialize() })
      .then(() => removeItemFromList(brokenItem.id))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const loadNextPage = () => {
    if (pagination.value.page < pagination.value.totalPages) {
      pagination.value.page++
      loadBrokenItems()
    }
  }

  return {
    DEFAULT_PER_PAGE,
    isLoading,
    isListLoading,
    pagination,
    brokenItemsList,
    isListLoadingNextPage,
    changeBrokenItemAfterUpdate,
    deleteBrokenItem,
    setBrokenItemStatusToClose,
    loadNextPage,
    initPageData
  }
}
