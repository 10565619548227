import { index, batchUpsert } from '@/api_client/BrokenItemDefaultDeadline.js'
import { isString } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'

const isLoading = ref(false)
let currentApiKey = null
let isLoaded = false

const brokenItemsDeadlineRef = ref([])
export default function useBrokenItemDeadline (apiKey) {
  const resetValues = () => {
    isLoading.value = false
    brokenItemsDeadlineRef.value = []
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  // Methods
  const loadBrokenItemDeadlines = async (forceLoad = false) => {
    if (isLoaded && !forceLoad) return

    isLoading.value = true
    const response = await index(apiKey)
    isLoading.value = false
    brokenItemsDeadlineRef.value = response.data
    isLoaded = true
  }

  const saveBrokenItemDeadlines = async (params) => {
    isLoading.value = true
    isLoaded = false
    const requestParams = { broken_item_deadlines: params }
    return batchUpsert(apiKey, requestParams)
      .finally(() => (isLoading.value = false))
  }
  // Computed
  const brokenItemsDeadline = computed(() => brokenItemsDeadlineRef.value)
  return {
    isLoading,
    brokenItemsDeadline,
    loadBrokenItemDeadlines,
    saveBrokenItemDeadlines
  }
}
