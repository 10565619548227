<template>
  <v-dialog v-model="dialog" max-width="350" persistent>
    <v-card class="chat-dialog" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-5 blue-grey lighten-5">
        <span class="headline full-width">
          {{ $t('chat_dialog.title') }}
        </span>
      </v-card-title>
      <v-row
        v-if="isLoading" align="center" justify="center"
        class="py-10"
      >
        <v-progress-circular indeterminate color="primary" />
      </v-row>
      <v-card-text v-else class="pa-0">
        <v-row align="start" justify="start" no-gutters>
          <v-responsive ref="chatBox" class="overflow-y-auto fill-height px-6 chat-box" height="400">
            <v-row no-gutters>
              <chat-message
                v-for="message in chatMessages"
                :id="`message-${message.id}`"
                :key="message.id"
                :message="message"
                :delete-message-is-loading="deleteMessageIsLoading[message.id]"
                @delete-message="deleteMessage"
              />
            </v-row>
          </v-responsive>
          <v-col cols="12" class="pa-6 blue-grey lighten-5">
            <v-row no-gutters>
              <v-col class="flex-grow-1 flex-shrink-0">
                <v-textarea
                  v-model="currentMessage"
                  class="mt-0 pt-0"
                  counter="350"
                  auto-grow
                  :label="$t('chat_dialog.message_placeholder')"
                  no-resize
                  :disabled="messageIsSending"
                  rows="1"
                />
              </v-col>
              <v-col class="flex-grow-0 flex-shrink-1 mb-6 pps-1">
                <v-btn
                  text small icon
                  class="black--text chat-dialog-send-btn"
                  :loading="messageIsSending"
                  :disabled="sendMessageDisabled"
                  @click="sendMessage"
                >
                  <v-icon>send</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" class="texts-end mt-1">
                <v-btn
                  color="blue darken-1" text :disabled="dialogCloseDisabled"
                  :loading="isDialogCloseLoading"
                  @click="close"
                >
                  {{ $t('close') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import ChatMessage from '@/components/chat_dialog/ChatMessage.vue'

import {
  create as RecordMessageClientCreate,
  index as RecordMessageClientIndex,
  destroy as RecordMessageClientDestroy
} from '@/api_client/RecordMessage.js'

import { create as RecordMessageTrackClientCreate } from '@/api_client/RecordMessageTrack.js'

import useAuth from '@/composables/useAuth.js'
import { newRecordMessageModel } from '@/store/modules/RecordMessage.js'
import { isString, isObject } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { isRtl } from '@/i18n'

import Vue, { ref, nextTick, computed } from 'vue'
const RECORD_TYPES_THAT_NEED_RECORD_TRACK = new Set(['OperationSchedule', 'BrokenItem'])

const validRecordTypes = ['BrokenItem', 'OperationSchedule']
export default {
  components: {
    'chat-message': ChatMessage
  },
  props: {
    recordType: {
      type: String,
      required: true,
      validator: function (value) {
        return validRecordTypes.indexOf(value) !== -1
      }
    }
  },
  setup (props, { emit }) {
    const dialog = ref(false)
    const isLoading = ref(false)
    const currentMessage = ref(null)
    const messageIsSending = ref(false)
    const recordId = ref(null)
    const chatMessages = ref([])
    const isDialogCloseLoading = ref(false)
    const deleteMessageIsLoading = ref(Object.create(null))
    const chatBox = ref(null)
    const { apiKey: ApiKeyRef } = useAuth()

    let apiKey = null
    let messageIdToScroll = null

    const isMessageExist = (messageId) => chatMessages.value.some((message) => message.id === messageId)
    const updateMessageCount = () => emit('update-message-count', { recordId: recordId.value, messageCount: chatMessages.value.length })

    const scrollToChatBottom = () => {
      const element = chatBox.value.$el
      element.scrollTop = element.scrollHeight
    }

    const scrollToChatMessage = (messageId) => {
      const element = chatBox.value.$el
      if (isObject(element) !== true) return

      const message = element.querySelector(`#message-${messageId}`)

      if (message === null) return scrollToChatBottom()

      message.scrollIntoView(true)
    }

    const setScrollPosition = () => {
      if (Number.isInteger(messageIdToScroll) && isMessageExist(messageIdToScroll)) {
        scrollToChatMessage(messageIdToScroll)
      } else {
        scrollToChatBottom()
      }
    }

    const loadStartupData = () => {
      if (!validRecord.value) return
      isLoading.value = true
      const { loadCurrentUser } = useAuth()
      const promises = [
        loadCurrentUser(),
        RecordMessageClientIndex(apiKey, props.recordType, recordId.value)
      ]
      Promise.all(promises)
        .then((responses) => (chatMessages.value = responses[1].data.data.map((message) => newRecordMessageModel(message))))
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
        .then(() => setScrollPosition())
    }

    const removeMessage = (messageId) => {
      const messageIndex = chatMessages.value.findIndex((message) => message.id === messageId)
      if (messageIndex === -1) return

      chatMessages.value.splice(messageIndex, 1)
      updateMessageCount()
    }

    const setDeleteButtonLoading = (isLoading, messageId) => {
      if (messageId in deleteMessageIsLoading.value) {
        deleteMessageIsLoading.value[messageId] = isLoading
      } else {
        Vue.set(deleteMessageIsLoading.value, messageId, isLoading)
      }
    }

    const closeDialog = () => {
      dialog.value = false
      isLoading.value = false
      messageIsSending.value = false
      chatMessages.value = []
      messageIdToScroll = null
      apiKey = null
    }

    const updateMessageReadAll = () => emit('mark-read-all', recordId.value)

    const closeWithRecordTrack = () => {
      isDialogCloseLoading.value = true
      RecordMessageTrackClientCreate(apiKey, props.recordType, recordId.value)
        .catch(errHandler)
        .then(() => updateMessageReadAll())
        .finally(() => {
          closeDialog()
          isDialogCloseLoading.value = false
        })
    }

    const appendMessageToList = (message) => {
      currentMessage.value = null
      chatMessages.value.push(newRecordMessageModel(message))
      updateMessageCount()
      nextTick(scrollToChatBottom)
    }
    // Methods

    const openDialog = ({ recordId: recordIdVal, apiKey: apiKeyVal, messageIdToScroll: messageIdToScrollVal }) => {
      recordId.value = recordIdVal

      apiKey = isString(apiKeyVal) && apiKeyVal.length > 0 ? apiKeyVal : ApiKeyRef.value
      loadStartupData()
      dialog.value = true
      messageIdToScroll = messageIdToScrollVal
      deleteMessageIsLoading.value = Object.create(null)
      currentMessage.value = null
    }

    const sendMessage = () => {
      messageIsSending.value = true
      const requestParams = { record_message: { content: currentMessage.value } }
      RecordMessageClientCreate(apiKey, props.recordType, recordId.value, requestParams)
        .then((response) => appendMessageToList(response.data.data))
        .catch(errHandler)
        .finally(() => (messageIsSending.value = false))
    }

    const deleteMessage = (messageId) => {
      setDeleteButtonLoading(true, messageId)
      RecordMessageClientDestroy(apiKey, props.recordType, recordId.value, messageId)
        .then(() => removeMessage(messageId))
        .catch(errHandler)
        .finally(() => setDeleteButtonLoading(false, messageId))
    }

    const close = () => {
      if (RECORD_TYPES_THAT_NEED_RECORD_TRACK.has(props.recordType)) {
        closeWithRecordTrack()
      } else {
        closeDialog()
      }
    }

    // Computed
    const validRecord = computed(() => Number.isInteger(recordId.value))
    const dialogCloseDisabled = computed(() => isDialogCloseLoading.value === true || messageIsSending.value === true)

    const sendMessageDisabled = computed(() => {
      if (!validRecord.value) return
      if (!isString(currentMessage.value)) return true
      return currentMessage.value.trim().length === 0
    })

    return {
      dialog,
      isLoading,
      currentMessage,
      messageIsSending,
      chatMessages,
      isRtl,
      isDialogCloseLoading,
      deleteMessageIsLoading,
      chatBox,
      sendMessageDisabled,
      dialogCloseDisabled,
      openDialog,
      sendMessage,
      close,
      deleteMessage
    }
  }
}
</script>
<style lang="scss">
.chat-dialog {
  .chat-box {
    scroll-behavior: smooth;
  }

}

.rtl {
  .chat-dialog-send-btn {
    transform: rotate(180deg) !important;
  }
}
</style>
