<template>
  <v-container
    fluid class="system-guidance text-center"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <v-row v-if="isLoading" align="center" justify="center">
      <v-progress-circular indeterminate color="primary" />
    </v-row>
    <v-row v-else align="center" justify="center">
      <v-col cols="12" class="page-title">
        {{ $t('menu_page.help') }}
      </v-col>
      <v-col cols="10" md="7" lg="6">
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(guidance, i) in guidances" :key="i" :disabled="!guidance.link"
          >
            <v-expansion-panel-header>
              {{ guidance.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="guidance.link" class="link-expand">
              <iframe
                :src="guidance.link" width="100%" height="100%"
                frameborder="0"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { index as indexSystemGuidances } from '@/api_client/SystemGuidance.js'

import useSystemGuidanceTrack from '@/composables/useSystemGuidanceTrack.js'

import useAuth from '@/composables/useAuth.js'

import { i18n, isRtl } from '@/i18n.js'

import { ref } from 'vue'
export default {
  setup () {
    const lang = i18n.locale
    const { apiKey } = useAuth()
    const { resetCounter } = useSystemGuidanceTrack(apiKey.value)
    const guidances = ref([])
    const isLoading = ref(true)

    indexSystemGuidances({ lang, apiKey: apiKey.value })
      .then((response) => (guidances.value = response.data))
      .finally(() => (isLoading.value = false))

    resetCounter()
    return {
      guidances,
      isLoading,
      isRtl
    }
  }
}
</script>
<style lang="scss">
.system-guidance {
  margin-top: 140px;

  .link-expand {
    min-height: 60vh;
  }
}
</style>
