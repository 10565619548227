<template>
  <v-col v-if="isLoading" class="flex-grow-1 flex-shrink-0 mb-8 overflow-y-auto">
    <v-skeleton-loader
      v-for="i in 5" :key="i" class="mt-3"
      type="list-item-three-line"
    />
  </v-col>
  <v-col v-else class="flex-grow-1 flex-shrink-0 mb-8 overflow-y-auto">
    <single-job
      v-for="job in activeJobsList"
      :key="job.id"
      class="mb-4"
      :job="job"
      :selected="job.id === activeJobId"
      :staff-account="staffAccount"
      @job-click="onJobClick"
      @toggle-shift-complete="onToggleJobShiftComplete"
    />
    <v-snackbar v-model="shiftCompleteSnakBar" timeout="2000">
      <div class="text-center">
        {{ $t('account_operations.mark_shift_completed') }}
      </div>
    </v-snackbar>
  </v-col>
</template>
<script>
import SingleJob from '@/components/tasks/jobs_list/SingleJob.vue'
import useDailyJobs from '@/composables/useDailyJobs.js'

import { ref } from 'vue'
export default {
  components: {
    'single-job': SingleJob
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    staffAccount: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const shiftCompleteSnakBar = ref(false)
    const { isLoading, activeJobsList, activeJobId, changeActiveJob, toggleJobShiftComplete } = useDailyJobs(props.apiKey)

    // Methods
    const onJobClick = changeActiveJob
    const onToggleJobShiftComplete = (job) => toggleJobShiftComplete(job)
      .then((isShiftCompleted) => {
        if (isShiftCompleted) shiftCompleteSnakBar.value = true
      })
    return {
      isLoading,
      activeJobsList,
      activeJobId,
      shiftCompleteSnakBar,
      onJobClick,
      onToggleJobShiftComplete
    }
  }
}
</script>
