
import { isString } from '@/helpers/Utils.js'

import { ref } from 'vue'

const defaultFilters = () => ({ users: [], accounts: [] })
const filters = ref(defaultFilters())

let currentApiKey = null
export default function useCompanyDriveFile (apiKey) {
  const resetValues = () => {
    filters.value = defaultFilters()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const buildFilter = () => {
    const filter = {}
    const filtersValue = filters.value
    if (filtersValue.users.length > 0) {
      filter.owner_id = filtersValue.users.map(user => user.id)
    }
    if (filtersValue.accounts.length > 0) {
      filter.api_keys = filtersValue.accounts
    }
    return filter
  }

  return {
    filters,
    buildFilter
  }
}
