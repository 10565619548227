<template>
  <div class="calander-job-event texts-start">
    <div class="t-700-14 black--text">
      {{ nameAndShift }}
    </div>
    <div v-if="event.departmentName" class="t-700-14 black--text">
      {{ event.departmentName }}
    </div>
    <v-divider class="my-2 job-divider" />
    <div
      v-for="(operation, index) in event.operationsPreview"
      :key="index" class="description t-400-14 color5"
    >
      {{ operation.nameAndDescription }}
    </div>
    <div v-if="showMore" class="my-2 pointer color1 t-700-16">
      {{ $t('jobs_calander.show_all', { count: allCount }) }}
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const showMore = computed(() => props.event.operationIdsSize > props.event.operationsPreview.length)
    const allCount = computed(() => props.event.operationIdsSize)
    const nameAndShift = computed(() => `${props.event.jobName} - ${props.event.shiftTranslated}`)
    // Methods

    return {
      showMore,
      allCount,
      nameAndShift
    }
  }
}
</script>
<style scoped lang="scss">
.calander-job-event {
  padding: 8px;

  .job-divider {
    background-color: #C9C9C9;
  }

  .colored-title {
    color: black;
    background-color: #F8B195;
  }

  .description {
    width: 95%;

    &::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-bottom: 1px;
      border-radius: 50%;
      background-color: black;
    }

    @media only screen and (max-width: 600px) {
      font-size: 10px;
    }

    @media only screen and (min-width: 600px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
