<template>
  <div>
    <confirm-delete ref="deleteDialog" />
    <image-carousel-dialog ref="imageCarouselDialog" />
    <chat-dialog
      ref="chatDialog"
      record-type="OperationSchedule"
      @update-message-count="updateMessageCount"
      @mark-read-all="markReadAllChatMessages"
    />
    <share-content ref="shareDialog" :share-content="createShareContent" show-dialog />
    <single-task-notification ref="singleTaskNotification" />
    <report-item-operation-list-dialog
      ref="reportItemOperationListDialog"
      :read-only="tasksDisabled"
      :client-date="clientDateIso"
    />
  </div>
</template>
<script>
import ChatDialog from '@/components/chat_dialog/ChatDialog.vue'
import ImageCarouselDialog from '@/components/shared/ImageCarouselDialog.vue'
import ShareContent from '@/components/shared/ShareContent.vue'
import SingleTaskNotification from '@/components/shared/SingleTaskNotification.vue'
import ReportItemOperationListDialog from '@/components/shared/item_operation_list_dialog/ReportItemOperationListDialog.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import useCrossAccountDailyTask from '@/composables/useCrossAccountDailyTask.js'
import { createShareContent as createOperationShareContent } from '@/classes/OperationScheduleShareContent.js'
import { isString } from '@/helpers/Utils.js'

import {
  EventBus, TASK_CHAT_CLICK, TASK_GUIDANCE_IMAGE_CLICK, TASK_SHARE_CLICK,
  TASK_NOTIFICATION_CLICK, TASK_TOGGLE_COMPLETE_CLICK, TASK_IMAGE_CLICK, TASK_DELETE_CLICK,
  NEW_CROSS_ACCOUNT_OPERATION
} from '@/EventBus.js'

import { i18n } from '@/i18n.js'

import { ref, onUnmounted } from 'vue'

let shareTask = null
export default {
  components: {
    'chat-dialog': ChatDialog,
    'confirm-delete': ConfirmDelete,
    'image-carousel-dialog': ImageCarouselDialog,
    'share-content': ShareContent,
    'single-task-notification': SingleTaskNotification,
    'report-item-operation-list-dialog': ReportItemOperationListDialog
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const chatDialog = ref(null)
    const imageCarouselDialog = ref(null)
    const shareDialog = ref(null)
    const singleTaskNotification = ref(null)
    const reportItemOperationListDialog = ref(null)
    const deleteDialog = ref(null)

    const {
      findTask, changeTotalReportedOperations, onSingleOperationDeleted, reloadPageData,
      activeCrossAccountTaskId, clientDateIso, clientDate, tasksDisabled
    } =
      useCrossAccountDailyTask(props.apiKey)

    const showDeleteTaskDialog = (task) => {
      const description = task.operation_description || ''
      const deleteDialogParams = { title: i18n.t('job_operation_edit.delete_operation') }
      if (isString(description) && description.trim().length > 0) {
        deleteDialogParams.content = description
      }
      return deleteDialog.value.open(deleteDialogParams)
    }

    const showTaskReportedImageDialog = (task) => {
      const deleteDialogParams = { content: i18n.t('tasks_page.delete_task_image') }
      return deleteDialog.value.open(deleteDialogParams)
    }
    // Methods
    const showChatDialog = (task) => chatDialog.value.openDialog({
      recordId: task.operationRef.operation_id,
      apiKey: task.operationRef.account_api_key
    })

    const updateMessageCount = ({ recordId, messageCount }) => {
      const task = findTask(recordId)
      if (task !== null) task.updateOperationMessageCount(messageCount)
    }

    const markReadAllChatMessages = (recordId) => {
      const task = findTask(recordId)
      if (task !== null) task.updateOperationReadAllMessages()
    }

    const showTaskGuidanceImages = (task) => {
      const imageUrls = task.guidance_images.map((image) => image.url)
      imageCarouselDialog.value.openDialog({ imageUrls })
    }

    const onShareTask = (task) => {
      const taskRef = task.operationRef
      const pdfShareEnabled = task.tasksList().length > 0

      shareTask = JSON.parse(JSON.stringify(taskRef))
      const title = i18n.t('operation_share.title')
      shareDialog.value.openDialog({ title, pdfShareEnabled })
    }

    const createShareContent = (medium) => {
      return createOperationShareContent({
        operation: shareTask,
        currentDate: clientDate.value,
        apiKey: props.apiKey,
        hideShifts: props.hideShifts,
        medium
      })
    }

    const onCreateTaskNotification = (task) => {
      const taskRef = task.operationRef
      singleTaskNotification.value.openDialog({ operation: taskRef, apiKey: taskRef.account_api_key })
        .then(({ isUpdated, onCompleteTaskNotification = null, onUnCompleteTaskNotification = null }) => {
          if (isUpdated !== true) return

          task.updateOperationNotifications({ onCompleteTaskNotification, onUnCompleteTaskNotification })
        })
    }

    const openSubTaskList = ({ task, crossAccountOperationId }) => {
      const oldIsReported = task.isReported()
      reportItemOperationListDialog.value.openDialogWithReactiveOperation(task)
        .then((isUpdated) => {
          if (!isUpdated) return
          const newIsReported = task.isReported()
          if (oldIsReported === newIsReported) return
          const offset = newIsReported ? 1 : -1
          changeTotalReportedOperations({ crossAccountOperationId, offset })
        })
    }

    const markTaskAsNotComplete = async ({ task, crossAccountOperationId }) => {
      const hasSubTasks = task.tasksList().length > 0
      if (hasSubTasks) return openSubTaskList({ task, crossAccountOperationId })

      if (task.requireImage()) {
        const isDeleted = await showTaskReportedImageDialog(task.operationRef)
        if (!isDeleted) return false
        task.cancelOperationReport()
          .then(() => {
            changeTotalReportedOperations({ crossAccountOperationId, offset: -1 })
            deleteDialog.value.close()
          })
      } else {
        task.cancelOperationReport()
          .then(() => changeTotalReportedOperations({ crossAccountOperationId, offset: -1 }))
      }
    }

    const markTaskAsComplete = ({ task, event, crossAccountOperationId }) => {
      const hasSubTasks = task.tasksList().length > 0
      if (hasSubTasks) return openSubTaskList({ task, crossAccountOperationId })

      if (task.requireImage()) {
        const validFiles = event.target?.files?.length > 0
        if (!validFiles) return true
        const file = event.target.files[0]
        if (!file) return
        task.uploadImageForOperation({ clientDate: clientDate.value, file })
          .then(() => changeTotalReportedOperations({ crossAccountOperationId, offset: 1 }))
      } else {
        task.markOperationAsCompleted(clientDate.value)
          .then(() => changeTotalReportedOperations({ crossAccountOperationId, offset: 1 }))
      }
    }

    const toggleTaskComplete = ({ task, event }) => {
      const crossAccountOperationId = activeCrossAccountTaskId.value
      if (task.isReported()) {
        markTaskAsNotComplete({ task, crossAccountOperationId })
      } else {
        markTaskAsComplete({ task, event, crossAccountOperationId })
      }
    }

    const onImageClick = (task) => {
      const imageUrl = task.operationRef.image_url
      imageCarouselDialog.value.openDialog({ imageUrls: [imageUrl] })
    }

    const onDeleteTask = async (task) => {
      const isDeleted = await showDeleteTaskDialog(task.operationRef)
      if (!isDeleted) return false

      await onSingleOperationDeleted(task)

      deleteDialog.value.close()
      return true
    }

    EventBus.on(TASK_CHAT_CLICK, showChatDialog)
    EventBus.on(TASK_GUIDANCE_IMAGE_CLICK, showTaskGuidanceImages)
    EventBus.on(TASK_SHARE_CLICK, onShareTask)
    EventBus.on(TASK_NOTIFICATION_CLICK, onCreateTaskNotification)
    EventBus.on(TASK_TOGGLE_COMPLETE_CLICK, toggleTaskComplete)
    EventBus.on(TASK_IMAGE_CLICK, onImageClick)
    EventBus.on(TASK_DELETE_CLICK, onDeleteTask)
    EventBus.on(NEW_CROSS_ACCOUNT_OPERATION, reloadPageData)

    onUnmounted(() => {
      EventBus.off(TASK_CHAT_CLICK, showChatDialog)
      EventBus.off(TASK_GUIDANCE_IMAGE_CLICK, showTaskGuidanceImages)
      EventBus.off(TASK_SHARE_CLICK, onShareTask)
      EventBus.off(TASK_NOTIFICATION_CLICK, onCreateTaskNotification)
      EventBus.off(TASK_TOGGLE_COMPLETE_CLICK, toggleTaskComplete)
      EventBus.off(TASK_IMAGE_CLICK, onImageClick)
      EventBus.off(TASK_DELETE_CLICK, onDeleteTask)
      EventBus.off(NEW_CROSS_ACCOUNT_OPERATION, reloadPageData)
    })
    return {
      chatDialog,
      imageCarouselDialog,
      shareDialog,
      singleTaskNotification,
      reportItemOperationListDialog,
      deleteDialog,
      showChatDialog,
      clientDateIso,
      tasksDisabled,
      updateMessageCount,
      markReadAllChatMessages,
      showTaskGuidanceImages,
      createShareContent,
      onShareTask,
      onCreateTaskNotification,
      toggleTaskComplete,
      onImageClick,
      onDeleteTask
    }
  }
}
</script>
