<template>
  <v-dialog v-model="dialog" max-width="350" persistent>
    <v-card class="likes-dialog" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-5 blue-grey lighten-5">
        <span class="headline full-width">
          {{ $t('blog_post.likes') }}
        </span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row
          v-if="isLoading" align="center" justify="center"
          no-gutters
        >
          <v-progress-circular indeterminate color="primary" />
        </v-row>
        <v-row v-else align="start" justify="center">
          <v-col cols="12" class="total mt-4">
            {{ likesTotal }} Likes
            <v-icon color="#E2340D">
              favorite
            </v-icon>
          </v-col>
          <v-col
            v-for="(like, index) in likesList" :key="index" cols="12"
            class="like-list"
          >
            <single-blog-post-avatar :creator="like" />
            <span class="mr-2">{{ like.first_name }} {{ like.last_name }}</span>
          </v-col>
          <v-col cols="12" class="mr-4 mt-5">
            <v-card-actions class="pt-2 pr-2">
              <v-spacer />
              <v-btn color="blue darken-1" text @click="close">
                {{ $t('close') }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import useAuth from '@/composables/useAuth.js'
import useBlogPost from '@/composables/useBlogPost.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { isRtl } from '@/i18n'
import { ref } from 'vue'
export default {
  components: {
    'single-blog-post-avatar': () => import('@/components/blog/shared/SingleBlogPostAvatar.vue')
  },
  setup (_) {
    const dialog = ref(false)
    const isLoading = ref(false)
    const likesTotal = ref(0)
    const likesList = ref([])
    const { apiKey } = useAuth()
    const { loadLikesList } = useBlogPost(apiKey.value)

    const resetValues = () => {
      isLoading.value = false
      likesTotal.value = 0
      likesList.value = []
    }

    // Methods
    const openDialog = (post) => {
      dialog.value = true
      isLoading.value = true
      loadLikesList(post)
        .then(({ data: { data, meta } }) => {
          likesTotal.value = meta.total
          likesList.value = data
        })
        .catch(errHandler)
        .finally((isLoading.value = false))
    }

    const close = () => {
      dialog.value = false
      resetValues()
    }

    return {
      dialog,
      isLoading,
      likesTotal,
      likesList,
      isRtl,
      openDialog,
      close
    }
  }
}
</script>
<style lang="scss">
.likes-dialog {

  .total,
  .like-list {
    font-size: 16px;
  }
}
</style>
