<template>
  <v-row
    v-if="isCompanyAdminRegionOrAbove" align="start" justify="start"
    class="full-height"
  >
    <v-col lg="3" md="4" class="d-flex align-self-stretch pa-0">
      <accounts-list-layout
        :api-key="apiKey"
      />
    </v-col>
    <v-col lg="9" md="8" class="d-flex align-self-stretch">
      <items-list-layout :api-key="apiKey" />
    </v-col>
  </v-row>
  <v-row
    v-else align="start" justify="start"
    class="full-height"
  >
    <v-col cols="12" class="d-flex align-self-stretch">
      <items-list-layout :api-key="apiKey" />
    </v-col>
  </v-row>
</template>

<script>
import AccountListLayout from '@/components/items/account_list/AccountListLayout.vue'
import ItemsListLayout from '@/components/items/item_list/ItemsListLayout.vue'

import useAuth from '@/composables/useAuth.js'
export default {
  components: {
    'accounts-list-layout': AccountListLayout,
    'items-list-layout': ItemsListLayout
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (_, { emit }) {
    const { isCompanyAdminRegionOrAbove } = useAuth()

    return {
      isCompanyAdminRegionOrAbove
    }
  }
}
</script>
