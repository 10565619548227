import Client from '@/api_client/client.js'

const index = (apiKey) => {
  const INDEX_HQ_TASK_JOB_GROUP = `/accounts/${apiKey}/hq_task_job_groups`
  return Client.get(INDEX_HQ_TASK_JOB_GROUP)
}

function create ({ apiKey, name, jobIds }) {
  const CREATE_HQ_TASK_JOB_GROUP = `/accounts/${apiKey}/hq_task_job_groups`
  return Client.post(CREATE_HQ_TASK_JOB_GROUP, { hq_job_group: { name, job_ids: jobIds } })
}

function update ({ apiKey, name, jobIds, id }) {
  const UPDATE_HQ_TASK_JOB_GROUP = `/accounts/${apiKey}/hq_task_job_groups/${id}`
  return Client.put(UPDATE_HQ_TASK_JOB_GROUP, { hq_job_group: { name, job_ids: jobIds } })
}

function destroy ({ apiKey, id }) {
  const DESTROY_HQ_TASK_JOB_GROUP = `/accounts/${apiKey}/hq_task_job_groups/${id}`
  return Client.delete(DESTROY_HQ_TASK_JOB_GROUP)
}

export {
  index,
  create,
  update,
  destroy
}
