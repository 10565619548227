<template>
  <v-row align="center" justify="start">
    <v-img
      v-for="(imageUrl, index) in betterQualityImageUrls" :key="index"
      height="120" width="120" max-width="120"
      max-height="120"
      contain
      class="pointer mme-4 mb-4 rounded-lg grey darken-4"
      :src="imageUrl"
      @click="imageClick(index)"
    >
      <template #placeholder>
        <v-row
          align="center" justify="center" class="fill-height"
          no-gutters
        >
          <v-progress-circular indeterminate color="primary" />
        </v-row>
      </template>
    </v-img>
  </v-row>
</template>
<script>
import { betterImageQuality } from '@/helpers/Utils.js'
import { computed } from 'vue'
export default {
  props: {
    imageUrls: {
      type: Array,
      required: true
    }
  },
  setup (props, { emit }) {
    // Methods
    const imageClick = (index) => emit('image-click', index)

    // Computed

    const betterQualityImageUrls = computed(() => props.imageUrls.map(betterImageQuality))
    return {
      betterQualityImageUrls,
      imageClick
    }
  }
}
</script>
