<template>
  <span>
    <account-department-create-dialog ref="createDepartmentDialog" :api-key="apiKey" />
    <v-autocomplete
      :value="value"
      :items="allDepartmentsOptions"
      :loading="isLoading"
      clearable
      append-icon="search"
      :class="outerClassValue"
      color="black"
      :rules="[isRequired ? requiredField : true]"
      :no-data-text="$t('no_result')"
      item-value="id"
      item-text="name"
      return-object
      :disabled="disabled"
      :multiple="multiple"
      :placeholder="$t('account_department_select.placeholder')"
      :menu-props="menuProps"
      @change="change"
      @click:clear="clear"
      @input="update"
    >
      <template v-if="multiple && showSelectionCount" #selection="{ index }">
        <div v-if="index === 0" class="v-select__selection v-select__selection--comma">
          {{ $t('account_department_select.x_selected', { count: value.length }) }}
        </div>
      </template>
      <template #item="{ item: department, attrs }">
        <v-row
          v-if="!multiple" align="center" justify="start"
          no-gutters
        >
          {{ department.name }}
        </v-row>
        <v-row
          v-else no-gutters justify="start"
          align="center" :class="{ 'rtl': isRtl }"
        >
          <v-col cols="10" class="font-weight-bold">
            {{ department.name }}
          </v-col>
          <v-col cols="2" class="texts-end">
            <v-icon v-if="attrs.inputValue">check_box</v-icon>
            <v-icon v-else>check_box_outline_blank</v-icon>
          </v-col>
        </v-row>
      </template>
      <template v-if="!isValueSelected" #label>
        {{ $t('account_department_select.placeholder') }}
      </template>
      <template v-if="showAddDepartment" #prepend-item>
        <v-list-item
          @click="addNewDepartment"
        >
          <v-list-item-content>
            <v-list-item-title>
              <v-icon color="#0E4AFD" class="mx-2">add_circle_outline</v-icon>
              {{ $t('org_job_new_dialog.add_department') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2" />
      </template>
    </v-autocomplete>
  </span>
</template>
<script>
import AccountDepartmentCreateDialog from '@/components/account_departments/AccountDepartmentCreateDialog.vue'
import AccountDepartmentStore from '@/components/shared/account_department/AccountDepartmentStore.js'

import { isObject, isString } from '@/helpers/Utils.js'

import { requiredField } from '@/classes/InputValidators.js'
import { i18n, isRtl } from '@/i18n.js'

import { ref, computed, watch } from 'vue'
export default {
  components: {
    'account-department-create-dialog': AccountDepartmentCreateDialog
  },
  props: {
    value: {
      type: [Object, Array, Number],
      default: null
    },
    apiKey: {
      type: String,
      required: true
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    addEmptyDepartment: {
      type: Boolean,
      required: false,
      default: false
    },
    showAddDepartment: {
      type: Boolean,
      required: false,
      default: false
    },
    showSelectionCount: {
      type: Boolean,
      required: false,
      default: false
    },
    returnObject: {
      type: Boolean,
      required: false,
      default: false
    },
    outerClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props, { emit }) {
    const createDepartmentDialog = ref(null)
    const getDepartmentId = (department) => {
      if (Number.isInteger(department)) return department
      if (isObject(department)) return department.id
      return department
    }
    // Methods
    const update = (newValue) => emit('input', onChange(newValue))
    const change = (newValue) => emit('change', onChange(newValue))
    const addNewDepartment = () => createDepartmentDialog.value.openDialog()
      .then(({ isCreated, departmentId }) => {
        if (isCreated !== true) return

        loadAllDeparmtnets(true)
          .then(() => {
            const foundDepartment = allDepartmentsOptions.value.find((department) => department.id === departmentId)
            if (isObject(foundDepartment)) update(foundDepartment)
          })
      })

    const onChange = (newValue) => {
      if (props.returnObject === true) return newValue
      if (props.multiple === true) {
        return newValue.map(getDepartmentId)
      } else {
        return getDepartmentId(newValue)
      }
    }
    const clear = () => emit('click:clear')

    const { isLoading, allDepartments, loadAllDeparmtnets, changeApiKey, EMPTY_DEPARTMENT_ID } = AccountDepartmentStore(props.apiKey)

    loadAllDeparmtnets()

    // Computed

    const allDepartmentsOptions = computed(() => {
      if (props.addEmptyDepartment !== true || allDepartments.value.length === 0) return allDepartments.value
      return [{ id: EMPTY_DEPARTMENT_ID, name: i18n.t('total_tasks_by_department.empty_department') }, ...allDepartments.value]
    })
    const isValueSelected = computed(() => {
      const currentVal = props.value
      if (currentVal === null) return false

      if (Number.isInteger(currentVal)) return true

      if (Array.isArray(currentVal) && currentVal.length > 0) return true

      if (isObject(currentVal) && Object.keys(currentVal).length > 0) return true

      return false
    })
    const outerClassValue = computed(() => {
      const outerClass = props.outerClass
      const classRes = isString(outerClass) && outerClass.length > 0
        ? `dep-search ${outerClass}`
        : 'dep-search'
      if (props.isRequired && !isValueSelected.value) {
        return classRes + ' required'
      }
      return classRes
    })
    watch(() => props.apiKey, () => {
      changeApiKey(props.apiKey)
      loadAllDeparmtnets()
    })
    return {
      isLoading,
      allDepartments,
      allDepartmentsOptions,
      isValueSelected,
      createDepartmentDialog,
      addNewDepartment,
      isRtl,
      update,
      change,
      clear,
      requiredField,
      outerClassValue
    }
  }
}
</script>
<style lang="scss">
.dep-search.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
</style>
