import Client from '@/api_client/client.js'

function create (apiKey, accountJobId, params) {
  const OPERATION_SCHEDULE_CREATE_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules`
  return Client.post(OPERATION_SCHEDULE_CREATE_PATH, { operation_schedule: params })
}

function createWithItem (apiKey, accountJobId, params) {
  const OPERATION_SCHEDULE_CREATE_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules/create_with_item`
  return Client.post(OPERATION_SCHEDULE_CREATE_PATH, { operation_schedule: params })
}

function updateWithItem (apiKey, accountJobId, opSchedId, params) {
  const OPERATION_SCHEDULE_UPDATE_WITH_ITEM_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules/${opSchedId}/update_with_item`
  return Client.put(OPERATION_SCHEDULE_UPDATE_WITH_ITEM_PATH, { operation_schedule: params })
}

function update (apiKey, accountJobId, opSchedId, params) {
  const OPERATION_SCHEDULE_UPDATE_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules/${opSchedId}`
  return Client.put(OPERATION_SCHEDULE_UPDATE_PATH, { operation_schedule: params })
}

function destroy (apiKey, accountJobId, opSchedId) {
  const OPERATION_SCHEDULE_DELETE_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules/${opSchedId}`
  return Client.delete(OPERATION_SCHEDULE_DELETE_PATH)
}

function copyOperation ({ apiKey, accountJobId, destJobId, opSchedId, days = null }) {
  const OPERATION_SCHEDULE_COPY_PATH = `/accounts/${apiKey}/account_jobs/${accountJobId}/operation_schedules/${opSchedId}/copy`
  return Client.post(OPERATION_SCHEDULE_COPY_PATH, { days, dest_job_id: destJobId })
}

export {
  create,
  createWithItem,
  updateWithItem,
  update,
  destroy,
  copyOperation
}
