<template>
  <v-snackbar
    v-model="snackbarOpen"
    :timeout="timout"
    class="add-to-home"
    vertical
    :class="{ rtl: isRtl }"
  >
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12" class="text-right">
        <v-btn small icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center">
        {{ $t('install_prompt.chrome') }}
      </v-col>
      <v-col cols="12" class="text-center btm">
        <v-btn
          color="pink"
          text
          class="ml-0 mr-1"
          @click="addToHomeScreen"
        >
          {{ $t('add') }}
        </v-btn>
        <v-btn
          color="pink"
          class="mr-0 ml-1"
          text
          @click="close"
        >
          {{ $t('close') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>
<script>
import { isRtl } from '@/i18n.js'
import { ref } from 'vue'
export default {
  setup () {
    const snackbarOpen = ref(false)
    let deferredPrompt = null
    const timout = ref(-1)

    // Methods
    const close = () => {
      deferredPrompt = null
      snackbarOpen.value = false
    }

    const addToHomeScreen = () => {
      deferredPrompt.prompt()
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        snackbarOpen.value = false
        deferredPrompt = null
      })
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault()
      // Stash the event so it can be triggered later.
      deferredPrompt = e
      // Update UI to notify the user they can add to home screen
      snackbarOpen.value = true
    })
    return {
      snackbarOpen,
      timout,
      isRtl,
      close,
      addToHomeScreen
    }
  }
}
</script>
<style lang="scss">
.add-to-home {
  .v-snack__wrapper {
    min-width: 250px;
    max-width: 250px;
    .btm {
      .v-btn {
        margin-top: 10px !important;
      }
    }
  }
}
</style>
