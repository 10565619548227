<template>
  <v-tooltip :disabled="!showAccountsToolTip" bottom>
    <template #activator="{ on }">
      <span v-on="on">
        <v-icon color="rgba(0, 0, 0, 0.54)" class="material-icons-outlined">
          group
        </v-icon>
        <span class="t-500-13 color5 pps-1">
          {{ accountsText }}
        </span>
      </span>
    </template>
    <div v-for="account in allAccounts" :key="account.id">
      {{ account.name }}
    </div>
  </v-tooltip>
</template>
<script>

import { i18n } from '@/i18n.js'

import { computed } from 'vue'
export default {
  props: {
    resource: {
      type: Object,
      required: true
    },
    showToolTip: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const resourceAccounts = computed(() => Array.isArray(props.resource.accounts) ? props.resource.accounts : [])
    const accountsText = computed(() => {
      const belongsToAllAccounts = props.resource.belongs_to_all_accounts
      if (belongsToAllAccounts) return i18n.t('drive.all_accounts')

      const accounts = resourceAccounts.value
      const totalAccounts = Number.isInteger(props.resource.total_accounts)
        ? props.resource.total_accounts
        : accounts.length

      const accountName = accounts[0].name
      if (accounts.length === 1 && totalAccounts === 1) return accountName
      return `${accountName} +${totalAccounts - 1}`
    })

    const showAccountsToolTip = computed(() => props.showToolTip &&
      !props.resource.belongs_to_all_accounts && resourceAccounts.value.length > 1)

    const allAccounts = computed(() => {
      if (props.resource.belongs_to_all_accounts) return []
      return resourceAccounts.value.map((account, index) => ({ id: index, name: account.name }))
    })
    return {
      showAccountsToolTip,
      accountsText,
      allAccounts
    }
  }
}
</script>
