import { dailyJobs as dailyJobsForManagers, indexOperationsByDay as indexAccountOperationsByDay, dailyStats as accountDailyStats } from '@/api_client/AccountOperationSchedule.js'
import { dailyJobs as dailyJobsForEmployees, indexOperationsByDay as indexEmployeeOperationsByDay } from '@/api_client/EmployeeOperationSchedule.js'

import {
  create as createAccountJobCompletedShift,
  destroy as destroyAccountJobCompletedShift
} from '@/api_client/AccountJobCompletedShift.js'
import { totalTasksPerDepartment as accountDepartmentotalTasksPerDepartment } from '@/api_client/AccountDepartment.js'
import AccountDepartmentStore from '@/components/shared/account_department/AccountDepartmentStore.js'

import { getShiftDateTimes } from '@/store/modules/WeeklySchedule.js'

import { isObject, isString } from '@/helpers/Utils.js'
import {
  dateToISO8601String, isDateInTheFuture, isDateIsToday, isDateInThePast,
  timeStringToUserFormat
} from '@/helpers/DateTime.js'

import useAccount from '@/composables/useAccount.js'
import useAuth from '@/composables/useAuth.js'
import UseOperationSchedule from '@/composables/UseOperationSchedule.js'

import { i18n } from '@/i18n.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { computed, ref } from 'vue'

const DEFAULT_PER_PAGE = 100
const DEFAULT_PAGE = 1
const EMPTY_DEPARTMENT_ID = 'empty_department'

const defaultPagination = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, total: null, totalPages: null })
const defaultStats = () => ({ tasksCount: 0, finishedTasksCount: 0 })
const isLoading = ref(false)
const isLoadingTasks = ref(false)
const isLoadingTasksNextPage = ref(false)
const jobsPagination = ref(defaultPagination())
const tasksPagination = ref(defaultPagination())
const departmentsPagination = ref(defaultPagination())
const jobsList = ref([])
const departmentsList = ref([])
const stats = ref(defaultStats())
const clientDate = ref(new Date())
const activeJobId = ref(null)
const activeDepartmentName = ref(null)
const hasDepartments = ref(false)
const uiModes = { jobs: 'jobs', departments: 'departments' }
const uiMode = ref(uiModes.jobs)

const jobsInShift = new Set()
let currentApiKey = null
let isCurrentUserEmployee = null
let isCurrentUserAccountAdminOrAbove = null
let lastFilters = {}

export default function useDailyJobs (apiKey) {
  const resetValues = () => {
    isLoading.value = false
    isLoadingTasks.value = false
    isLoadingTasksNextPage.value = false
    jobsList.value = []
    departmentsList.value = []
    jobsPagination.value = defaultPagination()
    tasksPagination.value = defaultPagination()
    departmentsPagination.value = defaultPagination()
    activeJobId.value = null
    activeDepartmentName.value = null
    hasDepartments.value = false
    stats.value = defaultStats()
    uiMode.value = uiModes.jobs
    lastFilters = {}
    jobsInShift.clear()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const getTotalPages = ({ total, perPage }) => {
    const totalItems = total || 0
    if (totalItems === 0 || totalItems <= perPage) {
      return 0
    }
    return Math.ceil(totalItems / perPage)
  }

  const jobExists = (jobId) => jobsList.value.some((job) => job.id === jobId)

  const setPaginationFromResponse = ({ meta, paginationVal }) => {
    paginationVal.total = meta.total
    paginationVal.page = meta.page
    paginationVal.perPage = meta.per_page
    paginationVal.totalPages = getTotalPages(paginationVal)
  }

  const commonRequestParams = ({ paginationVal } = {}) => {
    const clientDateVal = clientDate.value
    const params = {
      client_date: dateToISO8601String(clientDateVal),
      client_date_is_today: isDateIsToday(clientDateVal),
      client_date_is_future: isDateInTheFuture(clientDateVal)
    }
    if (isObject(lastFilters)) params.filters = lastFilters
    if (isObject(paginationVal)) {
      params.page = paginationVal.page
      params.per_page = paginationVal.perPage
    }
    return params
  }

  const requestParamsForTasks = () => {
    const params = commonRequestParams({ paginationVal: tasksPagination.value })
    params.with_jobs_data = false
    return params
  }

  const formatJobsResponse = (response) => {
    const { accountLocaleCode } = useAccount()
    const localeCode = accountLocaleCode.value
    response.forEach((job) => {
      job.shiftStartTimeUserFormat = timeStringToUserFormat({ timeString: job.shift_start_time, localeCode })
      job.shiftEndTimeUserFormat = timeStringToUserFormat({ timeString: job.shift_end_time, localeCode })
      job.toggleShiftLoading = false
      job.tasks = []
    })
  }

  const formatDepartmentsResponse = (response) => {
    response.forEach((department) => {
      if (department.department_id === null) {
        department.department_name = i18n.t('total_tasks_by_department.empty_department')
        department.department_id = EMPTY_DEPARTMENT_ID
      }
    })
  }

  const addTaskFormat = (task) => {
    task.account_api_key = currentApiKey
  }

  const addTasksForJobs = (tasks) => {
    const tasksByJobId = tasks.reduce((acc, task) => {
      addTaskFormat(task)
      const key = task.account_job_id
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(task)
      return acc
    }, {})
    jobsList.value.forEach((job) => {
      const tasksForJob = tasksByJobId[job.id] || []
      tasksForJob.forEach((task) => (task.shift_id = job.shift_id))
      const currentTaskIds = new Set(job.tasks.map((task) => task.operationRef.operation_id))
      const filteredTasks = tasksForJob.filter((task) => !currentTaskIds.has(task.operation_id))
      job.tasks = job.tasks.concat(filteredTasks.map((task) => new UseOperationSchedule(task)))
    })
  }

  const dailyStatsRequest = async () => accountDailyStats(currentApiKey, commonRequestParams())
  const dailyJobsRequest = async () => {
    const requestParams = commonRequestParams({ paginationVal: jobsPagination.value })
    return isCurrentUserEmployee
      ? dailyJobsForEmployees(currentApiKey, requestParams)
      : dailyJobsForManagers(currentApiKey, requestParams)
  }

  const dailyDepartmentsRequest = () =>
    accountDepartmentotalTasksPerDepartment(currentApiKey, commonRequestParams({ paginationVal: departmentsPagination.value }))

  const dailyTasksRequest = async (requestParams) =>
    isCurrentUserEmployee
      ? indexEmployeeOperationsByDay(currentApiKey, requestParams)
      : indexAccountOperationsByDay(currentApiKey, requestParams)

  const setTasksStats = (response) => {
    if (!isObject(response)) return

    const { tasks_count: taskCount = 0, finished_tasks_count: finishedTasksCount = 0 } = response
    stats.value.tasksCount = taskCount
    stats.value.finishedTasksCount = finishedTasksCount
  }

  const loadJobsTasks = async ({ jobs, showLoading = true }) => {
    const jobIds = jobs.map((job) => job.id)
    if (jobIds.length === 0) return

    if (showLoading) isLoadingTasks.value = true
    const requestParams = requestParamsForTasks()
    return dailyTasksRequest(requestParams)
      .then((response) => addTasksForJobs(response.data.data))
      .catch(errHandler)
      .finally(() => (isLoadingTasks.value = false))
  }

  const checkIfHaveDepartments = async () => {
    if (isCurrentUserEmployee) return
    const { isAccountDepartmentWithJobExists } = AccountDepartmentStore(currentApiKey)

    return isAccountDepartmentWithJobExists()
      .then((response) => (hasDepartments.value = response))
  }

  // Methods
  const changeApiKey = (apiKey) => {
    currentApiKey = apiKey
    lastFilters = {}
    const showDepartmentsUiMode = uiMode.value = uiMode.value === uiModes.departments
    initPageData({ clientDateInitial: clientDate.value, showDepartmentsUiMode })
  }

  const applyFilters = ({ filters } = {}) => {
    if (isObject(filters)) lastFilters = filters
    if (uiMode.value === uiModes.jobs) return loadDailyJobs({ reset: true }).then(setFirstJobAsActive)

    return loadDailyDepartments({ reset: true })
  }

  const loadDailyJobs = async ({ reset = false, showLoading = true, withStats = true } = {}) => {
    if (showLoading) isLoading.value = true
    const requests = [dailyJobsRequest()]
    if (withStats) requests.push(dailyStatsRequest())

    return Promise.all(requests).then((responses) => {
      const jobsResponse = responses[0]
      if (withStats) {
        const statsResponse = responses[1]
        setTasksStats(statsResponse.data.data)
      }
      const { data: jobs, meta } = jobsResponse.data
      formatJobsResponse(jobs)
      loadJobsTasks({ jobs, showLoading })

      if (reset === true) jobsList.value = []
      jobsList.value = jobsList.value.concat(jobs)
      setPaginationFromResponse({ meta, paginationVal: jobsPagination.value })
    })
      .finally(() => (isLoading.value = false))
      .catch(errHandler)
  }

  const loadDailyDepartments = async ({ reset = false, showLoading = true }) => {
    return dailyDepartmentsRequest()
      .then((response) => {
        formatDepartmentsResponse(response.data)
        departmentsList.value = response.data
        const departmentIds = departmentsList.value.map((department) => department.department_id)
        if (departmentIds.length === 0) return
        return loadDailyJobs({ reset, showLoading })
      })
  }

  const setFirstJobAsActive = () => {
    if (activeJobsList.value.length > 0) {
      activeJobId.value = activeJobsList.value[0].id
    }
  }

  const toggleUiMode = () => {
    if (uiMode.value === uiModes.jobs) {
      uiMode.value = uiModes.departments
      activeJobId.value = null
    } else {
      uiMode.value = uiModes.jobs
      activeDepartmentName.value = null
    }

    if (uiMode.value === uiModes.jobs) return loadDailyJobs({ reset: true }).then(setFirstJobAsActive)

    return loadDailyDepartments({ reset: true })
  }

  const changeActiveJob = (job) => (activeJobId.value = job.id)

  const changeActiveDepartment = (department) => {
    activeDepartmentName.value = department.department_id === EMPTY_DEPARTMENT_ID ? EMPTY_DEPARTMENT_ID : department.department_name
    setFirstJobAsActive()
  }
  const unSelectDepartment = () => (activeDepartmentName.value = null)

  const findJob = (jobId) => jobsList.value.find((job) => job.id === jobId)

  const findTask = (taskId) => {
    for (const job of jobsList.value) {
      for (const task of job.tasks) {
        if (task.operationRef.operation_id === taskId) {
          return task
        }
      }
    }
    return null
  }

  const deleteTask = async (task) => {
    const isReported = task.isReported()
    await task.deleteOperation()
    const jobId = task.operationRef.account_job_id
    const job = findJob(jobId)
    if (!job) return
    const taskId = task.operationRef.operation_id
    const taskIndex = job.tasks.findIndex((row) => row.operationRef.operation_id === taskId)
    if (taskIndex === -1) return

    job.tasks.splice(taskIndex, 1)
    if (job.total_tasks > 0) job.total_tasks -= 1
    if (isReported && job.total_tasks_reported > 0) job.total_tasks_reported -= 1

    if (stats.value.tasksCount > 0) stats.value.tasksCount -= 1
    if (isReported && stats.value.finishedTasksCount > 0) stats.value.finishedTasksCount -= 1

    if (uiMode.value !== uiModes.departments) return

    const activeDepartment = findActiveDepartment()
    if (!isObject(activeDepartment)) return

    if (activeDepartment.total_tasks > 0) activeDepartment.total_tasks -= 1
    if (isReported && activeDepartment.total_reported > 0) activeDepartment.total_reported -= 1
  }

  const reloadJobsAndTasks = async (jobId) => {
    await loadDailyJobs({ reset: true, showLoading: false })

    if (jobExists(jobId) !== true) setFirstJobAsActive()
  }

  const changeJobCompleteTask = ({ jobId, offset }) => {
    const job = findJob(jobId)
    if (!job) return

    if (offset === -1 && job.total_tasks_reported > 0 && job.total_tasks > 0) {
      job.total_tasks_reported -= 1
    } else if (offset === 1 && job.total_tasks > job.total_tasks_reported && job.total_tasks > 0) {
      job.total_tasks_reported += 1
    }
  }

  const findActiveDepartment = () => departmentsList.value.find((department) => {
    if (activeDepartmentName.value === EMPTY_DEPARTMENT_ID) {
      return department.department_id === EMPTY_DEPARTMENT_ID
    }
    return department.department_name === activeDepartmentName.value
  })

  const changeDepartmentStat = ({ task, offset }) => {
    if (uiMode.value !== uiModes.departments) return
    const activeDepartment = findActiveDepartment()
    if (!isObject(activeDepartment)) return
    if (offset === -1 && activeDepartment.total_reported > 0 && activeDepartment.total_tasks > 0) {
      activeDepartment.total_reported -= 1
    } else if (offset === 1 && activeDepartment.total_tasks > activeDepartment.total_reported && activeDepartment.total_tasks > 0) {
      activeDepartment.total_reported += 1
    }
  }

  const changeStatsCompleteTask = (offset) => {
    const totalTasksReported = stats.value.finishedTasksCount
    const totalTasks = stats.value.tasksCount
    if (offset === -1 && totalTasksReported > 0 && totalTasks > 0) {
      stats.value.finishedTasksCount -= 1
    } else if (offset === 1 && totalTasks > totalTasksReported && totalTasks > 0) {
      stats.value.finishedTasksCount += 1
    }
  }

  const afterTaskMarkUnComplete = (task) => {
    const jobId = task.operationRef.account_job_id
    changeJobCompleteTask({ jobId, offset: -1 })
    changeDepartmentStat({ task, offset: -1 })
    changeStatsCompleteTask(-1)
  }

  const afterTaskMarkComplete = (task) => {
    const jobId = task.operationRef.account_job_id
    changeJobCompleteTask({ jobId, offset: 1 })
    changeDepartmentStat({ task, offset: 1 })
    changeStatsCompleteTask(1)
  }

  const jobShiftTimes = (jobId) => {
    const activeJob = findJob(jobId)
    if (!activeJob) return false

    return getShiftDateTimes({
      startTime: activeJob.shift_start_time,
      endTime: activeJob.shift_start_time,
      shiftSpanTwoDays: activeJob.shift_is_two_days,
      date: clientDate.value
    })
  }

  const isShiftStartTimeInFuture = (jobId) => {
    const jobShiftTime = jobShiftTimes(jobId)
    if (!isObject(jobShiftTime)) return false

    const currentTime = new Date()
    return currentTime.getTime() < jobShiftTime.startTimeDate.getTime()
  }

  const allowToggleTaskComplete = (task) => {
    const jobId = task.operationRef.account_job_id
    return jobsInShift.has(jobId) || !isShiftStartTimeInFuture(jobId)
  }

  const toggleJobShiftComplete = (job) => {
    job.toggleShiftLoading = true
    const newShiftComplete = !job.shift_mark_complete

    const requestParams = { apiKey: currentApiKey, shiftId: job.shift_id, clientDate: clientDateIso.value }
    const request = newShiftComplete === true ? createAccountJobCompletedShift : destroyAccountJobCompletedShift
    return request(requestParams)
      .then(() => {
        job.shift_mark_complete = newShiftComplete
        return newShiftComplete
      })
      .finally(() => (job.toggleShiftLoading = false))
  }

  const changeDate = async (newDate) => {
    clientDate.value = newDate
    applyFilters(lastFilters)
  }

  const loadJobForCalendar = async ({ jobId, date }) => {
    clientDate.value = date
    const lastFilterCopy = { ...lastFilters }
    lastFilters = { account_job_ids: [jobId] }
    await loadDailyJobs({ reset: true, withStats: false })
    lastFilters = lastFilterCopy
    setFirstJobAsActive()
  }

  const initPageData = async ({ clientDateInitial, showDepartmentsUiMode = false }) => {
    clientDate.value = clientDateInitial
    resetValues()
    const { isUserAccountEmployee, isAccountAdminOrAbove } = useAuth()
    isCurrentUserEmployee = isUserAccountEmployee.value
    isCurrentUserAccountAdminOrAbove = isAccountAdminOrAbove.value
    await checkIfHaveDepartments()
    if (showDepartmentsUiMode === true && hasDepartments.value === true) {
      uiMode.value = uiModes.departments
      return loadDailyDepartments({ reset: true })
    } else {
      await loadDailyJobs()
      setFirstJobAsActive()
    }
  }

  // Computed
  const jobsUiMode = computed(() => uiMode.value === uiModes.jobs)
  const activeDepartmentComputed = computed(() => findActiveDepartment())
  const activeJob = computed(() => findJob(activeJobId.value))
  const departmentSelectedUiMode = computed(() => uiMode.value === uiModes.departments &&
    isString(activeDepartmentName.value))

  const activeJobsList = computed(() => uiMode.value === uiModes.departments ? activeDepartmentJobs.value : jobsList.value)

  const activeJobTasks = computed(() => {
    if (!Number.isInteger(activeJobId.value)) return []

    const activeJob = findJob(activeJobId.value)
    return activeJob ? activeJob.tasks : []
  })

  const activeDepartmentJobs = computed(() => {
    if (!isString(activeDepartmentName.value)) return []

    if (activeDepartmentName.value === EMPTY_DEPARTMENT_ID) {
      return jobsList.value.filter((job) => !isString(job.department_name))
    }
    return jobsList.value.filter((job) => job.department_name === activeDepartmentName.value)
  })

  const clientDateIso = computed(() => dateToISO8601String(clientDate.value))

  const tasksDisabled = computed(() => {
    if (dateToISO8601String(new Date()) === clientDateIso.value) return false
    const previousDay = new Date()
    previousDay.setDate(previousDay.getDate() - 1)
    if (dateToISO8601String(previousDay) === clientDateIso.value) return false
    if (isCurrentUserAccountAdminOrAbove && isDateInThePast(clientDate.value)) return false
    return true
  })

  const loadActiveJobTasksNextPage = () => {
    const job = findJob(activeJobId.value)
    if (!isObject(job)) return

    const sholdLoadTasks = job.total_tasks > job.tasks.length
    if (!sholdLoadTasks) return

    isLoadingTasksNextPage.value = true
    const lastFilterJobIds = lastFilters.account_job_ids
    lastFilters.account_job_ids = job.id
    const nextPage = getNextPage(job.tasks.length)
    const requestParams = commonRequestParams({ paginationVal: { page: nextPage, perPage: DEFAULT_PER_PAGE } })
    requestParams.with_jobs_data = false

    return dailyTasksRequest(requestParams)
      .then((response) => {
        const newTasks = response.data.data
        const currentTaskIds = new Set(job.tasks.map((task) => task.operationRef.operation_id))
        const filteredTasks = newTasks.filter((task) => !currentTaskIds.has(task.operation_id))
        filteredTasks.forEach((task) => {
          task.shift_id = job.shift_id
          addTaskFormat(task)
        })

        job.tasks = job.tasks.concat(filteredTasks.map((task) => new UseOperationSchedule(task)))
      })
      .catch(errHandler)
      .finally(() => {
        isLoadingTasksNextPage.value = false
        lastFilters.account_job_ids = lastFilterJobIds
      })
  }

  const getNextPage = (currentItemsLoaded) => {
    const nextPage = Math.ceil(currentItemsLoaded / DEFAULT_PER_PAGE)
    return currentItemsLoaded % DEFAULT_PER_PAGE === 0 ? nextPage + 1 : nextPage
  }

  return {
    isLoading,
    isLoadingTasks,
    jobsList,
    activeJobTasks,
    activeJobId,
    clientDate,
    jobsInShift,
    tasksDisabled,
    clientDateIso,
    hasDepartments,
    stats,
    uiMode,
    uiModes,
    jobsUiMode,
    departmentSelectedUiMode,
    activeDepartmentComputed,
    departmentsList,
    activeJobsList,
    activeJob,
    applyFilters,
    changeActiveDepartment,
    resetValues,
    initPageData,
    changeActiveJob,
    findTask,
    deleteTask,
    reloadJobsAndTasks,
    findJob,
    afterTaskMarkComplete,
    afterTaskMarkUnComplete,
    allowToggleTaskComplete,
    toggleJobShiftComplete,
    toggleUiMode,
    changeApiKey,
    changeDate,
    loadJobForCalendar,
    unSelectDepartment,
    loadActiveJobTasksNextPage
  }
}
