<template>
  <v-select
    :value="value"
    clearable
    :items="featureOptions"
    item-text="translatedName"
    item-value="name"
    multiple
    :label="$t('feature_select.enable_features')"
    @input="update"
  />
</template>

<script>
import { i18n } from '@/i18n.js'

const features = {
  brokenItems: {
    create: 'broken_item_create',
    update: 'broken_item_update',
    close: 'broken_item_close',
    destroy: 'broken_item_delete'
  },
  brokenItemHistory: {
    read: 'broken_item_history_read',
    edit: 'broken_item_update',
    delete: 'broken_item_delete'
  }
}
export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const featureOptions = [
      { header: i18n.t('feature_select.broken_items') },
      { name: features.brokenItems.create, translatedName: i18n.t('feature_select.create') },
      { name: features.brokenItems.update, translatedName: i18n.t('feature_select.edit') },
      { name: features.brokenItems.close, translatedName: i18n.t('feature_select.close') },
      { name: features.brokenItems.destroy, translatedName: i18n.t('feature_select.delete') },
      { header: i18n.t('feature_select.broken_item_history') },
      { name: features.brokenItemHistory.read, translatedName: i18n.t('feature_select.read') }
    ]

    // Methods

    const update = (value) => emit('input', value)

    return {
      featureOptions,
      update
    }
  }
}
</script>
