<template>
  <v-container fluid class="tasks-layout full-height pa-0">
    <confirm-delete ref="deleteDialog" />
    <schedule-new-staff-operation-dialog ref="scheduleNewStaffOperation" />
    <cross-account-task-actions :api-key="apiKey" />
    <cross-account-tasks-layout-big-devices
      v-if="$vuetify.breakpoint.smAndUp"
      :api-key="apiKey"
      @edit-cross-account-task-click="onEditCrossAccountTaskClick"
      @delete-cross-account-task-click="onDeleteCrossAccountTaskClick"
      @copy-cross-account-task-click="onCopyCrossAccountTaskClick"
      @task-click="onTaskClick"
    />
    <cross-account-tasks-layout-small-devices
      v-else
      :api-key="apiKey"
      @edit-cross-account-task-click="onEditCrossAccountTaskClick"
      @delete-cross-account-task-click="onDeleteCrossAccountTaskClick"
      @copy-cross-account-task-click="onCopyCrossAccountTaskClick"
      @task-click="onTaskClick"
    />
    <v-btn
      color="black" class="add-task"
      dark
      small
      fab
      @click="addTask"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import CrossAccountTasksLayoutBigDevices from '@/components/corss_account_tasks/layout/CrossAccountTasksLayoutBigDevices.vue'
import CrossAccountTasksLayoutSmallDevices from '@/components/corss_account_tasks/layout/CrossAccountTasksLayoutSmallDevices.vue'
import ScheduleNewStaffOperationDialog from '@/components/operation_schedule_dialog/ScheduleNewStaffOperationDialog.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import CrossAccountTaskActions from '@/components/corss_account_tasks/CrossAccountTaskActions.vue'

import { dateToISO8601String } from '@/helpers/DateTime.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n } from '@/i18n.js'

import useCrossAccountDailyTask from '@/composables/useCrossAccountDailyTask.js'
import useAuth from '@/composables/useAuth.js'

import { EventBus, TASK_EDIT_CLICK, TASK_COPY_CLICK } from '@/EventBus.js'

import { ref, watch, onUnmounted } from 'vue'
export default {
  components: {
    'cross-account-tasks-layout-big-devices': CrossAccountTasksLayoutBigDevices,
    'cross-account-tasks-layout-small-devices': CrossAccountTasksLayoutSmallDevices,
    'schedule-new-staff-operation-dialog': ScheduleNewStaffOperationDialog,
    'confirm-delete': ConfirmDelete,
    'cross-account-task-actions': CrossAccountTaskActions
  },
  props: {
    clientDate: {
      type: Date,
      required: true
    }
  },
  setup (props) {
    const scheduleNewStaffOperation = ref(null)
    const deleteDialog = ref(null)

    const { apiKey } = useAuth()
    const { deleteCrossAccountOperation, onCrossAccountClick, getActiveCrossAccountTask, onDateChange, clientDate } = useCrossAccountDailyTask(apiKey.value)
    clientDate.value = props.clientDate

    // Methods
    const addTask = () => scheduleNewStaffOperation.value.openCrossAccountCreate()
    const onTaskClick = onCrossAccountClick

    const onEditClick = () => {
      const task = getActiveCrossAccountTask()
      return onEditCrossAccountTaskClick(task)
    }

    const onCopyClick = () => {
      const task = getActiveCrossAccountTask()
      return onCopyCrossAccountTaskClick(task)
    }

    const onEditCrossAccountTaskClick = (operation) =>
      scheduleNewStaffOperation.value.openCrossAccountUpdate(operation, apiKey.value)

    const onDeleteCrossAccountTaskClick = (task) => {
      const title = i18n.t('cross_account_page.delete_cross_operation')
      const content = i18n.t('cross_account_page.delete_cross_operation_detail')
      deleteDialog.value.open({ title, content })
        .then((shouldDelete) => {
          if (!shouldDelete) return
          deleteCrossAccountOperation(task.cross_account_operation_id)
            .then(() => deleteDialog.value.close())
            .catch(errHandler)
        })
    }

    const onCopyCrossAccountTaskClick = (task) =>
      scheduleNewStaffOperation.value.openCrossAccountCopy(task, apiKey.value)

    EventBus.on(TASK_EDIT_CLICK, onEditClick)
    EventBus.on(TASK_COPY_CLICK, onCopyClick)

    watch(() => props.clientDate, (newDate) => {
      if (newDate === null) return
      if (dateToISO8601String(newDate) === dateToISO8601String(clientDate.value)) return

      onDateChange(newDate)
    })

    onUnmounted(() => {
      EventBus.off(TASK_EDIT_CLICK, onEditClick)
      EventBus.off(TASK_COPY_CLICK, onCopyClick)
    })
    return {
      scheduleNewStaffOperation,
      deleteDialog,
      apiKey,
      onEditCrossAccountTaskClick,
      onDeleteCrossAccountTaskClick,
      onCopyCrossAccountTaskClick,
      addTask,
      onTaskClick,
      onEditClick
    }
  }
}
</script>
<style lang="scss">
.add-task {
  position: fixed;
  z-index: 4;
  bottom: 86px;
  right: 10px;

  @media only screen and (max-width: 600px) {
    bottom: 80px;
  }
}
</style>
