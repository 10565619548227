<template>
  <v-container fluid class="org-structure-providers" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
    <new-company-provider-dialog ref="providerDialog" />
    <confirm-delete ref="deleteDialog" />
    <v-btn
      v-if="isAccountAdminOrAbove" fab small
      color="black white--text"
      class="add-button"
      @click="createNewProvider"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-row align="center" justify="space-around">
      <v-col cols="12" class="page-title">
        {{ $t("org_structure_providers.page_title") }}
      </v-col>
      <v-col cols="11" lg="8">
        <v-row align="start" justify="start" no-gutters>
          <v-col cols="4" class="pb-0">
            <company-provider-filter :api-key="apiKey" />
          </v-col>
          <v-col cols="12" class="mt-2 ml-2 font-weight-bold">
            <span>
              {{ $t("org_structure_providers.total_providers") }}
            </span>
            <span class="blue--text">
              {{ totalProviders }}
            </span>
          </v-col>
          <v-col cols="12" class="mt-2">
            <v-data-table
              :items="providersTableList"
              :items-per-page="DEFAULT_PER_PAGE"
              hide-default-header
              class="elevation-1"
              :loading="isLoading"
              :loading-text="$t('loading')"
              hide-default-footer
              item-key="id"
            >
              <template #item="{ item }">
                <company-providers-tab-row
                  :provider="item"
                  :read-only="!isAccountAdminOrAbove"
                  @edit-provider="editProvider"
                  @delete-provider="onDeleteProvider"
                />
              </template>
              <template #no-data>
                <v-alert :value="true" color="white black--text">
                  {{ $t("no_result") }}
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
          <v-col v-if="totalPages > 0" cols="12" class="text-center">
            <v-pagination
              v-model="pagination.page" circle total-visible="10"
              :length="totalPages"
              @input="pageChange"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import CompanyProvidersTabRow from '@/components/company_providers/CompanyProvidersTabRow.vue'
import NewCompanyProviderDialog from '@/components/company_providers/NewCompanyProviderDialog.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import CompanyProviderFilter from '@/components/company_providers/CompanyProviderFilter.vue'

import useCompanyProvider from '@/composables/useCompanyProvider.js'
import useAuth from '@/composables/useAuth.js'

import { scrollToTop } from '@/classes/TopScroll.js'

import { isRtl } from '@/i18n.js'

import { ref } from 'vue'
export default {
  components: {
    'company-providers-tab-row': CompanyProvidersTabRow,
    'new-company-provider-dialog': NewCompanyProviderDialog,
    'confirm-delete': ConfirmDelete,
    'company-provider-filter': CompanyProviderFilter
  },
  setup () {
    const providerDialog = ref(null)
    const deleteDialog = ref(null)
    const { apiKey, isAccountAdminOrAbove } = useAuth()
    const {
      isLoading, pagination, totalProviders, providersTableList, totalPages, DEFAULT_PER_PAGE,
      loadTablePage, deleteProvider, providerSearchChange, clearSearch
    } = useCompanyProvider(apiKey.value)

    // Methods

    const createNewProvider = () => {
      providerDialog.value.openForCreate().then((providerCreated) => {
        if (providerCreated) {
          loadTablePage()
        }
      })
    }

    const editProvider = (data) => {
      providerDialog.value.openForUpdate(data.provider).then((providerUpdated) => {
        if (providerUpdated) {
          loadTablePage()
        }
      })
    }

    const onDeleteProvider = (data) => {
      deleteDialog.value.open()
        .then((shouldDelete) => {
          if (!shouldDelete) return
          deleteProvider(data?.provider?.id)
            .finally(() => deleteDialog.value.close())
        })
    }

    const pageChange = () => {
      loadTablePage()
      scrollToTop()
    }

    loadTablePage()
    return {
      apiKey: apiKey.value,
      totalProviders,
      pagination,
      totalPages,
      DEFAULT_PER_PAGE,
      isLoading,
      isAccountAdminOrAbove,
      providersTableList,
      providerDialog,
      deleteDialog,
      isRtl,
      createNewProvider,
      editProvider,
      onDeleteProvider,
      pageChange,
      providerSearchChange,
      clearSearch
    }
  }
}
</script>
<style lang="scss">
.org-structure-providers {
  margin-top: 116px;

  .v-data-table__wrapper {
    overflow-x: hidden;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 136px;
  }
}
</style>
