import axios from 'axios'

const IMAGE_FLAGS = 'f_auto,q_auto,dpr_auto,c_limit,w_800,h_800'
function uploadImage (file, params) {
  const formData = new FormData()
  formData.append('file', file)
  for (const key in params) {
    formData.append(key, params[key])
  }
  return axios.post(import.meta.env.VITE_APP_CLOUDINARY_IMAGE_UPLOAD_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then((response) => {
    return `${import.meta.env.VITE_APP_CLOUDINARY_IMAGE_SHOW_URL}${IMAGE_FLAGS}/${response.data.public_id}`
  })
}

export {
  uploadImage
}
