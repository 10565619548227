import { totalCompanyTasks } from '@/api_client/CompanyStats.js'
import { companyCount as companyFulatyDeviceTotalClient } from '@/api_client/AccountFaultyDevice.js'
import { getClientDate, isDateInTheFuture, isDateIsToday } from '@/helpers/DateTime.js'

import { ref } from 'vue'

let currentApiKey = null
const stats = ref({
  totalTasks: 0,
  totalTasksDone: 0,
  totalHqTasks: 0,
  totalHqTasksDone: 0,
  totalBrokenItems: 0
})

export default function useCompany (apiKey) {
  currentApiKey = apiKey

  const loadTasksStats = async (clientDate) => {
    const requestParams = {
      client_date: getClientDate(clientDate),
      client_date_is_today: isDateIsToday(clientDate),
      client_date_is_future: isDateInTheFuture(clientDate)
    }
    return totalCompanyTasks(currentApiKey, requestParams).then(({ data: { data } }) => {
      const statValue = stats.value
      statValue.totalTasks = data.tasks_count
      statValue.totalTasksDone = data.finished_tasks_count
      statValue.totalHqTasks = data.cross_account_tasks_count
      statValue.totalHqTasksDone = data.finished_cross_account_tasks_count
    })
  }

  const loadTotalBrokenItem = async () => companyFulatyDeviceTotalClient(currentApiKey)
    .then(response => (stats.value.totalBrokenItems = response.data.data.total))

  // Methods
  const loadAllStats = async (clientDate) => Promise.all([loadTasksStats(clientDate), loadTotalBrokenItem()])

  return {
    stats,
    loadTasksStats,
    loadAllStats
  }
}
