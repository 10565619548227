<template>
  <login-layout
    show-back-button
    @back-click="onBackClick"
  >
    <template #main-part>
      <v-col cols="12" class="text-center t-400-32 white--text pt-0">
        {{ $t('verify_code.enter_code') }}
      </v-col>
      <v-col cols="12" class="text-center t-500-13 color3">
        {{ $t('verify_code.your_phone', { phone: phone }) }}
      </v-col>
      <v-col
        cols="12" lg="5" md="6"
        sm="8"
      >
        <v-otp-input
          v-model="smsCode"
          class="ltr"
          type="number"
          :length="CODE_LENGTH"
        />
        <div v-if="invalidCodeMessage" class="t-400-12 color14 text-center">
          <v-icon color="#FF0040">
            error
          </v-icon>
          {{ $t('verify_code.wrong_code') }}
        </div>
      </v-col>
      <v-col v-if="hasEmail" cols="12" class="text-center t-400-12 color3">
        <span v-if="showMagicLink">
          {{ $t('verify_code.didnt_recieve') }}
          <a @click="sendMagicLink">{{ $t('verify_code.didnt_recieve_1') }}</a>
        </span>
        <v-progress-circular
          v-else-if="isLoadingSendEmailLink" indeterminate
          color="primary"
        />
        <span v-else-if="showMagicLinkSent">{{ $t('verify_code.check_email') }}</span>
      </v-col>
      <v-col cols="12" class="pa-0" />
    </template>
    <template #buttons>
      <v-btn
        class="mb-4 login-btn"
        :disabled="loginDisabled"
        :loading="isLoading"
        depressed
        block
        color="#0040FF"
        @click="login"
      >
        {{ $t('next') }}
      </v-btn>
    </template>
  </login-layout>
</template>
<script>
import LoginLayout from '@/components/login/LoginLayout.vue'

import { sendEmailLink as AuthClientSendEmailLink } from '@/api_client/auth.js'

import { isValidPhone } from '@/helpers/Utils.js'

import { ref, computed, onBeforeMount } from 'vue'

import { currentLanguage } from '@/composables/useLanguage.js'
import useAuth from '@/composables/useAuth.js'

import { useRoute, useRouter } from 'vue-router/composables'

const CODE_LENGTH = 6
export default {
  components: {
    'login-layout': LoginLayout
  },
  setup () {
    const imageLogoPath = new URL('@/assets/dex_logo_white.svg', import.meta.url).href
    const smsCode = ref('')
    const isLoading = ref(false)
    const isLoadingSendEmailLink = ref(false)
    const isEmailLinkSent = ref(false)
    const invalidCodeMessage = ref(false)
    const router = useRouter()

    const { phone, hasEmail } = useRoute().params
    const { loginUser, loadLoginData, afterLogin } = useAuth()

    // Methods
    const login = () => {
      isLoading.value = true
      loginUser({ phone, verify_code: smsCode.value })
        .then(() => Promise.all([loadLoginData(), afterLogin()]))
        .catch(() => {
          smsCode.value = ''
          invalidCodeMessage.value = true
        })
        .finally(() => (isLoading.value = false))
    }
    const sendMagicLink = () => {
      const language = currentLanguage.value
      isLoadingSendEmailLink.value = true
      AuthClientSendEmailLink({ phone, language })
        .then(() => (isEmailLinkSent.value = true))
        .finally(() => (isLoadingSendEmailLink.value = false))
    }

    const onBackClick = () => router.push({ name: 'login' })
    // Computed
    const loginDisabled = computed(() => smsCode.value.length !== CODE_LENGTH)
    const showMagicLink = computed(() => hasEmail && !isEmailLinkSent.value && !isLoadingSendEmailLink.value)
    const showMagicLinkSent = computed(() => hasEmail && isEmailLinkSent.value && !isLoadingSendEmailLink.value)

    const loadCred = () => {
      if (!navigator.credentials) return
      const ac = new AbortController()
      navigator.credentials.get({
        otp: { transport: ['sms'] },
        signal: ac.signal
      }).then((otp) => {
        smsCode.value = otp.code
        login()
      })
    }

    onBeforeMount(() => {
      if (!isValidPhone(phone)) {
        router.push({ name: 'login' })
      }
    })
    loadCred()

    return {
      CODE_LENGTH,
      imageLogoPath,
      smsCode,
      isSaveDisabled: true,
      isLoading,
      hasEmail,
      phone,
      showMagicLink,
      isLoadingSendEmailLink,
      isEmailLinkSent,
      showMagicLinkSent,
      invalidCodeMessage,
      loginDisabled,
      login,
      sendMagicLink,
      onBackClick
    }
  }
}
</script>
